import { FormFieldTypes } from "../../FormFields";

export const READ_ONLY_TEXT = "Read Only Text";

export const columnTypeNamesEnum = {
  READ_ONLY_TEXT: READ_ONLY_TEXT,
  TEXT: "Text",
  TEXT_AREA: "Text Area",
  NUMBER: "Number",
  SINGLE_CHOICE: "Single Choice",
  MULTIPLE_CHOICE: "Multiple Choice",
  PDF_FILE: "PDF File",
};

export const columnTypesToFormFields = {
  [READ_ONLY_TEXT]: FormFieldTypes.TEXT_AREA,
  Text: FormFieldTypes.TEXT,
  "Text Area": FormFieldTypes.TEXT_AREA,
  Number: FormFieldTypes.NUMBER,
  "Single Choice": FormFieldTypes.STRING_SELECT,
  // "Multiple Choice": FormFieldTypes.MULTI_STRING_SELECT,
  "PDF File": FormFieldTypes.PDF_FILE,
};
