import React, { useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Card,
  Tab,
  Tabs,
} from "react-bootstrap";
import { AppBarChart } from "../../../utils/components/AppCharts";
import PaginatedEmployeesDisplay from "../Employee/PaginatedEmployeesDisplay";
import { NoInfoCard } from "../../../utils/components";
import { updateArrayState } from "../../../utils/functions";

function ReviewPeriodFormStatsView({
  periodForm,
  periodFormStats,
  performanceForm,
  loaderCallback,
  getRequest,
}) {
  const columnTotals = periodFormStats?.column_calculations?.totals || [];
  const columnAverages = periodFormStats?.column_calculations?.averages || [];
  const rangesTotals = periodFormStats.column_ranges || {};
  const singleChoiceStats = periodFormStats.single_choice_stats || {};

  const [shownRangeEmployees, setShownRangeEmployees] = useState([]);

  if (
    columnTotals.length === 0 &&
    Object.keys(singleChoiceStats).length === 0
  ) {
    return (
      <NoInfoCard
        text={
          "No stats are available for this period form yet. You will see stats when employees start submitting their reviews."
        }
      />
    );
  } else {
    return (
      <div>
        <Tabs defaultActiveKey={"number-columns"} id={"stats-tabs"}>
          <Tab title={"Number Columns Stats"} eventKey={"number-columns"}>
            <Card body={true} className={"mb-3 pt-4"}>
              <h4>Number Columns Totals</h4>
              <p>
                The bar chart below displays totals for each number column in
                the form
              </p>
              <div className={"mt-5"} />
              <AppBarChart data={columnTotals} xName={"Total"} />
            </Card>

            <Card body={true} className={"mb-5 pt-4"}>
              <h4>Number Columns Averages</h4>
              <p>
                The bar chart below displays averages for each number column in
                the form
              </p>
              <div className={"mt-5"} />
              <AppBarChart data={columnAverages} xName={"Average"} />
            </Card>

            <div className={"mb-5"}>
              <h4>Number Column Ranges</h4>
              <p>
                Below are all the ranges for each number column. Click on a
                range to view the employees that fall within it.
              </p>
            </div>

            {columnTotals.map((item, index) => {
              const columnName = item.column_title;
              const columnRanges = rangesTotals[columnName] || [];

              return (
                <div key={index} className={"mb-5"}>
                  <h5 className={"text-center my-4"}>{columnName}</h5>
                  {columnRanges.map((range, i) => {
                    const rangeKey = `${columnName}-${range.name}`;

                    return (
                      <Accordion key={i} className={"mb-3"}>
                        <AccordionHeader
                          onClick={() => {
                            if (!shownRangeEmployees.includes(rangeKey)) {
                              updateArrayState(setShownRangeEmployees, [
                                rangeKey,
                              ]);
                            }
                          }}
                        >
                          {range.name} | {range.start} - {range.end} |{" "}
                          {range.count} Employee{range.count === 1 ? "" : "s"}
                        </AccordionHeader>
                        <AccordionBody>
                          {range.count === 0 && (
                            <NoInfoCard text={"No employees in this range"} />
                          )}
                          {range.count > 0 &&
                            shownRangeEmployees.includes(rangeKey) && (
                              <PaginatedEmployeesDisplay
                                endpoint={`/performance/period-form/${periodForm.id}/column/${range.column_id}/${range.start}/${range.end}/employees`}
                                getRequest={getRequest}
                                minimal={true}
                                extraColumns={[
                                  {
                                    columnName: "Total",
                                    key: "total_value",
                                  },
                                ]}
                              />
                            )}
                        </AccordionBody>
                      </Accordion>
                    );
                  })}
                </div>
              );
            })}
          </Tab>
          <Tab title={"Single Choice Column Stats"} eventKey={"single-choice"}>
            <Card body={true} className={"mb-5 pt-4"}>
              <h4>Single Choice Column Stats</h4>
              <p>
                The bar charts below display totals for each single choice
                column and row combination in the form
              </p>
            </Card>
            <div className={"mt-5"} />
            {Object.entries(singleChoiceStats).map(
              ([rowKey, rowValue], index) => {
                return (
                  <div key={index} className={"mb-5"}>
                    <p className={"fs-5"}>
                      <strong>Choice Description:</strong>{" "}
                      <span>{rowValue.description}</span>
                    </p>
                    {Object.entries(rowValue.columns).map(
                      ([columnName, columnStats], i) => {
                        return (
                          <div key={i} className={"mb-5"}>
                            <p className={"mb-5"}>
                              <strong>Column Name:</strong>{" "}
                              <span className={"fs-4"}>{columnName}</span>
                            </p>
                            <AppBarChart data={columnStats} xName={"Total"} />
                          </div>
                        );
                      }
                    )}
                  </div>
                );
              }
            )}

            {Object.entries(singleChoiceStats).length === 0 && (
              <NoInfoCard
                text={
                  "No single choice stats available or this form does not have any Single Choice Columns"
                }
              />
            )}
          </Tab>
        </Tabs>
      </div>
    );
  }
}

export default ReviewPeriodFormStatsView;
