import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../utils/components/AppContext";
import { useNavigate, useParams } from "react-router-dom";
import DashboardPageContainer from "./components/DashboardPageContainer";
import { LoadingView } from "../utils/components";
import { EmployeeLargeCard } from "./components/EmployeeCards";
import { Card } from "react-bootstrap";
import { TextWithLineBreaks } from "../utils/components";
import EmployeePerformanceForm from "./Forms/Performance/EmployeePerformanceForm";

function EmployeePerformancePeriodFormReviews({
  getRequest,
  postRequest,
  putRequest,
  patchRequest,
}) {
  const { contextState, updateContextState } = useContext(AppContext);
  const { permissions } = contextState;

  const navigate = useNavigate();

  const { periodFormId, employeeId } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const [otherReviews, setOtherReviews] = useState([]);
  const [revieweeEmployee, setRevieweeEmployee] = useState({});
  const [otherFormData, setOtherFormData] = useState({});
  const [reviewData, setReviewData] = useState({});
  const [reviewer, setReviewer] = useState({});
  const [form, setForm] = useState({});
  const [period, setPeriod] = useState({});
  const [formTableData, setFormTableData] = useState({});
  const [step, setStep] = useState({});

  useEffect(() => {
    getRequest(
      `/performance/period-form/${periodFormId}/employee/${employeeId}`,
      setIsLoading,
      (response) => {
        setForm(response.form);
        setPeriod(response.period);
        setReviewer(response.reviewer);
        setRevieweeEmployee(response.reviewee_employee);
        setReviewData(response.reviewer.form_data);
        setOtherReviews(response.other_reviews);
        setFormTableData(response.form_table_data);
      }
    );
  }, []);
  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <div>
          <h2 className={"text-center my-4"}>Viewing</h2>
          <EmployeeLargeCard employee={revieweeEmployee} />

          <Card className={"mt-4"}>
            <Card.Header className={"py-3"}>
              <Card.Title className={"text-center"}>
                <span className={"text-muted"}>Review Period</span>
                <br />
                <br />
                {period.name}
              </Card.Title>
            </Card.Header>
            <Card.Body className={"py-3"}>
              <TextWithLineBreaks text={period.description} />
            </Card.Body>
          </Card>

          <Card className={"mt-4"}>
            <Card.Header className={"py-3 text-center"}>
              <Card.Title className={""}>Reviews Submitted</Card.Title>
              <Card.Subtitle>
                Below are the reviews that have been submitted for this employee
              </Card.Subtitle>
            </Card.Header>
            <Card.Body className={"py-3"}>
              <Card.Title>{form.name}</Card.Title>
              <Card.Subtitle as={"p"} className={"mb-4 mt-2"}>
                {form.description}
              </Card.Subtitle>
              {form.id && (
                <EmployeePerformanceForm
                  rows={form.rows}
                  columns={form.columns}
                  formData={reviewData}
                  setFormData={setReviewData}
                  writeColumns={reviewer.write_columns}
                  reviewer={reviewer}
                  step={step}
                  otherData={otherFormData}
                  otherReviews={otherReviews}
                  setOtherData={setOtherFormData}
                  formTableData={formTableData}
                  onSubmit={null}
                  readOnly={true}
                />
              )}
            </Card.Body>
          </Card>
        </div>
      }
    />
  );
}

function EmployeePerformancePeriodFormReviewsPage() {
  return (
    <DashboardPageContainer
      PageComponent={EmployeePerformancePeriodFormReviews}
    />
  );
}

export default EmployeePerformancePeriodFormReviewsPage;
