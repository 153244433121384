import React, { useContext } from "react";
import { Button, Card, Table } from "react-bootstrap";
import EmployeeHighlightCard from "./EmployeeHighlightCard";
import {
  dateFormats,
  formatDate,
  hrsMinsSecsDiff,
} from "../../../utils/dateTime";
import { StatusBadge } from "../../../utils/components";
import { secsToHrsMinsSecs, useAppModal } from "../../../utils/functions";
import { FaExternalLinkAlt, FaMapMarkerAlt } from "react-icons/fa";
import { appConfigs } from "../../../configs";
import { AppContext } from "../../../utils/components/AppContext";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../utils/appRoutes";
import EmployeeBasicHighlight from "../Shared/EmployeeBasicHighlight";

function ScheduleAttendanceTable({ schedules }) {
  const dateTimeFormat = dateFormats.daySingleDigitDMY12Hr;
  const { showAppModal } = useAppModal();
  const { contextState } = useContext(AppContext);
  const { permissions } = contextState;

  const displayMap = (latitude, longitude, title) => {
    showAppModal({
      title: title,
      hideFooter: true,
      size: "xl",
      component: (
        <iframe
          width="100%"
          height="600"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          src={`https://www.google.com/maps/embed/v1/place?key=${appConfigs.googleMapsApiKey}&q=${latitude},${longitude}`}
        />
      ),
    });
  };

  return (
    <div>
      <Table striped bordered responsive hover>
        <thead>
          <tr className={"fs-5 text-center align-middle"}>
            <th></th>
            <th>Employee</th>
            <th>Scheduled Time</th>
            <th>Activity</th>
            <th>Duration</th>
          </tr>
        </thead>
        <tbody>
          {schedules.map((schedule, index) => {
            const attendances =
              schedule.attendances.length > 0 ? schedule.attendances : null;
            return (
              <tr key={schedule.id} className={"text-center align-middle"}>
                <td>{index + 1}</td>
                <td>
                  <EmployeeBasicHighlight employee={schedule.employee} />
                  {permissions.VIEW_EMPLOYEE_DATA && (
                    <Button
                      variant={"link"}
                      href={`${AppRoutes.Employee.paramLessPath}${schedule.employee.id}`}
                      target={"_blank"}
                      className={"mt-3"}
                    >
                      View Profile <FaExternalLinkAlt className={"ms-2"} />
                    </Button>
                  )}
                </td>
                <td>
                  {formatDate(schedule.start_datetime, dateTimeFormat)}
                  <br />
                  {formatDate(schedule.end_datetime, dateTimeFormat)}
                  <br />
                  {hrsMinsSecsDiff(
                    schedule.start_datetime,
                    schedule.end_datetime,
                    false,
                    true
                  )}
                </td>
                <td className={"py-4"}>
                  {attendances ? (
                    <>
                      {attendances.map((attendance, j) => {
                        return (
                          <Card
                            body={true}
                            key={j}
                            className={`${
                              attendances.length - 1 > j ? "mb-3" : ""
                            } py-3`}
                          >
                            <div className={"d-flex justify-content-between"}>
                              <div>
                                <h6 className={"text-center"}>Clock In</h6>
                                {formatDate(
                                  attendance.clockin_time,
                                  dateTimeFormat
                                )}
                                <br />
                                {attendance.clockin_latitude &&
                                attendance.clockin_longitude ? (
                                  <Button
                                    className={"mt-3"}
                                    variant={"outline-primary"}
                                    size={"sm"}
                                    onClick={() =>
                                      displayMap(
                                        attendance.clockin_latitude,
                                        attendance.clockin_longitude,
                                        "Clock In Location"
                                      )
                                    }
                                  >
                                    <FaMapMarkerAlt className={"me-2"} /> View
                                    Location
                                  </Button>
                                ) : (
                                  "No Location Data"
                                )}
                              </div>
                              <div>
                                <h6 className={"text-center"}>Clock Out</h6>
                                {formatDate(
                                  attendance.clockout_time,
                                  dateTimeFormat
                                )}
                                <br />
                                {attendance.clockout_latitude &&
                                attendance.clockout_longitude ? (
                                  <Button
                                    className={"mt-3"}
                                    variant={"outline-primary"}
                                    size={"sm"}
                                    onClick={() =>
                                      displayMap(
                                        attendance.clockout_latitude,
                                        attendance.clockout_longitude,
                                        "Clock Out Location"
                                      )
                                    }
                                  >
                                    <FaMapMarkerAlt className={"me-2"} /> View
                                    Location
                                  </Button>
                                ) : (
                                  "No Location Data"
                                )}
                              </div>
                              <div>
                                <h6 className={"text-center"}>Status</h6>
                                <StatusBadge
                                  status={attendance.approval_status}
                                />
                              </div>
                            </div>
                          </Card>
                        );
                      })}
                    </>
                  ) : (
                    "No Attendance Activity"
                  )}
                </td>
                <td>{secsToHrsMinsSecs(schedule.attended_seconds)}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

export default ScheduleAttendanceTable;
