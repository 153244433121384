import React, { useEffect, useState } from "react";
import Pagination from "react-bootstrap/Pagination";

function PaginationComponent({ pagingInfo, onPageChange }) {
  const [paginationItems, setPaginationItems] = useState([]);
  const pages = pagingInfo.total_pages;
  const currentPage = pagingInfo.current_page;

  useEffect(() => {
    const newPaginationItems = [];
    let leftSide = currentPage - 2;
    let rightSide = currentPage + 2;

    if (currentPage > pages - 2) {
      leftSide -= 4 - (pages - currentPage);
    }

    if (currentPage < 3) {
      rightSide += 4 - currentPage;
    }

    for (let number = 1; number <= pages; number++) {
      if (
        number === 1 ||
        number === pages ||
        (number >= leftSide && number <= rightSide)
      ) {
        newPaginationItems.push(
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={() => {
              if (number === currentPage) return;
              onPageChange(number);
            }}
          >
            {number}
          </Pagination.Item>
        );
      } else if (number === leftSide - 1 || number === rightSide + 1) {
        newPaginationItems.push(<Pagination.Ellipsis key={number} />);
      }
    }

    setPaginationItems(newPaginationItems);
  }, [pages, currentPage, onPageChange]);

  return (
    <div className={"d-flex flex-row"}>
      <h5 className={"me-4 my-auto"}>{pagingInfo.range_info}</h5>
      <Pagination>
        <Pagination.First onClick={() => onPageChange(1)} />
        <Pagination.Prev
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        />
        {paginationItems}
        <Pagination.Next
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === pages}
        />
        <Pagination.Last onClick={() => onPageChange(pages)} />
      </Pagination>
    </div>
  );
}

export default PaginationComponent;
