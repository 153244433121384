import React from "react"
import { Modal, Row, Col, Button, Badge } from "react-bootstrap"
import {
  FaRegCalendarAlt,
  FaMapMarkerAlt,
  FaBell,
  FaRetweet,
  FaRegFileAlt,
  FaTrashAlt,
  FaEdit,
  FaCopy,
} from "react-icons/fa"
import {
  formatDateInDayJs,
  dateFormats,
  timeDiffText,
} from "../../../utils/dateTime"
import { LoadingView } from "../../../utils/components"
import { priorityColor } from "../../../utils/functions"

const ModalContent = ({ data }) => {
  const repeatSubtitle = (repeatInfo) => {
    if (!repeatInfo) {
      return ""
    }

    if (repeatInfo.repeat_option === "Daily") {
      return repeatInfo.repeat_choice === "Yes" ? "Every Day" : "Every Weekday"
    } else if (repeatInfo.second_repeat_choice) {
      return `Every ${repeatInfo.second_repeat_choice.join(", ")}`
    } else {
      const choices = repeatInfo.repeat_choice
      if (typeof choices === "string") {
        return `Every ${choices}`
      } else {
        return `Every ${choices.join(", ")}`
      }
    }
  }

  return (
    <>
      <Row className="align-items-center py-2">
        <Col
          xs={1}
          className="text-end"
        >
          <FaRegCalendarAlt size={20} />
        </Col>
        <Col xs={11}>
          <div className="fs-6">
            {formatDateInDayJs(data?.start_datetime)}
            <br />
            {`${formatDateInDayJs(
              data?.start_datetime,
              dateFormats.time24Hour
            )} - ${formatDateInDayJs(
              data?.end_datetime,
              dateFormats.time24Hour
            )} (${timeDiffText(data?.start_datetime, data?.end_datetime)})`}
          </div>
        </Col>
      </Row>

      <Row className="align-items-center py-4">
        <Col
          xs={1}
          className="text-end"
        >
          <FaMapMarkerAlt
            size={20}
            className="me-1"
          />
        </Col>
        <Col xs={11}>
          <p className="fs-6 mb-0">{data.location || "No Location set"}</p>
        </Col>
      </Row>

      <Row className="align-items-center py-4">
        <Col
          xs={1}
          className="text-end"
        >
          <FaBell
            size={20}
            className="me-1"
          />
        </Col>
        <Col xs={11}>
          {data?.creation_form_data?.reminder_datetimes?.length > 0 ? (
            <div className="d-flex flex-wrap">
              {data.creation_form_data.reminder_datetimes.map(
                (reminder, index) => (
                  <Badge
                    key={index}
                    pill
                    className="me-2 mb-2 p-3 fs-7"
                    bg="primary"
                  >
                    {reminder}
                  </Badge>
                )
              )}
            </div>
          ) : (
            <span>No Reminders set</span>
          )}
        </Col>
      </Row>
      {data?.creation_form_data?.repeat && (
        <Row className="align-items-center py-4">
          <Col
            xs={1}
            className="text-end"
          >
            <FaRetweet
              size={20}
              className="me-1"
            />
          </Col>
          <Col xs={11}>
            <div className="fs-6">
              {`Repeats ${data?.creation_form_data?.repeat_option}`}
              <br />
              {`${repeatSubtitle(
                data?.creation_form_data
              )} untill ${formatDateInDayJs(
                data?.creation_form_data?.repeat_end_date,
                dateFormats.shortDayShortMonthFullDayYear
              )}`}
            </div>
          </Col>
        </Row>
      )}
      <Row className="align-items-center py-4">
        <Col
          xs={1}
          className="text-end"
        >
          <FaRegFileAlt
            size={20}
            className="me-1"
          />
        </Col>
        <Col xs={11}>
          <p className="fs-6 mb-0">{data.description || "No description"}</p>
        </Col>
      </Row>
    </>
  )
}

const AppointmentModal = ({
  showModal,
  setShowModal,
  data,
  setCanvasInfo,
  setShowOffCanvas,
  setData,
  cancelAppointment,
  showAppModal,
  resetData,
  showAppModal2,
  closeModal,
  isLoading,
  CREATE_APPOINTMENT = "create_apointment",
}) => {
  if (isLoading) {
    ;<LoadingView isLoading={isLoading} />
  }

  return (
    <Modal
      show={showModal}
      onHide={() => {
        resetData()
        setShowModal(false)
      }}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title className="d-flex align-items-center text-center me-8">
          <div
            style={{
              width: "30px",
              height: "30px",
              borderRadius: "50%", // Use 'borderRadius' for rounded shape
              backgroundColor:
                priorityColor(data.priority, data.is_past) + "7a",
              marginRight: "8px", // Add some spacing between the div and title
            }}
          />
          {data?.title || "Appointment Details"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ModalContent data={data} />
      </Modal.Body>
      <Modal.Footer>
        {!data.is_past && (
          <Button
            variant="secondary"
            onClick={() => {
              setShowModal(false)
              if (data.series_id) {
                showAppModal2({
                  titleComponent: "Cancel Series",
                  subTitleComponent: (
                    <p className={"text-left"}>
                      This appointment is part of a repeat series. Do you want
                      to cancel the entire series?
                      <br />
                      This will only affect future data in the series.
                    </p>
                  ),
                  truthyFunction1: () => {
                    cancelAppointment(false, data.id) // Cancels this appointment
                    closeModal()
                  },
                  truthyFunction2: () => {
                    cancelAppointment(true, data.id) // Cancels entire series
                    closeModal()
                  },
                  falsyFunction: () => {
                    closeModal()
                    resetData()
                  },

                  truthyText1: "Cancel this Appointment", // First button text
                  truthyText2: "Cancel Entire Series", // Second button text
                  falsyText: "Close", // Close button text
                })
              } else {
                showAppModal({
                  size: "lg",
                  title: `Cancel Appointment`,
                  component: (
                    <p className={"text-center"}>
                      Are you sure you want to Proceed?
                    </p>
                  ),
                  truthyFunction: () => {
                    cancelAppointment(false, data.id)
                    closeModal() // Close the modal after cancelling
                    resetData()
                  },
                })
              }
            }}
          >
            <FaTrashAlt className="me-2" /> Cancel Appointment
          </Button>
        )}

        {!data.is_past ? (
          <>
            {(() => {
              const updateData = {
                is_edit: true,
                ...data.creation_form_data,
                start_datetime: data.start_datetime,
                id: data.id,
                end_datetime: data.end_datetime,
                series_id: data.series_id,
                is_past: false,
              }

              return (
                <Button
                  variant="primary"
                  onClick={() => {
                    setShowModal(false)
                    if (data.series_id) {
                      showAppModal2({
                        titleComponent: "Edit Series",
                        subTitleComponent: (
                          <p className={"text-left"}>
                            This appointment is part of a repeat series. Do you
                            want to edit the entire series?
                          </p>
                        ),
                        truthyFunction1: () => {
                          const update = {
                            ...updateData,
                            apply_to_series: false,
                            is_edit: true,
                            repeat: true,
                            hide_repeat_options: true,
                          }
                          closeModal()
                          setShowOffCanvas(true)
                          setCanvasInfo({
                            title: `Edit Appointment`,
                            subtitle: `Please fill in the details below.`,
                            type: CREATE_APPOINTMENT,
                          })
                          setData(update)
                        },
                        truthyFunction2: () => {
                          const update = {
                            ...updateData,
                            apply_to_series: true,
                            repeat: true,
                            is_edit: true,
                            hide_repeat_options: false,
                          }

                          closeModal()
                          setShowOffCanvas(true)
                          setCanvasInfo({
                            title: `Edit Appointment`,
                            subtitle: `Please fill in the details below.`,
                            type: CREATE_APPOINTMENT,
                          })
                          setData(update)
                        },
                        falsyFunction: () => {
                          closeModal()
                          resetData()
                        },
                        onClose: () => {
                          resetData()
                        },
                        truthyText1: "Edit this Appointment",
                        truthyText2: "Edit Entire Series",
                        falsyText: "Close",
                      })
                    } else {
                      showAppModal({
                        size: "lg",
                        title: `Edit Appointment`,
                        component: (
                          <p className={"text-center"}>
                            Are you sure you want to proceed?
                          </p>
                        ),
                        truthyFunction: () => {
                          const data = {
                            ...updateData,
                            apply_to_series: false,
                            repeat: false,
                            is_edit: true,
                          }
                          closeModal()
                          setShowOffCanvas(true)
                          setCanvasInfo({
                            title: `Edit Appointment`,
                            subtitle: `Please fill in the details below.`,
                            type: CREATE_APPOINTMENT,
                          })
                          setData(data)
                        },
                        falsyFunction: () => {
                          resetData()
                        },
                      })
                    }
                  }}
                >
                  {data.is_past ? (
                    <>
                      <FaCopy className="me-2" /> Copy Appointment
                    </>
                  ) : (
                    <>
                      <FaEdit className="me-2" /> Edit Appointment
                    </>
                  )}
                </Button>
              )
            })()}
          </>
        ) : (
          (() => {
            const updateData = {
              ...data.creation_form_data,
              id: data.appointment_calendar_id,
              start_datetime: null,
              end_datetime: null,
              is_edit: false,
              is_past: data.is_past,
              series_id: data.series_id,
            }

            return (
              <Button
                variant="primary"
                onClick={() => {
                  if (data.series_id) {
                    setShowModal(false)
                    const update = {
                      ...updateData,
                      repeat: true,
                    }
                    closeModal()
                    setShowOffCanvas(true)
                    setCanvasInfo({
                      title: `Edit Appointment`,
                      subtitle: `Please fill in the details below.`,
                      type: CREATE_APPOINTMENT,
                    })
                    setData(update)
                  } else {
                    setShowModal(false)
                    const update = {
                      ...updateData,
                      repeat: false,
                    }

                    closeModal()
                    setShowOffCanvas(true)
                    setCanvasInfo({
                      title: `Edit Appointment`,
                      subtitle: `Please fill in the details below.`,
                      type: CREATE_APPOINTMENT,
                    })
                    setData(update)
                  }
                }}
              >
                {data.is_past ? (
                  <>
                    <FaCopy className="me-2" /> Copy Appointment
                  </>
                ) : (
                  <>
                    <FaEdit className="me-2" /> Edit Appointment
                  </>
                )}
              </Button>
            )
          })()
        )}
      </Modal.Footer>
    </Modal>
  )
}
export default AppointmentModal
