import React, { useContext, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { AddItemButton, ConditionalView } from "../../../utils/components";
import AvailableTimeOffForm from "../../Forms/People/AvailableTimeOffForm";
import {
  getObjectFromListByKey,
  updateObjectState,
  useAppModal,
  useOffCanvas,
} from "../../../utils/functions";
import AvailableTimeOffCard from "./AvailableTimeOffCard";
import EmployeeTimeOffRequestForm from "../../Forms/Employee/EmployeeTimeOffRequestForm";
import ApprovalsTable from "./ApprovalsTable";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { AppContext } from "../../../utils/components/AppContext";

function ManageTimeOff({
  employee,
  setEmployee,
  permissions,
  configs,
  putRequest,
  patchRequest,
  getRequest,
  loaderCallback,
  isAdmin = true,
}) {
  const { showOffCanvas } = useOffCanvas();
  const { updateContextState } = useContext(AppContext);
  const { showAppModal } = useAppModal();

  const [data, setData] = useState({
    current_year: new Date().getUTCFullYear().toString(),
  });

  return (
    <div>
      <Card className={"shadow rounded-3"}>
        <Card.Header className={"py-4 d-flex justify-content-between"}>
          <Card.Title>{employee.first_name}'s Time Off</Card.Title>
          {permissions.MANAGE_ATTENDANCE && isAdmin && (
            <AddItemButton
              title={"Available Time Off"}
              onClick={() => {
                updateContextState({
                  formData: {
                    time_off_policy_id:
                      configs?.time_off_policies?.length > 0
                        ? configs?.time_off_policies[0].id
                        : null,
                  },
                });
                showOffCanvas({
                  title: `New Available Time Off`,
                  subtitle: "",
                  component: (
                    <AvailableTimeOffForm
                      configs={configs}
                      onSubmit={(updatedData) => {
                        putRequest(
                          `/employee/available-time-off/${employee.id}`,
                          loaderCallback,
                          (response) => {
                            updateObjectState(setEmployee, {
                              available_time_off: response.available_time_off,
                            });
                          },
                          updatedData,
                          false
                        );
                      }}
                    />
                  ),
                });
              }}
            />
          )}
        </Card.Header>
      </Card>
      <ConditionalView
        condition={
          employee.available_time_off &&
          Object.keys(employee.available_time_off)?.length > 0
        }
        falseView={
          <Card body={true}>
            <p className={"text-center my-4"}>
              {employee.first_name} has no active time offs
            </p>
          </Card>
        }
        trueView={
          <div>
            <Card body={true}>
              {Object.keys(employee?.available_time_off || {}).map(
                (year, index) => (
                  <Button
                    key={index}
                    className={"me-3"}
                    variant={
                      data.current_year === year ? "primary" : "outline-primary"
                    }
                    onClick={() => {
                      updateObjectState(setData, {
                        time_off_year: year,
                        current_year: year,
                      });
                    }}
                  >
                    {year}
                  </Button>
                )
              )}
            </Card>
            {!employee.available_time_off[data.current_year] ? (
              <div />
            ) : (
              employee.available_time_off[data.current_year].map(
                (timeOff, index) => {
                  return (
                    <AvailableTimeOffCard
                      key={index}
                      timeOff={timeOff}
                      permissions={permissions}
                      policy={getObjectFromListByKey(
                        configs?.time_off_policies,
                        "id",
                        timeOff.time_off_policy_id
                      )}
                      canManage={permissions.MANAGE_ATTENDANCE}
                      onEditClick={() => {
                        updateContextState({
                          formData: { ...timeOff },
                        });
                        showOffCanvas({
                          title: `Edit Time Off ${timeOff.name}`,
                          subtitle: "",
                          component: (
                            <AvailableTimeOffForm
                              configs={configs}
                              onSubmit={(updatedData) => {
                                patchRequest(
                                  `/employee/available-time-off/${employee.id}/${timeOff.id}`,
                                  loaderCallback,
                                  (response) => {
                                    updateObjectState(setEmployee, {
                                      available_time_off:
                                        response.available_time_off,
                                    });
                                  },
                                  updatedData,
                                  false
                                );
                              }}
                            />
                          ),
                        });
                      }}
                      onAddRequestClick={(policy) => {
                        updateContextState({
                          formData: {
                            employee_id: employee.id,
                            available_time_off_id: timeOff.id,
                          },
                        });
                        showOffCanvas({
                          title: `Request Time Off for ${employee.first_name}`,
                          subtitle: "",
                          component: (
                            <EmployeeTimeOffRequestForm
                              configs={configs}
                              timeOff={timeOff}
                              policy={policy}
                              onSubmit={(updatedData) => {
                                putRequest(
                                  `/employee/time-off-requests`,
                                  loaderCallback,
                                  (response) => {
                                    updateObjectState(setEmployee, {
                                      available_time_off:
                                        response.available_time_off,
                                    });
                                  },
                                  updatedData,
                                  false
                                );
                              }}
                            />
                          ),
                        });
                      }}
                      onShowApproversClick={(approvalSteps, policy) => {
                        showAppModal({
                          title: `Approval Status for Request`,
                          hideFooter: true,
                          component: (
                            <div>
                              <ApprovalsTable approvalSteps={approvalSteps} />
                            </div>
                          ),
                        });
                      }}
                      actions={(request) => {
                        const actions = [];
                        if (request.can_cancel) {
                          actions.push({
                            title: (
                              <p className={"text-danger me-2 fw-bold"}>
                                <AiOutlineCloseCircle className={"fs-5 me-1"} />
                                Cancel Request
                              </p>
                            ),
                            onClick: () => {
                              showAppModal({
                                title: "Cancel Time Off Request",
                                component: (
                                  <p>
                                    Are you sure you want to cancel this
                                    request?
                                  </p>
                                ),
                                truthyFunction: () => {
                                  getRequest(
                                    `/employee/time-off-requests/cancel/${request.id}`,
                                    loaderCallback,
                                    (response) => {
                                      updateObjectState(setEmployee, {
                                        available_time_off:
                                          response.available_time_off,
                                      });
                                    },
                                    true
                                  );
                                },
                              });
                            },
                          });
                        }
                        return actions;
                      }}
                    />
                  );
                }
              )
            )}
          </div>
        }
      />
    </div>
  );
}

export default ManageTimeOff;
