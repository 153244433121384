import React, { useContext } from "react";
import {
  AddItemButton,
  ConditionalView,
  InfoDisplayTable,
  NoInfoCard,
} from "../../../utils/components";
import { addFillColorToList, useOffCanvas } from "../../../utils/functions";
import { AppContext } from "../../../utils/components/AppContext";
import { Alert, Button, Card, Col, Row } from "react-bootstrap";
import GenericForm from "../../Forms/Shared/GenericForm";
import PerformanceFormConfigs from "../../Forms/FormsConfigs/PerformanceFormConfigs";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../utils/appRoutes";
import { OffCanvasContext } from "../../../utils/components/OffCanvasContext";
import { AppPieChart } from "../../../utils/components/AppCharts";

function ReviewPeriodForms({
  reviewPeriod,
  configs,
  periodForms,
  setPeriodForms,
  permissions,
  putRequest,
  deleteRequest,
  loadingCallback,
}) {
  const { showOffCanvas } = useOffCanvas();
  const { updateContextState } = useContext(AppContext);
  const { closeOffCanvas } = useContext(OffCanvasContext);
  const navigate = useNavigate();

  return (
    <div>
      {permissions.MANAGE_PERFORMANCE_REVIEWS && (
        <div className={"mb-4"}>
          <AddItemButton
            title={"Add Form"}
            onClick={() => {
              // filter out already added forms
              const availableForms = configs.forms.filter((form) => {
                return !periodForms.find(
                  (periodForm) => periodForm.form.id === form.id
                );
              });

              updateContextState({
                formData: {},
              });
              showOffCanvas({
                title: `Add a form to this review period`,
                // subtitle: `Add a form to this period`,
                component: (
                  <>
                    <ConditionalView
                      condition={availableForms.length === 0}
                      trueView={
                        <div>
                          <Alert className={"small"} variant={"warning"}>
                            There are no Performance forms available to add to
                            this period. Please add a performance form to
                            proceed
                          </Alert>
                          <Button
                            variant={"outline-primary"}
                            className={"w-100"}
                            onClick={() => {
                              closeOffCanvas();
                              navigate(
                                `${AppRoutes.Performance.path}?activeTab=forms`
                              );
                            }}
                          >
                            Go to Performance Forms
                          </Button>
                        </div>
                      }
                      falseView={
                        <GenericForm
                          formConfigs={PerformanceFormConfigs.reviewPeriodForm(
                            configs.forms
                          )}
                          onSubmit={(data) => {
                            putRequest(
                              `/performance/review-period/${reviewPeriod.id}/form`,
                              loadingCallback,
                              (response) => {
                                setPeriodForms([
                                  response.period_form,
                                  ...periodForms,
                                ]);
                              },
                              data
                            );
                          }}
                        />
                      }
                    />
                  </>
                ),
              });
            }}
          />
        </div>
      )}

      <Card className={"mb-4"}>
        <Card.Header className={"py-4"}>
          <Card.Title>Review Period Forms</Card.Title>
          <Card.Subtitle as={"p"} className={""}>
            These are the forms employees will fill during this review period
          </Card.Subtitle>
        </Card.Header>
      </Card>

      {periodForms.length === 0 ? (
        <NoInfoCard text={"No forms have been added to this review period"} />
      ) : (
        periodForms.map((periodForm, i) => {
          const form = periodForm?.form;
          return (
            <Card key={i} className={"mb-3 border-1 border-primary"}>
              <Card.Header className={"py-4"}>
                <Card.Title>"{form?.name}" Form</Card.Title>
              </Card.Header>
              <Card.Body>
                <Row className={"mt-4"}>
                  <Col lg={6} className={"mb-4"}>
                    <InfoDisplayTable
                      title={"Form Details"}
                      data={{
                        "Assigned To": periodForm.entity_type,
                        "Assigned Entities":
                          periodForm?.entity_names?.join(", "),
                        "Assigned Employees": periodForm?.employees_count,
                      }}
                    />
                  </Col>
                  <Col lg={6} className={"mb-4"}>
                    <h6 className={"text-center mb-3"}>
                      Overall Review Statuses
                    </h6>
                    <AppPieChart
                      data={addFillColorToList(periodForm.statuses || []) || []}
                      xName={"# of Reviews"}
                      chartHeight={250}
                    />
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className={"py-4 d-flex justify-content-end"}>
                <Button
                  variant={"primary"}
                  onClick={() => {
                    navigate(
                      `${AppRoutes.PerformanceReviewPeriodForm.paramLessPath}/${periodForm.id}`
                    );
                  }}
                >
                  View Details >
                </Button>
              </Card.Footer>
            </Card>
          );
        })
      )}
    </div>
  );
}

export default ReviewPeriodForms;
