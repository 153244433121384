import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import EducationsList from "./EducationsList";
import SkillssList from "./EmployeeSkillsList";

function EducationAndSkillsView({
  employee,
  setEmployee,
  configs,
  purRequest,
  patchRequest,
  permissions,
  loaderCallback,
}) {
  return (
    <div>
      <Row>
        <Col sm={6}>
          <EducationsList
            permissions={permissions}
            configs={configs}
            employee={employee}
            loaderCallback={loaderCallback}
            purRequest={purRequest}
            patchRequest={patchRequest}
            setEmployee={setEmployee}
          />
        </Col>
        <Col sm={6}>
          <SkillssList
            permissions={permissions}
            configs={configs}
            employee={employee}
            loaderCallback={loaderCallback}
            purRequest={purRequest}
            patchRequest={patchRequest}
            setEmployee={setEmployee}
          />
        </Col>
      </Row>
    </div>
  );
}

export default EducationAndSkillsView;
