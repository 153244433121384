import React, { useContext, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { AppContext } from "../../../utils/components/AppContext";
import { COUNTRIES } from "../../../data/countries";
import { RELIGIONS } from "../../../data/religions";
import { RACIAL_DEMOGRAPHICS } from "../../../data/races";
import { formatDateStrings } from "../../../utils/dateTime";

function PersonalInformationForm({ onSubmit, configs, isSelf = false }) {
  const { contextState, updateContextState } = useContext(AppContext);
  const [formState, setFormState] = useState(
    formatDateStrings({ ...contextState.formData })
  );

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (event, file_key) => {
    const file = event.target.files[0];
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setFormState((prevState) => ({
          ...prevState,
          [file_key]: file,
          // [`${file_key}_str`]: reader.result,
        }));
      };
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateContextState({
      formData: { ...formState },
    });
    onSubmit(formState);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <h3 className={"mb-4"}>Personal Information</h3>

      {/* Email */}
      <Form.Group controlId="email" className={"mb-4"}>
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          name="email"
          value={formState.email || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      {/* Phone */}
      <Form.Group controlId="phone" className={"mb-4"}>
        <Form.Label>Phone</Form.Label>
        <Form.Control
          type="tel"
          name="phone"
          value={formState.phone || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      {/* Religion */}
      <Form.Group controlId="religionId" className={"mb-4"}>
        <Form.Label>Religion</Form.Label>
        <Form.Select
          name="religion"
          value={formState.religion || ""}
          onChange={handleInputChange}
        >
          <option value="">Select a Religion</option>
          {RELIGIONS.map((item) => (
            <option key={item + "id"} value={item}>
              {item}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      {/* Marital Status */}
      <Form.Group controlId="maritalStatus" className={"mb-4"}>
        <Form.Label>Marital Status</Form.Label>
        <Form.Select
          name="marital_status"
          value={formState.marital_status || ""}
          onChange={handleInputChange}
        >
          <option value="">Select a Marital Status</option>
          {configs.marital_statuses.map((item) => (
            <option key={item + "id"} value={item}>
              {item}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      {/* Race */}
      <Form.Group controlId="race" className={"mb-4"}>
        <Form.Label>Race</Form.Label>
        <Form.Select
          name="race"
          value={formState.race || ""}
          onChange={handleInputChange}
        >
          <option value="">Select a Race</option>
          {RACIAL_DEMOGRAPHICS.map((item) => (
            <option key={item + "id"} value={item}>
              {item}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      <Form.Group controlId="idNo" className={"mb-4"}>
        <Form.Label>Tribe</Form.Label>
        <Form.Control
          type="text"
          name="tribe"
          value={formState.tribe || ""}
          onChange={handleInputChange}
        />
      </Form.Group>
      {/* Nationality */}
      <Form.Group controlId="nationality" className={"mb-4"}>
        <Form.Label>Nationality</Form.Label>
        <Form.Select
          name="nationality"
          value={formState.nationality || ""}
          onChange={handleInputChange}
        >
          <option value="">Select a Nationality Country</option>
          {Object.keys(COUNTRIES).map((key) => (
            <option key={key + "id"} value={key}>
              {COUNTRIES[key]}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      {!isSelf && (
        <div>
          {/* ID Type */}
          <Form.Group controlId="idType" className={"mb-4"}>
            <Form.Label>ID Type</Form.Label>
            <Form.Select
              name="id_type"
              value={formState.id_type || ""}
              onChange={handleInputChange}
            >
              <option value="">Select an ID Type</option>
              {configs.id_types.map((item) => (
                <option key={item + "id"} value={item}>
                  {item}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          {/* ID No. */}
          <Form.Group controlId="idNo" className={"mb-4"}>
            <Form.Label>ID Number</Form.Label>
            <Form.Control
              type="text"
              name="id_number"
              value={formState.id_number || ""}
              onChange={handleInputChange}
            />
          </Form.Group>

          {/* ID Expiry */}
          <Form.Group controlId="idExpiry" className={"mb-4"}>
            <Form.Label>ID Expiry</Form.Label>
            <Form.Control
              type="date"
              name="id_expiry"
              value={formState.id_expiry || ""}
              onChange={handleInputChange}
            />
          </Form.Group>
          {/* ID Image */}
          <Form.Group controlId="idImage" className={"mb-4"}>
            <Form.Label>ID Image</Form.Label>
            <Form.Control
              type="file"
              name="id_image"
              accept=".jpg, .jpeg, .png"
              onChange={(event) => {
                handleFileChange(event, "id_image");
              }}
            />
            <Form.Text as={"p"} className={"mt-2 fw-bold text-muted"}>
              Accepts jpg, jpeg or png
              <br />
              Will replace the existing image if one exists
            </Form.Text>
          </Form.Group>

          {/* Work Authorization Expiry */}
          <Form.Group controlId="workAuthorizationExpiry" className={"mb-4"}>
            <Form.Label>Work Authorization Expiry</Form.Label>
            <Form.Control
              type="date"
              name="work_authorization_expiry"
              value={formState.work_authorization_expiry || ""}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Text as={"p"} className={"mt-2 fw-bold text-muted"}>
            Leave empty if {formState.first_name} is a citizen or does not have
            an expiring work authorization
          </Form.Text>

          {/* Work Authorization Image */}
          <Form.Group controlId="workAuthorizationImage" className={"mb-4"}>
            <Form.Label>Work Authorization Image</Form.Label>
            <Form.Control
              type="file"
              name="work_authorization_image"
              accept=".jpg, .jpeg, .png"
              onChange={(event) => {
                handleFileChange(event, "work_authorization_image");
              }}
            />
            <Form.Text as={"p"} className={"mt-2 fw-bold text-muted"}>
              Accepts jpg, jpeg or png
              <br />
              Will replace the existing image if one exists
            </Form.Text>
          </Form.Group>
        </div>
      )}

      <Button variant="primary" type="submit" className={"w-100 mt-3"}>
        Submit
      </Button>
    </Form>
  );
}

export default PersonalInformationForm;
