import React from "react";
import { Button, Card, Row } from "react-bootstrap";
import { TimeOffRequestsTable } from "./TimeOffRequestTable";
import InfoCard from "../../../utils/components/InfoCard";
import { formatDate } from "../../../utils/dateTime";

const AvailableTimeOffCard = ({
  timeOff,
  policy,
  canManage,
  onEditClick,
  onAddRequestClick,
  onShowApproversClick,
  actions,
  permissions,
}) => {
  const { time_usage } = timeOff;

  policy = policy || {};

  const infoItems = {
    "Total Time Allocated": `${timeOff?.available_time} ${policy?.time_unit}`,
    "Available Time": `${time_usage?.remaining} ${policy?.time_unit}`,
    "Time Used / Pending": `${time_usage?.used} ${policy?.time_unit}`,
    "Effective Date": formatDate(timeOff?.start_date),
  };

  const activeDate =
    new Date(timeOff.start_date).getUTCFullYear() >=
    new Date().getUTCFullYear();

  return (
    <Card className={"mt-3 py-3"}>
      <Card.Header className={"text-center mb-4 pb-3"}>
        <Card.Title as={"h3"}>{policy.name}</Card.Title>
        <Card.Subtitle>{policy.description}</Card.Subtitle>
      </Card.Header>
      <Card.Body>
        <Row>
          {Object.entries(infoItems).map(([header, body], i) => (
            <InfoCard key={i} header={header} body={body} />
          ))}
        </Row>
      </Card.Body>
      <Card.Footer>
        <div
          className={"d-flex justify-content-between justify-content-center"}
        >
          <h5 className={"align-self-center"}>
            Time Off Requests: {timeOff.requests.length}
          </h5>
          {permissions.MANAGE_ATTENDANCE && activeDate && (
            <Button
              variant={"warning"}
              onClick={() => onAddRequestClick(policy)}
            >
              New Request
            </Button>
          )}
        </div>
        <div className={"mt-4"}>
          <TimeOffRequestsTable
            requests={timeOff.requests}
            onShowApproversClick={onShowApproversClick}
            actions={actions}
            policies={{ [policy.id]: policy }}
            showEmployee={false}
          />
        </div>
      </Card.Footer>
    </Card>
  );
};

export default AvailableTimeOffCard;
