import React, { useContext, useEffect, useState } from "react"
import { AddItemButton, AppOffCanvasLocal } from "../../../utils/components"
import {
  formatMoney,
  toTitleCase,
  updateArrayState,
  updateObjectState,
  useAppModal,
  useOffCanvas,
} from "../../../utils/functions"
import { AppContext } from "../../../utils/components/AppContext"
import { Accordion, Button, Card, Col, Row, Table } from "react-bootstrap"
import { StaffPermissions } from "../../../enums/staffEnums"
import GenericForm from "../../Forms/Shared/GenericForm"
import { StaffFormsConfigs } from "../../Forms/FormsConfigs/StaffFormsConfigs"
import { OffCanvasContext } from "../../../utils/components/OffCanvasContext"
import { FormFieldTypes } from "../../FormFields"
import InfoCard from "../../../utils/components/InfoCard"
import {
  dateFormats,
  formatDate,
  formatDateStrings,
} from "../../../utils/dateTime"
import PDFViewer from "../Shared/PDFViewer"
import { FaEdit, FaUpload } from "react-icons/fa"

function StaffOrgSubscriptionView({
  subscriptions,
  setSubscriptions,
  organization,
  putRequest,
  patchREquest,
  loaderCallback,
  configs,
}) {
  const { showOffCanvas } = useOffCanvas()
  const { closeOffCanvas } = useContext(OffCanvasContext)
  const { showAppModal, closeModal } = useAppModal()

  const { contextState, updateContextState, hasStaffPermission } =
    useContext(AppContext)

  const [subscriptionData, setSubscriptionData] = useState({})
  const [subscriptionItemData, setSubscriptionItemData] = useState({})
  const [subItemFormConfigs, setSubItemFormConfigs] = useState([])
  const [showLocalOffCanvas, setShowLocalOffCanvas] = useState(false)

  const showSubItemForm = (defaultData, subData) => {
    const formConfigs = StaffFormsConfigs.subscriptionItem(
      configs.sub_item_statuses,
      configs.product_bundles,
      true
    )

    setSubItemFormConfigs(formConfigs)

    setSubscriptionItemData({
      ...defaultData,
      added_seats: "0",
      bundle: "Starter",
      onSubmit: (formData) => {
        const submitData = {
          ...subData,
          subscription_item: formData,
        }
        putRequest(
          `/staff/organization/${organization.id}/subscription`,
          loaderCallback,
          (response) => {
            setSubscriptions([response.subscription, ...subscriptions])
          },
          submitData,
          false,
          false
        )
      },
    })

    setShowLocalOffCanvas(true)
  }

  const fileUpload = (vals) => {
    updateContextState({
      formData: { file_type: vals.fileKey },
    })

    showOffCanvas({
      title: `Add / Update the ${vals.fileKey} file`,
      component: (
        <GenericForm
          useFormData={true}
          formConfigs={[
            {
              valueKey: "pdf_file",
              type: FormFieldTypes.PDF,
              maxFileMb: 5,
            },
          ]}
          onSubmit={(formData) => {
            putRequest(
              `/staff/organization-subscription-item/${vals.subItemId}/document`,
              loaderCallback,
              (response) => {
                const subIndex = vals.subIndex
                const subItemIndex = vals.subItemIndex
                const newSubscriptions = [...subscriptions]
                newSubscriptions[subIndex].items[subItemIndex] =
                  response.subscription_item
                setSubscriptions(newSubscriptions)
              },
              formData,
              true
            )
          }}
        />
      ),
    })
  }

  const addOrUpdateSubItem = (vals) => {
    const formConfigs = StaffFormsConfigs.subscriptionItem(
      configs.sub_item_statuses,
      configs.product_bundles,
      false
    )

    setSubItemFormConfigs(formConfigs)

    setSubscriptionItemData({
      ...vals,
      added_seats: "0",
      onSubmit: (data) => {
        const endpoint = vals.isEdit
          ? `/staff/organization-subscription-item/${vals.subItemId}`
          : `/staff/organization-subscription/${vals.subId}/item`
        putRequest(
          endpoint,
          loaderCallback,
          (response) => {
            if (vals.isEdit) {
              const subIndex = vals.subIndex
              const subItemIndex = vals.subItemIndex
              const newSubscriptions = [...subscriptions]
              newSubscriptions[subIndex].items[subItemIndex] =
                response.subscription_item
              setSubscriptions(newSubscriptions)
            } else {
              const newSubscriptions = [...subscriptions]
              newSubscriptions[vals.subIndex].items = [
                response.subscription_item,
                ...newSubscriptions[vals.subIndex].items,
              ]
            }
          },
          data,
          false,
          false
        )
      },
    })

    setShowLocalOffCanvas(true)
  }

  useEffect(() => {
    if (subscriptionItemData.bundle) {
      const disabled = subscriptionItemData.bundle !== "Add On"
      const bundleItems = {
        valueKey: "bundle_items",
        valueLabel: "Bundle Items",
        info: disabled
          ? "You cannot modify the bundle items for this bundle. Use the 'Add On' bundle to add specific products."
          : null,
        type: FormFieldTypes.MULTI_STRING_SELECT,
        optionsList: configs.product_bundles[subscriptionItemData.bundle],
        disabled: disabled,
      }
      // check if the bundle items field is already in the form configs
      const bundleIndex = subItemFormConfigs.findIndex(
        (config) => config.valueKey === "bundle_items"
      )
      const newFormConfigs = [...subItemFormConfigs]
      if (bundleIndex > -1) {
        newFormConfigs[bundleIndex] = bundleItems
      } else {
        newFormConfigs.push(bundleItems)
      }
      setSubItemFormConfigs(newFormConfigs)

      // set the value of the bundle items
      if (disabled) {
        updateObjectState(setSubscriptionItemData, {
          bundle_items: bundleItems.optionsList,
        })
      } else {
        updateObjectState(setSubscriptionItemData, {
          bundle_items: subscriptionItemData.bundle_items || [],
        })
      }
    }
  }, [subscriptionItemData.bundle])

  return (
    <div>
      {hasStaffPermission(
        StaffPermissions.MANAGE_ORGANIZATIONS_SUBSCRIPTIONS
      ) && (
        <div className={"mb-4"}>
          <AddItemButton
            title={"New Subscription"}
            onClick={() => {
              updateContextState({
                formData: { ...subscriptionData },
              })
              showOffCanvas({
                title: `Add Subscription`,
                subtitle: `Add a new subscription to the ${organization.name} organization`,
                component: (
                  <>
                    <GenericForm
                      formConfigs={StaffFormsConfigs.subscription}
                      completeButtonTitle={"Next > Add Subscription Item"}
                      updateGlobalStateOnSubmit={false}
                      onSubmit={(data) => {
                        closeOffCanvas()
                        setTimeout(() => {
                          showSubItemForm(
                            {
                              start_date: data.start_date,
                              end_date: data.end_date,
                            },
                            { ...data }
                          )
                        }, 750)
                      }}
                    />
                  </>
                ),
              })
            }}
          />
        </div>
      )}

      <Card className={"mb-4"}>
        <Card.Header className={"py-4"}>
          <Card.Title>Organization Subscriptions</Card.Title>
          <Card.Subtitle
            as={"p"}
            className={"mt-3"}
          >
            View, add or update the subscriptions for this organization based on
            your permissions
          </Card.Subtitle>
        </Card.Header>
      </Card>

      {subscriptions.map((subscription, subIndex) => {
        return (
          <Accordion
            key={subscription.id}
            className={"mb-4"}
          >
            <Accordion.Header>Subscription {subIndex + 1}</Accordion.Header>
            <Accordion.Body className={"px-0"}>
              <Card.Subtitle
                as={"p"}
                className={"mt-3"}
              >
                {subscription.description}
              </Card.Subtitle>
              <Card
                className={"mt-4"}
                key={subscription.id}
              >
                <Card.Body>
                  <Row>
                    <InfoCard
                      lg={4}
                      header={"Start Date"}
                      body={
                        formatDate(
                          subscription?.start_date,
                          dateFormats.shortDateTime24Hr
                        ) || "N/A"
                      }
                    />
                    <InfoCard
                      lg={4}
                      header={"End Date"}
                      body={
                        formatDate(
                          subscription?.end_date,
                          dateFormats.shortDateTime24Hr
                        ) || "N/A"
                      }
                    />
                    <InfoCard
                      lg={4}
                      header={"Seats Bought"}
                      body={subscription?.available_seats || "N/A"}
                    />
                  </Row>

                  <h5 className={"mt-5 mb-3 text-center"}>
                    Subscription Items
                  </h5>
                  <AddItemButton
                    title={"new Subscription Item"}
                    onClick={() => {
                      addOrUpdateSubItem({
                        subId: subscription.id,
                        subIndex: subIndex,
                        isEdit: false,
                      })
                    }}
                  />

                  <Table
                    striped
                    bordered
                    responsive
                    className={"mt-4"}
                  >
                    <thead>
                      <tr
                        className={"text-center fs-6 fw-bold min-width-table"}
                      >
                        <th>Invoice #</th>
                        <th>Amounts</th>
                        <th>Paid On</th>
                        <th>Valid Dates</th>
                        <th>Bundles</th>
                        <th>Status</th>
                        <th>Files</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {subscription.items.map((item, subItemIndex) => {
                        return (
                          <tr
                            key={item.id}
                            className={"text-center"}
                          >
                            <td>{item.invoice_number}</td>
                            <td className={"d-flex"}>
                              {[
                                "normal_price",
                                "discount_amount",
                                "amount_paid",
                              ].map((key, i) => {
                                return (
                                  <div
                                    key={i}
                                    className={"mb-2"}
                                  >
                                    <strong>
                                      {item.currency} {formatMoney(item[key])}
                                    </strong>
                                    <br />
                                    <small>{toTitleCase(key)}</small>
                                  </div>
                                )
                              })}
                            </td>
                            <td>{formatDate(item.payment_date)}</td>
                            <td className={"small"}>
                              {formatDate(
                                item.start_date,
                                dateFormats.shortDayShortMonthFullDayYearTime12Hr
                              )}
                              <br /> to <br />
                              {formatDate(
                                item.end_date,
                                dateFormats.shortDayShortMonthFullDayYearTime12Hr
                              )}
                            </td>
                            <td>
                              {Object.entries(item.bundles).map(
                                ([bundle, products], i) => {
                                  return (
                                    <div
                                      key={i}
                                      className={"mt-3"}
                                    >
                                      <strong>{bundle}</strong>
                                      <br />
                                      <small>{products?.join(", ")}</small>
                                    </div>
                                  )
                                }
                              )}
                            </td>
                            <td>{item.status}</td>
                            <td
                              style={{
                                minWidth: "400px",
                              }}
                            >
                              <Row className={""}>
                                {Object.entries(
                                  configs?.sub_item_doctypes
                                )?.map(([fileKey, values], fileIndex) => {
                                  const file = item[values.filename]
                                  return (
                                    <Col
                                      xs={6}
                                      key={`${fileIndex}-${fileKey}-${item.id}`}
                                    >
                                      <Card
                                        body={true}
                                        className={"mb-2"}
                                      >
                                        <strong>{fileKey}</strong>
                                        {file && (
                                          <div>
                                            <PDFViewer pdfUrl={file} />
                                            <Button
                                              variant={"link"}
                                              onClick={() => {
                                                fileUpload({
                                                  subItemId: item.id,
                                                  subIndex: subIndex,
                                                  subItemIndex: subItemIndex,
                                                  fileKey: fileKey,
                                                })
                                              }}
                                            >
                                              <FaEdit /> Change
                                            </Button>
                                          </div>
                                        )}
                                        {!file && (
                                          <div className={"text-center"}>
                                            <Button
                                              variant={"link"}
                                              onClick={() => {
                                                fileUpload({
                                                  subItemId: item.id,
                                                  subIndex: subIndex,
                                                  subItemIndex: subItemIndex,
                                                  fileKey: fileKey,
                                                })
                                              }}
                                            >
                                              <FaUpload className={"me-2"} />
                                              Upload PDF
                                            </Button>
                                          </div>
                                        )}
                                      </Card>
                                    </Col>
                                  )
                                })}
                              </Row>
                            </td>
                            <td>
                              {hasStaffPermission(
                                StaffPermissions.MANAGE_ORGANIZATIONS_SUBSCRIPTIONS
                              ) && (
                                <Button
                                  variant={"link"}
                                  onClick={() => {
                                    const bundle = Object.keys(item.bundles)[0]

                                    addOrUpdateSubItem({
                                      bundle_items: item.bundles[bundle],
                                      subId: subscription.id,
                                      subItemId: item.id,
                                      subIndex: subIndex,
                                      subItemIndex: subItemIndex,
                                      isEdit: true,
                                      ...formatDateStrings(item),
                                      bundle: bundle,
                                    })
                                  }}
                                >
                                  <FaEdit /> Edit
                                </Button>
                              )}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Accordion.Body>
          </Accordion>
        )
      })}

      <AppOffCanvasLocal
        showOffCanvas={showLocalOffCanvas}
        setShowOffCanvas={setShowLocalOffCanvas}
        title={"Add Subscription Item"}
        subtitle={"Add a new subscription item to the organization"}
        component={
          <div>
            <GenericForm
              stateData={subscriptionItemData}
              setStateData={setSubscriptionItemData}
              formConfigs={subItemFormConfigs}
              completeButtonTitle={"Add / Update Subscription Item"}
              useGlobalState={false}
              updateGlobalStateOnStateChange={false}
              onSubmit={(data) => {
                subscriptionItemData.onSubmit(data)
                setShowLocalOffCanvas(false)
              }}
            />
          </div>
        }
      />
    </div>
  )
}

export default StaffOrgSubscriptionView
