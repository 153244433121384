import { Country, State, City } from "country-state-city";
import { FormFieldTypes } from "../../FormFields";
import { useContext, useEffect, useState } from "react";
import { isValidEmail, updateObjectState } from "../../../utils/functions";
import { appConfigs } from "../../../configs";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { AppContext } from "../../../utils/components/AppContext";

export const EmployeeFormFieldsDefinitions = (
  state,
  setState,
  displayCountryCodes = false,
  imageWidth = 100
) => {
  const { contextState } = useContext(AppContext);
  const { labelsOverride } = contextState;

  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    let city = null;
    let region = null;
    if (state.country) {
      const countryRegions = State.getStatesOfCountry(state.country);
      setRegions(countryRegions);
      region = countryRegions.find((r) => r.name === state.region) || {};
      if (region.name) {
        city = City.getCitiesOfState(state.country, region.isoCode) || {};
        city = city.name;
      }
      region = region.name;
    }
    updateObjectState(setState, { region: region, city: city });
  }, [state.country]);

  useEffect(() => {
    let city;
    if (state.region) {
      const region =
        State.getStatesOfCountry(state.country).find(
          (r) => r.name === state.region
        ) || {};
      const regionCities = City.getCitiesOfState(state.country, region.isoCode);
      setCities(regionCities);
      city = regionCities.find((c) => c.name === state.city) || {};
      city = city.name;
    }
    updateObjectState(setState, { city: city });
  }, [state.region]);

  return [
    {
      valueKey: "profile_image",
      type: FormFieldTypes.IMAGE,
      imageRounded: true,
      imageWidth: imageWidth,
      maxFileMb: 0.15,
      errorMsg:
        "Please click the image to upload a profile picture for this employee.",
      isRequired: false,
      validation: () => {
        return true;
      },
    },
    { valueKey: "first_name", labelName: "First Name" },
    { valueKey: "middle_name", labelName: "Middle Name", isRequired: false },
    { valueKey: "last_name", labelName: "Last Name" },
    {
      valueKey: "gender",
      labelName: "Gender",
      type: FormFieldTypes.STRING_SELECT,
      optionsList: ["Male", "Female"],
    },
    {
      valueKey: "date_of_birth",
      labelName: "Date of Birth",
      errorMsg: "Please enter a valid date",
      type: FormFieldTypes.DATE,
    },
    {
      valueKey: "work_phone",
      labelName: "Work Phone Number",
      info: "Example +23276123456",
      validation: (phoneNumber) => {
        const phone = parsePhoneNumberFromString(phoneNumber || "");
        return phone ? phone.isValid() : false;
      },
      type: FormFieldTypes.TEL,
    },
    {
      valueKey: "work_email",
      labelName: "Work Email",
      errorMsg: "Please enter a valid work email",
      validation: (email) => {
        return isValidEmail(email);
      },
      info: "This is the email that the employee will use to log into the system. If it is changed, the employee will need to use the new email to log in.",
    },
    {
      valueKey: "employee_number",
      labelName: labelsOverride.employee_number || "Employee Number",
    },
    {
      valueKey: "start_date",
      labelName: labelsOverride.start_date || "Employment Start Date",
      type: FormFieldTypes.DATE,
    },
    {
      valueKey: "country",
      labelName: "Home Address Country",
      type: FormFieldTypes.OBJECT_SELECT,
      optionsList: Country.getAllCountries(),
      optionsValueKey: "isoCode",
      optionsLabelKey: displayCountryCodes ? "isoCode" : "name",
    },
    {
      valueKey: "region",
      labelName: "Home Address Region / State / District",
      optionsList: regions,
      type: FormFieldTypes.OBJECT_SELECT,
      optionsValueKey: "name",
      optionsLabelKey: "name",
    },
    {
      valueKey: "city",
      labelName: "Home Address City / Town",
      type: FormFieldTypes.OBJECT_SELECT,
      optionsList: cities,
      optionsValueKey: "name",
      optionsLabelKey: "name",
    },
    { valueKey: "address", labelName: "Home Address" },
    {
      valueKey: "address_1",
      labelName: "Home Address Apt / Unit",
      isRequired: false,
    },
    {
      valueKey: "type",
      labelName: "Employment Type",
      optionsList: ["Full Time", "Contractor", "Part Time"],
      type: FormFieldTypes.STRING_SELECT,
    },
    {
      valueKey: "wage_type",
      labelName: "Wage Type",
      optionsList: ["Salary", "Hourly"],
      type: FormFieldTypes.STRING_SELECT,
      info: `This will be used to calculate the employee's pay.
          E.g. An hourly employee will be paid their hourly wage based on how
          many hours they work.
          A Salary employee will be paid the same regardless of how many
          hours they work.`,
    },
    // {
    //   valueKey: "allow_login",
    //   type: FormFieldTypes.SWITCH,
    //   info: `This will allow the employee to log into ${appConfigs.appName} and be able to manage some of their information, view company resources, make requests and more.`,
    // },
  ];
};
export const SelfEmployeeFormFieldsDefinitions = (
  state,
  setState,
  displayCountryCodes = false,
  imageWidth = 100
) => {
  const { contextState } = useContext(AppContext);
  const { labelsOverride } = contextState;

  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);
  useEffect(() => {
    let city = null;
    let region = null;
    if (state.country) {
      const countryRegions = State.getStatesOfCountry(state.country);
      setRegions(countryRegions);
      region = countryRegions.find((r) => r.name === state.region) || {};
      if (region.name) {
        city = City.getCitiesOfState(state.country, region.isoCode) || {};
        city = city.name;
      }
      region = region.name;
    }
    updateObjectState(setState, { region: region, city: city });
  }, [state.country]);

  useEffect(() => {
    let city;
    if (state.region) {
      const region =
        State.getStatesOfCountry(state.country).find(
          (r) => r.name === state.region
        ) || {};
      const regionCities = City.getCitiesOfState(state.country, region.isoCode);
      setCities(regionCities);
      city = regionCities.find((c) => c.name === state.city) || {};
      city = city.name;
    }
    updateObjectState(setState, { city: city });
  }, [state.region]);

  return [
    {
      valueKey: "profile_image",
      type: FormFieldTypes.IMAGE,
      imageRounded: true,
      imageWidth: imageWidth,
      maxFileMb: 0.15,
      errorMsg:
        "Please click the image to upload a profile picture for this employee.",
      isRequired: false,
      validation: () => {
        return true;
      },
    },
    {
      valueKey: "work_phone",
      labelName: "Work Phone Number",
      info: "Example +23276123456",
      validation: (phoneNumber) => {
        const phone = parsePhoneNumberFromString(phoneNumber || "");
        return phone ? phone.isValid() : false;
      },
      type: FormFieldTypes.TEL,
    },
    {
      valueKey: "work_email",
      labelName: "Work Email",
      errorMsg: "Please enter a valid work email",
      validation: (email) => {
        return isValidEmail(email);
      },
      info: "This is the email that the employee will use to log into the system. If it is changed, the employee will need to use the new email to log in.",
    },
    {
      valueKey: "country",
      labelName: "Home Address Country",
      type: FormFieldTypes.OBJECT_SELECT,
      optionsList: Country.getAllCountries(),
      optionsValueKey: "isoCode",
      optionsLabelKey: displayCountryCodes ? "isoCode" : "name",
    },
    {
      valueKey: "region",
      labelName: "Home Address Region / State / District",
      optionsList: regions,
      type: FormFieldTypes.OBJECT_SELECT,
      optionsValueKey: "name",
      optionsLabelKey: "name",
    },
    {
      valueKey: "city",
      labelName: "Home Address City / Town",
      type: FormFieldTypes.OBJECT_SELECT,
      optionsList: cities,
      optionsValueKey: "name",
      optionsLabelKey: "name",
    },
    { valueKey: "address", labelName: "Home Address" },
    {
      valueKey: "address_1",
      labelName: "Home Address Apt / Unit",
      isRequired: false,
    },
  ];
};
