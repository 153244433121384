import React from "react";
import { Button, Card, Dropdown, Table } from "react-bootstrap";
import { FaChevronLeft, FaChevronRight, FaEdit, FaPlus } from "react-icons/fa";
import { ConditionalView } from "../../../utils/components";
import { BsLock, BsThreeDotsVertical, BsTrash } from "react-icons/bs";
import {
  dateFormats,
  formatDate,
  formDate,
  getUTCDateTime,
  hrsMinsSecsDiff,
  isoWeekday,
  time24Hrs,
} from "../../../utils/dateTime";
import { ABBR_DAYS_OF_WEEK } from "../../../data/weeks";

function AttendanceCalendar({
  activeDate,
  attendance,
  navigation,
  attendanceInfo,
  navCallback,
  permissions,
  canManage,
  addCallback,
  editCallback,
  deleteCallback,
  contextState,
}) {
  if (!attendance) return <div />;
  const daysInMonth = parseInt(
    activeDate.endOf("month").startOf("day").format("D")
  );

  const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);

  const weeks = [];
  let week = [];

  days.forEach((day, index) => {
    const date = activeDate.add(index, "day");
    const dayOfWeek = isoWeekday(date);
    const isAttended = attendance[`${day}`].length > 0;

    // add empty cells at the start of the month
    if (day === 1 && dayOfWeek !== 1) {
      const emptyCells = Array.from({ length: dayOfWeek - 1 }, () => null);
      week = [...emptyCells, { day, isAttended }];
    } else {
      week.push({ day, isAttended });
    }

    if (week.length === 7 || index === days.length - 1) {
      weeks.push(week);
      week = [];
    }
  });

  const schedule_assignments = attendanceInfo.schedule_assignments;
  const times_off = attendanceInfo.times_off;

  return (
    <Card>
      <Card.Header className={"py-4 d-flex justify-content-between"}>
        <Button
          variant={"outline-primary"}
          onClick={() => {
            const nav = { ...navigation };
            nav.start_date = nav.previous_month;
            navCallback(nav);
          }}
        >
          <FaChevronLeft size={30} />
        </Button>
        <h4>{formatDate(activeDate, "MMMM YYYY")}</h4>
        <Button
          variant={"outline-primary"}
          onClick={() => {
            const nav = { ...navigation };
            nav.start_date = nav.next_month;
            navCallback(nav);
          }}
        >
          <FaChevronRight size={30} />
        </Button>
      </Card.Header>
      <Card.Body>
        <Table responsive={true} bordered className={"border-5"}>
          <thead>
            <tr className={"text-center py-4 bg-primary"}>
              {ABBR_DAYS_OF_WEEK.map((day, index) => (
                <th key={index} className={"py-3"}>
                  <h6>{day}</h6>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {weeks.map((week, i) => (
              <tr key={`week${i}`} className={"py-5 border-3"}>
                {week.map((day, j) => {
                  if (day) {
                    const dayVal = day.day;

                    const dayScheduleAssignment = schedule_assignments[dayVal];
                    const dayAttendance = attendance[dayVal];
                    const dayTimesOff = times_off[dayVal];

                    const hasDayScheduleAssignment =
                      dayScheduleAssignment.length > 0;
                    const hasDayAttendance = dayAttendance.length > 0;
                    const hasDayTimesOff = dayTimesOff.length > 0;

                    const dayDate = activeDate.date(dayVal);
                    const isPast = dayDate.isBefore(
                      getUTCDateTime().endOf("day")
                    );

                    // const hasData =
                    //   dayAttendance.length > 0 ||
                    //   dayTimesOff.length > 0 ||
                    //   dayScheduleAssignment.length > 0;

                    return (
                      <td key={j} className={`custom-cell`}>
                        <div className={"mt-2 d-flex justify-content-between"}>
                          <h5 className={"my-auto"}>{day.day}</h5>
                          {permissions.MANAGE_CLOCKIN_CLOCKOUT && isPast && (
                            <Button
                              variant={"outline-primary"}
                              size={"sm"}
                              onClick={() => {
                                addCallback(dayDate);
                              }}
                            >
                              <FaPlus />
                            </Button>
                          )}
                        </div>

                        <Card
                          className={`mt-3 border-${
                            hasDayScheduleAssignment ? "2" : "1"
                          } border-${
                            hasDayScheduleAssignment ? "warning" : "secondary"
                          }`}
                        >
                          <Card.Header
                            className={`border-${
                              hasDayScheduleAssignment ? "2" : "1"
                            } border-${
                              hasDayScheduleAssignment ? "warning" : "secondary"
                            }`}
                          >
                            <Card.Title>Schedule</Card.Title>
                          </Card.Header>
                          <Card.Body>
                            <ConditionalView
                              condition={hasDayScheduleAssignment}
                              trueView={
                                <>
                                  {dayScheduleAssignment.map(
                                    (assignment, index) => (
                                      <Card.Text key={index}>
                                        {time24Hrs(assignment.start_datetime)} -{" "}
                                        {time24Hrs(assignment.end_datetime)}
                                      </Card.Text>
                                    )
                                  )}
                                </>
                              }
                              falseView={
                                <>
                                  <strong>None</strong>
                                </>
                              }
                            />
                          </Card.Body>
                        </Card>

                        <Card
                          className={`mt-2 border-${
                            hasDayAttendance ? "2" : "1"
                          } border-${
                            hasDayAttendance ? "success" : "secondary"
                          }`}
                        >
                          <Card.Header
                            className={`border-${
                              hasDayAttendance ? "2" : "1"
                            } border-${
                              hasDayAttendance ? "success" : "secondary"
                            }`}
                          >
                            <Card.Title>Attended</Card.Title>
                          </Card.Header>
                          <Card.Body className={"p-2"}>
                            <ConditionalView
                              condition={hasDayAttendance}
                              trueView={
                                <>
                                  {dayAttendance.map((attendance, index) => {
                                    const [hours, minutes, seconds] =
                                      hrsMinsSecsDiff(
                                        attendance.clockin_time,
                                        attendance.clockout_time
                                      );

                                    return (
                                      <div
                                        key={index}
                                        className={
                                          "mb-1 d-flex justify-content-between align-items-center"
                                        }
                                      >
                                        <Card.Text className={"fw-bold"}>
                                          In:{" "}
                                          {time24Hrs(attendance.clockin_time)}
                                          <br />
                                          Out:{" "}
                                          {attendance.clockout_time
                                            ? time24Hrs(
                                                attendance.clockout_time
                                              )
                                            : "Now"}
                                          <br />
                                          <strong className={"text-success"}>
                                            {hours}h {minutes}m {seconds}s
                                          </strong>
                                        </Card.Text>
                                        <div>
                                          <ConditionalView
                                            condition={
                                              attendance.payroll_locked
                                            }
                                            trueView={
                                              <div>
                                                <BsLock />
                                              </div>
                                            }
                                            falseView={
                                              <div>
                                                <Dropdown>
                                                  <Dropdown.Toggle
                                                    variant={
                                                      contextState.darkMode
                                                        ? "outline-light"
                                                        : "outline-dark"
                                                    }
                                                    className={
                                                      "m-0 border-0 p-0 hide-caret"
                                                    }
                                                  >
                                                    <BsThreeDotsVertical
                                                      className={"pointer"}
                                                    />
                                                  </Dropdown.Toggle>
                                                  <Dropdown.Menu>
                                                    <Dropdown.Item
                                                      className={
                                                        "primary-hover"
                                                      }
                                                      onClick={() => {
                                                        editCallback(
                                                          dayDate,
                                                          attendance
                                                        );
                                                      }}
                                                    >
                                                      <FaEdit /> Edit
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                      className={"danger-hover"}
                                                      onClick={() => {
                                                        deleteCallback(
                                                          attendance.id
                                                        );
                                                      }}
                                                    >
                                                      <BsTrash /> Delete
                                                    </Dropdown.Item>
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              </div>
                                            }
                                          />
                                        </div>
                                      </div>
                                    );
                                  })}
                                </>
                              }
                              falseView={
                                <>
                                  <strong>None</strong>
                                </>
                              }
                            />
                          </Card.Body>
                        </Card>

                        <Card
                          className={`mt-2 border-${
                            hasDayTimesOff ? "2" : "1"
                          } border-${hasDayTimesOff ? "danger" : "secondary"}`}
                        >
                          <Card.Header
                            className={`border-${
                              hasDayTimesOff ? "2" : "1"
                            } border-${
                              hasDayTimesOff ? "danger" : "secondary"
                            }`}
                          >
                            <Card.Title>Time Off</Card.Title>
                          </Card.Header>
                          <Card.Body>
                            <ConditionalView
                              condition={hasDayTimesOff}
                              trueView={
                                <>
                                  {dayTimesOff.map((timeOff, index) => (
                                    <Card.Text key={index}>
                                      {formatDate(
                                        timeOff.start_date,
                                        dateFormats.shortDate
                                      )}
                                      {" - "}
                                      {formatDate(
                                        timeOff.end_date,
                                        dateFormats.shortDate
                                      )}
                                    </Card.Text>
                                  ))}
                                </>
                              }
                              falseView={
                                <>
                                  <strong>None</strong>
                                </>
                              }
                            />
                          </Card.Body>
                        </Card>
                      </td>
                    );
                  } else {
                    return <td key={j} className={"custom-cell"} />;
                  }
                })}
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}

export default AttendanceCalendar;
