import React, { useContext, useEffect, useRef, useState } from "react";
import { LoadingView } from "../utils/components";
import DashboardPageContainer from "./components/DashboardPageContainer";
import {
  toTitleCase,
  useAppModal,
  useOffCanvas,
  useToast,
} from "../utils/functions";
import { AppContext } from "../utils/components/AppContext";
import { OffCanvasContext } from "../utils/components/OffCanvasContext";
import { useNavigate } from "react-router-dom";
import OrganizationTree from "./components/Organization/OrganizationTree";
import PageMetaTags from "./components/Shared/PageMetaTags";

export function OrganizationTreePageComponent({ getRequest }) {
  const navigate = useNavigate();
  const { showOffCanvas } = useOffCanvas();
  const { contextState, updateContextState } = useContext(AppContext);
  const { closeOffCanvas, restoreOffCanvas } = useContext(OffCanvasContext);

  useEffect(() => {
    getOrganizationTreeInfo();
  }, []);

  const [treeData, setTreeData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getOrganizationTreeInfo = async () => {
    getRequest(`/organization/org-tree`, setIsLoading, (response) => {
      setTreeData(response.organization_tree);
    });
  };

  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <div>
          <PageMetaTags title={`Organization Tree`} />
          {treeData && <OrganizationTree organizationTree={treeData} />}
        </div>
      }
    />
  );
}

function OrganizationTreePage() {
  return (
    <DashboardPageContainer PageComponent={OrganizationTreePageComponent} />
  );
}
export default OrganizationTreePage;
