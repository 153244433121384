import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import {
  FaPhone,
  FaEnvelope,
  FaCalendarAlt,
  FaEdit,
  FaPlus,
  FaDirections,
} from "react-icons/fa";
import { BsFillBriefcaseFill } from "react-icons/bs";
import { formatDate } from "../../utils/dateTime";
import { TbSwitch, TbSwitchVertical } from "react-icons/tb";
import { AppRoutes } from "../../utils/appRoutes";
import { apiRequest } from "../../utils/apiRequests";
import { useAppModal } from "../../utils/functions";

export const OrganizationCard = ({
  nodeData,
  organization,
  onEditClick,
  onAddChildClick,
}) => {
  organization = organization || nodeData;
  const {
    name,
    id,
    description,
    logo,
    industry,
    industry_category,
    date_founded,
  } = organization;

  const { showAppModal } = useAppModal();

  return (
    <Card className="rounded-3 shadow">
      <Card.Header className={"text-center py-5"}>
        <div className={"d-flex justify-content-end mb-3"}>
          {onEditClick && (
            <Button
              onClick={() => {
                onEditClick();
              }}
              variant="outline-primary"
              className="me-2"
            >
              <FaEdit className={"me-2"} />
              Edit
            </Button>
          )}
        </div>
        <img
          src={logo}
          alt={name}
          className={"rounded-circle mx-auto"}
          width={"auto"}
          height={100}
        />
        <Card.Title className="fs-2 my-3">{name}</Card.Title>
        <Card.Subtitle className="mb-4">{description}</Card.Subtitle>
        <Card.Subtitle className={"fs-4"}>{industry}</Card.Subtitle>
      </Card.Header>
      <Card.Body>
        <div className={"p-4"}>
          <Row className="my-4">
            {industry_category && (
              <Col className="d-flex align-items-center">
                <BsFillBriefcaseFill className="text-primary me-2" size={35} />
                <Card.Text className="fs-6">{industry_category}</Card.Text>
              </Col>
            )}
            {date_founded && (
              <Col className="d-flex align-items-center">
                <FaCalendarAlt className="text-primary me-2" size={35} />
                <Card.Text className="fs-6">
                  {formatDate(date_founded)}
                </Card.Text>
              </Col>
            )}
          </Row>
        </div>
      </Card.Body>
      {(onAddChildClick || organization.is_member) && (
        <Card.Footer>
          <div className={"d-flex justify-content-end py-3"}>
            {organization.is_member && !organization.is_current && (
              <div className={"d-flex align-items-center"}>
                <Button
                  variant="outline-primary"
                  className="me-2"
                  onClick={() => {
                    showAppModal({
                      title: `Switch to ${organization.name}`,
                      component: (
                        <p>
                          Are you sure you want to switch to the{" "}
                          {organization.name} organization? Your page will
                          reload and you will be logged out of your current
                          organization.
                        </p>
                      ),
                      truthyFunction: async () => {
                        const { success, response } = await apiRequest.get(
                          `/organization/switch/${organization.id}`
                        );
                        if (success) {
                          window.location.href = AppRoutes.Home.path;
                        } else {
                          showAppModal({
                            title: "Error",
                            component: <p>{response.message}</p>,
                            hideFooter: true,
                          });
                        }
                      },
                    });
                  }}
                >
                  <TbSwitchVertical className={"me-2"} />
                  Switch to this Organization
                </Button>
              </div>
            )}
            {organization.is_member && organization.is_current && (
              <h6 className={"text-warning fst-italic"}>
                Current Organization
              </h6>
            )}

            {onAddChildClick && (
              <Button
                onClick={() => {
                  onAddChildClick();
                }}
                variant="outline-primary"
                className="me-2"
              >
                <FaPlus className={"me-2"} />
                Add a Child Organization
              </Button>
            )}
          </div>
        </Card.Footer>
      )}
    </Card>
  );
};
