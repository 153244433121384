export const RELIGIONS = [
  "Christianity",
  "Islam",
  "Hinduism",
  "Buddhism",
  "Judaism",
  "Sikhism",
  "Taoism",
  "Confucianism",
  "Shintoism",
  "Zoroastrianism",
  "Jainism",
  "Bahá'í Faith",
  "Rastafarianism",
  "Scientology",
  "Wicca",
  "Asatru",
  "Druidism",
  "Vodou (Voodoo)",
  "Santeria",
  "Animism",
];
