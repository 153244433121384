import React, { useContext } from "react"
import { AddItemButton } from "../../../utils/components"
import { useOffCanvas } from "../../../utils/functions"
import { AppContext } from "../../../utils/components/AppContext"
import { Card, Table } from "react-bootstrap"
import GenericForm from "../../Forms/Shared/GenericForm"
import { dateFormats, formatDate } from "../../../utils/dateTime"

function StaffAttendanceDevices({
  devices,
  setDevices,
  putRequest,
  loaderCallback,
  organizationId,
}) {
  const { showOffCanvas } = useOffCanvas()
  const { updateContextState } = useContext(AppContext)

  return (
    <div>
      <div className={"mb-4"}>
        <AddItemButton
          title={"Generate Device Codes"}
          onClick={() => {
            updateContextState({
              formData: {},
            })
            showOffCanvas({
              title: `Add a Device`,
              subtitle: `Add a new  device for this organization`,
              component: (
                <>
                  <GenericForm
                    formConfigs={[
                      {
                        valueKey: "number_of_codes",
                        info: "Number of codes to generate to be used for device authorization",
                      },
                    ]}
                    onSubmit={(data) => {
                      putRequest(
                        `/staff/organization/${organizationId}/attendance-device-codes`,
                        loaderCallback,
                        (res) => {
                          setDevices([...res.devices, ...devices])
                        },
                        data
                      )
                    }}
                  />
                </>
              ),
            })
          }}
        />
      </div>

      <Card>
        <Card.Header className={"py-4"}>
          <Card.Title>Attendance Device Authorization Codes</Card.Title>
          <Card.Subtitle
            as={"p"}
            className={"mt-3"}
          >
            Device authorization codes are used to link devices to this
            organization. These devices will enable the devices to be used as
            clock in/out devices for staff.
          </Card.Subtitle>
        </Card.Header>
        <Card.Body>
          <Table
            striped
            bordered
            responsive
          >
            <thead>
              <tr>
                <th>Code</th>
                <th>Used</th>
                <th>Created On</th>
              </tr>
            </thead>
            <tbody>
              {devices.map((device, i) => {
                return (
                  <tr key={i}>
                    <td>{device.device_code}</td>
                    <td>{device.organization_id ? "Yes" : "No"}</td>
                    <td>
                      {formatDate(
                        device.timestamp,
                        dateFormats.shortDateTime24HrSecs
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  )
}

export default StaffAttendanceDevices
