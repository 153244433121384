import React, { useEffect, useState } from "react";
import { Button, Card, Col, ProgressBar, Row } from "react-bootstrap";
import DateTimeNavHeader from "./DateTimeNavHeader";
import { LoadingView, NoInfoCard } from "../../../utils/components";
import EmployeeActivityCard from "../People/EmployeeActivityCard";
import AttendanceTable from "../Employee/AttendanceTable";
import {
  formatDate,
  formDate,
  secondsToHoursFloat,
  thisWeekEnd,
  thisWeekStart,
  weekMonthAndYearStartAndEnd,
} from "../../../utils/dateTime";
import InfoCard from "../../../utils/components/InfoCard";
import { calculatePercentage } from "../../../utils/functions";
import ScheduleAttendanceTable from "../Employee/ScheduleAttendanceTable";

function EmployeesAttendanceView({
  attendancesEndpoint,
  statsEndpoint,
  subTitle,
  getRequest,
  useAttendanceTable = false,
}) {
  const [allStats, setAllStats] = useState({});
  const [activeDate, setActiveDate] = useState(weekMonthAndYearStartAndEnd());
  const [statsListInfo, setStatsListInfo] = useState([]);
  const [stats, setStats] = useState({});

  const [secondLoader, setSecondLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [attendances, setAttendances] = useState({});
  const [fetchMoreData, setFetchMoreData] = useState(false);
  const [activeData, setActiveData] = useState({
    data: [],
  });

  const weekKey = (date) => {
    return `${thisWeekStart(date)}-${thisWeekEnd(date)}`;
  };

  const getStats = async (date = "none") => {
    const uriEncodedDate = encodeURIComponent(date);
    getRequest(`${statsEndpoint}?date=${uriEncodedDate}`, null, (response) => {
      setStats(response.stats);
      setAllStats({
        ...allStats,
        [weekKey(date)]: response.stats,
      });
    });
  };

  useEffect(() => {
    const periodDate = activeData?.periodInfo?.datetime_end;

    if (periodDate) {
      setActiveDate(weekMonthAndYearStartAndEnd(periodDate));
      if (!allStats[weekKey(periodDate)]) {
        getStats(periodDate);
      } else {
        getStats(periodDate);
      }
    }
  }, [activeData?.periodInfo?.datetime_end]);

  useEffect(() => {
    if (activeDate.weekStart) {
      setStatsListInfo([
        {
          title: "Week Attendance",
          key: "week",
        },
        {
          title: "Month Attendance",
          key: "month",
        },
        {
          title: "Year Attendance",
          key: "year",
        },
      ]);
    }
  }, [activeDate]);

  return (
    <div>
      <Card>
        <Card.Header className={"py-4"}>
          <Card.Title as={"h4"} className={"mb-3"}>
            Attendance
          </Card.Title>
          <Card.Subtitle>{subTitle}</Card.Subtitle>
        </Card.Header>
        <Card.Body>
          <DateTimeNavHeader
            fetchedData={attendances}
            setFetchedData={setAttendances}
            activeData={activeData}
            setActiveData={setActiveData}
            fetchDataEndpoint={attendancesEndpoint}
            setIsLoading={setIsLoading}
            setSecondLoader={setSecondLoader}
            fetchMoreData={fetchMoreData}
            setfetchMoreData={setFetchMoreData}
            requestParams={{}}
          />

          <Row className={"my-5"}>
            {statsListInfo.map((info, index) => {
              const percentage = calculatePercentage(
                stats[`${info.key}_attended_seconds`],
                stats[`${info.key}_scheduled_seconds`]
              );

              return (
                <Col sm={6} lg={4} key={index} className={"mb-3"}>
                  <Card className={"text-center"}>
                    <Card.Header className={"py-3"}>
                      <Card.Title>{`${info.title}`}</Card.Title>
                    </Card.Header>
                    <Card.Body className={"py-4"}>
                      <div className={"d-flex justify-content-between mb-4"}>
                        <div className={"text-center w-50"}>
                          <h4>
                            {secondsToHoursFloat(
                              stats[`${info.key}_attended_seconds`]
                            )}
                          </h4>
                          <small>Attended Hours</small>
                        </div>
                        <div className={"text-center w-50"}>
                          <h4>
                            {secondsToHoursFloat(
                              stats[`${info.key}_scheduled_seconds`]
                            )}
                          </h4>
                          <small>Scheduled Hours</small>
                        </div>
                      </div>
                      <ProgressBar
                        now={percentage || 0}
                        label={`${percentage}%`}
                        variant={"success"}
                      />
                    </Card.Body>
                    <Card.Footer className={"py-3"}>
                      <Card.Subtitle>
                        {formatDate(activeDate[`${info.key}Start`])} -{" "}
                        {formatDate(activeDate[`${info.key}End`])}
                      </Card.Subtitle>
                    </Card.Footer>
                  </Card>
                </Col>
              );
            })}
          </Row>

          <LoadingView
            isLoading={isLoading}
            centerLoader={false}
            fullHeight={false}
            addAppToasts={true}
            view={
              <div>
                <div className={"mt-4"}>
                  {useAttendanceTable ? (
                    <AttendanceTable attendances={activeData.data} />
                  ) : (
                    <ScheduleAttendanceTable schedules={activeData.data} />
                  )}
                  {activeData.data.length === 0 && (
                    <NoInfoCard
                      text={"No attendance records found for this date range"}
                    />
                  )}
                </div>
              </div>
            }
          />
        </Card.Body>
        <Card.Footer>
          <div className={"justify-content-center text-center my-5"}>
            {secondLoader && (
              <div className={"spinner-border text-primary"} role={"status"}>
                <span className={"visually-hiddens"}>Loading...</span>
              </div>
            )}
            {activeData?.pagingInfo && (
              <div className={"mb-3"}>
                <h6>Last Batch: {activeData?.pagingInfo.range_info}</h6>
              </div>
            )}
            {!secondLoader && activeData?.pagingInfo?.has_next && (
              <Button
                variant={"primary"}
                onClick={() => {
                  setFetchMoreData(true);
                }}
              >
                Load {activeData?.pagingInfo?.per_page} More
              </Button>
            )}
          </div>
        </Card.Footer>
      </Card>
    </div>
  );
}

export default EmployeesAttendanceView;
