import React, { useContext, useState } from "react";
import { Button, Form, Alert } from "react-bootstrap";
import { AppContext } from "../../../utils/components/AppContext";
import { formatDateStrings } from "../../../utils/dateTime";
import { COUNTRIES } from "../../../data/countries";

function EmployeeSkillForm({ onSubmit, configs }) {
  const { contextState, updateContextState } = useContext(AppContext);
  const [formState, setFormState] = useState(
    formatDateStrings({ ...contextState.formData })
  );
  const [fileError, setFileError] = useState("");

  const handleInputChange = (event) => {
    const { name, value, files } = event.target;

    if (name === "certificate_file") {
      const file = files[0];
      if (file) {
        if (file.size > 5242880) {
          // 5MB in bytes
          setFileError("File size cannot exceed 5MB");
          return;
        }
        if (file.type !== "application/pdf") {
          setFileError("Only PDF files are allowed");
          return;
        }
        setFileError("");
        setFormState((prevState) => ({
          ...prevState,
          [name]: file,
        }));
      }
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const formValid = () => {
    const requiredFields = [
      "name",
      "description",
      "proficiency",
      "completion_date",
    ];
    return requiredFields.every((field) => formState[field]) && !fileError;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!formValid()) {
      alert("Please correct the errors before submitting.");
      return;
    }
    updateContextState({
      formData: { ...formState },
    });
    onSubmit(formState);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Text as={"p"} className={"fw-bold"}>
        Required fields are marked with an{" "}
        <span className="text-danger">*</span>
      </Form.Text>

      {/* Name */}
      <Form.Group controlId="fieldOfStudy" className={"mb-4"}>
        <Form.Label>
          Name<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          name="name"
          value={formState.name || ""}
          onChange={handleInputChange}
          required
        />
      </Form.Group>

      {/* Description */}
      <Form.Group controlId="institutionName" className={"mb-4"}>
        <Form.Label>
          Description<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          name="description"
          value={formState.description || ""}
          onChange={handleInputChange}
          required
        />
      </Form.Group>

      {/* Proficiency */}
      <Form.Group controlId="level" className={"mb-4"}>
        <Form.Label>
          Proficiency<span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          name="proficiency"
          value={formState.proficiency || ""}
          onChange={handleInputChange}
          required
        >
          <option value="">Select Proficiency</option>
          {configs.employee_skill_proficiency_levels.map((proficiency) => (
            <option key={proficiency} value={proficiency}>
              {proficiency}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      {/*Dates*/}
      <Form.Group controlId="startDate" className={"mb-4"}>
        <Form.Label>
          Completion Date<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="date"
          name="completion_date"
          value={formState.completion_date || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      {/* File Upload for Degree/Diploma/Certificate Document */}
      <Form.Group
        controlId="degreeDiplomaCertificateDocumentFileKey"
        className={"mb-4"}
      >
        <Form.Label>Certificate PDF Document (Optional)</Form.Label>
        <Form.Control
          type="file"
          name="certificate_file"
          onChange={handleInputChange}
          accept=".pdf"
          placeholder={"Upload a PDF file"}
        />
        <Form.Text ad={"p"} className={"my-3"}>
          Only PDF files are allowed. Maximum file size is 5MB.
        </Form.Text>
        {fileError && (
          <Alert variant="danger" className="mt-2">
            {fileError}
          </Alert>
        )}
      </Form.Group>

      <Button
        variant="primary"
        type="submit"
        className={"w-100 mb-5 mt-3"}
        disabled={!formValid()}
      >
        Save
      </Button>
    </Form>
  );
}

export default EmployeeSkillForm;
