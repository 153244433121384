import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../utils/components/AppContext";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import DashboardPageContainer from "../components/DashboardPageContainer";
import { LoadingView } from "../../utils/components";
import { toTitleCase } from "../../utils/functions";
import PageMetaTags from "../components/Shared/PageMetaTags";
import { Tab, Tabs } from "react-bootstrap";
import { StaffPermissions } from "../../enums/staffEnums";
import StaffOrganizationCard from "../components/Staff/StaffOrganizationCard";

function StaffMain({ getRequest, postRequest, putRequest, patchRequest }) {
  const {
    contextState,
    updateContextState,
    hasStaffPermission,
    hasAnyStaffPermission,
  } = useContext(AppContext);
  const { permissions } = contextState;

  const updateQueryParam = (key, value) => {
    setSearchParams({ ...searchParams, [key]: value });
  };
  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(searchParams.get("activeTab"));
  const [organizations, setOrganizations] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const [fetchedTab, setFetchedTab] = useState([]);
  const [configs, setConfigs] = useState({});

  const activateTab = (key) => {
    setActiveTab(key);
    updateQueryParam("activeTab", key);
  };

  useEffect(() => {
    activateTab(searchParams.get("activeTab"));
  }, [searchParams]);

  useEffect(() => {
    getRequest("/staff/home", setIsLoading, (response) => {
      setOrganizations(response.organizations);
      setStaffs(response.staffs);
      setConfigs(response.configs);
    });
  }, []);

  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <div>
          <PageMetaTags title={`Staff | ${toTitleCase(activeTab)}`} />
          <Tabs
            activeKey={activeTab}
            className={"mb-2 mt-3 sticky-top bg-body"}
            variant={"underline"}
            onSelect={(key) => activateTab(key)}
          >
            {hasAnyStaffPermission([
              StaffPermissions.VIEW_ORGANIZATIONS,
              StaffPermissions.MANAGE_ORGANIZATIONS_SUBSCRIPTIONS,
              StaffPermissions.MANAGE_ORGANIZATIONS,
            ]) && (
              <Tab eventKey={"organizations"} title="Organizations">
                <StaffOrganizationCard
                  organizations={organizations}
                  setOrganizations={setOrganizations}
                  configs={configs}
                  putRequest={putRequest}
                  postRequest={postRequest}
                  loaderCallback={setIsLoading}
                />
              </Tab>
            )}
            {hasAnyStaffPermission([
              StaffPermissions.VIEW_STAFF,
              StaffPermissions.MANAGE_STAFF,
            ]) && (
              <Tab eventKey={"staff"} title="Staff">
                <div></div>
              </Tab>
            )}
          </Tabs>
        </div>
      }
    />
  );
}

function StaffMainPage() {
  return <DashboardPageContainer PageComponent={StaffMain} />;
}

export default StaffMainPage;
