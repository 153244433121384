import React from "react";

function EmployeeBasicHighlight({ employee }) {
  return (
    <div>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className={"d-flex flex-row align-items-center"}>
          <img
            src={employee.profile_image}
            alt={employee.full_name}
            width={40}
            height={40}
            className="rounded-circle me-1"
          />
          <div className={"text-star"}>
            <strong>{employee.full_name}</strong>
            <div className={"small"}>
              {employee?.position?.title || "No Position"} - (
              {employee.employee_number})
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmployeeBasicHighlight;
