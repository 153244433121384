import React from "react";
import { Form, Button } from "react-bootstrap";
import { isEmpty } from "../../utils/functions";

export function DepartmentForm({ onSubmit, state, setState }) {
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(state);
  };

  const formValid = () => {
    const requiredFields = ["name", "code"];
    let valid = true;
    for (const field of requiredFields) {
      if (isEmpty(state[field])) {
        valid = false;
        break;
      }
    }
    return valid;
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="departmentName" className={"mb-4"}>
        <Form.Label>Name *</Form.Label>
        <Form.Control
          type="text"
          name="name"
          required
          value={state.name || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="departmentDescription" className={"mb-4"}>
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          name="description"
          value={state.description || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="departmentCode" className={"mb-4"}>
        <Form.Label>Code *</Form.Label>
        <Form.Control
          type="text"
          name="code"
          required
          value={state.code || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="departmentDateFormed" className={"mb-4"}>
        <Form.Label>Date Formed</Form.Label>
        <Form.Control
          type="date"
          name="date_formed"
          value={state.date_formed || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Button
        variant="primary"
        type="submit"
        disabled={!formValid()}
        className={"w-100"}
      >
        Save
      </Button>
    </Form>
  );
}
