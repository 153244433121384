import React, { useEffect, useState } from "react";
import { Button, Card, Form, InputGroup } from "react-bootstrap";
import { AppConstants } from "../../../utils/appConstants";
import { apiRequest } from "../../../utils/apiRequests";
import { toQueryParams, updateObjectState } from "../../../utils/functions";
import {
  FaCalendarDay,
  FaChevronLeft,
  FaChevronRight,
  FaTimes,
} from "react-icons/fa";
import {
  dateFormats,
  formatDate,
  getUTCDateTime,
  periodStartEndDatetime,
} from "../../../utils/dateTime";
import { ConditionalView } from "../../../utils/components";
import AppToasts from "../../../utils/components/AppToasts";

function DateTimeNavHeader({
  fetchedData,
  setFetchedData,
  activeData,
  setActiveData,
  fetchDataEndpoint,
  setIsLoading,
  setSecondLoader,
  fetchMoreData,
  setfetchMoreData,
  requestParams = {},
}) {
  const [isDateSelection, setIsDateSelection] = useState(false);
  const [allowDateSelection, setAllowDateSelection] = useState(false);
  const [customDate, setCustomDate] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (activeData?.periodInfo?.period === "Daily") {
      setAllowDateSelection(true);
    } else {
      setAllowDateSelection(false);
    }
  }, [activeData?.periodInfo?.period]);

  const constructKey = (period, datetime) => {
    const datetimeStr = datetime
      ? formatDate(datetime, dateFormats.fullDateTime)
      : periodStartEndDatetime(period).end;
    return `${period}-${datetimeStr}`;
  };

  const fetchOrUpdate = (period, datetime) => {
    const key = constructKey(period, datetime);

    if (fetchedData[key]) {
      setActiveData(fetchedData[key]);
    } else {
      fetchData({ period: period, datetime_end: datetime });
    }
  };

  const fetchData = async (
    periodInfo = null,
    paging = null,
    loader = setIsLoading
  ) => {
    loader(true);
    const { success, response } = await apiRequest.post(
      `${fetchDataEndpoint}?${toQueryParams(paging || {})}`,
      { ...(periodInfo || activeData.periodInfo), ...requestParams }
    );

    if (success) {
      const time_period_info = response.period_info;
      let activeInfo = {
        periodInfo: time_period_info,
        data: response.data,
        pagingInfo: response.paging_info || {},
      };

      const key = constructKey(
        time_period_info.period,
        time_period_info?.datetime_end
      );

      if (fetchedData[key] && Array.isArray(activeInfo.data)) {
        activeInfo.data = [...fetchedData[key].data, ...response.data];
      }

      updateObjectState(setFetchedData, {
        [key]: { ...activeInfo },
      });
      setActiveData(activeInfo);
    }
    loader(false);
  };

  const getMoreData = async () => {
    if (fetchMoreData && activeData?.pagingInfo?.has_next) {
      await fetchData(
        activeData.periodInfo,
        activeData.pagingInfo,
        setSecondLoader
      );
      setfetchMoreData(false);
    }
  };

  useEffect(() => {
    getMoreData();
  }, [fetchMoreData]);

  return (
    <div>
      <Card>
        <Card.Header className={"d-flex justify-content-center py-4"}>
          {AppConstants.timePeriodTypes.map((item, index) => {
            return (
              <Button
                key={index}
                variant={
                  activeData?.periodInfo?.period === item
                    ? "primary"
                    : "outline-primary"
                }
                className={"me-2"}
                onClick={() => {
                  let datetime = null;
                  Object.keys(fetchedData).forEach((key) => {
                    if (key.includes(item)) {
                      datetime = fetchedData[key].periodInfo.datetime_end;
                      return;
                    }
                  });
                  fetchOrUpdate(item, datetime);
                }}
              >
                {item}
              </Button>
            );
          })}
        </Card.Header>
        {activeData?.periodInfo?.show_date_nav && (
          <Card.Body className={"d-flex justify-content-center py-4"}>
            <Button
              variant={"outline-primary"}
              onClick={() => {
                fetchOrUpdate(
                  activeData?.periodInfo?.period,
                  activeData?.periodInfo?.previous_datetime_end
                );
              }}
            >
              <FaChevronLeft className={"me-2"} />
              Previous
            </Button>
            <div className={"mx-5 text-center"}>
              <ConditionalView
                condition={isDateSelection}
                trueView={
                  <InputGroup>
                    <InputGroup.Text
                      className={"pointer"}
                      onClick={() => {
                        setIsDateSelection(false);
                      }}
                    >
                      <FaTimes />
                    </InputGroup.Text>
                    <Form.Control
                      type={"date"}
                      value={customDate || ""}
                      onChange={(e) => {
                        setCustomDate(e.target.value);
                      }}
                    />
                    <Button
                      variant={"primary"}
                      onClick={() => {
                        setIsDateSelection(false);
                        fetchOrUpdate(
                          activeData?.periodInfo?.period,
                          customDate
                        );
                      }}
                    >
                      Submit
                    </Button>
                  </InputGroup>
                }
                falseView={
                  <h5>
                    {`${formatDate(
                      activeData?.periodInfo?.datetime_start,
                      dateFormats.shortDateTime24HrSecs
                    )} - ${formatDate(
                      activeData?.periodInfo?.datetime_end,
                      dateFormats.shortDateTime24HrSecs
                    )}`}
                  </h5>
                }
              />
              {allowDateSelection && !isDateSelection && (
                <Button
                  variant={"link"}
                  onClick={() => {
                    setIsDateSelection(true);
                  }}
                >
                  <FaCalendarDay className={"me-2"} />
                  Enter Date
                </Button>
              )}
            </div>
            <Button
              variant={"outline-primary"}
              onClick={() => {
                fetchOrUpdate(
                  activeData?.periodInfo?.period,
                  activeData?.periodInfo?.next_datetime_end
                );
              }}
              className={"ms-2"}
            >
              <FaChevronRight className={"me-2"} />
              Next
            </Button>
          </Card.Body>
        )}
      </Card>
    </div>
  );
}

export default DateTimeNavHeader;
