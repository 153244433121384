import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function AppBreadCrumb({ items }) {
  /*
  items: array of objects with the following
  [
    {
      text: string,
      href: string,
      path: string,
      onClick: function
    }
   */
  const navigate = useNavigate();
  return (
    <Breadcrumb>
      {items.map((item, index) => {
        const isLast = index === items.length - 1 && items.length > 1;
        return (
          <Breadcrumb.Item
            key={index}
            href={item.href || "#"}
            active={isLast}
            onClick={
              isLast
                ? undefined
                : (e) => {
                    e.preventDefault();
                    if (item.onClick) {
                      item.onClick();
                    } else {
                      navigate(item.path);
                    }
                  }
            }
          >
            {item.text}
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
}

export default AppBreadCrumb;
