import React from "react";
import IndexNavbar from "../components/Index/IndexNavbar";
import { Col, Container, Image, Row } from "react-bootstrap";
import headerBackgroundImage from "../../assets/images/header.jpg";
import employeeProfile from "../../assets/images/employee-page.png";
import orgChart from "../../assets/images/org-chart.png";
import headerImage from "../../assets/images/header-image.png";
import { MdOutlineBusinessCenter } from "react-icons/md";
import { FaUsers } from "react-icons/fa";
import { BiStats } from "react-icons/bi";
import { AiOutlineSchedule } from "react-icons/ai";
import FeatureSection from "../components/Index/FeatureSection";

import appHomePage from "../../assets/gosl/images/app-home-page.png";
import appHClockinAllowed from "../../assets/gosl/images/app-clockin-allowed.png";
import appClockInNotAllowed from "../../assets/gosl/images/app-clockin-not-allowed.png";
import PageMetaTags from "../components/Shared/PageMetaTags";
import { useMediaQuery } from "react-responsive";
import { appConfigs } from "../../configs";
import ImageWithInfoCard from "../components/Shared/ImageWithInfoCard";
import humnceWhiteColorOutlineLogo from "../../assets/logos/white-color-outline.png";
import { AppConstants } from "../../utils/appConstants";

const featuresData = [
  {
    title: "Organizational Mastery",
    subtitle:
      "Manage branches, permissions, and departments efficiently within the Government of Sierra Leone.",
    icon: MdOutlineBusinessCenter,
  },
  {
    title: "Government Workforce Management",
    subtitle:
      "Access and organize government employee data, team structures, and role definitions to support governance and collaboration.",
    icon: FaUsers,
  },
  {
    title: "Attendance Management",
    subtitle:
      "Utilize flexible time-off policies and geo-tagged attendance tracking to maintain accurate records for government employees.",
    icon: AiOutlineSchedule,
  },
  {
    title: "Data Insights",
    subtitle:
      "Utilize analytics to monitor and evaluate government employee performance and operational trends effectively.",
    icon: BiStats,
  },
];

const featuresSectionData = [
  {
    title: "Manage Government Employees",
    bulletPoints: [
      "View comprehensive data for each government employee.",
      "Keep track of position assignments, levels, and departmental histories.",
      "Handle personal and professional milestones efficiently.",
    ],
    image: employeeProfile,
    imageRight: false,
  },
  {
    title: "Organizational Structure Visualization",
    bulletPoints: [
      "Easily navigate through government departmental and team structures.",
      "Comprehend hierarchies and direct reporting lines within the government.",
      "Gain a complete perspective of your organizational architecture.",
    ],
    image: orgChart,
    imageRight: true,
  },
];

function GoSLIndexPage() {
  const headerStyle = {
    backgroundImage: `url(${headerBackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    height: "50vh",
  };

  const overlayStyle = {
    backgroundColor: "rgba(4, 26, 188, 0.5)", // Updated with GoSL color
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
  };

  const isSmallDevice = useMediaQuery({ maxWidth: 768 });

  const appFeatures = [
    // {
    //   title: "Welcome to Your Government HR Dashboard",
    //   bulletPoints: [
    //     "Get a personalized greeting and a snapshot of your upcoming week in the government sector.",
    //     "Switch effortlessly between your schedule, announcements, and more for government activities.",
    //     "Never miss out on your colleagues' milestones and official government events.",
    //   ],
    //   image: appHomePage,
    // },
    {
      title: "Attendance Made Easy for Government Employees",
      bulletPoints: [
        "Track your attendance status and view your proximity to your assigned workplace.",
        "Check in and out with a simple tap, using geo-tagged time tracking for your work locations.",
        "Review your attendance records anytime for personal tracking and accountability within the government framework.",
      ],
      imageRight: !isSmallDevice,
      image: appHClockinAllowed,
    },
    {
      title: "Stay Within Reach",
      bulletPoints: [
        "Receive instant notifications if you're clocking in from outside the allowed distance for government facilities.",
        "Clear instructions to correct your location for successful clock-ins or out.",
        "Full visibility into your attendance history for reliability and trust within government operations.",
      ],
      image: appClockInNotAllowed,
    },
  ];

  return (
    <div>
      <PageMetaTags title={`${appConfigs.appName} - GoSL HR Hub`} />
      <IndexNavbar />
      <Container className={"mt-5 pt-5"}>
        <Row>
          <Col lg={6} className={"d-flex align-items-center"}>
            <div>
              <h1 className={""}>
                Welcome to the HR Hub for the
                <br />
                Government of Sierra Leone
              </h1>
              <p className={"fs-4 mt-4"}>
                Your comprehensive hub for streamlined employee management,
                attendance tracking, and HR resource access.
              </p>
            </div>
          </Col>
          <Col
            lg={6}
            className={"d-flex align-items-center justify-content-center"}
            style={{
              padding: "100px 20px 100px 20px",
            }}
          >
            <Image
              src={headerImage}
              alt={"Government HR Software"}
              fluid
              className={"img-fluid mx-auto"}
              width={500}
            />
          </Col>
        </Row>
      </Container>
      <div className={"bg-primary text-white py-5"}>
        <Container className={"my-5"}>
          <Row>
            {featuresData.map((feature, index) => (
              <div key={index} className={"col-md-6 col-lg-3 mb-4"}>
                <div className="text-center">
                  <feature.icon size={50} />
                  <h5 className={"mt-3"}>{feature.title}</h5>
                  <p>{feature.subtitle}</p>
                </div>
              </div>
            ))}
          </Row>
        </Container>
      </div>
      <div>
        <Container>
          {featuresSectionData.map((feature, index) => {
            if (isSmallDevice) {
              feature.imageRight = false;
            }
            return <FeatureSection {...feature} key={index} />;
          })}
        </Container>
      </div>
      <div className={"py-5"}>
        <Container>
          <h1 className={"text-center"}>
            Easy to use app for government employees
          </h1>
          <p className={"text-center mb-5 mt-3"}>
            Empower your government team with personalized dashboards, seamless
            attendance tracking, and real-time organizational insights.
          </p>
          <Row>
            {appFeatures.map((feature, index) => (
              <Col lg={6} key={index}>
                <ImageWithInfoCard {...feature} />
              </Col>
            ))}
          </Row>
        </Container>
      </div>

      <footer className="d-flex flex-column align-items-center mt-5">
        <div>
          <Image
            src={humnceWhiteColorOutlineLogo}
            alt={"HR Software"}
            width={50}
          />
        </div>
        <div className={"mt-3 mb-5 text-center"}>
          <h5>
            <em>Powered by humnce</em>
          </h5>
          <p className={"mt-3"}>
            © {new Date().getFullYear()} humnce. - All Rights Reserved
          </p>
        </div>
      </footer>
    </div>
  );
}

export default GoSLIndexPage;
