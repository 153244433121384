import React, { useState, useEffect, useContext } from "react";
import { Navbar, Nav, Image, Dropdown, Container, Col } from "react-bootstrap";
import Brand from "./Brand";
import { AppRoutes } from "../../utils/appRoutes";
import { AppContext } from "../../utils/components/AppContext";
import { FaCog, FaSignOutAlt, FaUser, FaUserTie } from "react-icons/fa";
import { AppConstants } from "../../utils/appConstants";
import { appConfigs } from "../../configs";
import { useNavigate } from "react-router-dom";

function TopNavbar({ getRequest, bkgColor, toggleSidebar }) {
  const [bgColor, setBgColor] = useState("bg-transparent");
  const { contextState, updateContextState } = useContext(AppContext);

  const employee = contextState.employee || {};
  const organization = contextState.organization || {};

  const navigation = useNavigate();

  if (contextState.isMobileApp) {
    return <div />;
  }

  return (
    <Navbar
      expand={"lg"}
      className={`fixed-to ${bkgColor} d-flex justify-content-between align-items-center ps-lg-5 pe-lg-3`}
    >
      {/* Left: Navbar Toggle */}
      <div>
        <Navbar.Toggle
          aria-controls="navbar-nav"
          className={"border-0"}
          onClick={(e) => {
            e.preventDefault();
            toggleSidebar();
          }}
        />
      </div>

      {/* Center: Logo */}
      <div className="d-flex justify-content-center ms-3 ms-lg-0">
        <Image
          src={appConfigs.primaryLogo}
          alt={`${appConfigs.appName} logo`}
          height="35"
          width="35"
          className={"align-self-center pointer"}
          onClick={() => {
            navigation(AppRoutes.Home.path);
          }}
        />
      </div>

      {/* Right: Profile Dropdown */}
      <div className={"z-3"}>
        <Dropdown align="end" className={"border-0 z-3"}>
          <Dropdown.Toggle
            id="dropdown-basic"
            className={"bg-transparent border-0"}
          >
            <Image
              src={employee.profile_image}
              alt="Profile"
              height="30"
              width="30"
              className="rounded-circle"
            />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Header>
              <strong>{employee.full_name}</strong>
              <br />
              <small>{employee?.position?.title || "No Position"}</small>
              <br />
              <small>{organization.name}</small>
            </Dropdown.Header>
            <Dropdown.Divider />
            <Dropdown.Item
              onClick={() => {
                navigation(AppRoutes.MyProfile.path);
              }}
              className={"mb-2"}
            >
              <FaUserTie /> My Profile
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                getRequest("/user/signout");
              }}
              className="text-danger"
            >
              <FaSignOutAlt /> Sign Out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </Navbar>
  );
}

export default TopNavbar;
