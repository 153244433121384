// EmployeePayslipDocument.jsx
import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { formatMoney } from "../../../utils/functions";

const EmployeePayslipDocument = ({ data, organization }) => {
  // Define styles
  const styles = StyleSheet.create({
    page: {
      padding: 20,
      fontSize: 11,
      fontFamily: "Helvetica",
      lineHeight: 1.3,
    },
    header: {
      borderBottom: "2 solid #000",
      marginBottom: 15,
      paddingBottom: 10,
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    companyInfo: {
      flexDirection: "column",
    },
    employeeInfoSection: {
      marginBottom: 15,
      padding: 10,
      backgroundColor: "#F5F5F5",
      borderRadius: 5,
    },
    sectionTitle: {
      fontSize: 12,
      fontWeight: "bold",
      marginBottom: 5,
      borderBottom: "1 solid #000",
      paddingBottom: 5,
    },
    table: {
      display: "table",
      width: "auto",
      borderStyle: "solid",
      borderColor: "#EEE",
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      marginBottom: 10,
    },
    tableRow: {
      flexDirection: "row",
    },
    tableColHeader: {
      width: "25%",
      borderStyle: "solid",
      borderColor: "#000",
      borderBottomColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      backgroundColor: "#D3D3D3",
      padding: 5,
    },
    tableCol: {
      width: "25%",
      borderStyle: "solid",
      borderColor: "#EEE",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      padding: 5,
    },
    tableCellHeader: {
      fontWeight: "bold",
    },
    footer: {
      position: "absolute",
      bottom: 40,
      left: 40,
      right: 40,
      textAlign: "center",
      fontSize: 10,
      color: "grey",
    },
    logo: {
      width: 60,
      height: 60,
    },
    title: {
      fontSize: 16,
      marginBottom: 15,
      textAlign: "center",
      // textDecoration: "underline",
    },
    netPaySection: {
      padding: 10,
      backgroundColor: "#F0FFF0",
      borderRadius: 5,
      marginBottom: 10,
    },
  });

  // Destructure data
  const {
    full_name,
    employee_number,
    position_title,
    net_comp,
    total_comp,
    deductions,
    comp_total = total_comp,
    total_deductions,
    profile_image,
    compensations,
    payment_date,
    period_start_date,
    period_end_date,
  } = data;

  const {
    name: companyName,
    logo: companyLogo,
    address: companyAddress,
  } = organization;

  // Calculate total employer contributions
  const totalEmployerContributions = data.org_deductions_paid_total;

  // Calculate total deductions including employer contributions
  const grandTotalDeductions = data.total_dedcutions;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Header */}
        <View style={styles.header}>
          <View style={styles.companyInfo}>
            <Text style={{ fontSize: 16, fontWeight: "bold" }}>
              {companyName}
            </Text>
            <Text>{companyAddress}</Text>
            {/* Include company email if available */}
          </View>
          <View>
            {companyLogo && <Image src={companyLogo} style={styles.logo} />}
          </View>
        </View>

        {/* Title */}
        <Text style={styles.title}>
          Payslip for {period_start_date} - {period_end_date}
        </Text>

        <Text
          style={{
            fontWeight: "bold",
            marginBottom: 10,
          }}
        >
          Payment Date: {payment_date}
        </Text>

        {/* Employee Info */}
        <View style={styles.employeeInfoSection}>
          <Text style={styles.sectionTitle}>Employee Information</Text>
          <Text>Name: {full_name}</Text>
          <Text>Employee ID: {employee_number}</Text>
          <Text>Position: {position_title}</Text>
          <Text style={{ marginTop: 5 }}>Address:</Text>
          <Text>{data.address}</Text>
        </View>

        {/* Compensation Table */}
        <View>
          <Text style={styles.sectionTitle}>Compensation</Text>
          <View style={styles.table}>
            {/* Table Header */}
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Description</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Amount</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Frequency</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Currency</Text>
              </View>
            </View>

            {/* Compensation Rows */}
            {compensations.map((comp) => (
              <View style={styles.tableRow} key={comp.id}>
                <View style={styles.tableCol}>
                  <Text>{comp.name}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text>{formatMoney(comp.amount)}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text>{comp.frequency}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text>
                    {comp.currency_convertion_rate
                      ? `${comp.currency} to ${
                          data.currency
                        } @ 1 = ${formatMoney(comp.currency_convertion_rate)}`
                      : comp.currency}
                  </Text>
                </View>
              </View>
            ))}
            {/* Total Compensation */}
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={{ fontWeight: "bold" }}>Total Earnings</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={{ fontWeight: "bold" }}>
                  {formatMoney(comp_total)}
                </Text>
              </View>
              <View style={styles.tableCol}>
                {/* Empty cell for alignment */}
                <Text></Text>
              </View>
              <View style={styles.tableCol}>
                <Text></Text>
              </View>
            </View>
          </View>
        </View>

        {/* Deductions Table */}
        <View>
          <Text style={styles.sectionTitle}>Deductions</Text>
          <View style={styles.table}>
            {/* Table Header */}
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Description</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>
                  Employee Contribution
                </Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>
                  Employer Contribution
                </Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>From</Text>
              </View>
            </View>

            {/* Deductions Rows */}
            {deductions.map((deduction) => (
              <View style={styles.tableRow} key={deduction.id}>
                <View style={styles.tableCol}>
                  <Text>{deduction.name}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text>-{formatMoney(deduction.emp_total_amount)}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text>{formatMoney(deduction.org_total_amount)}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text>{deduction.comp_name}</Text>
                </View>
              </View>
            ))}

            {/* Total Deductions */}
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={{ fontWeight: "bold" }}>Total Deductions</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={{ fontWeight: "bold" }}>
                  -{formatMoney(data.emp_deductions_paid_total)}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={{ fontWeight: "bold" }}>
                  {formatMoney(data.org_deductions_paid_total)}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text></Text>
              </View>
            </View>
          </View>
        </View>

        {/* Net Pay */}
        <View style={styles.netPaySection}>
          <Text style={{ fontWeight: "bold", marginBottom: 7, fontSize: 11 }}>
            Net Pay
          </Text>
          <Text
            style={{
              fontSize: 14,
              fontWeight: "bold",
              textAlign: "left",
            }}
          >
            {data.currency} {formatMoney(net_comp)}
          </Text>
        </View>

        {/* Footer */}
        <Text style={styles.footer}>
          This payslip was generated electronically and is valid without a
          signature.
        </Text>
      </Page>
    </Document>
  );
};

export default EmployeePayslipDocument;
