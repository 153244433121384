import React, { useContext, useEffect, useState } from "react"
import { AppOffCanvasLocal, LoadingView } from "../utils/components"
import { apiRequest } from "../utils/apiRequests"
import DashboardPageContainer from "./components/DashboardPageContainer"
import {
  toQueryParams,
  toTitleCase,
  updateObjectState,
  useAppModal,
  useOffCanvas,
  useToast,
} from "../utils/functions"
import { AppContext } from "../utils/components/AppContext"
import { OffCanvasContext } from "../utils/components/OffCanvasContext"
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
import { AppRoutes } from "../utils/appRoutes"
import { FaChevronLeft } from "react-icons/fa"
import { EmployeeWorkInfo } from "./components/EmployeeManagement"
import { formatDateStrings } from "../utils/dateTime"
import EmployeeForm from "./Forms/Employee"
import { Tab, Tabs } from "react-bootstrap"
import ManageProfile from "./components/Employee/ManageProfile"
import ManageCompensation from "./components/Employee/ManageCompensation"
import ManageTimeOff from "./components/Employee/ManageTimeOff"
import AttendanceManagement from "./components/Employee/AttendanceManagement"
import { TeamHighlight } from "./components/Team"
import PageMetaTags from "./components/Shared/PageMetaTags"
import EducationAndSkillsView from "./components/Employee/EducationAndSkillsView"
import DocumentsView from "./components/Employee/DocumentsView"
import { SelfEmployeeFormFieldsDefinitions } from "./Forms/Employee/EmployeeFormFieldsDefinitions"
import GenericForm from "./Forms/Shared/GenericForm"
import { FormFieldTypes } from "./FormFields"
import { Products } from "../enums/OrgEnums"

export function MyProfile({
  getRequest,
  postRequest,
  putRequest,
  patchRequest,
  deleteRequest,
}) {
  const { contextState, updateContextState, hasOrgProduct } =
    useContext(AppContext)
  // const { permissions } = contextState;
  const { showOffCanvas } = useOffCanvas()
  const permissions = {}
  const { showAppModal, closeModal } = useAppModal()

  const navigate = useNavigate()

  const location = useLocation()

  const [showAoc, setShowAoc] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [formState, setFormState] = useState({})
  const formConfigs = SelfEmployeeFormFieldsDefinitions(formState, setFormState)

  const updateQueryParam = (key, value) => {
    setSearchParams({ ...searchParams, [key]: value })
  }

  useEffect(() => {
    getEmployeeInfoInfo()
  }, [])

  const [data, setData] = useState({
    current_month: new Date().getUTCMonth().toString(),
    requested_time_off_data: false,
    requested_attendance_data: false,
  })
  const [activeTab, setActiveTab] = useState("profile_info")
  const [configs, setConfigs] = useState({ teams: [], time_off_policies: [] })
  const [employee, setEmployee] = useState({
    id: null,
    available_time_off: {},
  })
  const [showSecondLoader, setShowSecondLoader] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [viewedAttendanceRecords, setViewedAttendanceRecords] = useState({})

  const getEmployeeInfoInfo = async () => {
    getRequest(`/employee/my-profile`, setIsLoading, (response) => {
      updateObjectState(setEmployee, response.employee)
      updateObjectState(setConfigs, response.configs)
      setFormState(response.employee)
    })
  }

  useEffect(() => {
    // if (!configs.time_off_policies) return;
    if (searchParams.get("activeTab")) {
      activateTab(searchParams.get("activeTab"))
    }
  }, [configs, employee.id])

  const getAttendances = async (nav) => {
    let viewed = viewedAttendanceRecords[nav.start_date]
    let attendances = {}
    let navigation = {}
    let attendance_info = {}
    if (viewed) {
      attendances = { ...viewed.attendances }
      navigation = { ...viewed.navigation }
      attendance_info = { ...viewed.attendance_info }
    } else {
      await getRequest(
        `/employee/attendance/${employee.id}?${toQueryParams(nav)}`,
        setShowSecondLoader,
        (response) => {
          attendances = { ...response.attendances }
          navigation = response.navigation
          attendance_info = response.attendance_info

          updateObjectState(setViewedAttendanceRecords, {
            [`${navigation.active_month}`]: {
              attendances,
              navigation,
              attendance_info,
            },
          })
        }
      )
    }
    updateObjectState(setEmployee, {
      attendances,
      navigation,
      attendance_info,
    })
  }

  const activateTab = (key) => {
    setActiveTab(key)
    updateQueryParam("activeTab", key)
    if (key === "timeOff" && !data.requested_time_off_data) {
      getRequest(
        `/employee/available-time-off/${employee.id}`,
        setShowSecondLoader,
        (response) => {
          updateObjectState(setConfigs, {
            time_off_policies: response.time_off_policies,
          })
          updateObjectState(setEmployee, {
            available_time_off: response.available_time_off,
          })
          updateObjectState(setData, {
            requested_time_off_data: true,
          })
        }
      )
    }

    if (key === "attendance" && !data.requested_attendance_data) {
      getRequest(
        `/employee/attendance/${employee.id}`,
        setShowSecondLoader,
        (response) => {
          updateObjectState(setEmployee, {
            attendances: response.attendances,
            navigation: response.navigation,
            last_attendance: response.last_attendance,
            attendance_info: response.attendance_info,
          })
          updateObjectState(setData, {
            requested_attendance_data: true,
          })
          updateObjectState(setViewedAttendanceRecords, {
            [response.navigation.active_month]: {
              attendances: response.attendances,
              navigation: response.navigation,
              attendance_info: response.attendance_info,
            },
          })
        }
      )
    }
  }

  const updateProfile = (formData) => {
    patchRequest(
      `/employee/self`,
      setIsLoading,
      (response) => {
        updateObjectState(setEmployee, response.employee)
        setFormState(response.employee)
        setShowAoc(false)
      },
      formData,
      true,
      false
    )
  }

  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <div className={"mb-5"}>
          <PageMetaTags title={`My Profile | ${toTitleCase(activeTab)}`} />
          <>
            {!contextState.isMobileApp && (
              <h3 className={"mb-4 text-center"}>My Profile</h3>
            )}
            <div className={"mb-4"}>
              <EmployeeWorkInfo
                employee={employee}
                canManage={true}
                allowSupervisorClick={permissions.VIEW_EMPLOYEE_DATA}
                onEditClick={() => {
                  setShowAoc(true)
                }}
              />
            </div>
          </>

          <Tabs
            activeKey={activeTab}
            className={"mb-2 mt-3 sticky-top bg-body"}
            variant={"underline"}
            onSelect={(key) => activateTab(key)}
          >
            {hasOrgProduct(Products.PEOPLE_MANAGEMENT) && (
              <Tab
                eventKey="profile_info"
                title="Profile"
              >
                <LoadingView
                  isLoading={showSecondLoader}
                  centerLoader={false}
                  view={
                    <>
                      <ManageProfile
                        employee={employee}
                        setEmployee={setEmployee}
                        patchRequest={patchRequest}
                        putRequest={putRequest}
                        permissions={permissions}
                        configs={configs}
                        loaderCallback={setShowSecondLoader}
                        isSelf={true}
                      />
                    </>
                  }
                />
              </Tab>
            )}
            {hasOrgProduct(Products.PAYROLL_MANAGEMENT) && (
              <Tab
                eventKey="compensationInfo"
                title="Compensation"
              >
                <LoadingView
                  isLoading={showSecondLoader}
                  centerLoader={false}
                  view={
                    <>
                      <ManageCompensation
                        employee={employee}
                        setEmployee={setEmployee}
                        permissions={permissions}
                        configs={configs}
                        putRequest={putRequest}
                        patchRequest={patchRequest}
                        loaderCallback={setShowSecondLoader}
                      />
                    </>
                  }
                />
              </Tab>
            )}
            {hasOrgProduct(Products.TIME_OFF_MANAGEMENT) && (
              <Tab
                eventKey="timeOff"
                title="Time Off"
              >
                <LoadingView
                  isLoading={showSecondLoader}
                  centerLoader={false}
                  view={
                    <>
                      <ManageTimeOff
                        employee={employee}
                        setEmployee={setEmployee}
                        permissions={{
                          MANAGE_ATTENDANCE: true,
                        }}
                        configs={configs}
                        putRequest={putRequest}
                        patchRequest={patchRequest}
                        getRequest={getRequest}
                        loaderCallback={setShowSecondLoader}
                        isAdmin={false}
                      />
                    </>
                  }
                />
              </Tab>
            )}

            {hasOrgProduct(Products.ATTENDANCE_MANAGEMENT) && (
              <Tab
                eventKey="attendance"
                title="Attendance"
              >
                {/* Attendance Content */}
                <LoadingView
                  isLoading={showSecondLoader && activeTab === "attendance"}
                  centerLoader={false}
                  view={
                    <>
                      {data.requested_attendance_data && (
                        <AttendanceManagement
                          employee={employee}
                          setEmployee={setEmployee}
                          getRequest={getRequest}
                          loaderCallback={setShowSecondLoader}
                          navCallback={(nav) => {
                            getAttendances(nav)
                          }}
                          permissions={permissions}
                          contextState={contextState}
                          putRequest={putRequest}
                          patchRequest={patchRequest}
                          deleteRequest={deleteRequest}
                        />
                      )}
                    </>
                  }
                />
              </Tab>
            )}

            {/*{permissions.VIEW_EXPENSE_REQUESTS && (*/}
            {/*  <Tab eventKey="expenses" title="Expenses">*/}
            {/*    /!* Performance Content *!/*/}
            {/*  </Tab>*/}
            {/*)}*/}

            {/*{permissions.VIEW_EMPLOYEE_PERFORMANCE && (*/}
            {/*  <Tab eventKey="performance" title="Performance">*/}
            {/*    /!* Performance Content *!/*/}
            {/*  </Tab>*/}
            {/*)}*/}

            {/*{permissions.VIEW_ASSET_ASSIGNMENT && (*/}
            {/*  <Tab eventKey="assets" title="Assets">*/}
            {/*    /!* Assets Content *!/*/}
            {/*  </Tab>*/}
            {/*)}*/}

            {hasOrgProduct(Products.PEOPLE_MANAGEMENT) && (
              <Tab
                eventKey="teams"
                title="Teams"
              >
                {configs.teams.map((team, index) => (
                  <TeamHighlight
                    key={index}
                    team={team}
                    onMakePrimaryClick={
                      !permissions.MANAGE_EMPLOYEE_DATA
                        ? null
                        : () => {
                            getRequest(
                              `/employee/primary=team/${employee.id}/${team.id}`,
                              setShowSecondLoader,
                              (response) => {
                                updateObjectState(setConfigs, {
                                  teams: response.teams,
                                })
                                updateObjectState(
                                  setEmployee,
                                  response.employee
                                )
                              },
                              true
                            )
                          }
                    }
                  />
                ))}
              </Tab>
            )}

            {hasOrgProduct(Products.PEOPLE_MANAGEMENT) && (
              <Tab
                eventKey="education_&_skills"
                title="Education & Skills"
              >
                <LoadingView
                  isLoading={showSecondLoader}
                  centerLoader={false}
                  view={
                    <>
                      <EducationAndSkillsView
                        employee={employee}
                        setEmployee={setEmployee}
                        configs={configs}
                        purRequest={putRequest}
                        patchRequest={patchRequest}
                        permissions={permissions}
                        loaderCallback={setShowSecondLoader}
                      />
                    </>
                  }
                />
              </Tab>
            )}

            {hasOrgProduct(Products.PEOPLE_MANAGEMENT) && (
              <Tab
                eventKey="documents"
                title="Documents"
              >
                <LoadingView
                  isLoading={showSecondLoader}
                  centerLoader={false}
                  view={
                    <>
                      <DocumentsView
                        employee={employee}
                        setEmployee={setEmployee}
                        configs={configs}
                        purRequest={putRequest}
                        patchRequest={patchRequest}
                        permissions={permissions}
                        loaderCallback={setShowSecondLoader}
                      />
                    </>
                  }
                />
              </Tab>
            )}
          </Tabs>

          <AppOffCanvasLocal
            showOffCanvas={showAoc}
            setShowOffCanvas={setShowAoc}
            title={"Update your Profile"}
            subtitle={
              "You will only be able to change some of your information. If you want to change something else, please contact your HR department."
            }
            component={
              <div>
                <GenericForm
                  formConfigs={formConfigs}
                  useFormData={true}
                  useGlobalState={false}
                  stateData={formState}
                  setStateData={setFormState}
                  onSubmit={(formData) => {
                    if (formState.work_email !== employee.work_email) {
                      updateContextState({
                        formData: {},
                      })
                      showAppModal({
                        title: "You are about to update your email",
                        hideFooter: true,
                        component: (
                          <div>
                            <h6>
                              To proceed, please enter your account password
                            </h6>

                            <GenericForm
                              formConfigs={[
                                {
                                  valueKey: "password",
                                  type: FormFieldTypes.PASSWORD,
                                },
                              ]}
                              useFormData={false}
                              onSubmit={(pwData) => {
                                closeModal()
                                formData.append("password", pwData.password)
                                updateProfile(formData)
                              }}
                            />
                          </div>
                        ),
                      })
                    } else {
                      updateProfile(formData)
                    }
                  }}
                />
              </div>
            }
          />
        </div>
      }
    />
  )
}

function MyProfilePage() {
  return <DashboardPageContainer PageComponent={MyProfile} />
}
export default MyProfilePage
