import React, { useContext, useEffect, useState } from "react"
import { AppContext } from "../../utils/components/AppContext"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import DashboardPageContainer from "../components/DashboardPageContainer"
import { LoadingView } from "../../utils/components"
import PageMetaTags from "../components/Shared/PageMetaTags"
import { toTitleCase, updateObjectState } from "../../utils/functions"
import { Tab, Tabs } from "react-bootstrap"
import { StaffPermissions } from "../../enums/staffEnums"
import PeopleStats from "../components/People/PeopleStats"
import { OrganizationCard } from "../components/OrganizationCard"
import GenericDocumentsView from "../components/Shared/GenericDocumentsView"
import StaffOrgSubscriptionView from "../components/Staff/StaffOrgSubscriptionView"
import AccountingAccountsTab from "../components/Accounting/AccountingAccountsTab"
import StaffAttendanceDevices from "../components/Staff/StaffAttendanceDevices"

function StaffOrganizationView({
  getRequest,
  postRequest,
  putRequest,
  patchRequest,
}) {
  const { contextState, updateContextState, hasStaffPermission } =
    useContext(AppContext)
  const { permissions, labelsOverride } = contextState

  const navigate = useNavigate()

  const { organizationId } = useParams()

  const [searchParams, setSearchParams] = useSearchParams()
  const updateQueryParam = (key, value) => {
    setSearchParams({ ...searchParams, [key]: value })
  }

  const [isLoading, setIsLoading] = useState(false)
  const [activeTab, setActiveTab] = useState(searchParams.get("activeTab"))
  const [showSecondLoader, setShowSecondLoader] = useState(false)
  const [organization, setOrganization] = useState({})
  const [documents, setDocuments] = useState([])
  const [subscriptions, setSubscriptions] = useState([])
  const [overview, setOverview] = useState({})
  const [configs, setConfigs] = useState({})
  const [devices, setDevices] = useState([])
  const [cashAccounts, setCashAccounts] = useState({})

  useEffect(() => {
    getRequest(
      `/staff/organization/${organizationId}`,
      setIsLoading,
      (response) => {
        setOrganization(response.info)
        setConfigs(response.configs)
        setSubscriptions(response.subscriptions)
        setDocuments(response.documents)
        setCashAccounts(response.cash_accounts)
        setDevices(response.attendance_devices)
      }
    )
  }, [])

  useEffect(() => {
    if (
      !configs.stats_types ||
      !hasStaffPermission(StaffPermissions.VIEW_DETAILED_ORGANIZATION_STATS)
    )
      return
    const getOrgOverview = async () => {
      for (const type_ of configs.stats_types) {
        await getRequest(
          `/staff/organization/${organizationId}/overview?type=${type_}`,
          null,
          (response) => {
            updateObjectState(setOverview, {
              ...response.results,
            })
          }
        )
      }
    }
    getOrgOverview()
  }, [configs.stats_types])

  const activateTab = (key) => {
    setActiveTab(key)
    updateQueryParam("activeTab", key)
  }

  useEffect(() => {
    activateTab(searchParams.get("activeTab"))
  }, [searchParams])

  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <div>
          <PageMetaTags title={`Staff | ${toTitleCase(activeTab)}`} />
          <Tabs
            activeKey={activeTab}
            className={"mb-2 mt-3 sticky-top bg-body"}
            variant={"underline"}
            onSelect={(key) => activateTab(key)}
          >
            {hasStaffPermission(
              StaffPermissions.VIEW_DETAILED_ORGANIZATION_STATS
            ) && (
              <Tab
                eventKey="overview"
                title="Overview"
              >
                <OrganizationCard organization={organization} />
                <PeopleStats
                  overview={overview}
                  includeAddChildrenOption={false}
                />
              </Tab>
            )}

            <Tab
              eventKey="subscriptions"
              title={"Subscriptions"}
            >
              <LoadingView
                isLoading={showSecondLoader}
                centerLoader={false}
                view={
                  <>
                    <StaffOrgSubscriptionView
                      subscriptions={subscriptions}
                      setSubscriptions={setSubscriptions}
                      organization={organization}
                      putRequest={putRequest}
                      patchRequest={patchRequest}
                      loaderCallback={setShowSecondLoader}
                      configs={configs}
                    />
                  </>
                }
              />
            </Tab>

            {hasStaffPermission(
              StaffPermissions.VIEW_ORGANIZATIONS_DOCUMENTS
            ) && (
              <Tab
                eventKey="documents"
                title={"Documents"}
              >
                <LoadingView
                  isLoading={showSecondLoader}
                  centerLoader={false}
                  view={
                    <>
                      <GenericDocumentsView
                        documents={documents}
                        hasManagePermission={hasStaffPermission(
                          StaffPermissions.MANAGE_ORGANIZATIONS_DOCUMENTS
                        )}
                        onAdd={(formData) => {
                          putRequest(
                            `/staff/organization/${organizationId}/document`,
                            setShowSecondLoader,
                            (response) => {
                              setDocuments([response.document, ...documents])
                            },
                            formData,
                            true
                          )
                        }}
                        onUpdate={(formData, index) => {
                          patchRequest(
                            `/staff/organization-document/${organization.documents[index].id}`,
                            setShowSecondLoader,
                            (response) => {
                              setDocuments(
                                documents.map((doc, i) =>
                                  i === index ? response.document : doc
                                )
                              )
                            },
                            formData,
                            true
                          )
                        }}
                      />
                    </>
                  }
                />
              </Tab>
            )}

            {hasStaffPermission(StaffPermissions.VIEW_FINANCIALS) && (
              <Tab
                eventKey="cash_accounts"
                title={"Cash Accounts"}
              >
                <LoadingView
                  isLoading={showSecondLoader}
                  centerLoader={false}
                  view={
                    <>
                      <AccountingAccountsTab
                        accounts={cashAccounts}
                        setAccounts={setCashAccounts}
                        permissions={{}}
                        getRequest={getRequest}
                        putRequest={putRequest}
                        loaderCallback={setShowSecondLoader}
                        patchRequest={patchRequest}
                        configs={configs}
                        staffManageMode={hasStaffPermission(
                          StaffPermissions.MANAGE_FINANCIALS
                        )}
                      />
                    </>
                  }
                />
              </Tab>
            )}

            <Tab
              eventKey="attendance_devices"
              title={"Attendance Devices"}
            >
              <LoadingView
                isLoading={showSecondLoader}
                centerLoader={false}
                view={
                  <>
                    <StaffAttendanceDevices
                      devices={devices}
                      setDevices={setDevices}
                      putRequest={putRequest}
                      loaderCallback={setShowSecondLoader}
                      organizationId={organizationId}
                    />
                  </>
                }
              />
            </Tab>
          </Tabs>
        </div>
      }
    />
  )
}

function StaffOrganizationViewPage() {
  return <DashboardPageContainer PageComponent={StaffOrganizationView} />
}

export default StaffOrganizationViewPage
