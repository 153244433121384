import React, { useEffect, useRef, useState } from "react";
import {
  dateFormats,
  formatDateInDayJs,
  getDayJsDateTime,
} from "../../../utils/dateTime";
import { priorityColor } from "../../../utils/functions";

function DayHours({ day, items, darkMode, onboxLongPress, seriesFormDatas }) {
  const hours = Array.from({ length: 24 }, (_, i) => i);
  const [isToday, setIsToday] = useState(false);
  const [currentHour, setCurrentHour] = useState(null);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [currentTimeTopPosition, setCurrentTimeTopPosition] = useState(0);

  // Assuming each hour has a height of 100
  const hourHeight = 100;
  const minuteHeight = hourHeight / 60;

  const scrollViewRef = useRef(null);
  const itemRefs = useRef([]);


  useEffect(() => {
    setCurrentHour(`${getDayJsDateTime().hour()}`);
    updateCurrentTimePosition();
  }, []);
  // Function to handle content size change
  const handleContentSizeChange = () => {
    setContentLoaded(true); 
  };

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      handleContentSizeChange(); // Call the handler when the size changes
    });
    if (scrollViewRef.current) {
      observer.observe(scrollViewRef.current);
    }
    // Clean up the observer when the component unmounts
    return () => {
      if (scrollViewRef.current) {
        observer.unobserve(scrollViewRef.current);
      }
    };
  }, [scrollViewRef, items]);

  const updateCurrentTimePosition = () => {
    const now = getDayJsDateTime();
    const currentHour = now.hour();
    const currentMinutes = now.minute();
    const topPosition =
      currentHour * hourHeight + currentMinutes * minuteHeight;
    setCurrentTimeTopPosition(topPosition);
    // Scroll to the current time position after the view is loaded
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({
        top: topPosition - hourHeight / 2, // Adjusting so that the current time is not at the very top
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    // Check if the displayed day is today
    const today = getDayJsDateTime().startOf('day');
    const isCurrentDay = getDayJsDateTime(day).isSame(today, 'day');
    setIsToday(isCurrentDay);

    if (isCurrentDay) {
      setCurrentHour(`${getDayJsDateTime().hour()}`);
      updateCurrentTimePosition();
    }
  }, [day]);

  useEffect(() => {
    const intervalId = setInterval(updateCurrentTimePosition, 60000);
    return () => clearInterval(intervalId);
  }, []);

  const indexAndHourItems = (item, itemIndex, currentHrItemsCount) => {
    const crossOverItemsIndexes = [];
    const overlapInfo = [];

    // Start and end time of the current item
    const itemStart = getDayJsDateTime(item.start_datetime);
    const itemEnd = getDayJsDateTime(item.end_datetime);
    let totalHrItemsCount = currentHrItemsCount;

    // Loop through all the hours (0-23) and check for overlapping events
    for (let i = 0; i < 24; i++) {
      const hourItems = items[`${i}`] || [];
      const hourItemsCount = hourItems.length;

      hourItems.forEach((hourItem, index) => {
        const hourItemStart = getDayJsDateTime(hourItem.start_datetime);
        const hourItemEnd = getDayJsDateTime(hourItem.end_datetime);
        const isOverlap =
          itemStart.isBefore(hourItemEnd) && itemEnd.isAfter(hourItemStart);

        if (isOverlap && hourItem.id !== item.id && i !== itemStart.hour()) {
          if (itemStart.hour() > hourItemStart.hour()) {
            crossOverItemsIndexes.push(index);
            overlapInfo.push({ index, hour: i });
          }
          if (currentHrItemsCount <= hourItemsCount) {
            totalHrItemsCount += 1;
          }
        }
      });
    }

    let newItemIndex = itemIndex;

    // Adjust index based on overlapping info
    if (crossOverItemsIndexes.length > 0) {
      for (let i = 0; i < totalHrItemsCount; i++) {
        if (!crossOverItemsIndexes.includes(i)) {
          newItemIndex = i;
          break;
        }
      }
    }

    return {
      newItemIndex,
      hourEventsCount: totalHrItemsCount,
    };
  };
  return (
    <div
      className={`card mb-4 ${darkMode ? "text-white" : "text-black"}`}
      ref={scrollViewRef}
      style={{
        width: "100%",
        padding: "0 5px",
        position: "relative",
        maxHeight: "calc(100vh - 100px)", // Set maxHeight to allow scrolling
        overflowY: "auto", // Enable vertical scrolling
      }}
    
    >
      {/* First Layer: Time and Grid */}
      <div
        style={{
          position: "relative",
          paddingTop: 10,
          zIndex: 0,
        }}
      >
        {hours.map((i) => {
          const hrStr = `${i < 10 ? "0" : ""}${i}`;
          return (
            <div
              key={i}
              style={{
                height: hourHeight,
                borderBottomWidth: 0.1,
                borderTopWidth: i === 0 ? 0.1 : 0,
                display: "flex",
                alignItems: "center",
              }}
            >
              {/* Time Column */}
              <div
                ref={(el) => (itemRefs.current[i.toString()] = el)}
                style={{
                  width: "6%",
                  paddingRight: 4,
                  textAlign: "right",
                }}
              >
                <p
                  className="mb-1"
                  style={{
                    textAlign: "right",
                    marginTop: -15,
                  }}
                >
                  {hrStr}:30
                </p>
                <p
                  className="mb-0"
                  style={{
                    textAlign: "right",
                    fontWeight: "bold",
                    marginTop: -70,
                  }}
                >
                  {hrStr}:00
                </p>
              </div>

              {/* Grid Column */}
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  borderLeft: "0.3px solid #ccc",
                  position: "relative",
                  borderBottom: "0.3px solid #ccc",
                  paddingLeft: 8,
                  flexDirection: "column",
                }}
              ></div>
            </div>
          );
        })}
      </div>

      {/* Second Layer: Events */}
      <div
        style={{
          position: "absolute",
          top: 8,
          left: "8%",
          width: "90%",
          height: "100%",
          pointerEvents: "box-none",
          zIndex: 0,
        }}
      >
        {hours.map((i) => {
          const hourItems = items[`${i}`]  ||[];

          return (
            <div
              key={i}
              style={{
                height: hourHeight,
                position: "relative",
                zIndex: 1,
              }}
            >
              {hourItems.map((item, itemIndex) => {
                const startMinutes = parseInt(
                  formatDateInDayJs(item.start_datetime, "m")
                );
                const topPosition = startMinutes * minuteHeight;
                const eventHeight = item.duration * minuteHeight;

                // Get the number of events and the current event index
                const { newItemIndex, hourEventsCount } = indexAndHourItems(
                  item,
                  itemIndex,
                  hourItems.length
                );

                const eventWidth = `${100 / hourEventsCount}%`;
                const leftPosition = `${
                  (newItemIndex % hourEventsCount) * (100 / hourEventsCount)
                }%`;

                return (
                  <div
                    onClick={() => {
                        const data = {
                            ...item,
                            creation_form_data:
                              item.creation_form_data ||
                              seriesFormDatas[item.series_id],
                          }
                        onboxLongPress(data);
                      }}
                    key={itemIndex}
                    className="pointer px-1"
                    style={{
                      position: "absolute",
                      top: topPosition,
                      height: eventHeight,
                      width: eventWidth,
                      left: leftPosition,
                      overflow: "visible",
                      pointerEvents: "box-none",
                      zIndex: 2,
                    }}
                  >
                    <button
                    
                      className="pointer"
                      style={{
                        backgroundColor: priorityColor(item.priority, item.is_past) + "7a",
                        borderRadius: 5,
                        padding: "0 5px",
                        height: "100%",
                        borderColor: priorityColor(item.priority),
                        borderWidth: 1,
                        width: "100%",
                        zIndex: newItemIndex + 1,
                      }}
                    >
                      <p
                        className="position text-left px-4 py-2 font-weight-bold"
                        style={{ position: "absolute", top: 0, left: 0 }}
                      >
                        {item.title}
                      </p>
                      <p
                        className="position-top text-center py-2 px-4 font-weight-bold"
                        style={{ position: "absolute", top: 20, left: 0 }}
                      >
                        {formatDateInDayJs(
                          item.start_datetime,
                          dateFormats.time24Hour
                        )}{" "}
                        -{" "}
                        {formatDateInDayJs(
                          item.end_datetime,
                          dateFormats.time24Hour
                        )}
                      </p>
                    </button>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>

      {/* Current Time Indicator */}
   {isToday &&  (<div
        style={{
          position: "absolute",
          top: currentTimeTopPosition,
          left: 15,
          width: "100%",
          height: 2,
          backgroundColor: "red",
          zIndex: 3,
        }}
      >
        <div style={{ flexDirection: "row", textAlign: "left" }}>
          <div style={{ width: "6%", right: "40%" }}>
            <p>
              {`${
                [0, 30].includes(getDayJsDateTime().minute())
                  ? ""
                  : getDayJsDateTime().format("HH:mm")
              }`}
            </p>
          </div>
          <div style={{ width: "88%", left: "10%" }}></div>
        </div>
      </div>)}
    </div>
  );
}

export default DayHours;
