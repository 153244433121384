import React, { useContext, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { AppContext } from "../../../utils/components/AppContext";
import { updateObjectState } from "../../../utils/functions";
import { formatDateStrings } from "../../../utils/dateTime";

function DeductionForm({ onSubmit, configs }) {
  const { contextState, updateContextState } = useContext(AppContext);
  const [formState, setFormState] = useState(
    formatDateStrings({ ...contextState.formData })
  );

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const formValid = () => {
    const requiredFields = ["name", "type", "currency", "amount_type"];
    const isAmountValid =
      formState.amount_type === "Amount"
        ? formState.amount
        : formState.percentage_decimal;
    return requiredFields.every((field) => formState[field]) && isAmountValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateContextState({
      formData: { ...formState },
    });
    onSubmit(formState);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Text as={"p"} className={"fw-bold fs-6"}>
        Required fields are marked with an{" "}
        <span className="text-danger">*</span>
      </Form.Text>
      <Form.Group controlId="name" className={"mb-4"}>
        <Form.Label>
          Name<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          name="name"
          value={formState.name || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="description" className={"mb-4"}>
        <Form.Label>Description</Form.Label>
        <Form.Control
          type="text"
          name="description"
          value={formState.description || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="isActive" className={"mb-4"}>
        <Form.Switch
          type="checkbox"
          label="Is Active"
          name="is_active"
          checked={formState.is_active || false}
          onChange={(e) =>
            handleInputChange({
              target: { name: "is_active", value: e.target.checked },
            })
          }
        />
      </Form.Group>

      <Form.Group controlId="type" className={"mb-4"}>
        <Form.Label>
          Type<span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          name="type"
          value={formState.type || ""}
          onChange={handleInputChange}
        >
          <option value="">Select a Type</option>
          {configs.default_deduction_types?.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      <Form.Group controlId="amountType" className={"mb-4"}>
        <Form.Label>
          Amount Type<span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          name="amount_type"
          value={formState.amount_type || ""}
          onChange={handleInputChange}
        >
          <option value="">Select an Amount Type</option>
          {configs.compensation_amount_types?.map((amountType) => (
            <option key={amountType} value={amountType}>
              {amountType}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      <Form.Group controlId="currency" className={"mb-4"}>
        <Form.Label>
          Currency<span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          name="currency"
          value={formState.currency || ""}
          onChange={handleInputChange}
        >
          <option value="">Select a Currency</option>
          {configs.currencies?.map((currency) => (
            <option key={currency} value={currency}>
              {currency}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      <Form.Group controlId="organizationPays" className={"mb-4"}>
        <Form.Switch
          type="checkbox"
          label="Organization Pays"
          name="organization_pays"
          checked={formState.organization_pays || false}
          onChange={(e) => {
            handleInputChange({
              target: { name: "organization_pays", value: e.target.checked },
            });
            updateObjectState(setFormState, {
              organization_pay_amount: "0",
              organization_pay_percentage_decimal: "0",
            });
          }}
        />
        <small>
          Turn this switch on if your organization will be paying all or some of
          this tax / deduction
        </small>
      </Form.Group>

      {formState.amount_type === "Amount" && (
        <>
          <Form.Group controlId="amount" className={"mb-4"}>
            <Form.Label>
              Employees Pay<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="number"
              name="amount"
              value={formState.amount || ""}
              onChange={handleInputChange}
            />
          </Form.Group>
          {formState.organization_pays && (
            <Form.Group controlId="organizationAmount" className={"mb-4"}>
              <Form.Label>
                Organization Pays<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="number"
                name="organization_pay_amount"
                value={formState.organization_pay_amount || ""}
                onChange={handleInputChange}
              />
            </Form.Group>
          )}
        </>
      )}

      {formState.amount_type === "Percentage" && (
        <>
          <Form.Group controlId="percentageDecimal" className={"mb-4"}>
            <Form.Label>
              Employees Pay (%)<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="number"
              name="percentage_decimal"
              value={formState.percentage_decimal || ""}
              onChange={handleInputChange}
            />
          </Form.Group>
          {formState.organization_pays && (
            <Form.Group
              controlId="organizationPercentageDecimal"
              className={"mb-4"}
            >
              <Form.Label>
                Organization Pays (%)<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="number"
                name="organization_pay_percentage_decimal"
                value={formState.organization_pay_percentage_decimal || ""}
                onChange={handleInputChange}
              />
            </Form.Group>
          )}
        </>
      )}

      <Button
        variant="primary"
        type="submit"
        className={"w-100 mb-5"}
        disabled={!formValid()}
      >
        Save
      </Button>
    </Form>
  );
}

export default DeductionForm;
