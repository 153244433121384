import React, { useContext, useState } from "react";
import { Button, Form, Card, Alert } from "react-bootstrap";
import { AppContext } from "../../../utils/components/AppContext";
import EntitySelection from "../EntitySelection";
import { formatDateStrings, time24Hrs } from "../../../utils/dateTime";

function ShiftScheduleAssignmentForm({ onSubmit, date, readOnlyDate = false }) {
  const { contextState, updateContextState } = useContext(AppContext);
  const [formState, setFormState] = useState({
    ...formatDateStrings({ ...contextState.formData }),
    start_datetime: contextState.formData?.start_datetime || "09:00",
    end_datetime: contextState.formData?.end_datetime || "17:00",
  });

  const [requiredFields, setRequiredFields] = useState([
    "start_datetime",
    "end_datetime",
    "date",
  ]);
  const [selectedShift, setSelectedShift] = useState(null);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const formValid = () => {
    return requiredFields.every((field) => formState[field]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateContextState({
      formData: { ...formState },
    });
    onSubmit(formState);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Text as={"p"} className={"fw-bold fs-6"}>
        Required fields are marked with an{" "}
        <span className="text-danger">*</span>
      </Form.Text>

      <Form.Group controlId="startDate" className={"mb-4"}>
        <Form.Label>
          Date<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="date"
          name="date"
          value={formState.date || ""}
          onChange={handleInputChange}
          readOnly={readOnlyDate}
          disabled={readOnlyDate}
        />
      </Form.Group>

      <Form.Group controlId="branchDescription" className={"mb-4"}>
        <Form.Label>Note / Description</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          name="notes"
          value={formState.notes || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="startDate" className={"mb-4"}>
        <Form.Label>
          Start Time<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="time"
          name="start_datetime"
          value={formState.start_datetime || ""}
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group controlId="endDate" className={"mb-4"}>
        <Form.Label>
          End Time<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="time"
          name="end_datetime"
          value={formState.end_datetime || ""}
          onChange={handleInputChange}
        />
        <Alert variant={"info"} className={"mt-3"}>
          If the End Time is smaller than the Start Time, the End Time will be
          set to the next day. For example, if the start date is 28/11/2023 and
          the Start Time is 19:00 and the End Time is 02:00 the end date will be
          29/11/2023.
        </Alert>
      </Form.Group>

      <Button
        variant="primary"
        type="submit"
        className={"w-100 mb-5"}
        disabled={!formValid()}
      >
        Save
      </Button>
    </Form>
  );
}

export default ShiftScheduleAssignmentForm;
