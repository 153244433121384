import React, { useEffect, useState } from "react";
import { Alert, Button, Card } from "react-bootstrap";
import { LoadingView, TextWithLineBreaks } from "../../../utils/components";
import GenericForm from "../../Forms/Shared/GenericForm";
import { FormFieldTypes } from "../../FormFields";
import { updateObjectState, useAppModal } from "../../../utils/functions";
import EmployeeHighlightCard from "../Employee/EmployeeHighlightCard";
import PDFViewer from "../Shared/PDFViewer";
import { dateFormats, formatDate } from "../../../utils/dateTime";

function PerformanceAttestationMessages({ reviewer, putRequest }) {
  const { showAppModal } = useAppModal();

  const [reviewerState, setReviewerState] = useState(reviewer);

  const [isLoading, setIsLoading] = useState(false);
  const [startAttestation, setStartAttestation] = useState(false);
  const [messages, setMessages] = useState(
    reviewerState.attestation_messages || []
  );
  const [data, setData] = useState({});

  const hasMessages = messages.length > 0;

  useEffect(() => {
    if (startAttestation) {
      // scroll to bottom of attestations container
      const container = document.getElementById("attestation-container");
      container.scrollTop = container.scrollHeight;
    }
  }, [startAttestation]);

  const addMessage = (submitData, extraData = {}) => {
    const formData = new FormData();
    const vals = { ...submitData };

    if (vals.pdf_file_url) {
      formData.append("pdf_file", vals.pdf_file);
      delete vals.pdf_file_url;
    }

    delete vals.validations;

    formData.append(
      "inputs",
      JSON.stringify({
        ...vals,
        ...extraData,
      })
    );

    putRequest(
      `/employee/self/performance/review/${reviewerState.id}/message`,
      setIsLoading,
      (response) => {
        setMessages([...messages, response.review_message]);
        updateObjectState(setReviewerState, {
          attestation_complete: response.attestation_complete,
        });
        setData({});
      },
      formData,
      true,
      false
    );
  };

  if (
    !reviewerState.show_attestation_messaging &&
    !reviewerState.attestation_awaiting_init
  ) {
    return <div />;
  } else if (
    !reviewerState.show_attestation_messaging &&
    reviewerState.attestation_awaiting_init
  ) {
    return (
      <div>
        <Alert variant={"info"} className={"text-center mt-4"}>
          <strong>
            This review is awaiting the reviewee's initiation of the attestation
            process
          </strong>
        </Alert>
      </div>
    );
  } else {
    return (
      <LoadingView
        isLoading={isLoading}
        text={"Adding Message..."}
        fullHeight={false}
        view={
          <Card className={"mb-5"} id={"attestation-container"}>
            <Card.Header className={"py-3"}>
              <Card.Title>Review Attestation</Card.Title>
              <Card.Subtitle as={"p"} className={"mt-2"}>
                <TextWithLineBreaks
                  text={reviewerState.attestation_instructions}
                />
              </Card.Subtitle>
            </Card.Header>
            <Card.Body className={"py-3"}>
              {!hasMessages &&
                reviewerState.can_init_attestation &&
                !startAttestation && (
                  <div className={"text-center"}>
                    <p>
                      You have not yet attested to this review. Click the button
                      below to start.
                    </p>
                    <Button
                      variant={"primary"}
                      className={"my-3"}
                      onClick={() => setStartAttestation(true)}
                    >
                      Start Attestation
                    </Button>
                  </div>
                )}

              {(hasMessages || startAttestation) && (
                <div className={"mb-3"}>
                  <h4 className={"my-4"}>Attestation Discussion</h4>
                  {messages.map((message, i) => {
                    const isCurrentUser =
                      message.participant_employee_id ===
                      reviewerState.reviewer_employee_id;

                    let cardClass = "w-75 ";
                    if (isCurrentUser) {
                      cardClass += "ms-auto";
                    } else {
                      cardClass += "";
                    }

                    return (
                      <Card key={i} className={`mb-4 ${cardClass}`}>
                        {!isCurrentUser && (
                          <Card.Header className={"ps-0"}>
                            <EmployeeHighlightCard
                              employee={message.participant}
                              forceRow={true}
                            />
                          </Card.Header>
                        )}
                        <Card.Body
                          className={`text-white small fw-bold bg-opacity-75 ${
                            isCurrentUser
                              ? "bg-primary"
                              : "bg-secondary bg-opacity-75"
                          }`}
                        >
                          <p>
                            <TextWithLineBreaks text={message.text} />
                          </p>
                        </Card.Body>
                        <Card.Footer
                          className={
                            "d-flex justify-content-center align-items-center"
                          }
                        >
                          {message.file_url && (
                            <PDFViewer pdfUrl={message.file_url} />
                          )}
                          <p
                            className={
                              "fst-italic ms-auto align-items-center h-100"
                            }
                          >
                            {formatDate(
                              message.timestamp,
                              dateFormats.daySingleDigitDMY12Hr
                            )}
                          </p>
                        </Card.Footer>
                      </Card>
                    );
                  })}

                  {!reviewerState.attestation_complete &&
                    !reviewerState.cannot_add_message && (
                      <div className={"mt-5 pt-5 border-3 border-primary"}>
                        <h6 className={"text-center mb-4"}>
                          {messages.length === 0
                            ? "Start Attestation"
                            : "Add Message"}
                        </h6>
                        <GenericForm
                          stateData={data}
                          setStateData={setData}
                          useGlobalState={false}
                          useFormData={false}
                          showCompleteButton={false}
                          updateGlobalStateOnSubmit={false}
                          formConfigs={[
                            {
                              valueKey: "text",
                              labelName: "Type a Message",
                              type: FormFieldTypes.TEXT_AREA,
                            },
                            {
                              valueKey: "pdf_file",
                              labelName:
                                "Upload a file to support your message",
                              type: FormFieldTypes.PDF,
                              isRequired: false,
                              maxFileMb: 5,
                              info: "Max PDF file size: 5MB",
                            },
                          ]}
                        />

                        <div className={"text-end mt-3"}>
                          <Button
                            variant={"primary"}
                            disabled={
                              !data.validations ||
                              Object.values(data.validations).includes(false)
                            }
                            onClick={() => {
                              addMessage(data);
                            }}
                          >
                            Add Message
                          </Button>
                          {reviewerState.is_reviewee && (
                            <Button
                              variant={"success"}
                              className={"ms-3"}
                              disabled={
                                !data.validations ||
                                Object.values(data.validations).includes(false)
                              }
                              onClick={() => {
                                showAppModal({
                                  title: "Accept & Complete this Review?",
                                  component: (
                                    <p>
                                      By accepting this review, you are
                                      confirming that you have read and
                                      understood the contents of this review.
                                      You will not be able to make any further
                                      changes to this review.
                                    </p>
                                  ),
                                  truthyFunction: () => {
                                    addMessage(data, {
                                      is_complete: true,
                                    });
                                  },
                                });
                              }}
                            >
                              Add Message & Accept Review
                            </Button>
                          )}
                        </div>
                      </div>
                    )}
                </div>
              )}

              {reviewerState.attestation_complete && (
                <div className={"mt-5"}>
                  <Alert variant={"success"} className={"text-center"}>
                    <strong>This review is complete</strong>
                  </Alert>
                </div>
              )}
            </Card.Body>
          </Card>
        }
      />
    );
  }
}

export default PerformanceAttestationMessages;
