import React, { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { updateObjectState, useAppModal } from "../../../utils/functions";
import {
  FaChevronLeft,
  FaChevronRight,
  FaCopy,
  FaEdit,
  FaPlus,
  FaTrash,
} from "react-icons/fa";
import {
  AppOffCanvasLocal,
  LoadingView,
  TextWithLineBreaks,
} from "../../../utils/components";
import PerformanceReviewFormColumnForm from "./PerformanceReviewFormColumnForm";
import { FormFieldTypes } from "../../FormFields";
import {
  columnTypeNamesEnum,
  columnTypesToFormFields,
  READ_ONLY_TEXT,
} from "./PerformanceFormHelpers";
import EmployeePerformanceForm from "./EmployeePerformanceForm";
import { FaTrashCan } from "react-icons/fa6";

function PerformanceReviewForm({ data, onSubmit, forUpdates = true }) {
  data = data || {};
  const { showAppModal, closeModal } = useAppModal();
  const [otherData, setOtherData] = useState({
    name: data?.name || "",
    description: data?.description || "",
  });
  const [columns, setColumns] = useState(data?.columns || []);
  const [rows, setRows] = useState(data?.rows || [{}]);
  const [showColumnForm, setShowColumnForm] = useState(false);
  const [columnFormData, setColumnFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setRows(data?.rows || [{}]);
    setColumns(data?.columns || []);
  }, [data.id]);

  return (
    <LoadingView
      fullHeight={false}
      isLoading={isLoading}
      view={
        <div>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            {forUpdates && (
              <>
                <Form.Group controlId="levelName" className={"mb-4"}>
                  <Form.Label>Form Name *</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    required
                    value={otherData.name || ""}
                    onChange={(e) => {
                      e.preventDefault();
                      updateObjectState(setOtherData, {
                        name: e.target.value,
                      });
                    }}
                  />
                </Form.Group>

                <Form.Group className={"mb-4"}>
                  <Form.Label>Description*</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="description"
                    value={otherData.description || ""}
                    placeholder={"Enter a description for the form"}
                    onChange={(e) => {
                      e.preventDefault();
                      updateObjectState(setOtherData, {
                        description: e.target.value,
                      });
                    }}
                  />
                </Form.Group>
              </>
            )}

            <div className={"mt-4"}>
              {forUpdates && (
                <div className={"d-flex justify-content-end mb-4"}>
                  <Button
                    variant={"outline-primary"}
                    onClick={() => {
                      setColumnFormData({});
                      setShowColumnForm(true);
                    }}
                  >
                    <FaPlus className={"me-2"} />
                    Column
                  </Button>
                </div>
              )}

              <div>
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      {columns.map((column, colsIndex) => {
                        return (
                          <th
                            key={colsIndex}
                            className={"align-middle"}
                            style={{
                              minWidth: "200px",
                            }}
                          >
                            {column.title}

                            {forUpdates && (
                              <div className={"float-end text-break mt-1"}>
                                <Button
                                  variant={"link"}
                                  className={"mt-2"}
                                  size={"sm"}
                                  onClick={() => {
                                    const newColumns = [...columns];
                                    const copyColumn = {
                                      ...column,
                                      title: `${column.title} Copy`,
                                    };
                                    newColumns.splice(
                                      colsIndex + 1,
                                      0,
                                      copyColumn
                                    );
                                    setColumns(newColumns);

                                    // Also copy the cells
                                    const newRows = [...rows];
                                    newRows.forEach((row) => {
                                      row[copyColumn.title] = row[column.title];
                                    });
                                    setRows(newRows);
                                  }}
                                >
                                  <FaCopy />
                                </Button>
                                <Button
                                  variant={"link"}
                                  className={"mt-2"}
                                  size={"sm"}
                                  onClick={() => {
                                    setColumnFormData({
                                      ...column,
                                      is_edit: true,
                                      onSubmit: (submitData) => {
                                        const newColumns = [...columns];
                                        if (submitData.title !== column.title) {
                                          // If the title has changed, update the rows
                                          const newRows = [...rows];
                                          newRows.forEach((row) => {
                                            row[submitData.title] =
                                              row[column.title];
                                            delete row[column.title];
                                          });
                                        }

                                        newColumns[colsIndex] = submitData;
                                        setColumns(newColumns);
                                      },
                                    });
                                    setShowColumnForm(true);
                                  }}
                                >
                                  <FaEdit />
                                </Button>
                                <Button
                                  variant={"link"}
                                  className={"mt-2 text-danger"}
                                  size={"sm"}
                                  onClick={() => {
                                    const newColumns = [...columns];
                                    newColumns.splice(colsIndex, 1);
                                    setColumns(newColumns);
                                  }}
                                >
                                  <FaTrashCan />
                                </Button>
                                {/*Button to shift column left if it is not the first column*/}
                                <Button
                                  variant={"link"}
                                  className={"mt-2"}
                                  size={"sm"}
                                  disabled={colsIndex === 0}
                                  onClick={() => {
                                    if (colsIndex > 0) {
                                      const newColumns = [...columns];
                                      const columnToMove = newColumns.splice(
                                        colsIndex,
                                        1
                                      )[0];
                                      newColumns.splice(
                                        colsIndex - 1,
                                        0,
                                        columnToMove
                                      );
                                      setColumns(newColumns);
                                    }
                                  }}
                                >
                                  <FaChevronLeft />
                                </Button>
                                {/*Button to shift column right if it is not the last column*/}
                                <Button
                                  variant={"link"}
                                  className={"mt-2"}
                                  size={"sm"}
                                  disabled={colsIndex === columns.length - 1}
                                  onClick={() => {
                                    if (colsIndex < columns.length - 1) {
                                      const newColumns = [...columns];
                                      const columnToMove = newColumns.splice(
                                        colsIndex,
                                        1
                                      )[0];
                                      newColumns.splice(
                                        colsIndex + 1,
                                        0,
                                        columnToMove
                                      );
                                      setColumns(newColumns);
                                    }
                                  }}
                                >
                                  <FaChevronRight className={"rotate-180"} />
                                </Button>
                              </div>
                            )}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  {columns.length > 0 && (
                    <tbody>
                      {rows.map((row, rowsIndex) => (
                        <tr key={rowsIndex}>
                          {columns.map((column, colsIndex2) => {
                            const cell = row[column.title] || {};

                            let choices = cell.choices || column.choices;
                            if (typeof choices === "string") {
                              choices = choices.split("\n");
                            }

                            let minValue = cell.min_value || column.min_value;
                            let maxValue = cell.max_value || column.max_value;

                            return (
                              <td key={colsIndex2} className={"ps-4 pt-4"}>
                                {column.column_type ===
                                  columnTypeNamesEnum.READ_ONLY_TEXT &&
                                  (forUpdates ? (
                                    <div>
                                      <small className={"text-muted"}>
                                        Enter the text people will see in this
                                        column
                                      </small>
                                      <Form.Control
                                        as="textarea"
                                        rows={3}
                                        className={"mt-3"}
                                        value={cell?.text || ""}
                                        onChange={(e) => {
                                          e.preventDefault();
                                          const newRows = [...rows];
                                          newRows[rowsIndex][column.title] = {
                                            ...cell,
                                            text: e.target.value,
                                          };
                                          setRows(newRows);
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <div>
                                      <TextWithLineBreaks
                                        text={cell.text || ""}
                                        textClassNames={"small"}
                                      />
                                    </div>
                                  ))}

                                {column.column_type !==
                                  columnTypeNamesEnum.READ_ONLY_TEXT && (
                                  <div>
                                    <p>
                                      <strong>
                                        {column.column_type} Field
                                      </strong>
                                    </p>

                                    {[
                                      columnTypeNamesEnum.NUMBER,
                                      columnTypeNamesEnum.PDF_FILE,
                                    ].includes(column.column_type) && (
                                      <div>
                                        {column.column_type ===
                                          columnTypeNamesEnum.NUMBER && (
                                          <>
                                            Min Value: {minValue}
                                            <br />
                                            Max Value: {maxValue}
                                            <br />
                                          </>
                                        )}
                                        {column.column_type ===
                                          columnTypeNamesEnum.PDF_FILE && (
                                          <>
                                            Min Pages: {parseInt(minValue)}
                                            <br />
                                            Max Pages: {parseInt(maxValue)}
                                          </>
                                        )}
                                        {forUpdates && (
                                          <Button
                                            variant={"outline-primary"}
                                            className={"my-4"}
                                            size={"sm"}
                                            onClick={() => {
                                              setColumnFormData({
                                                column_type: column.column_type,
                                                title: column.title,
                                                is_row: true,
                                                min_value: null,
                                                max_value: null,
                                                onSubmit: (submitData) => {
                                                  const newRows = [...rows];
                                                  newRows[rowsIndex][
                                                    column.title
                                                  ] = {
                                                    min_value:
                                                      submitData.min_value,
                                                    max_value:
                                                      submitData.max_value,
                                                  };
                                                  setRows(newRows);
                                                },
                                              });
                                              setShowColumnForm(true);
                                            }}
                                          >
                                            <FaEdit className={"me-2"} />
                                            Cell Min & Max
                                          </Button>
                                        )}
                                      </div>
                                    )}

                                    {[
                                      columnTypeNamesEnum.SINGLE_CHOICE,
                                      columnTypeNamesEnum.MULTIPLE_CHOICE,
                                    ].includes(column.column_type) && (
                                      <div>
                                        <strong>Choices:</strong>
                                        <ul>
                                          {choices.map((choice, index) => (
                                            <li key={index}>{choice}</li>
                                          ))}
                                        </ul>

                                        {forUpdates && (
                                          <Button
                                            variant={"outline-primary"}
                                            className={"my-2"}
                                            size={"sm"}
                                            onClick={() => {
                                              setColumnFormData({
                                                column_type: column.column_type,
                                                title: column.title,
                                                is_row: true,
                                                choices: "",
                                                onSubmit: (submitData) => {
                                                  const newRows = [...rows];
                                                  newRows[rowsIndex][
                                                    column.title
                                                  ] = {
                                                    choices: submitData.choices,
                                                  };
                                                  setRows(newRows);
                                                },
                                              });
                                              setShowColumnForm(true);
                                            }}
                                          >
                                            <FaEdit className={"me-2"} />
                                            Cell Choices
                                          </Button>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </td>
                            );
                          })}

                          {rowsIndex > 0 && forUpdates && (
                            <td className={"align-middle ps-4"}>
                              <Button
                                variant={"outline-danger"}
                                onClick={() => {
                                  const newRows = [...rows];
                                  newRows.splice(rowsIndex, 1);
                                  setRows(newRows);
                                }}
                              >
                                <FaTrashCan />
                              </Button>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  )}
                </Table>

                {columns.length > 0 && forUpdates && (
                  <div className={"d-flex justify-content-end mt-4"}>
                    <Button
                      variant={"outline-primary"}
                      onClick={() => {
                        setRows([...rows, {}]);
                      }}
                    >
                      <FaPlus className={"me-2"} />
                      Row
                    </Button>
                  </div>
                )}
                {columns.length > 0 && (
                  <div className={"d-flex justify-content-end mt-4"}>
                    <Button
                      variant={"outline-info"}
                      className={"mt-1"}
                      onClick={() => {
                        showAppModal({
                          title: `Preview of ${
                            otherData.name || "Performance Review"
                          } Form`,
                          hideFooter: true,
                          size: "xl",
                          component: (
                            <>
                              <EmployeePerformanceForm
                                rows={rows}
                                columns={columns}
                              />
                            </>
                          ),
                        });
                      }}
                    >
                      See How It Will Look
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </Form>

          {forUpdates && (
            <div className={"d-flex justify-content-center mt-4 mb-4"}>
              <Button
                variant={"primary"}
                disabled={
                  !otherData.name ||
                  !otherData.description ||
                  !columns ||
                  columns.length < 1
                }
                onClick={() => {
                  // check if there  are columns with the same title
                  const columnTitles = columns.map((column) => column.title);
                  const uniqueColumnTitles = new Set(columnTitles);
                  if (columnTitles.length !== uniqueColumnTitles.size) {
                    showAppModal({
                      title: "Error",
                      hideFooter: true,
                      component: (
                        <div>
                          <p>
                            You cannot have 2 columns with the same title.
                            Please change the title of the columns that are the
                            same.
                          </p>
                        </div>
                      ),
                    });
                    return;
                  }
                  onSubmit(
                    {
                      form_data: otherData,
                      columns,
                      rows,
                    },
                    setIsLoading
                  );
                }}
              >
                Save Form
              </Button>
            </div>
          )}

          <AppOffCanvasLocal
            showOffCanvas={showColumnForm}
            setShowOffCanvas={setShowColumnForm}
            title={"Add Column Info"}
            component={
              <div>
                <PerformanceReviewFormColumnForm
                  data={columnFormData}
                  setData={setColumnFormData}
                  columnTypeOptions={Object.keys(columnTypesToFormFields)}
                  onSubmit={(colData) => {
                    if (!columnFormData.is_row && !columnFormData.is_edit) {
                      // check if the title already exists
                      const titleExists = columns.find(
                        (column) => column.title === colData.title
                      );
                      if (titleExists) {
                        showAppModal({
                          title: "Error",
                          hideFooter: true,
                          component: (
                            <div>
                              <p>
                                The column title{" "}
                                <strong>{colData.title}</strong> already exists.
                                You cannot have 2 columns with the same title.
                              </p>
                            </div>
                          ),
                        });
                        return;
                      }
                    }

                    if (columnFormData.onSubmit) {
                      columnFormData.onSubmit(colData);
                    } else {
                      setColumns([...columns, colData]);
                    }
                    setShowColumnForm(false);
                  }}
                />
              </div>
            }
          />
        </div>
      }
    />
  );
}

export default PerformanceReviewForm;
