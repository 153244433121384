import React from "react";
import { Table, Badge, Button, Col, Row, Card } from "react-bootstrap";
import { InfoDisplayTable } from "../../../utils/components";
import { formatDate } from "../../../utils/dateTime";

function PaymentAccount({ account, canManage, onEditClick }) {
  let data = {
    Status: account.is_active ? (
      <Badge bg="success">Active</Badge>
    ) : (
      <Badge bg="info">Inactive</Badge>
    ),
    Type: account.type,
    "Account Number": account.number,
    Currency: account.currency,
  };
  if (account.type === "Bank") {
    data = {
      ...data,
      ...{
        "Bank Name": account.bank_name,
        "Bank Address": account.bank_address,
        "Swift Code": account.swift_code,
        "Routing Number": account.routing_number,
        "Iban Number": account.iban_number,
      },
    };
  }
  data["Date Added"] = formatDate(account.timestamp);

  return (
    <InfoDisplayTable
      canEdit={canManage}
      title={account.name}
      onEditClick={() => {
        onEditClick();
      }}
      data={data}
    />
  );
}

export default PaymentAccount;
