export const repeatTypes = [
  { value: "Daily", label: "Daily" },
  { value: "Weekly", label: "Weekly" },
  { value: "Every 2 weeks", label: "Every 2 weeks" },
  { value: "Monthly", label: "Monthly" },
];

export const RepeatAppointmentConfigs = {
  Daily: {
    label: "Daily",
    options: {
      type: "includeWeekends",
      label: "Include Weekends?",
      choices: ["Yes", "No"],
    },
  },
  Weekly: {
    label: "Weekly",
    options: {
      type: "dayOfWeek",
      label: "Select Days of the Week",
      choices: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
    },
  },
  "Every 2 weeks": {
    label: "Every 2 weeks",
    options: {
      type: "dayOfWeek",
      label: "Select Days of the Week",
      choices: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
    },
  },
  Monthly: {
    label: "Monthly",
    options: {
      type: "monthRepeatOption",
      label: "Select Monthly Repeat Option",
      choices: ["Day of the Month", "Weekday of the Month"],
    },
    secondaryOptions: {
      "Day of the Month": {
        type: "dayOfMonth",
        label: "Select Days of the Month",
        choices: Array.from({ length: 31 }, (_, i) => `${i + 1}`),
      },
      "Weekday of the Month": {
        type: "weekdayOfMonth",
        label: "Select Weekdays of the Month",
        choices: [
          "1st Sunday",
          "1st Monday",
          "1st Tuesday",
          "1st Wednesday",
          "1st Thursday",
          "1st Friday",
          "1st Saturday",
          "2nd Sunday",
          "2nd Monday",
          "2nd Tuesday",
          "2nd Wednesday",
          "2nd Thursday",
          "2nd Friday",
          "2nd Saturday",
          "3rd Sunday",
          "3rd Monday",
          "3rd Tuesday",
          "3rd Wednesday",
          "3rd Thursday",
          "3rd Friday",
          "3rd Saturday",
          "4th Sunday",
          "4th Monday",
          "4th Tuesday",
          "4th Wednesday",
          "4th Thursday",
          "4th Friday",
          "4th Saturday",
          "Last Sunday",
          "Last Monday",
          "Last Tuesday",
          "Last Wednesday",
          "Last Thursday",
          "Last Friday",
          "Last Saturday",
        ],
      },
    },
  },
};

export const priorityOptions = [
  { value: "Low", label: "Low" },
  { value: "Medium", label: "Medium" },
  { value: "High", label: "High" },
];

export const appointmentReminder = [
  { value: "5 Minutes", label: "5 Minutes" },
  { value: "10 Minutes", label: "10 Minutes" },
  { value: "15 Minutes", label: "15 Minutes" },
  { value: "30 Minutes", label: "30 Minutes" },
  { value: "1 Hour", label: "1 Hour" },
  { value: "1 Day", label: "1 Day" },
];
