import React, { useState, useRef, useEffect } from "react";

const ExpandableText = ({ text, lines = 3 }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);
  const measuringRef = useRef(null);

  useEffect(() => {
    if (measuringRef.current) {
      const lineHeight = parseInt(
        window.getComputedStyle(measuringRef.current).lineHeight,
        10
      );
      const maxLinesHeight = lineHeight * lines;
      const measuringHeight = measuringRef.current.scrollHeight; // Use scrollHeight to measure content height

      setIsOverflow(measuringHeight > maxLinesHeight);
    }
  }, [text, lines]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const textStyle = isExpanded
    ? {}
    : {
        overflow: "hidden",
        display: "-webkit-box",
        WebkitLineClamp: lines,
        WebkitBoxOrient: "vertical",
        lineHeight: "1.2em", // Adjust based on your design
        maxHeight: `${1.2 * lines}em`, // Adjust based on your design
      };

  return (
    <div>
      <div style={textStyle} onClick={toggleExpand}>
        {text}
      </div>
      {!isExpanded && isOverflow && (
        <div
          style={{ color: "#007bff", cursor: "pointer" }}
          onClick={toggleExpand}
        >
          more...
        </div>
      )}
      {/* Invisible div for measuring, ensure it's hidden but retains its layout for accurate measurement */}
      <div
        ref={measuringRef}
        style={{
          ...textStyle,
          position: "absolute",
          visibility: "hidden",
          zIndex: -1,
          height: "auto",
          width: "100%",
          whiteSpace: "pre-wrap",
        }}
        aria-hidden="true"
      >
        {text}
      </div>
    </div>
  );
};

export default ExpandableText;
