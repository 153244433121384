import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../utils/components/AppContext";
import {
  getFileFromUrl,
  updateObjectState,
  useOffCanvas,
} from "../../../utils/functions";
import { AddItemButton, ConditionalView } from "../../../utils/components";
import { Button, Card } from "react-bootstrap";
import EmployeeSkillForm from "../../Forms/Employee/EmployeeSkillForm";
import AddOrUpdateDocumentForm from "../../Forms/Employee/AddOrUpdateDocumentForm";
import { formatDate } from "../../../utils/dateTime";
import { FaExternalLinkAlt, FaRegEdit } from "react-icons/fa";
import PDFViewer from "../Shared/PDFViewer";

function DocumentsView({
  employee,
  setEmployee,
  configs,
  purRequest,
  patchRequest,
  permissions,
  loaderCallback,
}) {
  const { updateContextState } = useContext(AppContext);
  const { showOffCanvas } = useOffCanvas();

  const [documents, setDocuments] = useState(employee.documents || []);

  return (
    <div>
      <div className={"mb-3"}>
        <div className={"d-flex justify-content-between align-items-center"}>
          <h4>Documents</h4>

          {permissions.MANAGE_EMPLOYEE_DATA && (
            <AddItemButton
              title={"Add Document"}
              onClick={() => {
                updateContextState({
                  formData: {},
                });
                showOffCanvas({
                  title: `Add a Document`,
                  subtitle: `Add a new document for ${employee.first_name}`,
                  component: (
                    <AddOrUpdateDocumentForm
                      onSubmit={(data) => {
                        const formData = new FormData();
                        formData.append("document_file", data.document_file);
                        delete data.document_file;
                        formData.append("inputs", JSON.stringify(data));

                        purRequest(
                          `/employee/${employee.id}/document`,
                          loaderCallback,
                          (response) => {
                            updateObjectState(setEmployee, {
                              documents: [response.document, ...documents],
                            });
                          },
                          formData,
                          true
                        );
                      }}
                    />
                  ),
                });
              }}
            />
          )}
        </div>
      </div>

      <ConditionalView
        condition={documents.length > 0}
        falseView={
          <Card body={true} className={"text-center py-3"}>
            <h6>No documents have been added</h6>
          </Card>
        }
        trueView={
          <div>
            {documents.map((doc, index) => {
              return (
                <Card key={index} className={"mb-4"}>
                  <Card.Header
                    className={
                      "py-3 d-flex justify-content-between align-items-center"
                    }
                  >
                    <h5>{doc.name}</h5>
                    {permissions.MANAGE_EMPLOYEE_DATA && (
                      <div>
                        <Button
                          variant={"outline-primary"}
                          onClick={() => {
                            updateContextState({
                              formData: doc,
                            });
                            showOffCanvas({
                              title: `Update Document`,
                              subtitle: `Update ${doc.name}`,
                              component: (
                                <AddOrUpdateDocumentForm
                                  isNew={false}
                                  onSubmit={(data) => {
                                    const formData = new FormData();
                                    formData.append(
                                      "document_file",
                                      data.document_file
                                    );
                                    delete data.document_file;
                                    formData.append(
                                      "inputs",
                                      JSON.stringify(data)
                                    );

                                    patchRequest(
                                      `/employee/document/${doc.id}`,
                                      loaderCallback,
                                      (response) => {
                                        updateObjectState(setEmployee, {
                                          documents: documents.map(
                                            (document) => {
                                              if (document.id === doc.id) {
                                                return response.document;
                                              }
                                              return document;
                                            }
                                          ),
                                        });
                                      },
                                      formData,
                                      true
                                    );
                                  }}
                                />
                              ),
                            });
                          }}
                        >
                          Update <FaRegEdit className={"ms-2"} />
                        </Button>
                      </div>
                    )}
                  </Card.Header>
                  <Card.Body>
                    <p>{doc.description}</p>
                  </Card.Body>
                  <Card.Footer
                    className={
                      "d-flex justify-content-between align-items-center"
                    }
                  >
                    <p className={"align-items-center"}>
                      Added On:
                      <br />
                      {formatDate(doc.timestamp)}
                    </p>
                    <div>
                      <PDFViewer
                        title={`${doc.name} Document`}
                        pdfUrl={doc.file_url}
                      />
                    </div>
                  </Card.Footer>
                </Card>
              );
            })}
          </div>
        }
      />
    </div>
  );
}

export default DocumentsView;
