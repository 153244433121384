import React, { useContext, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { isEmpty } from "../../utils/functions";
import { AppContext } from "../../utils/components/AppContext";
import { formatDateStrings } from "../../utils/dateTime";

export function LevelsForm({ onSubmit, state }) {
  const { contextState, updateContextState } = useContext(AppContext);
  const [formState, setFormState] = useState(
    formatDateStrings({ ...contextState.formData })
  );

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateContextState({
      formData: formState,
    });
    onSubmit(formState);
  };

  const formValid = () => {
    const requiredFields = ["name", "description", "minimum_pay", "max_pay"];
    let valid = true;
    for (const field of requiredFields) {
      if (isEmpty(formState[field])) {
        valid = false;
        break;
      }
    }
    return valid;
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="levelName" className={"mb-4"}>
        <Form.Label>Name *</Form.Label>
        <Form.Control
          type="text"
          name="name"
          required
          value={formState.name || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="levelDescription" className={"mb-4"}>
        <Form.Label>Description *</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          name="description"
          value={formState.description || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      {/*<Form.Group controlId="levelHierarchyPosition" className={"mb-4"}>*/}
      {/*  <Form.Label>Hierarchy Position *</Form.Label>*/}
      {/*  <Form.Control*/}
      {/*    type="number"*/}
      {/*    name="hierarchy_position"*/}
      {/*    required*/}
      {/*    value={formState.hierarchy_position || ""}*/}
      {/*    onChange={handleInputChange}*/}
      {/*  />*/}
      {/*</Form.Group>*/}

      <Form.Group controlId="levelMinimumPay" className={"mb-4"}>
        <Form.Label>Minimum Pay *</Form.Label>
        <Form.Control
          type="number"
          name="minimum_pay"
          required
          value={formState.minimum_pay || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="levelMaxPay" className={"mb-4"}>
        <Form.Label>Max Pay *</Form.Label>
        <Form.Control
          type="number"
          name="max_pay"
          required
          value={formState.max_pay || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Button
        variant="primary"
        type="submit"
        disabled={!formValid()}
        className={"w-100"}
      >
        Save
      </Button>
    </Form>
  );
}
