import React from "react";
import { Button, Table } from "react-bootstrap";
import EmployeeInfo from "../../EmployeeManagement";
import {
  dateFormats,
  formatDate,
  hrsMinsSecsDiff,
} from "../../../utils/dateTime";
import { FaCheck, FaTimes } from "react-icons/fa";
import EmployeeHighlightCard from "../Employee/EmployeeHighlightCard";
import { StatusBadge } from "../../../utils/components";
import { strTimeDifference } from "../../../utils/functions";

function OutOfPerimeterAttendanceRequests({ requests, onActionClick }) {
  return (
    <div>
      <Table striped bordered hover>
        <thead>
          <tr className={"align-middle align-items-center text-center fs-5"}>
            <th>Requester</th>
            <th>Reason</th>
            <th>Clock In Time</th>
            <th>Clock Out Time</th>
            <th>Total Time</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {requests.map((request, index) => {
            const [hours, minutes] = hrsMinsSecsDiff(
              request.clockin_time,
              request.clockout_time
            );
            return (
              <tr key={index} className={"align-middle align-items-center"}>
                <td>
                  <EmployeeHighlightCard employee={request.employee} />
                </td>
                <td>{request.employee_comment}</td>
                <td>
                  {formatDate(
                    request.clockin_time,
                    dateFormats.daySingleDigitDMY12Hr
                  )}
                </td>
                <td>
                  {formatDate(
                    request.clockout_time,
                    dateFormats.daySingleDigitDMY12Hr
                  )}
                </td>
                <td>
                  {hours} hrs {minutes} mins
                </td>

                <td>
                  <StatusBadge status={request.approval_status} />
                </td>
                <td>
                  {onActionClick && request.approval_status === "Pending" && (
                    <div className={"my-3"}>
                      <Button
                        className={"w-100"}
                        variant={"success"}
                        onClick={() => onActionClick(request, "Approved")}
                      >
                        <FaCheck className={"me-2"} />
                        Approve
                      </Button>
                      <Button
                        variant={"danger"}
                        className={"mt-3 w-100"}
                        onClick={() => onActionClick(request, "Rejected")}
                      >
                        <FaTimes className={"me-2"} />
                        Reject
                      </Button>
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

export default OutOfPerimeterAttendanceRequests;
