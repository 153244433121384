import React, { useContext, useEffect, useState } from "react";
import {
  AddItemButton,
  AppOffCanvasLocal,
  LoadingView,
  TextWithLineBreaks,
} from "../../../utils/components";
import {
  updateArrayState,
  updateObjectState,
  useAppModal,
  useOffCanvas,
} from "../../../utils/functions";
import { AppContext } from "../../../utils/components/AppContext";
import { Alert, Button, Card } from "react-bootstrap";
import { FaPlus, FaTimes } from "react-icons/fa";
import ReviewPeriodStepForm from "../../Forms/Performance/ReviewPeriodStepForm";
import ReviewPeriodFormStepCard from "./ReviewPeriodFormStepCard";
import { formatDateStrings } from "../../../utils/dateTime";
import GenericForm from "../../Forms/Shared/GenericForm";
import { FormFieldTypes } from "../../FormFields";

function PerformanceReviewPeriodFormStepTab({
  periodForm,
  setPeriodForm,
  performanceForm,
  steps,
  setSteps,
  permissions,
  putRequest,
  loaderCallback,
  deleteRequest,
  getRequest,
  patchRequest,
}) {
  const defaultSteps = [
    {
      name: "Self Review",
      description:
        "In this step, you have the opportunity to reflect on your own performance during the review period. Please " +
        "consider your achievements, challenges, and areas for improvement. Be honest and thoughtful as you evaluate " +
        "your progress toward your goals, the quality of your work, and how you’ve contributed to the success of your " +
        "team. Use this self-assessment to highlight your strengths, acknowledge areas where you could improve, and set " +
        "the stage for a constructive conversation with your manager.",
      order: 1,
      column_name: "add_superior_review_step",
      is_self_step: true,
    },
    {
      name: "Supervisor / Manager Review",
      description:
        "In this step, you, as a supervisor or manager, are asked to provide a detailed evaluation of the employee's " +
        "performance during the review period. Consider their accomplishments, the quality of their work, and their " +
        "contributions to team and organizational goals. Please assess their strengths, areas for improvement, and how " +
        "they have progressed toward their goals. Your feedback should be constructive, providing clear examples where " +
        "possible, and should help guide the employee's development and performance improvement. This review will be " +
        "an essential part of the performance discussion and future goal setting.",
      order: 2,
      column_name: "add_superior_review_step",
      is_supervisor_step: true,
    },
  ];

  const { showOffCanvas } = useOffCanvas();
  const { updateContextState } = useContext(AppContext);
  const { showAppModal, closeModal } = useAppModal();

  const performanceFormColumnTitles = performanceForm?.columns?.map(
    (column) => column.title
  );

  const performanceFormWritableColumns = performanceForm?.columns?.filter(
    (column) => column.is_writable
  );

  const performanceFormWritableColumnsTitles =
    performanceFormWritableColumns?.map((column) => column.title);

  const performanceFormReadOnlyColumns = performanceForm?.columns?.filter(
    (column) => !column.is_writable
  );

  const readOnlyColumnTitles = performanceFormReadOnlyColumns?.map(
    (column) => column.title
  );

  const formDefaults = {
    allow_extra_comments: false,
    allow_file_uploads: false,
    read_columns: readOnlyColumnTitles,
    write_columns: [],
    view_extra_comments: false,
    view_comments: false,
    view_uploaded_files: false,
    view_approval_status: false,
    require_extra_comments: false,
    require_file_uploads: false,
  };

  const [isLoading, setIsLoading] = useState(false);
  const [writeColumns, setWriteColumns] = useState([]);
  const [formData, setFormData] = useState(formDefaults);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    if (steps.length > 0 && !showForm) {
      // usedWriteColumns is an array of arrays
      const usedWriteColumns = steps.map((step) => step.write_columns);
      const joinedUsedWriteColumns = [].concat(...usedWriteColumns);
      const unusedWriteColumns = performanceFormWritableColumnsTitles.filter(
        (column) => !joinedUsedWriteColumns.includes(column)
      );
      setWriteColumns(unusedWriteColumns);
    } else if (!showForm) {
      setWriteColumns(performanceFormWritableColumnsTitles);
    }
  }, [steps, showForm]);

  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <div className={"mb-5 pb-5"}>
          <Card className={"mb-4"}>
            <Card.Header className={"py-4"}>
              <Card.Title>
                Review Steps for {periodForm.form?.name || "Form"}
              </Card.Title>
              <Card.Subtitle as={"p"} className={"mt-1"}>
                These steps will be the stages of the review process for this
                form.
              </Card.Subtitle>
            </Card.Header>
          </Card>

          {steps.length < defaultSteps.length && (
            <div>
              {defaultSteps.map((step, index) => {
                const idxNum = index + 1;
                const stepInfo = steps.find((st) => st.order === idxNum);

                if (stepInfo) {
                  return (
                    <div className={"mb-4"} key={index}>
                      <ReviewPeriodFormStepCard
                        step={stepInfo}
                        getRequest={getRequest}
                      />
                    </div>
                  );
                }

                return (
                  <Card key={index} className={"mb-4"}>
                    <Card.Body className={"py-3 text-center"}>
                      <Card.Title>{step.name} Step</Card.Title>
                      <Card.Subtitle as={"p"} className={"mt-1"}>
                        {step.description}
                      </Card.Subtitle>
                    </Card.Body>

                    {permissions.MANAGE_PERFORMANCE_REVIEWS && !stepInfo && (
                      <Card.Footer className={"text-center py-4"}>
                        <Button
                          variant={"primary"}
                          className={"me-3"}
                          onClick={() => {
                            setFormData({
                              ...formDefaults,
                              ...step,
                            });
                            setShowForm(true);
                          }}
                        >
                          <FaPlus className={"me-2"} />
                          Add {step.name} Step
                        </Button>
                        <Button
                          variant={"outline-danger"}
                          onClick={() => {
                            showAppModal({
                              title: `Skip ${step.name} Step`,
                              component: (
                                <p>
                                  Are you sure you want to skip the{" "}
                                  <strong>{step.name}</strong> step?
                                </p>
                              ),
                              truthyFunction: () => {
                                putRequest(
                                  `/performance/review-period/form/${periodForm.id}/step`,
                                  setIsLoading,
                                  (response) => {
                                    const respStep = response.step;
                                    const currSteps = [...steps];
                                    currSteps[respStep.order - 1] = respStep;
                                    setSteps(currSteps);
                                  },
                                  { ...formDefaults, ...step, type: "Skipped" },
                                  false,
                                  false
                                );
                              },
                            });
                          }}
                        >
                          <FaTimes className={"me-2"} />
                          Skip {step.name} Step
                        </Button>
                      </Card.Footer>
                    )}
                  </Card>
                );
              })}
            </div>
          )}

          {steps.length >= defaultSteps.length && (
            <div>
              {steps.map((step, index) => (
                <div className={"mb-4"} key={index}>
                  <ReviewPeriodFormStepCard
                    step={step}
                    getRequest={getRequest}
                    deleteRequest={deleteRequest}
                    onEditClick={
                      !permissions.MANAGE_PERFORMANCE_REVIEWS
                        ? null
                        : () => {
                            if (step.write_columns) {
                              setWriteColumns(
                                Array.from(
                                  new Set([
                                    ...writeColumns,
                                    ...step.write_columns,
                                  ])
                                )
                              );
                            }
                            const defaultStep =
                              defaultSteps[step.order - 1] || {};
                            setFormData(
                              formatDateStrings({
                                ...formDefaults,
                                ...defaultStep,
                                ...step,
                                isCustom: step.order > defaultSteps.length,
                              })
                            );
                            setShowForm(true);
                          }
                    }
                    onDeleteClick={
                      !permissions.MANAGE_PERFORMANCE_REVIEWS ||
                      !step.can_delete ||
                      step.order <= defaultSteps.length ||
                      step.order < steps.length
                        ? null
                        : () => {
                            deleteRequest(
                              `/performance/review-period/form/${periodForm.id}/step/${step.id}`,
                              setIsLoading,
                              () => {
                                setSteps(
                                  steps.filter((st) => st.id !== step.id)
                                );
                              }
                            );
                          }
                    }
                    canAddReviewer={step.can_modify_reviewers}
                    canRemoveReviewer={step.can_modify_reviewers}
                    putRequest={putRequest}
                  />
                </div>
              ))}

              {periodForm.attestation_enabled && (
                <div>
                  <Card className={"mb-4"}>
                    <Card.Header className={"py-3"}>
                      <Card.Title>Attestation Step</Card.Title>
                      <Card.Subtitle as={"p"} className={"mt-1"}>
                        This step is for the employee to attest to the review
                        process.
                      </Card.Subtitle>
                    </Card.Header>
                    <Card.Body className={"py-3"}>
                      <p className={"fw-bold"}>Instructions for Employees</p>
                      <p>
                        <TextWithLineBreaks
                          text={periodForm.attestation_instructions}
                        />
                      </p>
                    </Card.Body>
                  </Card>
                </div>
              )}

              {!periodForm.attestation_enabled && (
                <div className={"d-flex justify-content-end"}>
                  <Button
                    className={"mt-3 mb-5"}
                    variant={"primary"}
                    onClick={() => {
                      setFormData({
                        ...formDefaults,
                        isCustom: true,
                      });
                      setShowForm(true);
                    }}
                  >
                    <FaPlus className={"me-2"} />
                    Add Another Step
                  </Button>
                  <Button
                    className={"mt-3 mb-5 ms-3"}
                    variant={"outline-info"}
                    onClick={() => {
                      updateContextState({
                        formData: {
                          instructions: "",
                        },
                      });
                      showAppModal({
                        title: "Add Attestation Step",
                        hideFooter: true,
                        component: (
                          <div>
                            <Alert variant={"warning"} className={"mb-4"}>
                              <strong>
                                You won't be able to add another step after
                                adding an attestation step
                              </strong>
                            </Alert>

                            <GenericForm
                              formConfigs={[
                                {
                                  valueKey: "instructions",
                                  labelName: "Attestation Instructions",
                                  info: "These are the instructions the employee will see when they are attesting to the review",
                                  type: FormFieldTypes.TEXT_AREA,
                                },
                              ]}
                              onSubmit={(data) => {
                                closeModal();
                                patchRequest(
                                  `/performance/review-period/form/${periodForm.id}/attestation`,
                                  setIsLoading,
                                  (response) => {
                                    updateObjectState(setPeriodForm, {
                                      attestation_enabled: true,
                                    });
                                  },
                                  data
                                );
                              }}
                            />
                          </div>
                        ),
                      });
                    }}
                  >
                    <FaPlus className={"me-2"} />
                    Add Attestation Step
                  </Button>
                </div>
              )}
            </div>
          )}

          {periodForm.attestation_enabled && periodForm.can_remove_attestation && (
            <div className={"d-flex justify-content-end"}>
              <Button
                className={"mt-3 mb-5"}
                variant={"outline-danger"}
                onClick={() => {
                  showAppModal({
                    title: "Remove Attestation Step",
                    component: (
                      <p>
                        Are you sure you want to remove the Attestation Step?
                      </p>
                    ),
                    truthyFunction: () => {
                      patchRequest(
                        `/performance/review-period/form/${periodForm.id}/attestation/remove`,
                        setIsLoading,
                        (response) => {
                          updateObjectState(setPeriodForm, {
                            attestation_enabled: false,
                          });
                        },
                        { instructions: "" }
                      );
                    },
                  });
                }}
              >
                <FaTimes className={"me-2"} />
                Remove Attestation Step
              </Button>
            </div>
          )}

          <AppOffCanvasLocal
            showOffCanvas={showForm}
            setShowOffCanvas={setShowForm}
            title={"Performance Review Form Step"}
            subtitle={`${
              formData.id
                ? "Edit review form step " + formData.name
                : "Add a new review step to the form"
            }`}
            component={
              <>
                {formData.id && (
                  <Alert variant={"info"} className={"mb-4"}>
                    Only the reviews that are NOT in "Completed" status will be
                    affected by the changes you make here
                  </Alert>
                )}
                <ReviewPeriodStepForm
                  readColumns={performanceFormColumnTitles}
                  writeColumns={writeColumns}
                  formData={formData}
                  setFormData={setFormData}
                  onSubmit={(submittedData) => {
                    if (formData.id) {
                      patchRequest(
                        `/performance/review-period/form/${periodForm.id}/step/${formData.id}`,
                        setIsLoading,
                        (response) => {
                          setSteps(
                            steps.map((step) =>
                              step.id === response.step.id
                                ? response.step
                                : step
                            )
                          );
                          setShowForm(false);
                        },
                        submittedData,
                        false,
                        false
                      );
                    } else {
                      putRequest(
                        `/performance/review-period/form/${periodForm.id}/step`,
                        setIsLoading,
                        (response) => {
                          updateArrayState(setSteps, [response.step]);
                          setShowForm(false);
                        },
                        submittedData,
                        false,
                        false
                      );
                    }
                  }}
                />
              </>
            }
          />
        </div>
      }
    />
  );
}

export default PerformanceReviewPeriodFormStepTab;
