import React, { useContext, useState } from "react";
import { Button, Form, Card, Alert } from "react-bootstrap";
import { AppContext } from "../../../utils/components/AppContext";
import EmployeeSearch from "../../components/EmployeeSearch";
import { EmployeeList } from "../../components/EmployeeCards";
import { isEmpty } from "../../../utils/functions";

function PayrollApproversForm({ onSubmit, isEdit }) {
  const { contextState, updateContextState } = useContext(AppContext);

  const [formState, setFormState] = useState({
    is_active: true,
    is_required: false,
    approver_employee_id: contextState.formData?.employee?.id || {},
    ...contextState.formData,
  });
  const [approverEmployee, setApproverEmployee] = useState(
    contextState.formData?.employee || {}
  );

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const formValid = () => {
    const requiredFields = ["approver_employee_id"];
    return requiredFields.every((field) => !isEmpty(formState[field]));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateContextState({
      formData: { ...formState },
    });
    onSubmit(formState);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Text as={"p"} className={"fw-bold fs-6"}>
        Required fields are marked with an{" "}
        <span className="text-danger">*</span>
      </Form.Text>
      <Alert variant={"info"}>
        The selected employee will be notified when payroll is ready to be
        approved. They will also be added as approver to any open pay periods
        and future ones.
      </Alert>
      {!isEdit && (
        <EmployeeSearch
          onResultClick={(employee) => {
            setApproverEmployee(employee);
            handleInputChange({
              target: { name: "approver_employee_id", value: employee.id },
            });
          }}
        />
      )}

      {approverEmployee.id && <EmployeeList employees={[approverEmployee]} />}
      <Card body={true} className="mb-4">
        <Form.Group
          controlId="isActive"
          className={"d-flex justify-content-between align-items-center"}
        >
          <Form.Label>
            Payroll Approver Active?<span className="text-danger">*</span>
          </Form.Label>
          <Form.Switch
            name="is_active"
            checked={formState.is_active || false}
            onChange={(e) =>
              handleInputChange({
                target: { name: "is_active", value: e.target.checked },
              })
            }
          />
        </Form.Group>
      </Card>

      <Card body={true} className="mb-4">
        <Form.Group
          controlId="isRequired"
          className={"d-flex justify-content-between align-items-center"}
        >
          <Form.Label>
            Approver Required?<span className="text-danger">*</span>
          </Form.Label>
          <Form.Switch
            name="is_required"
            checked={formState.is_required || false}
            onChange={(e) =>
              handleInputChange({
                target: { name: "is_required", value: e.target.checked },
              })
            }
          />
        </Form.Group>

        <Form.Text>
          If this switch is on the pay period will not be closed until this
          approver approves the payroll pay period.
        </Form.Text>
      </Card>

      <Button
        variant="primary"
        type="submit"
        className={"w-100 mb-5"}
        disabled={!formValid()}
      >
        Save
      </Button>
    </Form>
  );
}

export default PayrollApproversForm;
