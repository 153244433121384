import React, { useState } from "react";
import DateTimeNavHeader from "../Shared/DateTimeNavHeader";
import EmployeeActivityCard from "./EmployeeActivityCard";
import { LoadingView, NoInfoCard } from "../../../utils/components";
import { Card } from "react-bootstrap";

function EmployeesActivities({
  permissions,
  fetchDataEndpoint,
  extraRequestParams = {},
}) {
  const [secondLoader, setSecondLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activities, setActivities] = useState({});
  const [fetchMoreData, setFetchMoreData] = useState(false);
  const [activeData, setActiveData] = useState({
    data: [],
  });

  return (
    <div>
      <Card className={"mb-4"}>
        <Card.Header>
          <Card.Title>Employee Activities</Card.Title>
        </Card.Header>
        <Card.Body>
          <Card.Text>
            View all priviledged activities of employees in your organization.
            These includes add or updating employee details, teams, position,
            levels, approvals etc.
          </Card.Text>
        </Card.Body>
      </Card>
      <DateTimeNavHeader
        fetchedData={activities}
        setFetchedData={setActivities}
        activeData={activeData}
        setActiveData={setActiveData}
        fetchDataEndpoint={fetchDataEndpoint}
        setIsLoading={setIsLoading}
        setSecondLoader={setSecondLoader}
        fetchMoreData={fetchMoreData}
        setfetchMoreData={setFetchMoreData}
        requestParams={extraRequestParams}
      />
      <LoadingView
        isLoading={isLoading}
        centerLoader={false}
        view={
          <div>
            <div className={"mt-4"}>
              {activeData.data.map((activity, index) => (
                <div key={index} className={"mb-4"}>
                  <EmployeeActivityCard activity={activity} />
                </div>
              ))}

              {activeData.data.length === 0 && (
                <NoInfoCard text={"This date range has no activities"} />
              )}
            </div>

            <div className={"d-flex justify-content-center my-5"}>
              {secondLoader && (
                <div className={"spinner-border text-primary"} role={"status"}>
                  <span className={"visually-hiddens"}>Loading...</span>
                </div>
              )}
              {!secondLoader && activeData?.pagingInfo?.has_next && (
                <button
                  className={"btn btn-primary"}
                  onClick={() => {
                    setFetchMoreData(true);
                  }}
                >
                  Load {activeData?.pagingInfo?.per_page} More Activities
                </button>
              )}
            </div>
          </div>
        }
      />
    </div>
  );
}

export default EmployeesActivities;
