import { FormFieldTypes } from "../../FormFields";

const SharedFormConfigs = {
  SingleEntitySelection: ({
    labelName = "Select The Organization Entity to Use",
    alertMsg,
    info,
  }) => {
    return [
      {
        valueKey: "entity_ids",
        type: FormFieldTypes.ENTITY_SELECTION,
        labelName: labelName,
        alertMsg: alertMsg,
        info: info,
        includeList: ["Positions", "Select Employees"],
        entitySelectionProps: {
          is_single_person: true,
        },
      },
    ];
  },
};

export { SharedFormConfigs };
