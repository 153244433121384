import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Card, Alert } from "react-bootstrap";
import { AppContext } from "../../../utils/components/AppContext";
import { formatDateStrings } from "../../../utils/dateTime";
import { isEmpty, updateObjectState } from "../../../utils/functions";

function TimeOffPolicyForm({
  onSubmit,
  configs,
  approvalWorkflows,
  isEdit = false,
}) {
  const { contextState, updateContextState } = useContext(AppContext);
  const [formState, setFormState] = useState(
    formatDateStrings({ ...contextState.formData })
  );

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (!formState.is_holiday) return;
    updateObjectState(setFormState, {
      approval_workflow_id: null,
    });
  }, [formState.is_holiday]);

  const formValid = () => {
    const requiredFields = ["name", "renewal_time_units", "type", "time_unit"];
    if (formState.is_holiday) {
      requiredFields.push("start_date");
      requiredFields.push("end_date");
    } else {
      requiredFields.push("approval_workflow_id");
    }

    if (
      (formState.apply_to_existing || formState.apply_to_new_employees) &&
      formState.apply_to_employee_types.length === 0
    ) {
      return false;
    }
    return requiredFields.every((field) => !isEmpty(formState[field]));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateContextState({
      formData: { ...formState },
    });
    onSubmit(formState);
  };

  if (approvalWorkflows.length === 0) {
    return (
      <Alert variant={"warning mt-2"}>
        Please create at least 1 "Approval Workflow" before you can create a
        time off policy.
      </Alert>
    );
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Text as={"p"} className={"fw-bold fs-6"}>
        Required fields are marked with an{" "}
        <span className="text-danger">*</span>
      </Form.Text>
      <Form.Group controlId="name" className={"mb-4"}>
        <Form.Label>
          Name<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          name="name"
          value={formState.name || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="description" className={"mb-4"}>
        <Form.Label>Description</Form.Label>
        <Form.Control
          type="text"
          name="description"
          value={formState.description || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="timeUnit" className={"mb-4"}>
        <Form.Label>
          Time Unit<span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          name="time_unit"
          value={formState.time_unit || ""}
          onChange={handleInputChange}
        >
          <option value={""}>Select a Time Unit</option>
          {configs.time_off_units?.map((unit) => (
            <option key={unit} value={unit}>
              {unit}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      <Form.Group controlId="renewalTimeUnits" className={"mb-4"}>
        <Form.Label>
          Available {formState.time_unit || "Time"}
          <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="number"
          name="renewal_time_units"
          value={formState.renewal_time_units || ""}
          onChange={handleInputChange}
        />
        {isEdit && (
          <Form.Text as={"p"} className={"mt-3"}>
            Changes here will apply to employees who have this policy assigned
            to them.
          </Form.Text>
        )}
      </Form.Group>

      <Form.Group controlId="type" className={"mb-4"}>
        <Form.Label>
          Time Off Type<span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          name="type"
          value={formState.type || ""}
          onChange={handleInputChange}
        >
          <option value={""}>Select a Type</option>
          {configs.employee_time_off_types?.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      {isEdit && (
        <Card body={true} className="mb-4">
          <Form.Group
            controlId="isActive"
            className={"d-flex justify-content-between align-items-center"}
          >
            <Form.Label>Is Active</Form.Label>
            <Form.Switch
              name="is_active"
              checked={formState.is_active || false}
              onChange={(e) =>
                handleInputChange({
                  target: { name: "is_active", value: e.target.checked },
                })
              }
            />
          </Form.Group>

          <Form.Text>
            Making this active will prevent current employees from requesting
            time off for this policy and it won't be added to new employees.
          </Form.Text>
        </Card>
      )}

      <Card body={true} className="mb-4">
        <Form.Group
          controlId="renewsYearly"
          className={"d-flex justify-content-between align-items-center"}
        >
          <Form.Label>Automatically Renew Yearly</Form.Label>
          <Form.Switch
            name="renews_yearly"
            checked={formState.renews_yearly || false}
            onChange={(e) =>
              handleInputChange({
                target: { name: "renews_yearly", value: e.target.checked },
              })
            }
          />
        </Form.Group>
      </Card>

      <Card body={true} className="mb-4">
        <Form.Group
          controlId="carryover"
          className={"d-flex justify-content-between align-items-center"}
        >
          <Form.Label>
            Carryover unused {formState.time_unit || "time"} to the next year
          </Form.Label>
          <Form.Switch
            name="carryover"
            checked={formState.carryover || false}
            onChange={(e) =>
              handleInputChange({
                target: { name: "carryover", value: e.target.checked },
              })
            }
          />
        </Form.Group>
      </Card>

      {!isEdit && (
        <Card body={true} className="mb-4">
          <Form.Group
            controlId="applyToExisting"
            className={"d-flex justify-content-between align-items-center"}
          >
            <div className={"me-2"}>
              <Form.Label>Apply to existing employees</Form.Label>
            </div>
            <Form.Switch
              name="apply_to_existing"
              checked={formState.apply_to_existing || false}
              onChange={(e) =>
                handleInputChange({
                  target: {
                    name: "apply_to_existing",
                    value: e.target.checked,
                  },
                })
              }
            />
          </Form.Group>
          {formState.apply_to_existing && (
            <div>
              <div>
                <Form.Text>
                  Select the employee types to apply this policy to.
                </Form.Text>
              </div>
              {configs.employee_types?.map((type, index) => {
                return (
                  <Button
                    key={index}
                    variant={
                      formState.apply_to_employee_types.includes(type)
                        ? "primary"
                        : "outline-primary"
                    }
                    className={"me-2 mt-3"}
                    onClick={() => {
                      setFormState({
                        ...formState,
                        apply_to_employee_types:
                          formState.apply_to_employee_types.includes(type)
                            ? formState.apply_to_employee_types.filter(
                                (item) => item !== type
                              )
                            : [...formState.apply_to_employee_types, type],
                      });
                    }}
                  >
                    {type}
                  </Button>
                );
              })}
            </div>
          )}
        </Card>
      )}

      <Card body={true} className="mb-4">
        <Form.Group
          controlId="applyToNewEmployees"
          className={"d-flex justify-content-between align-items-center"}
        >
          <Form.Label>Apply to new employees</Form.Label>
          <Form.Switch
            name="apply_to_new_employees"
            checked={formState.apply_to_new_employees || false}
            onChange={(e) =>
              handleInputChange({
                target: {
                  name: "apply_to_new_employees",
                  value: e.target.checked,
                },
              })
            }
          />
        </Form.Group>

        {formState.apply_to_new_employees && (
          <div>
            <div>
              <Form.Text>
                Select the employee types to apply this policy to.
              </Form.Text>
            </div>
            {configs.employee_types?.map((type, index) => {
              return (
                <Button
                  key={index}
                  variant={
                    formState.apply_to_employee_types.includes(type)
                      ? "primary"
                      : "outline-primary"
                  }
                  className={"me-2 mt-3"}
                  onClick={() => {
                    setFormState({
                      ...formState,
                      apply_to_employee_types:
                        formState.apply_to_employee_types.includes(type)
                          ? formState.apply_to_employee_types.filter(
                              (item) => item !== type
                            )
                          : [...formState.apply_to_employee_types, type],
                    });
                  }}
                >
                  {type}
                </Button>
              );
            })}
          </div>
        )}
      </Card>

      {!isEdit && (
        <Card body={true} className="mb-4">
          <Form.Group
            controlId="isHoliday"
            className={"d-flex justify-content-between align-items-center"}
          >
            <Form.Label>This is a Holiday</Form.Label>
            <Form.Switch
              name="is_holiday"
              checked={formState.is_holiday || false}
              onChange={(e) => {
                handleInputChange({
                  target: { name: "is_holiday", value: e.target.checked },
                });
              }}
            />
          </Form.Group>
        </Card>
      )}

      {formState.is_holiday && (
        <>
          <Form.Group controlId="startDate" className={"mb-4"}>
            <Form.Label>
              Holiday Start Date<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="date"
              name="start_date"
              value={formState.start_date || ""}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group controlId="endDate" className={"mb-4"}>
            <Form.Label>
              Holiday End Date<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="date"
              name="end_date"
              value={formState.end_date || ""}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Alert variant={"info"}>
            If you check the "Renews Yearly" box, the holiday will be
            aautomatically added for the same date every year. Note that some
            holidays do not fall on the same date every year.
          </Alert>
          <Alert variant={"warning"}>
            <strong>Note:</strong>
            If you select "Apply to new employees" or "Apply to existing
            employees". A time off request will be automatically created for all
            the selected mployees and marked as "Approved".
          </Alert>
        </>
      )}

      {!formState.is_holiday && (
        <Form.Group controlId="type" className={"mb-4"}>
          <Form.Label>
            Approval Workflow<span className="text-danger">*</span>
          </Form.Label>

          <Form.Select
            className={"mb-2"}
            name={"approval_workflow_id"}
            value={formState.approval_workflow_id || ""}
            onChange={(e) => {
              handleInputChange(e);
            }}
          >
            <option value={""}>Select an Approval Workflow</option>
            {approvalWorkflows?.map((workflow) => (
              <option key={workflow.id} value={workflow.id}>
                {workflow.name}
              </option>
            ))}
          </Form.Select>
          <Form.Text>
            Select the approval workflow that should be to approve this time off
            policy.
          </Form.Text>
        </Form.Group>
      )}

      <Button
        variant="primary"
        type="submit"
        className={"w-100 mt-4 mb-5"}
        disabled={!formValid()}
      >
        Save
      </Button>
    </Form>
  );
}

export default TimeOffPolicyForm;
