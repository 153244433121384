import React, { useContext, useState } from "react";
import { Button, Form, Card } from "react-bootstrap";
import { AppContext } from "../../../utils/components/AppContext";
import { formatDateStrings } from "../../../utils/dateTime";
import ProductPermissionCheckView from "../../components/ProductPermissionCheckView";
import { Products } from "../../../enums/OrgEnums";

function PayrollSettingsForm({ onSubmit, configs, isEdit }) {
  const { contextState, updateContextState } = useContext(AppContext);

  const [formState, setFormState] = useState({
    is_active: true,
    ...formatDateStrings({ ...contextState.formData }),
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const formValid = () => {
    const requiredFields = [
      "minimum_approvers",
      "total_workday_hours",
      "total_workweek_hours",
      "payments_start_date",
      "payment_frequency",
    ];
    return requiredFields.every((field) => formState[field]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateContextState({
      formData: { ...formState },
    });
    onSubmit(formState);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Text as={"p"} className={"fw-bold fs-6"}>
        Required fields are marked with an{" "}
        <span className="text-danger">*</span>
      </Form.Text>

      <Card body={true} className="mb-4">
        <Form.Group
          controlId="isActive"
          className={"d-flex justify-content-between align-items-center"}
        >
          <Form.Label>Is Payroll Active?</Form.Label>
          <Form.Switch
            name="is_active"
            checked={formState.is_active || false}
            onChange={(e) =>
              handleInputChange({
                target: { name: "is_active", value: e.target.checked },
              })
            }
          />
        </Form.Group>
      </Card>

      <Form.Group controlId="minimumApprovers" className={"mb-4"}>
        <Form.Label>
          Minimum Approvers<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="number"
          name="minimum_approvers"
          value={formState.minimum_approvers || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="totalWorkdayHours" className={"mb-4"}>
        <Form.Label>
          Total Workday Hours<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="number"
          name="total_workday_hours"
          value={formState.total_workday_hours || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="totalWorkweekHours" className={"mb-4"}>
        <Form.Label>
          Total Workweek Hours<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="number"
          name="total_workweek_hours"
          value={formState.total_workweek_hours || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <ProductPermissionCheckView product={Products.ATTENDANCE_MANAGEMENT}>
        <Form.Group controlId="totalWorkweekHours" className={"mb-4"}>
          <Form.Label>Attendance Threshold Percentage (1 to 100)</Form.Label>
          <Form.Control
            type="number"
            name="attendance_percentage_threshold"
            value={formState.attendance_percentage_threshold || ""}
            onChange={handleInputChange}
            className={"mb-2"}
          />
          <Form.Text className={"text-muted"}>
            When you enter a percentage above, we will show you all the
            employees that did not meet this percentage of attendance during a
            pay period, before you process payroll
          </Form.Text>
        </Form.Group>
      </ProductPermissionCheckView>

      <Form.Group controlId="paymentsStartDate" className={"mb-4"}>
        <Form.Label>
          First Pay Period Start Date<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="date"
          name="first_pay_period_start_date"
          value={formState.first_pay_period_start_date || ""}
          onChange={handleInputChange}
          readOnly={isEdit}
          disabled={isEdit}
        />

        <Form.Text className={"text-muted"}>
          {isEdit
            ? "This field cannot be changed."
            : "This is the date when the payroll period will start. This date will be used to calculate the payroll period. It cannot be changed once it is set."}
        </Form.Text>
      </Form.Group>

      <Form.Group controlId="paymentsStartDate" className={"mb-4"}>
        <Form.Label>
          Payments Start Date<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="date"
          name="payments_start_date"
          value={formState.payments_start_date || ""}
          onChange={handleInputChange}
          readOnly={isEdit}
          disabled={isEdit}
        />

        <Form.Text className={"text-muted"}>
          {isEdit
            ? "This field cannot be changed."
            : "This is the first date payments will be made after the first pay period. Make sure it is on or after the pay period ends. It cannot be changed once it is set."}
        </Form.Text>
      </Form.Group>

      <Form.Group controlId="paymentFrequency" className={"mb-4"}>
        <Form.Label>
          Payment Frequency<span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          name="payment_frequency"
          value={formState.payment_frequency || ""}
          onChange={handleInputChange}
        >
          <option value="">Select a Frequency</option>
          {configs.organization_salary_frequencies.map((frequency, index) => (
            <option key={index} value={frequency}>
              {frequency}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      <Button
        variant="primary"
        type="submit"
        className={"w-100 mb-5"}
        disabled={!formValid()}
      >
        Save
      </Button>
    </Form>
  );
}

export default PayrollSettingsForm;
