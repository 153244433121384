import React from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";

function InfoCard({
  header,
  body,
  footer,
  xs = 6,
  md = 4,
  lg = 3,
  colClass = "",
}) {
  return (
    <Col xs={xs} md={md} lg={lg} className={colClass}>
      <Card className={"text-center"}>
        <Card.Header className={"fw-bold"}>{header}</Card.Header>
        <Card.Body>
          <h5>{body}</h5>
        </Card.Body>
        {footer && (
          <Card.Footer>
            <div>{footer}</div>
          </Card.Footer>
        )}
      </Card>
    </Col>
  );
}

export default InfoCard;
