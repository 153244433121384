import React, { useContext, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { AppContext } from "../../../utils/components/AppContext";
import { formatDateStrings } from "../../../utils/dateTime";

function AvailableTimeOffForm({ onSubmit, configs }) {
  const { contextState, updateContextState } = useContext(AppContext);
  const [formState, setFormState] = useState(
    formatDateStrings({ ...contextState.formData })
  );

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const formValid = () => {
    const requiredFields = [
      "time_off_policy_id",
      "available_time",
      "start_date",
    ];
    return requiredFields.every((field) => formState[field]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateContextState({
      formData: { ...formState },
    });
    onSubmit(formState);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Text as={"p"} className={"fw-bold fs-6"}>
        Required fields are marked with an{" "}
        <span className="text-danger">*</span>
      </Form.Text>

      <Form.Group controlId="timeOffPolicyId" className={"mb-4"}>
        <Form.Label>
          Select a Time Off Policy<span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          name="time_off_policy_id"
          value={formState.time_off_policy_id || ""}
          onChange={handleInputChange}
        >
          {configs.time_off_policies.map((policy) => (
            <option key={policy.id} value={policy.id}>
              {policy.name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      <Form.Group controlId="availableTime" className={"mb-4"}>
        <Form.Label>
          Available Time to Add<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="number"
          name="available_time"
          value={formState.available_time || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      {/* Start Date*/}
      <Form.Group controlId="idExpiry" className={"mb-4"}>
        <Form.Label>Effective Date</Form.Label>
        <Form.Control
          type="date"
          name="start_date"
          value={formState.start_date || ""}
          onChange={handleInputChange}
        />
      </Form.Group>
      <Button
        variant="primary"
        type="submit"
        className={"w-100 mb-5"}
        disabled={!formValid()}
      >
        Save
      </Button>
    </Form>
  );
}

export default AvailableTimeOffForm;
