export const DATE_FORMATS = {
  fullDate: "YYYY-MM-DD", // e.g. 2023-09-09
  fullDate12Hr: "YYYY-MM-DD hh:mm A", // e.g. 2023-09-09 3:30 PM
  fullDate24Hr: "YYYY-MM-DD HH:mm", // e.g. 2023-09-09 15:30

  singleDigitMonth: "YYYY-M-DD", // e.g. 2023-9-09
  singleDigitDay: "YYYY-MM-D", // e.g. 2023-09-9
  singleDigitMonthDay: "YYYY-M-D", // e.g. 2023-9-9
  fullDateTime: "YYYY-MM-DD HH:mm:ss", // e.g. 2023-09-09 15:30:00
  monthDayYear: "MM/DD/YYYY", // e.g. 09/09/2023
  singleDigitMonthDaySlash: "M/D/YYYY", // e.g. 9/9/2023
  dayMonthYear: "DD/MM/YYYY", // e.g. 09/09/2023
  singleDigitDayMonthSlash: "D/M/YYYY", // e.g. 9/9/2023
  time12Hour: "hh:mm A", // e.g. 03:30 PM
  time24Hour: "HH:mm", // e.g. 15:30
  monthNameDayYear: "MMMM DD, YYYY", // e.g. September 09, 2023
  dayNameMonthDayYear: "dddd, MMMM DD, YYYY", // e.g. Saturday, September 09, 2023

  shortMonthFullDayYear: "MMM DD, YYYY", // e.g. Sep 09, 2023

  fullMonthShortDayYear: "MMMM D, YYYY", // e.g. September 9, 2023

  shortDayFullMonthDayYear: "ddd, MMMM DD, YYYY", // e.g. Sat, September 09, 2023

  shortDayShortMonthFullDayYear: "ddd, MMM DD, YYYY", // e.g. Sat, Sep 09, 2023
  shortDayShortMonthFullDayYearTime24Hr: "ddd, MMM DD, YYYY HH:mm", // e.g. Sat, Sep 09, 2023 15:30
  shortDayShortMonthFullDayYearTime12Hr: "ddd, MMM DD, YYYY hh:mm A", // e.g. Sat, Sep 09, 2023 3:30 PM

  shortDate: "M/D/YY", // e.g. 9/9/23
  shortDateTime: "M/D/YY h:mm A", // e.g. 9/9/23 3:30 PM
  shortDateTime24Hr: "M/D/YY HH:MM", // e.g. 9/9/23 15:30
};

export const COMMON_DATE_FORMATS = [
  "YYYY-MM-DD",
  "MM-DD-YYYY",
  "DD-MM-YYYY",
  "YYYY/MM/DD",
  "MM/DD/YYYY",
  "DD/MM/YYYY",
  "YYYY-M-DD",
  "M-DD-YYYY",
  "DD-M-YYYY",
  "YYYY/M/DD",
  "M/DD/YYYY",
  "DD/M/YYYY",
  "YYYY-M-D",
  "M-D-YYYY",
  "D-M-YYYY",
  "YYYY/M/D",
  "M/D/YYYY",
  "D/M/YYYY",
];
