import React from "react";
import { Button, Table } from "react-bootstrap";
import EmployeeHighlightCard from "./EmployeeHighlightCard";
import {
  dateFormats,
  formatDate,
  hrsMinsSecsDiff,
} from "../../../utils/dateTime";
import { StatusBadge } from "../../../utils/components";
import { secsToHrsMinsSecs, useAppModal } from "../../../utils/functions";
import { FaMapMarkerAlt } from "react-icons/fa";
import { appConfigs } from "../../../configs";

function AttendanceTable({ attendances }) {
  const dateTimeFormat = dateFormats.daySingleDigitDMY12Hr;
  const { showAppModal } = useAppModal();

  const displayMap = (latitude, longitude, title) => {
    showAppModal({
      title: title,
      hideFooter: true,
      size: "xl",
      component: (
        <iframe
          width="100%"
          height="600"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          src={`https://www.google.com/maps/embed/v1/place?key=${appConfigs.googleMapsApiKey}&q=${latitude},${longitude}`}
        />
      ),
    });
  };

  return (
    <div>
      <Table striped bordered responsive hover>
        <thead>
          <tr className={"fs-5 text-center align-middle"}>
            <th></th>
            <th>Employee</th>
            <th>Scheduled Time</th>
            <th>Time In</th>
            <th>Time Out</th>
            <th>Duration</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {attendances.map((attendance, index) => {
            return (
              <tr key={attendance.id} className={"text-center align-middle"}>
                <td>{index + 1}</td>
                <td>
                  <EmployeeHighlightCard employee={attendance.employee} />
                </td>
                <td>
                  {formatDate(attendance.scheduled_start_time, dateTimeFormat)}
                  <br />
                  {formatDate(attendance.scheduled_end_time, dateTimeFormat)}
                  <br />
                  {hrsMinsSecsDiff(
                    attendance.scheduled_start_time,
                    attendance.scheduled_end_time,
                    false,
                    true
                  )}
                </td>
                <td className={"py-4"}>
                  {formatDate(attendance.clockin_time, dateTimeFormat)}
                  <br />
                  {attendance.clockin_latitude &&
                  attendance.clockin_longitude ? (
                    <Button
                      className={"mt-3"}
                      variant={"outline-primary"}
                      size={"sm"}
                      onClick={() =>
                        displayMap(
                          attendance.clockin_latitude,
                          attendance.clockin_longitude,
                          "Clock In Location"
                        )
                      }
                    >
                      <FaMapMarkerAlt className={"me-2"} /> View Location
                    </Button>
                  ) : (
                    "No Location Data"
                  )}
                </td>
                <td className={"py-4"}>
                  {formatDate(attendance.clockout_time, dateTimeFormat)}
                  <br />
                  {attendance.clockout_latitude &&
                  attendance.clockout_longitude ? (
                    <Button
                      className={"mt-3"}
                      variant={"outline-primary"}
                      size={"sm"}
                      onClick={() =>
                        displayMap(
                          attendance.clockout_latitude,
                          attendance.clockout_longitude,
                          "Clock Out Location"
                        )
                      }
                    >
                      <FaMapMarkerAlt className={"me-2"} /> View Location
                    </Button>
                  ) : (
                    "No Location Data"
                  )}
                </td>
                <td>{secsToHrsMinsSecs(attendance.attended_seconds)}</td>
                <td>
                  <StatusBadge status={attendance.approval_status} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

export default AttendanceTable;
