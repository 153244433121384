import { useContext, useState, createContext } from "react";

export const ModalContext = createContext();

export const useModal = () => {
  return useContext(ModalContext);
};

export const ModalProvider = ({ children }) => {
  const initContent = {
    title: "",
    component: undefined,
    truthyFunction: undefined,
    falsyFunction: undefined,
    truthyText: null,
    falsyText: null,
    hideFooter: false,
    onClose: undefined,
  };

  const [show, setShow] = useState(false);
  const [content, setContent] = useState(initContent);

  const openModal = (newContent) => {
    setContent(newContent);
    setShow(true);
  };

  const restoreModal = () => {
    setShow(true);
  };
  const closeModal = () => {
    // setContent(initContent);
    if (content.onClose) {
      content.onClose();
    }
    setShow(false);
  };

  return (
    <ModalContext.Provider
      value={{
        show,
        content,
        openModal: openModal,
        closeModal,
        restoreModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
