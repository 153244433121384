import { Col, ListGroup, Row } from "react-bootstrap";
import React from "react";
import { BsCheckCircleFill } from "react-icons/bs";

const FeatureSection = ({ title, bulletPoints, image, imageRight }) => (
  <Row className="align-items-center my-5">
    {!imageRight && (
      <Col xs={12} lg={6} className="mb-3 mb-md-0">
        <img src={image} alt={title} className="img-fluid" />
      </Col>
    )}
    <Col xs={12} lg={6}>
      <h2 className={"mb-5"}>{title}</h2>
      <ul className="list-unstyled">
        {bulletPoints.map((point, idx) => (
          <li key={idx} className="mb-4">
            <BsCheckCircleFill className="me-2 text-primary" />
            {point}
          </li>
        ))}
      </ul>
    </Col>
    {imageRight && (
      <Col xs={12} lg={6} className="mb-3 mb-md-0">
        <img src={image} alt={title} className="img-fluid" />
      </Col>
    )}
  </Row>
);

export default FeatureSection;
