import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { BsEnvelope, BsLock } from "react-icons/bs";
import { apiRequest } from "../utils/apiRequests";
import { LoadingView, TextWithLineBreaks } from "../utils/components";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { AppRoutes } from "../utils/appRoutes";
import { isEmpty, useAppModal, useToast } from "../utils/functions";
import { AppConstants } from "../utils/appConstants";
import { AppContext } from "../utils/components/AppContext";
import PageMetaTags from "./components/Shared/PageMetaTags";
import { appConfigs } from "../configs";
import AppModal from "../utils/components/AppModal";

let displaySessionToast = true;

function PasswordResetPage({ isEmailEntry = true }) {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { contextState, updateContextState } = useContext(AppContext);
  const location = useLocation();
  const { showAppModal } = useAppModal();

  const [data, setData] = useState({
    email: "",
    password: "",
    password2: "",
  });
  const [searchParams, setSearchParams] = useSearchParams();

  const token = searchParams.get("token");

  useEffect(() => {
    if (token) {
      localStorage.setItem(AppConstants.authTokenKey, token);
      getUsersEmail();
    }
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (endpoint, vals) => {
    setIsLoading(true);
    const { success, response } = await apiRequest.post(endpoint, vals);
    if (success) {
      showAppModal({
        title: "Success",
        hideFooter: isEmailEntry,
        component: (
          <h5>
            <TextWithLineBreaks text={response.message} />
          </h5>
        ),
        truthyText: "Sign In",
        truthyFunction: () => {
          navigate(AppRoutes.SignIn.path);
        },
        falsyText: "Go Home",
        falsyFunction: () => {
          navigate(AppRoutes.Index.path);
        },
      });
      setData({});
    } else {
      showToast({
        message: response.message,
      });
    }
    setIsLoading(false);
  };

  const getUsersEmail = async () => {
    setIsLoading(true);
    const { success, response } = await apiRequest.get(`/user/email`);
    if (success) {
      setData({
        email: response.email,
      });
    } else {
      showToast({
        message: response.message,
      });
    }
    setIsLoading(false);
  };

  const formValid = (isEmailEntry = true) => {
    let requiredFields;
    if (isEmailEntry) {
      requiredFields = ["email"];
    } else {
      requiredFields = ["password", "password2"];
      if (!validatePassword(data.password)) {
        return false;
      }
    }

    let valid = true;
    for (const field of requiredFields) {
      if (isEmpty(data[field])) {
        valid = false;
        break;
      }
    }
    return valid;
  };

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?!.*[\\/<>\s]).{8,}$/;
    return passwordRegex.test(password);
  };

  return (
    <LoadingView
      isLoading={isLoading}
      addAppToasts={true}
      view={
        <Container fluid={true}>
          <PageMetaTags title={`Sign In`} />
          <Row className="justify-content-center align-items-center min-vh-100">
            <Col lg={8} lg={6} xl={4}>
              <Card
                className={
                  "w-f00 border-primary border-1 rounded-start shadow-lg"
                }
              >
                <Card.Header className="text-center">
                  <img
                    src={appConfigs.primaryLogo}
                    alt="Company Logo"
                    style={{ width: 80, height: "auto" }}
                    onClick={() => {
                      navigate(AppRoutes.Index.path);
                    }}
                    className={"pointer my-4"}
                  />
                  <h5 className={"my-3"}>
                    {isEmailEntry ? (
                      <span>
                        Enter your {appConfigs.appName} email address to get
                        started
                      </span>
                    ) : (
                      <span>Enter Your New Password</span>
                    )}
                  </h5>
                </Card.Header>
                <Card.Body>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (isEmailEntry) {
                        handleFormSubmit("/user/password-reset", data);
                      } else {
                        handleFormSubmit("/user/password/change", data);
                      }
                    }}
                  >
                    <Form.Group className={"mb-4"}>
                      <Form.Label>Email address</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <BsEnvelope />
                        </InputGroup.Text>
                        <Form.Control
                          type="email"
                          name="email"
                          autoComplete={"email"}
                          placeholder="Enter your email"
                          value={data.email || ""}
                          onChange={handleInputChange}
                          disabled={!isEmailEntry}
                        />
                      </InputGroup>
                    </Form.Group>

                    {!isEmailEntry && (
                      <>
                        <Form.Group className={"mb-5"}>
                          <Form.Label>Password</Form.Label>
                          <InputGroup>
                            <InputGroup.Text>
                              <BsLock />
                            </InputGroup.Text>
                            <Form.Control
                              type="password"
                              name="password"
                              placeholder="Password"
                              value={data.password || ""}
                              onChange={handleInputChange}
                              autoComplete={"new-password"}
                              isInvalid={
                                data.password &&
                                !validatePassword(data.password)
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              <ul>
                                {AppConstants.passwordHints.map((hint) => (
                                  <li key={hint}>{hint}</li>
                                ))}
                              </ul>
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>

                        <Form.Group className={"mb-5"}>
                          <Form.Label>Repeat Password</Form.Label>
                          <InputGroup>
                            <InputGroup.Text>
                              <BsLock />
                            </InputGroup.Text>
                            <Form.Control
                              type="password"
                              name="password2"
                              placeholder="Repeat Password"
                              value={data.password2 || ""}
                              onChange={handleInputChange}
                              autoComplete={"new-password"}
                              isInvalid={data.password2 !== data.password}
                            />
                            <Form.Control.Feedback type="invalid">
                              Passwords do not match.
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      </>
                    )}

                    <Button
                      variant="primary"
                      type="submit"
                      className="w-100"
                      disabled={!formValid()}
                    >
                      Submit
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <AppModal />
        </Container>
      }
    />
  );
}

export default PasswordResetPage;
