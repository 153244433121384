import React, { useState } from "react";
import { Form, Button, ListGroup, Badge } from "react-bootstrap";
import {
  extractItemsFromObject,
  isEmpty,
  updateObjectState,
} from "../../utils/functions";

export function PermissionGroupsForm({ onSubmit, state, setState }) {
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(state);
  };

  const formValid = () => {
    const requiredFields = ["name", "description"];
    let valid = true;
    for (const field of requiredFields) {
      if (isEmpty(state[field])) {
        valid = false;
        break;
      }
    }
    return valid;
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="permissionGroupName" className={"mb-4"}>
        <Form.Label>Name *</Form.Label>
        <Form.Control
          type="text"
          name="name"
          required
          disabled={state.is_default}
          readOnly={state.is_default}
          value={state.name || ""}
          onChange={handleInputChange}
        />
        {state.is_default && (
          <Form.Label className={"fs-6 mt-2 text-danger"}>
            You cannot change the name of a default permission group
          </Form.Label>
        )}
      </Form.Group>

      <Form.Group controlId="permissionGroupDescription" className={"mb-4"}>
        <Form.Label>Description *</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          name="description"
          required
          value={state.description || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Button
        variant="primary"
        type="submit"
        disabled={!formValid()}
        className={"w-100"}
      >
        Save
      </Button>
    </Form>
  );
}

export function PermissionsForm({
  permissions,
  setState,
  onSubmit,
  existingPermissions,
}) {
  const defaultPermissions = new Set(
    extractItemsFromObject(
      existingPermissions,
      "root_id",
      (obj) => obj.is_default
    )
  );
  const [selectedItems, setSelectedItems] = useState(
    new Set(extractItemsFromObject(existingPermissions, "root_id"))
  );

  const [onlyUnSelected, setOnlyUnSelected] = useState(false);
  const [onlySelected, setOnlySelected] = useState(false);
  const [filterTerm, setFilterTerm] = useState("");

  const handleItemClick = (id) => {
    if (defaultPermissions.has(id)) return;
    const newSelectedItems = new Set(selectedItems);
    if (newSelectedItems.has(id)) {
      newSelectedItems.delete(id);
    } else {
      newSelectedItems.add(id);
    }
    setSelectedItems(newSelectedItems);
    updateObjectState(setState, {
      permissions: Array.from(newSelectedItems),
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Switch
        id={`selected-items`}
        label={<Form.Label>Only Show Selected Permissions</Form.Label>}
        checked={onlySelected}
        onChange={() => {
          setOnlyUnSelected(false);
          setOnlySelected(!onlySelected);
        }}
        className={"mb-3"}
      />
      <Form.Switch
        type="checkbox"
        id={`unselected-items`}
        label={<Form.Label>Only Show Un-Selected Permissions</Form.Label>}
        checked={onlyUnSelected}
        onChange={() => {
          setOnlySelected(false);
          setOnlyUnSelected(!onlyUnSelected);
        }}
        className={"mb-3"}
      />

      <Form.Control
        type="text"
        value={filterTerm}
        onChange={(event) => {
          setFilterTerm(event.target.value);
        }}
        placeholder={"Search Permissions"}
        className={"mb-4"}
      />
      <ListGroup className="mb-3">
        {permissions.map((permission) => {
          if (onlySelected && !selectedItems.has(permission.id)) {
            return <div key={`x-${permission.id}`} />;
          }
          if (onlyUnSelected && selectedItems.has(permission.id)) {
            return <div key={`y-${permission.id}`} />;
          }
          if (
            filterTerm.length > 0 &&
            !permission.name.toLowerCase().includes(filterTerm.toLowerCase())
          ) {
            return <div key={`z-${permission.id}`} />;
          }

          return (
            <ListGroup.Item
              key={permission.id}
              className={`mb-3 rounded-3 border-2 ${
                selectedItems.has(permission.id) ? "border-primary" : ""
              }`}
            >
              <Form.Switch
                id={`permission-${permission.id}`}
                checked={selectedItems.has(permission.id)}
                onChange={() => handleItemClick(permission.id)}
                className={"mb-2"}
              />
              <div
                htmlFor={`permission-${permission.id}`}
                style={{
                  maxWidth: "100%",
                  overflowWrap: "break-word",
                }}
              >
                <div className="fw-bold fs-5">{permission.name}</div>
                <div>{permission.description}</div>
                {defaultPermissions.has(permission.id) && (
                  <>
                    <Badge className={"mt-2"} bg={"danger"} pill={true}>
                      Default - Cannot be removed
                    </Badge>
                  </>
                )}
              </div>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
      <Button variant="primary" type="submit" className={"w-100"}>
        Save
      </Button>
    </Form>
  );
}
