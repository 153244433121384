import React, { useContext, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { AppContext } from "../../../utils/components/AppContext";
import { formatDateStrings } from "../../../utils/dateTime";

function AttendanceForm({ onSubmit, date, readOnlyDate = true }) {
  const { contextState, updateContextState } = useContext(AppContext);
  const [formState, setFormState] = useState({
    ...formatDateStrings({ ...contextState.formData }),
    clockin_time: contextState.formData.clockin_time || "09:00",
    clockout_time: contextState.formData.clockout_time || "17:00",
  });
  const [requiredFields, setRequiredFields] = useState([
    "clockin_time",
    "clockout_time",
    "date",
  ]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const formValid = () => {
    return requiredFields.every((field) => formState[field]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateContextState({
      formData: { ...formState },
    });
    onSubmit(formState);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Text as={"p"} className={"fw-bold fs-6"}>
        Required fields are marked with an{" "}
        <span className="text-danger">*</span>
      </Form.Text>

      <Form.Group controlId="startDate" className={"mb-4"}>
        <Form.Label>
          Date<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="date"
          name="date"
          value={formState.date || ""}
          onChange={handleInputChange}
          readOnly={readOnlyDate}
          disabled={readOnlyDate}
        />
      </Form.Group>

      <Alert variant={"danger"}>
        <Alert.Heading>Note</Alert.Heading>
        Management will be able to see any and all changes you make here.
      </Alert>
      <Form.Group controlId="branchDescription" className={"mb-4"}>
        <Form.Label>Note / Description</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          name="comment"
          value={formState.comment || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="startDate" className={"mb-4"}>
        <Form.Label>
          Clockin Time<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="time"
          name="clockin_time"
          value={formState.clockin_time || ""}
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group controlId="endDate" className={"mb-4"}>
        <Form.Label>
          Clockout Time<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="time"
          name={"clockout_time"}
          value={formState.clockout_time || ""}
          onChange={handleInputChange}
        />
        <Alert variant={"info"} className={"mt-3"}>
          If the Clockout Time is smaller than the Clockin Time, the Clockout
          Time will be set to the next day. For example, if the clockin date is
          28/11/2023 and the clockin Time is 19:00 and the Clockout Time is
          02:00 the clockout date will be 29/11/2023.
        </Alert>
      </Form.Group>

      <Button
        variant="primary"
        type="submit"
        className={"w-100 mb-5"}
        disabled={!formValid()}
      >
        Save
      </Button>
    </Form>
  );
}

export default AttendanceForm;
