import React, { useContext, useEffect, useState } from "react";
import { LoadingView, TextWithLineBreaks } from "../utils/components";
import DashboardPageContainer from "./components/DashboardPageContainer";
import {
  toTitleCase,
  updateObjectState,
  useAppModal,
  useOffCanvas,
} from "../utils/functions";
import { AppContext } from "../utils/components/AppContext";
import { useNavigate, useParams } from "react-router-dom";
import AppBreadCrumb from "./components/Shared/AppBreadCrumb";
import { AppRoutes } from "../utils/appRoutes";
import PageMetaTags from "./components/Shared/PageMetaTags";
import { Card } from "react-bootstrap";
import { EmployeeLargeCard } from "./components/EmployeeCards";
import EmployeePerformanceForm from "./Forms/Performance/EmployeePerformanceForm";
import { apiRequest } from "../utils/apiRequests";

function FillPerformanceReviewFormPage({
  getRequest,
  postRequest,
  putRequest,
  patchRequest,
}) {
  const { contextState, updateContextState } = useContext(AppContext);
  const { permissions } = contextState;
  const { showOffCanvas } = useOffCanvas();
  const { showAppModal, closeModal } = useAppModal();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [otherReviews, setOtherReviews] = useState([]);
  const [revieweeEmployee, setRevieweeEmployee] = useState({});
  const [otherFormData, setOtherFormData] = useState({});
  const [reviewData, setReviewData] = useState({});
  const [reviewer, setReviewer] = useState({});
  const [form, setForm] = useState({});
  const [period, setPeriod] = useState({});
  const [formTableData, setFormTableData] = useState({});
  const [step, setStep] = useState({});

  const { reviewerId } = useParams();

  useEffect(() => {
    getRequest(
      `/employee/self/performance/review/info/${reviewerId}`,
      setIsLoading,
      (response) => {
        setForm(response.form);
        setPeriod(response.period);
        setStep(response.step);
        setReviewer(response.reviewer);
        setRevieweeEmployee(response.reviewee_employee);
        setReviewData(response.reviewer.form_data);
        setOtherReviews(response.other_reviews);
        setFormTableData(response.form_table_data);
      }
    );
  }, []);

  const uploadCellPDFs = async (urls, files) => {
    for (const [key, file] of Object.entries(files)) {
      setIsLoading(true);
      const url = urls[key];
      await apiRequest.uploadFileToS3(url, file);
    }
    setIsLoading(false);
  };

  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <div className={"mb-5"}>
          <PageMetaTags title={`Performance Review`} />
          <AppBreadCrumb
            items={[
              {
                text: "Home",
                path: AppRoutes.Home.path,
              },
              {
                text: "Performance Reviews",
                path: AppRoutes.PerformanceReviews.path,
              },
              {
                text: "Complete Review",
              },
            ]}
          />

          <h2 className={"text-center my-4"}>
            Currently {reviewer.is_complete ? "Viewing" : "Reviewing"}
          </h2>
          <EmployeeLargeCard employee={revieweeEmployee} />

          <Card className={"mt-4"}>
            <Card.Header className={"py-3"}>
              <Card.Title className={"text-center"}>
                <span className={"text-muted"}>Review Period</span>
                <br />
                <br />
                {period.name}
              </Card.Title>
            </Card.Header>
            <Card.Body className={"py-3"}>
              <TextWithLineBreaks text={period.description} />
            </Card.Body>
          </Card>

          <Card className={"mt-4"}>
            <Card.Header className={"py-3"}>
              <Card.Title className={"text-center"}>
                <span className={"text-muted"}>Review Step</span>
                <br />
                <br />
                {step.name}
              </Card.Title>
            </Card.Header>
            <Card.Body className={"py-3"}>
              <TextWithLineBreaks text={step.description} />
            </Card.Body>
          </Card>
          <Card className={"mt-4"}>
            <Card.Header className={"py-3"}>
              <Card.Title className={"text-center"}>Review Action</Card.Title>
              <Card.Subtitle>
                Please complete the form or review action below
              </Card.Subtitle>
            </Card.Header>
            <Card.Body className={"py-3"}>
              <Card.Title>{form.name}</Card.Title>
              <Card.Subtitle as={"p"} className={"mb-4 mt-2"}>
                {form.description}
              </Card.Subtitle>
              {form.id && (
                <EmployeePerformanceForm
                  rows={form.rows}
                  columns={form.columns}
                  formData={reviewData}
                  setFormData={setReviewData}
                  writeColumns={reviewer.write_columns}
                  readOnly={reviewer.is_complete}
                  reviewer={reviewer}
                  step={step}
                  otherData={otherFormData}
                  otherReviews={otherReviews}
                  setOtherData={setOtherFormData}
                  formTableData={formTableData}
                  putRequest={putRequest}
                  onSubmit={
                    reviewer.is_complete
                      ? null
                      : (formData, pdfCellFiles) => {
                          showAppModal({
                            title: "Submit Review",
                            component: (
                              <p>
                                Are you sure you want to submit this review?
                                <br />
                                You will not be able to make a change after
                                submission.
                              </p>
                            ),
                            truthyFunction: async () => {
                              putRequest(
                                `/employee/self/performance/review/${reviewerId}`,
                                setIsLoading,
                                async (response) => {
                                  if (Object.keys(pdfCellFiles).length > 0) {
                                    await uploadCellPDFs(
                                      response.cell_file_presigned_urls,
                                      pdfCellFiles
                                    );
                                  }
                                  navigate(AppRoutes.PerformanceReviews.path);
                                },
                                formData,
                                true,
                                false
                              );
                            },
                          });
                        }
                  }
                />
              )}
            </Card.Body>
          </Card>
        </div>
      }
    />
  );
}

function FillPerformanceReviewFormPagePage() {
  return (
    <DashboardPageContainer PageComponent={FillPerformanceReviewFormPage} />
  );
}

export default FillPerformanceReviewFormPagePage;
