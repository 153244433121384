import React from "react";
import EmployeeHighlightCard from "../Employee/EmployeeHighlightCard";
import { FaChevronRight, FaPlus } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { Button, Card } from "react-bootstrap";
import { apiRequest } from "../../../utils/apiRequests";
import { AppRoutes } from "../../../utils/appRoutes";
import { TbSwitchHorizontal, TbSwitchVertical } from "react-icons/tb";
import { useAppModal } from "../../../utils/functions";
import { ConditionalView, StatusBadge } from "../../../utils/components";
import ExpandableText from "../Shared/ExpandableText";
import Badge from "react-bootstrap/Badge";
import CopyWithTooltip from "../Shared/CopyWithTooltip";
import GenericForm from "../../Forms/Shared/GenericForm";

function OrganizationListItem({
  organization,
  onChangeStatusClick,
  onTransferClick,
  fullDescription = true,
  noOfDescLines = 2,
  imageWidth = 50,
}) {
  const { showAppModal, closeModal } = useAppModal();
  const otherStatus = organization.is_active ? "Inactive" : "Active";

  return (
    <Card>
      <Card.Header className={"d-flex justify-content-end py-3"}>
        <StatusBadge status={organization.is_active ? "Active" : "Inactive"} />
      </Card.Header>
      <Card.Body>
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className={"d-flex flex-row align-items-center"}>
            <img
              src={organization.logo}
              alt={`${organization.name} Logo`}
              width={imageWidth}
              height={imageWidth}
              className="rounded-circle me-3"
            />
            <div>
              <h5 className={"text-start mb-2"}>
                {organization.name}
                {organization.is_primary && (
                  <Badge bg={"primary"} className={"ms-3"}>
                    Primary
                  </Badge>
                )}
              </h5>
              <div className={"text-start"}>
                <ConditionalView
                  condition={fullDescription}
                  trueView={<small>{organization.description}</small>}
                  falseView={
                    <ExpandableText
                      text={organization.description}
                      lines={noOfDescLines}
                    />
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </Card.Body>
      <Card.Footer>
        <div className={"d-flex justify-content-between py-3"}>
          <div>
            <small>
              Organization ID:
              <br />
              {organization.id} <CopyWithTooltip text={organization.id} />
            </small>
          </div>
          {!organization.is_primary && (
            <div className={"d-flex align-items-center"}>
              {onTransferClick && (
                <div>
                  <Button
                    variant={"outline-primary"}
                    className={"me-3"}
                    onClick={() => {
                      showAppModal({
                        title: `Transfer to ${organization.name}?`,
                        hideFooter: true,
                        component: (
                          <div>
                            <p className={"mb-3"}>
                              Are you sure you want to initiate a transfer for
                              this employee to "{organization.name}"?.
                              <br />
                              The admins of {organization.name} will be notified
                              and they will have to accept the transfer.
                            </p>
                            <GenericForm
                              useGlobalState={false}
                              formConfigs={[
                                {
                                  valueKey: "comments",
                                  labelName: `Reason for Transfer`,
                                },
                              ]}
                              onSubmit={(data) => {
                                onTransferClick(organization, data.comments);
                                closeModal();
                              }}
                            />
                          </div>
                        ),
                      });
                    }}
                  >
                    <TbSwitchHorizontal className={"me-2"} />
                    Transfer to this Organization
                  </Button>
                </div>
              )}

              {onChangeStatusClick && (
                <Button
                  variant={
                    organization.is_active
                      ? "outline-danger"
                      : "outline-success"
                  }
                  onClick={() => {
                    showAppModal({
                      title: `Change to ${otherStatus}`,
                      component: (
                        <p>
                          Are you sure you want to change {organization.name}'s
                          status to {otherStatus} for this employee?
                        </p>
                      ),
                      truthyFunction: async () => {
                        onChangeStatusClick(organization);
                      },
                    });
                  }}
                >
                  <TbSwitchVertical className={"me-2"} />
                  Make {otherStatus}
                </Button>
              )}

              {organization.transfer_pending && (
                <Badge bg={"warning"} className={"py-2 text-dark"}>
                  Employee Transfer Request Pending
                </Badge>
              )}
            </div>
          )}
        </div>
      </Card.Footer>
    </Card>
  );
}

export default OrganizationListItem;
