import React, { useContext, useEffect, useState } from "react";
import { AddItemButton } from "../../../utils/components";
import { LevelsForm } from "../../Forms/Levels";
import { Card, Tab, Tabs } from "react-bootstrap";
import { LevelList } from "../Levels";
import { useOffCanvas } from "../../../utils/functions";
import { AppContext } from "../../../utils/components/AppContext";
import OrganizationChart from "@dabeng/react-orgchart";
import { AppRoutes } from "../../../utils/appRoutes";

const LevelNode = ({ nodeData }) => {
  return (
    <div className={"primary-hover"}>
      <div className="">
        <Card className={"border-3 border-primary"}>
          <Card.Body>
            <Card.Title>{nodeData.name}</Card.Title>
            {/*<Card.Subtitle>Level</Card.Subtitle>*/}
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

function ManageLevels({
  levels,
  permissions,
  putRequest,
  patchRequest,
  setLevels,
  loaderCallback,
}) {
  const { showOffCanvas } = useOffCanvas();
  const { updateContextState } = useContext(AppContext);
  const [levelsTree, setLevelsTree] = useState({});

  useEffect(() => {
    if (levels && levels.length > 0) {
      let root = JSON.parse(JSON.stringify(levels[0]));
      let current = root;

      for (let i = 1; i < levels.length; i++) {
        if (!current.children) {
          current.children = [];
        }
        let newItem = JSON.parse(JSON.stringify(levels[i]));
        current.children.push(newItem);
        current = newItem;
      }

      setLevelsTree(root);
    }
  }, [levels]);

  return (
    <div>
      {permissions.MANAGE_LEVELS && (
        <div className={"mb-4"}>
          <AddItemButton
            title={"New Level"}
            onClick={() => {
              updateContextState({
                formData: {},
              });
              showOffCanvas({
                title: `Add a Level`,
                subtitle: `Add a new level to your organization`,
                component: (
                  <LevelsForm
                    onSubmit={(updatedData) => {
                      putRequest(
                        "/people/levels",
                        loaderCallback,
                        (response) => {
                          setLevels(response.levels);
                        },
                        updatedData
                      );
                    }}
                  />
                ),
              });
            }}
          />
        </div>
      )}

      <Card>
        <Card.Header className={"mb-3"}>
          <Card.Title>Levels</Card.Title>
          <Card.Subtitle>
            <small>
              Organization levels reflect varying responsibility and
              decision-making power. Customize your hierarchy by adding,
              editing, or deactivating levels such as entry, mid, senior, and
              executive.
            </small>
            <br />
            <br />
            Click the arrows on the levels cards to re-arrange their hierarchy.
          </Card.Subtitle>
        </Card.Header>
        <Card.Body>
          <Tabs variant={"underline"}>
            <Tab eventKey="nested" title="Nested View" className={"pt-3"}>
              <LevelList
                levels={levels}
                canManage={permissions.MANAGE_LEVELS}
                onEditClick={(level, index) => {
                  updateContextState({
                    formData: level,
                  });
                  showOffCanvas({
                    title: `Update ${level.name} Level`,
                    subtitle: `Update the level's info`,
                    component: (
                      <LevelsForm
                        onSubmit={(updatedData) => {
                          patchRequest(
                            `/people/levels/${level.id}`,
                            loaderCallback,
                            (response) => {
                              setLevels(response.levels);
                            },
                            updatedData
                          );
                        }}
                      />
                    ),
                  });
                }}
                onAddChildClick={(level) => {
                  updateContextState({
                    formData: { parent_id: level.id },
                  });
                  showOffCanvas({
                    title: `Add a Sub Level`,
                    subtitle: `Add a new sub level to your ${level.name} level`,
                    component: (
                      <LevelsForm
                        onSubmit={(updatedData) => {
                          putRequest(
                            "/people/levels",
                            loaderCallback,
                            (response) => {
                              setLevels(response.levels);
                            },
                            updatedData
                          );
                        }}
                      />
                    ),
                  });
                }}
                onMoveClick={(level, newPosition) => {
                  patchRequest(
                    `/people/levels/${level.id}/move`,
                    null,
                    (response) => {
                      setLevels(response.levels);
                    },
                    { new_position: newPosition, level }
                  );
                }}
              />
            </Tab>

            <Tab eventKey="tree" title="Tree View">
              <OrganizationChart
                datasource={levelsTree}
                chartClass={"bg-transparent"}
                containerClass={"bg-transparent border-0 vh-100"}
                NodeTemplate={LevelNode}
                draggable={false}
                pan={true}
                zoom={false}
                zoominLimit={4}
                collapsible={true}
              />
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
    </div>
  );
}

export default ManageLevels;
