import React, { useContext, useEffect } from "react";
import { AppContext } from "../../../utils/components/AppContext";
import { useAppModal } from "../../../utils/functions";
import PaginatedEmployeesDisplay from "./PaginatedEmployeesDisplay";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../utils/appRoutes";

function EmployeesTableDisplayModal({
  children,
  modalTitle,
  endpoint,
  getRequest,
}) {
  const { contextState } = useContext(AppContext);
  const { permissions } = contextState;
  const { showAppModal } = useAppModal();
  const navigate = useNavigate();

  const displayModal = () => {
    showAppModal({
      title: modalTitle,
      component: (
        <div>
          <PaginatedEmployeesDisplay
            endpoint={endpoint}
            getRequest={getRequest}
            onItemClick={
              permissions.VIEW_EMPLOYEE_DATA || permissions.MANAGE_EMPLOYEE_DATA
                ? (employee) => {
                    window.open(
                      `${AppRoutes.Employee.paramLessPath}${employee.id}`,
                      "_blank"
                    );
                  }
                : null
            }
          />
        </div>
      ),
      size: "xl",
      hideFooter: true,
    });
  };
  return (
    <div className={"pointer"} onClick={displayModal}>
      {children}
    </div>
  );
}

export default EmployeesTableDisplayModal;
