import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ModalContext, useModal } from "./ModalContext";
import { useOffCanvas } from "./OffCanvasContext";
import { Col, Row } from "react-bootstrap";

function AppModal() {
  const { show, content, closeModal } = useModal();

  return (
    <Modal
      show={show}
      onHide={closeModal}
      centered
      size={content.size}
      scrollable={true}
      fullscreen={content.fullScreen}
    >
      <Modal.Header closeButton>
        <Modal.Title>{content.title}</Modal.Title>
      </Modal.Header>
      {content.component && <Modal.Body>{content.component}</Modal.Body>}
      {!content.hideFooter && (
        <Modal.Footer className="d-flex justify-content-between">
          <Row className={"w-100"}>
            <Col xs={6}>
              <Button
                variant="success"
                className={"w-100"}
                onClick={() => {
                  content.truthyFunction();
                  closeModal();
                }}
              >
                {content.truthyText || "Yes"}
              </Button>
            </Col>
            <Col xs={6}>
              <Button
                variant="danger"
                className={"w-100"}
                onClick={() => {
                  if (content.falsyFunction) {
                    content.falsyFunction();
                  }
                  closeModal();
                }}
              >
                {content.falsyText || "No"}
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      )}
    </Modal>
  );
}

export default AppModal;
