import React from "react";
import { Helmet } from "react-helmet";
import { appConfigs } from "../../../configs";

function PageMetaTags({ title, description, image, url }) {
  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta
          property="og:title"
          content={`${appConfigs.appName} | ${title}`}
        />
        {description && (
          <meta property="og:description" content={description} />
        )}
        {image && <meta property="og:image" content={image} />}
        {url && <meta property="og:url" content={url} />}
        {/* Other Open Graph tags as needed */}
      </Helmet>
    </div>
  );
}

export default PageMetaTags;
