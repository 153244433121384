import React, { useEffect, useState } from "react";
import { industries } from "../../data/industries";
import { updateObjectState } from "../../utils/functions";
import GenericForm from "./Shared/GenericForm";
import { FormFieldTypes } from "../FormFields";

const availableIndustries = industries;

function OrganizationForm({ onSubmit, state, setState, isEdit = false }) {
  const [industryOptions, setIndustryOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);

  useEffect(() => {
    setIndustryOptions(Object.keys(availableIndustries));
    setCategoryOptions(availableIndustries[state.industry || "Agriculture"]);
  }, []);

  useEffect(() => {
    let industryCategory = null;
    if (!state.industry) {
      setCategoryOptions([]);
    } else if (
      !availableIndustries[state.industry].includes(state.industry_category)
    ) {
      industryCategory = null;
      setCategoryOptions(availableIndustries[state.industry]);
    } else {
      industryCategory = state.industry_category;
    }
    updateObjectState(setState, { industry_category: industryCategory });
  }, [state.industry]);

  return (
    <div>
      <GenericForm
        onSubmit={onSubmit}
        stateData={state}
        setStateData={setState}
        useGlobalState={false}
        formConfigs={[
          { valueKey: "name", labelName: "Organization Name" },
          {
            valueKey: "description",
            labelName: "Description",
            type: FormFieldTypes.TEXT_AREA,
            rows: 3,
          },
          {
            valueKey: "logo",
            labelName: "Logo",
            type: FormFieldTypes.IMAGE,
            info: "JPEG/PNG, max 2MB",
          },
          {
            valueKey: "industry",
            labelName: "Industry",
            type: FormFieldTypes.STRING_SELECT,
            optionsList: industryOptions,
          },
          {
            valueKey: "industry_category",
            labelName: "Industry Category",
            type: FormFieldTypes.STRING_SELECT,
            optionsList: categoryOptions,
          },
          {
            valueKey: "date_founded",
            labelName: "Date Founded / Started",
            type: FormFieldTypes.DATE,
          },
        ]}
      />
    </div>
  );
}

export default OrganizationForm;
