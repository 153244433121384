import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useAppModal } from "../../../utils/functions";
import { FaImage } from "react-icons/fa";
import { Image } from "react-bootstrap";
import "react-medium-image-zoom/dist/styles.css";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const ImageViewerComponent = ({
  imageUrl,
  title,
  buttonText,
  buttonVariant = "link",
}) => {
  const { showAppModal } = useAppModal();
  const [fileBlob, setFileBlob] = useState(null);

  useEffect(() => {
    getImageFile();
  }, []);

  const getImageFile = async () => {
    if (imageUrl) {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      setFileBlob(blob);
    }
  };

  const displayImage = () => {
    showAppModal({
      title: title || "Image Viewer",
      size: "lg",
      fullScreen: false,
      component: (
        <div className={"d-flex justify-content-center py-4 w-100"}>
          <Zoom>
            <img
              src={URL.createObjectURL(fileBlob)}
              alt={title}
              style={{
                objectFit: "contain",
              }}
            />
          </Zoom>
        </div>
      ),
      hideFooter: true,
    });
  };

  return (
    <div>
      {fileBlob ? (
        <Button variant={buttonVariant} onClick={displayImage}>
          <FaImage className="me-1 fs-4" />
          {buttonText || "View Image"}
        </Button>
      ) : (
        <p>None</p>
      )}
    </div>
  );
};

export default ImageViewerComponent;
