import React, { useContext, useState } from "react";
import { AppContext } from "../../../utils/components/AppContext";
import { formatDateStrings } from "../../../utils/dateTime";
import { Button, Card, Form } from "react-bootstrap";
import {
  extractItemsFromObject,
  updateObjectState,
} from "../../../utils/functions";
import OrganizationListItem from "../../components/Organization/OrganizationListItem";
import { ConditionalView } from "../../../utils/components";

function SelectOrganizationsForm({ organizations, onSubmit }) {
  const { contextState, updateContextState } = useContext(AppContext);
  const [formState, setFormState] = useState(
    formatDateStrings({ ...contextState.formData })
  );
  const [selectedItems, setSelectedItems] = useState(new Set());
  const [filterTerm, setFilterTerm] = useState("");

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleItemClick = (id) => {
    const newSelectedItems = new Set(selectedItems);
    if (newSelectedItems.has(id)) {
      newSelectedItems.delete(id);
    } else {
      newSelectedItems.add(id);
    }
    setSelectedItems(newSelectedItems);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(selectedItems);
  };

  const selectAll = () => {
    if (selectedItems.size === organizations.length) {
      setSelectedItems(new Set());
    } else {
      setSelectedItems(new Set(organizations.map((org) => org.id)));
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Text>
        Select the organizations you want to add the employee to
      </Form.Text>

      <ConditionalView
        condition={organizations && organizations.length > 0}
        trueView={
          <div className={"mt-3"}>
            <Form.Group controlId="organizationSearch" className={"mb-4"}>
              <Form.Control
                type="text"
                placeholder="Search for an organization"
                value={filterTerm}
                onChange={(event) => {
                  setFilterTerm(event.target.value);
                }}
              />
            </Form.Group>

            <Card body={true} className={"mb-3"}>
              <Form.Switch
                id={`organization-all`}
                checked={selectedItems.size === organizations.length}
                onChange={() => selectAll()}
                className={"pt-2"}
                label={"Select All Organizations"}
              />
            </Card>
            {organizations.map((organization, i) => {
              if (
                !organization.name
                  .toLowerCase()
                  .includes(filterTerm.toLowerCase())
              ) {
                return <div key={`x-${organization.id}`} />;
              }
              return (
                <Card className={"mb-4"} key={i}>
                  <Card.Header>
                    <Form.Switch
                      id={`organization-${organization.id}`}
                      checked={selectedItems.has(organization.id)}
                      onChange={() => handleItemClick(organization.id)}
                      className={"pt-2"}
                      label={"Select"}
                    />
                  </Card.Header>
                  <OrganizationListItem
                    organization={organization}
                    fullDescription={false}
                  />
                </Card>
              );
            })}
          </div>
        }
        falseView={
          <Card body={true} className="text-center mt-4">
            <small>
              There are no organizations to select from for this employee
            </small>
          </Card>
        }
      />

      <Button
        variant="primary"
        type="submit"
        className={"w-100 my-5"}
        disabled={selectedItems.size === 0}
      >
        Save
      </Button>
    </Form>
  );
}

export default SelectOrganizationsForm;
