import React, { useContext, useEffect, useRef, useState } from "react";
import { LoadingView } from "../utils/components";
import { apiRequest } from "../utils/apiRequests";
import DashboardPageContainer from "./components/DashboardPageContainer";
import {
  toTitleCase,
  useAppModal,
  useOffCanvas,
  useToast,
} from "../utils/functions";
import { AppContext } from "../utils/components/AppContext";
import { OffCanvasContext } from "../utils/components/OffCanvasContext";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Container,
  Image,
  Row,
  Table,
} from "react-bootstrap";
import EmployeeHighlightCard from "./components/Employee/EmployeeHighlightCard";
import OrganizationChart from "@dabeng/react-orgchart";
import {
  FaCalendar,
  FaDownload,
  FaEnvelope,
  FaExternalLinkAlt,
  FaPhone,
  FaSearch,
  FaSitemap,
  FaUser,
} from "react-icons/fa";
import { AppRoutes } from "../utils/appRoutes";
import PageMetaTags from "./components/Shared/PageMetaTags";
import { formatDate, formDate } from "../utils/dateTime";
import EmployeeSearch from "./components/EmployeeSearch";
import { EmpPermissions } from "../enums/EmpPermissions";

const EmployeeInfo = ({ employee, onViewClick, isCurrent = false }) => {
  const lefStyle = {
    width: "10px",
  };

  const { hasEmpPermission } = useContext(AppContext);

  return (
    <Card
      className={
        !isCurrent
          ? `border-0 border-top border-primary border-4 glow-animation`
          : "border-4 border-primary rounded-3"
      }
      id={employee.id}
    >
      <Card.Header className="d-flex flex-column align-items-center py-4">
        <Image
          src={employee.profile_image}
          roundedCircle
          className="mb-3"
          fluid
          height={65}
          width={65}
        />
        <h6 className={"text-center"}>
          {/*(#{employee.employee_number})?*/}
          {employee.full_name}
        </h6>
        <h6 className={"text-muted text-center mt-2"}>
          {employee.position_title}
        </h6>
      </Card.Header>
      <Card.Body>
        <Table borderless={true}>
          <tbody>
            {employee.work_phone && (
              <tr>
                <td style={lefStyle}>
                  <small>
                    <FaPhone className="text-primary" />
                  </small>
                </td>
                <td className={"text-break"}>
                  <small>{employee.work_phone}</small>
                </td>
              </tr>
            )}
            {employee.work_email && (
              <tr>
                <td style={lefStyle}>
                  <small>
                    <FaEnvelope className="text-primary" />
                  </small>
                </td>
                <td className={"text-break"}>
                  <small>{employee.work_email}</small>
                </td>
              </tr>
            )}
            {employee.position && (
              <tr>
                <td style={lefStyle}>
                  <small>
                    <FaUser className="text-primary" />
                  </small>
                </td>
                <td className={"text-break"}>
                  <small>{employee.position.title}</small>
                </td>
              </tr>
            )}
            {employee.start_date && (
              <tr>
                <td style={lefStyle}>
                  <small>
                    <FaCalendar className="text-primary" />
                  </small>
                </td>
                <td className={"text-break"}>
                  <small>{formatDate(employee.start_date)}</small>
                </td>
              </tr>
            )}
            {hasEmpPermission(EmpPermissions.VIEW_EMPLOYEE_DATA) && (
              <tr>
                <td style={lefStyle}></td>
                <td className={"text-break"}>
                  <Button
                    variant={"link"}
                    onClick={() => {
                      window.open(
                        `${AppRoutes.Employee.paramLessPath}${employee.id}`,
                        "_blank"
                      );
                    }}
                  >
                    View Profile <FaExternalLinkAlt className={"ms-2"} />
                  </Button>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card.Body>

      <Card.Footer className={"d-flex justify-content-center py-3"}>
        <FaSitemap
          className={`${
            isCurrent ? "text-primary" : "pointer text-muted hover-primary"
          }`}
          size={30}
          onClick={() => {
            if (isCurrent) return;
            onViewClick(employee.id);
          }}
        />
      </Card.Footer>
    </Card>
  );
};

export function Organogram({ getRequest }) {
  const navigate = useNavigate();
  const { showAppModal } = useAppModal();
  const { showOffCanvas } = useOffCanvas();
  const { contextState, updateContextState } = useContext(AppContext);
  const { closeOffCanvas, restoreOffCanvas } = useContext(OffCanvasContext);

  const { permissions } = contextState;

  useEffect(() => {
    getOrganizationChartInfo();
  }, []);

  const [activeData, setActiveData] = useState({});
  const [collectedData, setCollectedData] = useState({});
  const [currentEmployee, setCurrentEmployee] = useState(null);
  const [config, setConfig] = useState({});
  const [chartData, setChartData] = useState(null);
  const [secondaryLoading, setSecondaryLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpaque, setIsOpaque] = useState(false);

  const updateInfo = (response) => {
    const emp_info = response.employee_info;
    setCurrentEmployee(emp_info.employee);
    setActiveData(emp_info);
    setCollectedData({
      ...collectedData,
      [emp_info.employee.id]: emp_info,
    });
  };
  const getOrganizationChartInfo = async () => {
    getRequest(`/organization/org-chart`, setIsLoading, (response) => {
      updateInfo(response);
    });
  };

  const setOrFetch = (employeeId) => {
    if (collectedData[employeeId]) {
      setSecondaryLoading(true);
      setTimeout(() => {
        setActiveData(collectedData[employeeId]);
        setCurrentEmployee(collectedData[employeeId].employee);
        setSecondaryLoading(false);
      }, 1000);
    } else {
      getRequest(
        `/organization/org-chart/${employeeId}`,
        setSecondaryLoading,
        (response) => {
          updateInfo(response);
        }
      );
    }
  };

  useEffect(() => {
    let interval;
    if (secondaryLoading) {
      interval = setInterval(() => {
        setIsOpaque((prev) => !prev);
      }, 750);
    } else {
      setIsOpaque(true); // Ensure full opacity when not loading
    }

    // Clean up the interval when isLoading is false or when component unmounts
    return () => clearInterval(interval);
  }, [secondaryLoading]);

  useEffect(() => {
    // scroll to current employee card by employee.id
    if (currentEmployee) {
      const element = document.getElementById(currentEmployee.id);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    }
  }, [currentEmployee]);

  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <div className={`mb-5 ${isOpaque ? "opacity-100" : "opacity-50"}`}>
          <PageMetaTags title={`Organization Chart`} />
          <div className={"position-absolute end-0 pe-5 z-3"}>
            <Button
              variant={"outline-primary"}
              onClick={() => {
                showOffCanvas({
                  title: "Search Employee",
                  subtitle:
                    "Search for an employee to view their organizational chart",
                  component: (
                    <div>
                      <EmployeeSearch
                        onResultClick={(employee) => {
                          setOrFetch(employee.id);
                          closeOffCanvas();
                        }}
                      />
                    </div>
                  ),
                });
              }}
            >
              <FaSearch className={"me-2"} />
              Search
            </Button>
          </div>
          <div className={"mb-5"} />
          {activeData?.supervisory_chain?.map((supervisor, i) => {
            return (
              <Col xs={10} lg={3} key={supervisor.id} className="mx-auto mb-4">
                <EmployeeInfo employee={supervisor} onViewClick={setOrFetch} />
              </Col>
            );
          })}

          <Card
            body={true}
            className={
              "border-0 border-top border-1 border-secondary mt-3 bg-transparent"
            }
          >
            <h4 className={"mt-4 ms-4 text-primary"}>
              {currentEmployee?.first_name || "Employee"} and Peers
            </h4>
            <Row className="justify-content-center mt-5">
              {activeData?.peers?.map((emp, i) => {
                return (
                  <Col xs={11} lg={3} key={emp.id} className="mb-4">
                    <EmployeeInfo
                      employee={emp}
                      isCurrent={emp.id === currentEmployee.id}
                      onViewClick={setOrFetch}
                    />
                  </Col>
                );
              })}
            </Row>
          </Card>

          <Card
            body={true}
            className={
              "border-0 border-top border-1 border-secondary mt-3 bg-transparent"
            }
          >
            <h4 className={"mt-4 ms-4 text-primary"}>
              {currentEmployee?.first_name || "Employee"}'s Direct Reports
            </h4>
            <Row className="justify-content-center mt-5">
              {activeData?.reports?.map((emp, i) => {
                return (
                  <Col xs={11} lg={3} key={emp.id} className="mb-4">
                    <EmployeeInfo
                      employee={emp}
                      isCurrent={emp.id === currentEmployee.id}
                      onViewClick={setOrFetch}
                    />
                  </Col>
                );
              })}
            </Row>
          </Card>
        </div>
      }
    />
  );
}

function OrganogramPage() {
  return <DashboardPageContainer PageComponent={Organogram} />;
}
export default OrganogramPage;
