import React, { useEffect, useState } from "react";
import { LoadingView } from "../utils/components";
import { apiRequest } from "../utils/apiRequests";
import { CSSTransition } from "react-transition-group";
import { Card, Col, Container, ProgressBar, Row } from "react-bootstrap";
import OrganizationForm from "./Forms/Organization";
import BranchForm from "./Forms/Branch";
import EmployeeForm from "./Forms/Employee";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../utils/appRoutes";
import { updateObjectState, useToast } from "../utils/functions";
import { AppConstants as appConstants } from "../utils/appConstants";
import PageMetaTags from "./components/Shared/PageMetaTags";
import { appConfigs } from "../configs";
import AppToasts from "../utils/components/AppToasts";
import AppOffCanvas from "../utils/components/AppOffCanvas";
import AppModal from "../utils/components/AppModal";

const QUOTES = [
  `"The most valuable asset of any company is its employees. Let us help you manage them effectively."`,
  `"A successful company is one where employees feel valued, respected, and motivated. Let us help you create that environment."`,
  `"The success of any organization lies in its people. Invest in them and they will invest in you."`,
];

function Registration() {
  useEffect(() => {
    getSetupInfo();
  }, []);

  const navigate = useNavigate();
  const { showToast } = useToast();

  const [isLoading, setIsLoading] = useState();

  const getSetupInfo = async () => {
    setIsLoading(true);
    const { success, response } = await apiRequest.get(
      "/user/registration/status"
    );
    if (success) {
      setConfigs(response.configs);
      if (response.completed) {
        navigate(AppRoutes.Home.path);
      }
      const organization = response.organization;
      setCurrentSectionIndex(response.section_index);
      setOrganization(response.organization || {});
      setInformation(response.employee || {});
      if (organization) {
        updateObjectState(setHeadquarters, {
          industry: organization.industry,
          industry_category: organization.industry_category,
        });
      }
    } else {
      showToast({
        message: response.message,
      });
    }
    setIsLoading(false);
  };

  const sections = [
    {
      title: "Organization Info",
      component: OrganizationForm,
      type: "organization",
    },
    {
      title: "Organization Headquarters",
      component: BranchForm,
      type: "headquarters",
    },
    {
      title: "Your Employee Information",
      component: EmployeeForm,
      type: "information",
    },
  ];

  const [configs, setConfigs] = useState({});
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [organization, setOrganization] = useState({});
  const [headquarters, setHeadquarters] = useState({ is_headquarters: true });
  const [information, setInformation] = useState({});

  const handleSubmit = (section, data) => {
    const formData = new FormData();
    let endpoint = `/organization`;
    let image;
    switch (section) {
      case "organization":
        image = data.logo;
        delete data.logo;
        delete data.logo_img;
        formData.append("image", image);
        setOrganization(data);
        break;
      case "headquarters":
        endpoint = `/organization/branches`;
        setHeadquarters(data);
        break;
      case "information":
        endpoint = `/employee`;
        image = data.profile_image;
        delete data.profile_image;
        formData.append("image", image);
        setInformation(data);
        break;
      default:
        break;
    }
    formData.append("inputs", JSON.stringify(data));
    submitForm(endpoint, formData);
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  };

  const submitForm = async (endpoint, data) => {
    setIsLoading(true);
    const { success, response } = await apiRequest.put(endpoint, data, true);
    if (success) {
      if (response.completed) {
        showToast({
          title: "Registration Complete!",
          message:
            "You have successfully registered your organization. You can now start enjoying the powerful tools at your disposal.",
          success: true,
        });
        navigate(AppRoutes.Home.path);
      }
      if (currentSectionIndex === 0) {
        updateObjectState(setOrganization, {
          id: response.organization.id,
        });
      }
      setCurrentSectionIndex(response.section_index);
    } else {
      showToast({
        message: response.message,
      });
    }
    setIsLoading(false);
  };

  return (
    <LoadingView
      isLoading={isLoading}
      addAppToasts={true}
      view={
        <Container fluid={true}>
          <PageMetaTags title={`Registration`} />
          <Row className="justify-content-center align-items-center min-vh-100">
            <Col lg={8} lg={6}>
              <Card className={"w-100 border-1 border-primary my-5"}>
                <Card.Header className={"d-flex justify-content-start py-5"}>
                  <img
                    alt="Ventix Logo"
                    src={appConfigs.primaryLogo}
                    width="50"
                    height="auto"
                    className="d-inline-block align-top"
                  />
                  <p className={"ms-2 fw-bold fs-1 my-auto"}>
                    {appConfigs.appName}
                  </p>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col className={"px-3 py-4"}>
                      <h2 className={"text-center mb-5 text-secondary"}>
                        Register Your Organization
                      </h2>
                      {/*<h5 className={"text-center"}>*/}
                      {/*  STEP {currentSectionIndex + 1} OF 3*/}
                      {/*</h5>*/}
                      <ProgressBar
                        now={currentSectionIndex + 1}
                        label={`STEP ${currentSectionIndex + 1} OF 3`}
                        min={0}
                        max={3}
                        variant={"primary"}
                        animated={true}
                      />
                      <Row className={"mt-4"}>
                        <Col>
                          {sections.map((section, index) => (
                            <CSSTransition
                              key={section.title}
                              in={index === currentSectionIndex}
                              timeout={500}
                              classNames="fade"
                              unmountOnExit
                            >
                              <div>
                                <h5 className={"text-center mb-4"}>
                                  {section.title}
                                </h5>
                                <section.component
                                  onSubmit={(data) =>
                                    handleSubmit(section.type, data)
                                  }
                                  configs={configs}
                                  state={
                                    index === 0
                                      ? organization
                                      : index === 1
                                      ? headquarters
                                      : information
                                  }
                                  setState={
                                    index === 0
                                      ? setOrganization
                                      : index === 1
                                      ? setHeadquarters
                                      : setInformation
                                  }
                                />
                              </div>
                            </CSSTransition>
                          ))}
                        </Col>
                      </Row>
                    </Col>
                    {/*<Col*/}
                    {/*  lg={6}*/}
                    {/*  className={*/}
                    {/*    "bg-primary g-0 d-flex align-items-center justify-content-center px-3"*/}
                    {/*  }*/}
                    {/*>*/}
                    {/*  <h2 className={"text-white text-center"}>*/}
                    {/*    <i>{QUOTES[currentSectionIndex]}</i>*/}
                    {/*  </h2>*/}
                    {/*</Col>*/}
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <AppModal />
          <AppToasts />
        </Container>
      }
    />
  );
}

export default Registration;
