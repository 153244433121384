import React from "react";
import { Table, Badge, Button, Col, Row, Card } from "react-bootstrap";
import { InfoDisplayTable } from "../../../utils/components";

function DeductionsCards({ data, canManage, onEdit }) {
  return (
    <Row>
      {data.map((item, index) => {
        if (!item) return null;
        return (
          <Col lg={4} key={index} className={"mb-4"}>
            <Card body={true} className={"shadow rounded-3"}>
              <InfoDisplayTable
                canEdit={canManage}
                title={item.name}
                onEditClick={() => {
                  onEdit(item, index);
                }}
                data={{
                  Description: item.description,
                  Status: item.is_active ? (
                    <Badge bg="success">Active</Badge>
                  ) : (
                    <Badge bg="info">Inactive</Badge>
                  ),
                  Type: item.type,
                  "Amount Type": item.amount_type,
                  Currency: item.currency,
                  "Employees Pay": item.amount || "0",
                  "Employees Pay (%)": item.percentage_decimal || "0",
                  "Organization Pays": item.organization_pay_amount || "0",
                  "Organization Pays (%)":
                    item.organization_pay_percentage_decimal || "0",
                }}
              />
            </Card>
          </Col>
        );
      })}
    </Row>
  );
}

export default DeductionsCards;
