import React, { useContext, useEffect, useState } from "react";
import {
  AddItemButton,
  ConditionalView,
  LoadingView,
} from "../../../utils/components";
import EmployeeForm from "../../Forms/Employee";
import {
  Alert,
  Button,
  Card,
  Form,
  InputGroup,
  Tab,
  Tabs,
} from "react-bootstrap";
import EmployeeTable from "../EmployeeTable";
import { AppRoutes } from "../../../utils/appRoutes";
import {
  updateStateAtIndex,
  useAppModal,
  useOffCanvas,
} from "../../../utils/functions";
import { AppContext } from "../../../utils/components/AppContext";
import { OffCanvasContext } from "../../../utils/components/OffCanvasContext";
import { useNavigate } from "react-router-dom";
import PaginationComponent from "../../../utils/components/PaginationComponent";
import { apiRequest } from "../../../utils/apiRequests";
import {
  FaCheck,
  FaChevronRight,
  FaExclamation,
  FaExternalLinkAlt,
  FaSearch,
  FaTimes,
  FaUserPlus,
  FaUsersCog,
} from "react-icons/fa";
import employee from "../../Forms/Employee";
import PaginatedEmployeesDisplay from "../Employee/PaginatedEmployeesDisplay";
import { formatDate } from "../../../utils/dateTime";
import GenericForm from "../../Forms/Shared/GenericForm";
import { EmployeePopover, EmployeePopOverButtonLink } from "../EmployeeCards";

function ManagePeople({
  employees,
  setEmployees,
  putRequest,
  getRequest,
  config,
  permissions,
  patchRequest,
}) {
  const INACTIVE_EMPLOYEES_TABS = [
    {
      key: "resigned",
      title: "Resigned",
      subtitle: "Employees who have resigned from your organization",
    },
    {
      key: "retired",
      title: "Retired",
      subtitle: "Employees who have retired from your organization",
    },
    {
      key: "terminated",
      title: "Terminated",
      subtitle: "Employees who have been terminated from your organization",
    },
  ];

  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState(undefined);

  const { showAppModal, closeModal } = useAppModal();
  const { showOffCanvas } = useOffCanvas();
  const { contextState, updateContextState } = useContext(AppContext);
  const { labelsOverride, organization } = contextState;

  const navigate = useNavigate();

  const [searchEmployees, setSearchEmployees] = useState([]);

  const [activeTab, setActiveTab] = useState("employees");
  const [fetchedData, setFetchedData] = useState({
    other: false,
    transferred: false,
    retired: false,
    resigned: false,
    terminated: false,
  });
  const [showTransfers, setShowTransfers] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getSearchResults = async () => {
      if (searchTerm && searchTerm.length >= 1) {
        setIsSearching(true);
        getRequest(
          `/employee/search?search-term=${searchTerm}`,
          null,
          (response) => {
            setSearchEmployees(response.employees);
            setIsSearching(false);
          }
        );
      } else if (searchTerm === "") {
        setSearchEmployees([]);
        setIsSearching(false);
      }
    };

    const timeoutId = setTimeout(getSearchResults, 500);
    return () => clearTimeout(timeoutId);
  }, [searchTerm]);

  const submitTransferResponse = (isApprove, employee) => {
    const action = isApprove ? "Approve" : "Reject";

    showAppModal({
      title: `${action} Transfer Request for ${employee.full_name}?`,
      hideFooter: true,
      component: (
        <div>
          <p className={"mb-2 fw-bold"}>
            Are you sure you want to {action} this transfer request?
            <br />
            {isApprove
              ? "This will add them to your organization as a primary employee."
              : "The employee will not be transferred to your organization."}
          </p>
          <div className={"mt-4"}>
            <GenericForm
              useGlobalState={false}
              formConfigs={[
                {
                  valueKey: "comments",
                  labelName: `${action} Reason`,
                  isRequired: !isApprove,
                },
              ]}
              onSubmit={(formData) => {
                setShowTransfers(false);
                closeModal();
                patchRequest(
                  `/employee/organization/transfer/action/${employee.transfer_request_id}`,
                  null,
                  (response) => {
                    if (isApprove) {
                      showAppModal({
                        title: `Successfully accepted ${employee.full_name} into your organization`,
                        truthyText: (
                          <span>
                            View Profile
                            <FaExternalLinkAlt className={"ms-2"} />
                          </span>
                        ),
                        truthyFunction: () => {
                          window.open(
                            `${AppRoutes.Employee.paramLessPath}${employee.id}`,
                            "_blank"
                          );
                        },
                        falsyText: "Close",
                      });
                    }
                  },
                  {
                    comments: formData.comments,
                    is_approved: isApprove,
                  },
                  false,
                  false
                );
                setTimeout(() => {
                  setShowTransfers(true);
                }, 1000);
              }}
            />
          </div>
        </div>
      ),
    });
  };

  return (
    <LoadingView
      isLoading={isLoading}
      fullHeight={false}
      view={
        <div className={"mb-5 pb-5"}>
          {permissions.MANAGE_EMPLOYEE_DATA && (
            <div className={"mb-4 d-flex justify-content-end"}>
              <Button
                variant={"primary"}
                size={"sm"}
                className={"me-2"}
                onClick={() => {
                  updateContextState({
                    formData: {
                      allow_login: true,
                      country: "SL",
                      region: "Western Area",
                      city: "Freetown",
                    },
                  });
                  showOffCanvas({
                    title: `Add an Employee`,
                    subtitle: `Add a new employee to your organization`,
                    component: (
                      <EmployeeForm
                        onSubmit={(updateData) => {
                          const formData = new FormData();
                          const image = updateData.profile_image;
                          delete updateData.profile_image;
                          formData.append("image", image);
                          formData.append("inputs", JSON.stringify(updateData));
                          putRequest(
                            "/employee",
                            setIsLoading,
                            (response) => {
                              const employee = response.employee;
                              showAppModal({
                                title: `Successfully added ${employee.full_name}`,
                                truthyText: "View Profile",
                                truthyFunction: () => {
                                  navigate(
                                    `${AppRoutes.Employee.paramLessPath}${employee.id}`
                                  );
                                },
                                falsyText: "Close",
                              });
                            },
                            formData,
                            true
                          );
                        }}
                        showAccessSwitch={true}
                        configs={{
                          ...config,
                        }}
                      />
                    ),
                  });
                }}
              >
                <FaUserPlus className={"me-2"} />
                New Employee
              </Button>
              <Button
                variant={"outline-primary"}
                size={"sm"}
                onClick={() => {
                  navigate(AppRoutes.BulkAddEmployees.path, { state: config });
                }}
              >
                <FaUsersCog className={"me-2"} />
                Bulk Add Employees
                <FaChevronRight className={"ms-2"} />
              </Button>
            </div>
          )}

          <Tabs
            defaultActiveKey={activeTab}
            id="people-tabs"
            onSelect={(k) => {
              if (!fetchedData[k]) {
                setFetchedData({
                  ...fetchedData,
                  [k]: true,
                });
              }
              setActiveTab(k);
            }}
            className={"mb-4"}
          >
            <Tab eventKey="employees" title="Employees">
              <Card>
                <Card.Header className={"py-4"}>
                  <Card.Title>Primary Employees</Card.Title>
                  <Card.Subtitle className={"mt-3"}>
                    These are the employees that are directly under your
                    organization. They can be managed by people with the right
                    permissions.
                  </Card.Subtitle>
                </Card.Header>

                <Card.Body>
                  <InputGroup className={"mb-4"}>
                    <InputGroup.Text>
                      <FaSearch />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      value={searchTerm || ""}
                      onChange={(event) => {
                        setSearchTerm(event.target.value);
                      }}
                      placeholder={`Search employees by Name, E-mail or ${
                        labelsOverride.employee_number || "Employee Number"
                      }`}
                    />
                  </InputGroup>

                  {searchTerm && (
                    <div className={"mb-5"}>
                      <h4 className={"text-center my-5"}>
                        Showing search results for "{searchTerm}"
                      </h4>

                      <Alert variant={"warning"} className={"mb-4"}>
                        <FaExclamation className={"me-2"} />
                        If an employee is not directly under your current
                        organization, nothing will happen when you click on
                        their info.
                      </Alert>
                      <EmployeeTable
                        employees={searchEmployees}
                        onClick={(employee) => {
                          if (
                            organization.id !== employee.primary_organization_id
                          ) {
                            return;
                          }
                          navigate(
                            `${AppRoutes.Employee.paramLessPath}${employee.id}`
                          );
                        }}
                        extraColumns={[
                          {
                            columnName: "Primary Organization",
                            key: "primary_organization_name",
                          },
                        ]}
                      />

                      {isSearching && (
                        <h4 className={"text-center mt-5"}>
                          Searching for employees...
                        </h4>
                      )}
                    </div>
                  )}

                  <PaginatedEmployeesDisplay
                    hide={searchTerm}
                    endpoint={"/people/employees"}
                    getRequest={getRequest}
                    onItemClick={(employee) => {
                      navigate(
                        `${AppRoutes.Employee.paramLessPath}${employee.id}`
                      );
                    }}
                  />
                </Card.Body>
              </Card>
            </Tab>

            {contextState?.organization?.has_other_orgs && (
              <Tab eventKey="other" title="Other Employees">
                <Card>
                  <Card.Header className={"py-4"}>
                    <Card.Title>Other Employees</Card.Title>
                    <Card.Subtitle className={"mt-3"}>
                      These are the employees that are assigned to your
                      organization but are not directly under your organization,
                      e.g. an IT specialist that is assigned to your
                      organization. Their information cam only be managed by
                      admins in their primary organization.
                    </Card.Subtitle>
                  </Card.Header>

                  <Card.Body>
                    {fetchedData.other && (
                      <PaginatedEmployeesDisplay
                        endpoint={"/people/employees/other"}
                        getRequest={getRequest}
                        extraColumns={[
                          {
                            columnName: "Primary Organization",
                            key: "primary_organization_name",
                          },
                        ]}
                      />
                    )}
                  </Card.Body>
                </Card>
              </Tab>
            )}

            {contextState?.organization?.has_other_orgs && (
              <Tab eventKey="transferred" title="Transferred Employees">
                <Card>
                  <Card.Header className={"py-4"}>
                    <Card.Title>Transferred Employees</Card.Title>
                    <Card.Subtitle className={"mt-3"}>
                      These are the employees for whom a transfer request has
                      been made to your organization but have not been accepted
                      or rejected yet.
                    </Card.Subtitle>
                  </Card.Header>

                  <Card.Body>
                    {fetchedData.transferred && showTransfers && (
                      <PaginatedEmployeesDisplay
                        endpoint={"/people/employees/transferred"}
                        getRequest={getRequest}
                        extraColumns={[
                          {
                            columnName: "From Organization",
                            key: "from_organization_name",
                          },
                          {
                            columnName: "Request Date",
                            renderComponent: (employee) => {
                              return (
                                <span>{formatDate(employee.request_date)}</span>
                              );
                            },
                          },
                          {
                            columnName: "Action",
                            renderComponent: (employee) => {
                              if (
                                !permissions.MANAGE_EMPLOYEE_ORGANIZATION_TRANSITION
                              )
                                return null;
                              return (
                                <div className={"justify-content-center"}>
                                  <Button
                                    className={"mb-2"}
                                    size={"sm"}
                                    variant={"success"}
                                    onClick={() => {
                                      submitTransferResponse(true, employee);
                                    }}
                                  >
                                    <FaCheck className={"me-2"} />
                                    Approve
                                  </Button>
                                  <br />
                                  <Button
                                    size={"sm"}
                                    variant={"outline-danger"}
                                    onClick={() => {
                                      submitTransferResponse(false, employee);
                                    }}
                                  >
                                    <FaTimes className={"me-2"} />
                                    Reject
                                  </Button>
                                </div>
                              );
                            },
                          },
                        ]}
                      />
                    )}
                  </Card.Body>
                </Card>
              </Tab>
            )}

            {INACTIVE_EMPLOYEES_TABS.map((tab, i) => {
              return (
                <Tab key={i} eventKey={tab.key} title={tab.title}>
                  <Card>
                    <Card.Header className={"py-4"}>
                      <Card.Title>{tab.title}</Card.Title>
                      <Card.Subtitle className={"mt-3"}>
                        {tab.subtitle}
                      </Card.Subtitle>
                    </Card.Header>

                    <Card.Body>
                      {fetchedData[tab.key] && (
                        <PaginatedEmployeesDisplay
                          endpoint={`/people/employees/inactive/${tab.key}`}
                          getRequest={getRequest}
                          onItemClick={(employee) => {
                            navigate(
                              `${AppRoutes.Employee.paramLessPath}${employee.id}`
                            );
                          }}
                          extraColumns={[
                            {
                              columnName: "Employment End Date",
                              renderComponent: (employee) => {
                                return (
                                  <span>
                                    {formatDate(employee.inactive_date)}
                                  </span>
                                );
                              },
                            },
                            {
                              columnName: "Employment End Reason",
                              key: "reason",
                            },
                            {
                              columnName: "Ended By",
                              renderComponent: (employee) => {
                                return (
                                  <div>
                                    <EmployeePopOverButtonLink
                                      employee={employee.author_employee}
                                      popoverPosition={"left"}
                                    />
                                  </div>
                                );
                              },
                            },
                          ]}
                        />
                      )}
                    </Card.Body>
                  </Card>
                </Tab>
              );
            })}
          </Tabs>
        </div>
      }
    />
  );
}

export default ManagePeople;
