import React, { useContext, useState } from "react";
import { AddItemButton } from "../../../utils/components";
import { TeamsForm } from "../../Forms/Team";
import { TeamHighlight } from "../Team";
import { useAppModal, useOffCanvas } from "../../../utils/functions";
import { AppContext } from "../../../utils/components/AppContext";
import { Form, InputGroup } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";

function ManageTeams({
  teams,
  setTeams,
  putRequest,
  patchRequest,
  deleteRequest,
  loaderCallback,
  permissions,
  setData,
  setShowAppOffCanvas,
  config,
}) {
  const { showOffCanvas } = useOffCanvas();
  const [filterTerm, setFilterTerm] = useState("");
  const { contextState, updateContextState } = useContext(AppContext);
  const { labelsOverride } = contextState;
  const { showAppModal, closeModal } = useAppModal();

  return (
    <div>
      {permissions.MANAGE_TEAMS && (
        <div className={"mb-3"}>
          <AddItemButton
            title={`New ${labelsOverride.team || "Team"} `}
            onClick={() => {
              updateContextState({
                formData: { is_active: true },
              });
              showOffCanvas({
                title: `Create a ${labelsOverride.team || "Team"}`,
                subtitle: `Add a new ${
                  labelsOverride.team || "team"
                } to your organization`,
                component: (
                  <TeamsForm
                    departments={config.departments}
                    onSubmit={(updatedData) => {
                      putRequest(
                        "/people/teams",
                        loaderCallback,
                        (response) => {
                          setTeams([response.team, ...teams]);
                        },
                        updatedData
                      );
                    }}
                  />
                ),
              });
            }}
          />
        </div>
      )}

      <InputGroup className={"mb-4"}>
        <InputGroup.Text>
          <FaSearch />
        </InputGroup.Text>
        <Form.Control
          type="text"
          placeholder={"Search by name or supervisor name"}
          value={filterTerm}
          onChange={(e) => setFilterTerm(e.target.value)}
        />
      </InputGroup>

      {teams.map((team, i) => {
        if (
          filterTerm &&
          !team.name.toLowerCase().includes(filterTerm) &&
          !team.supervisor.full_name.toLowerCase().includes(filterTerm)
        ) {
          return null;
        }
        return (
          <div key={i}>
            <TeamHighlight
              team={team}
              canManage={permissions.MANAGE_TEAMS}
              onEditClick={() => {
                updateContextState({
                  formData: team,
                });
                showOffCanvas({
                  title: `Update ${team.name} ${labelsOverride.team || "Team"}`,
                  subtitle: `Update the ${
                    labelsOverride.team || "Team"
                  }'s information`,
                  component: (
                    <TeamsForm
                      departments={config.departments}
                      onSubmit={(updatedData) => {
                        patchRequest(
                          `/people/teams/${team.id}`,
                          loaderCallback,
                          (response) => {
                            const currentTeams = [...teams];
                            currentTeams[i] = response.team;
                            setTeams(currentTeams);
                          },
                          updatedData
                        );
                      }}
                    />
                  ),
                });
              }}
              onManageMembersClick={(members) => {
                setData({
                  title: `${labelsOverride.team || "Team"} Members`,
                  subtitle: `Add or remove ${
                    labelsOverride.team || "Team"
                  } members`,
                  team_id: team.id,
                  members,
                  index: i,
                  type: "members_map",
                });
                setShowAppOffCanvas(true);
              }}
              onDeleteClick={() => {
                showAppModal({
                  title: `Delete ${team.name} ${labelsOverride.team || "Team"}`,
                  component: (
                    <p>
                      Are you sure you want to delete this{" "}
                      {labelsOverride.team || "team"}?
                    </p>
                  ),
                  truthyFunction: () => {
                    deleteRequest(
                      `/people/teams/${team.id}`,
                      loaderCallback,
                      (response) => {
                        const currentTeams = [...teams];
                        currentTeams.splice(i, 1);
                        setTeams(currentTeams);
                        closeModal();
                      }
                    );
                  },
                });
              }}
            />
          </div>
        );
      })}
    </div>
  );
}

export default ManageTeams;
