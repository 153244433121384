import { FormFieldTypes } from "../../FormFields";
import { Country } from "country-state-city";

const PerformanceFormConfigs = {
  reviewPeriod: [
    {
      valueKey: "name",
      labelName: "Review Period Name",
    },
    {
      valueKey: "description",
      type: FormFieldTypes.TEXT_AREA,
      labelName: "Review Period Description",
    },
    {
      valueKey: "start_date",
      type: FormFieldTypes.DATE,
      info: "This Review period will start at 00:00:00 on the selected date.",
    },
    {
      valueKey: "end_date",
      type: FormFieldTypes.DATE,
      info: "This Review period will end at 00:00:00 on the selected date.",
    },
  ],
  reviewPeriodForm: (availableForms) => {
    return [
      {
        valueKey: "performance_form_id",
        labelName: "Select Form",
        type: FormFieldTypes.OBJECT_SELECT,
        optionsList: availableForms,
        optionsValueKey: "id",
        optionsLabelKey: "name",
      },
      {
        valueKey: "entity_ids",
        type: FormFieldTypes.ENTITY_SELECTION,
        labelName: "Entities to assign this form to",
      },
    ];
  },
  defaultReviewFormStep: (readColumns) => {
    return [
      {
        valueKey: "name",
        labelName: "Step Name",
      },
      {
        valueKey: "description",
        type: FormFieldTypes.TEXT_AREA,
        labelName: "Step Description",
      },
      {
        valueKey: "type",
        type: FormFieldTypes.STRING_SELECT,
        labelName: "Step Type",
        optionsList: ["Fill Form", "Comment", "Approve"],
      },
      {
        valueKey: "allow_extra_comments",
        type: FormFieldTypes.SWITCH,
        info: "This will add a comment box at the bottom of the form for the reviewer to fill out",
      },
      {
        valueKey: "require_extra_comments",
        type: FormFieldTypes.SWITCH,
        info: "This will require the reviewer to fill out the comment box at the bottom of the form",
      },
      {
        valueKey: "allow_file_uploads",
        type: FormFieldTypes.SWITCH,
        info: "This will allow the reviewer to upload PDF files during this review step. This could be useful for providing evidence",
      },
      {
        valueKey: "require_file_uploads",
        type: FormFieldTypes.SWITCH,
        info: "This will require the reviewer to upload a PDF file during this review step.",
      },
      {
        valueKey: "start_date",
        type: FormFieldTypes.DATE,
        info: "This step will start at 00:00:00 on the selected date.",
      },
      {
        valueKey: "end_date",
        type: FormFieldTypes.DATE,
        info: "This step will end at 00:00:00 on the selected date.",
      },
      {
        valueKey: "view_extra_comments",
        type: FormFieldTypes.SWITCH,
        labelName: "View Preceding Extra Comments",
        info: "Allows the reviewer to view extra comments made by REVIEWEES in preceding steps (if any)",
      },
      {
        valueKey: "view_comments",
        type: FormFieldTypes.SWITCH,
        labelName: "View Preceding Comments",
        info: 'Allows the reviewer to view comments made by REVIEWERS in the "Comment" step type in preceding steps (if any)',
      },
      {
        valueKey: "view_uploaded_files",
        type: FormFieldTypes.SWITCH,
        labelName: "View Preceding Uploaded Files",
        info: "Allows the reviewer to view files uploaded by REVIEWEES in preceding steps (if any)",
      },
      {
        valueKey: "view_approval_status",
        type: FormFieldTypes.SWITCH,
        labelName: "View Preceding Approval Status",
        info: "Allows the reviewer to view the approval status for REVIEWERS in the 'Approve' step type in preceding steps (if any)",
      },
      {
        valueKey: "read_columns",
        type: FormFieldTypes.MULTI_STRING_SELECT,
        labelName: "Columns the Reviewer Can View",
        optionsList: readColumns,
        info: "We've automatically selected the the Read Only Columns",
      },
    ];
  },
};

export default PerformanceFormConfigs;
