import React, { useContext } from "react";
import { Toast } from "react-bootstrap";
import { ToastContext } from "./ToastContext";
import ReactDOM from "react-dom";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

const AppToasts = () => {
  const { toasts, removeToast } = useContext(ToastContext);

  return ReactDOM.createPortal(
    <div className="toast-container">
      {toasts.map((toast) => (
        <Toast
          key={toast.id}
          show={toast.show}
          onClose={() => removeToast(toast.id)}
          autohide={toast.success || false}
          delay={10000}
        >
          <Toast.Header
            className={`${toast.success ? "bg-success" : "bg-danger"}`}
          >
            {toast.success ? (
              <FaCheckCircle className="me-2 text-white" />
            ) : (
              <FaTimesCircle className="me-2 text-white" />
            )}
            <strong className="me-auto text-white fw-bold">
              {toast.title || (toast.success ? "Success" : "Error")}
            </strong>
          </Toast.Header>
          <Toast.Body>{toast.message}</Toast.Body>
        </Toast>
      ))}
    </div>,
    document.getElementById("toast-root")
  );
};

export default AppToasts;
