import React, { useState } from "react";
import { Card, Button } from "react-bootstrap";
import { FaClock } from "react-icons/fa";
import { LuTimer, LuTimerOff } from "react-icons/lu";

function ClockInClockOutCard({
  headerText,
  bodyText,
  bodyBkg,
  onClick,
  clockIn = true,
}) {
  return (
    <Card
      className={
        "text-center text-white border-5 border-secondary shadow rounded-bottom-4 rounded-top-4"
      }
    >
      <Card.Header
        className={`bg-${clockIn ? "danger" : "success"} fw-bold fs-4 py-3`}
      >
        {!clockIn ? (
          <LuTimer size={40} className={"me-1 mb-2"} />
        ) : (
          <LuTimerOff size={40} className={"me-1 mb-2"} />
        )}
        {headerText}
      </Card.Header>
      <Card.Body
        className={`pointer ${bodyBkg} align-middle p-4`}
        onClick={() => {
          onClick();
        }}
      >
        <Card.Title as={"div"} className={"fw-bold fs-5"}>
          {clockIn ? (
            <LuTimer size={70} className={"fs-4"} />
          ) : (
            <LuTimerOff size={70} className={"fs-4"} />
          )}
          <br />
          <div className={"mt-3"} />
          <span className={"mt-2"}>{bodyText}</span>
        </Card.Title>
      </Card.Body>
    </Card>
  );
}

export default ClockInClockOutCard;
