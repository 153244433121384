import React, { useState, useEffect, useContext } from "react";
import { updateObjectState, useToast } from "../../../utils/functions";
import { Button, Col, InputGroup, ListGroup, Row } from "react-bootstrap";
import { ConditionalView, LoadingView } from "../../../utils/components";
import { FaSearch, FaTimes } from "react-icons/fa";
import GenericForm from "../../Forms/Shared/GenericForm";
import { FormFieldTypes } from "../../FormFields";
import { AppContext } from "../../../utils/components/AppContext";
import { apiRequest } from "../../../utils/apiRequests";

function EmployeeBulkSearch({ onAddResultsClick }) {
  const { showToast } = useToast();

  const [data, setData] = useState([]);
  const [results, setResults] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { contextState, updateContextState } = useContext(AppContext);
  const { labelsOverride } = contextState;

  const searchEmployees = async (data) => {
    setIsLoading(true);
    const { success, response } = await apiRequest.post(
      "/employee/bulk-search",
      data
    );

    if (success) {
      setResults(response.employees);
      updateObjectState(setData, {
        searched_count: response.searched_count,
      });
      setDataFetched(true);
    } else {
      showToast({
        title: "Error Searching Employees",
        message: response.message,
      });
    }
    setIsLoading(false);
  };

  return (
    <LoadingView
      isLoading={isLoading}
      fullHeight={false}
      view={
        <div>
          <ConditionalView
            condition={dataFetched}
            trueView={
              <div>
                {results.length === 0 && (
                  <div className={"text-center my-5"}>
                    <h4>No results found</h4>

                    <div className={"mt-4"}>
                      <Button
                        variant={"outline-primary"}
                        onClick={() => {
                          setDataFetched(false);
                        }}
                      >
                        <FaSearch className={"me-2"} />
                        Search Again
                      </Button>
                    </div>
                  </div>
                )}

                {results.length > 0 && (
                  <div>
                    <ListGroup className={"mt-4"}>
                      <Row>
                        {results.map((employee, i) => {
                          return (
                            <Col key={i} xs={12}>
                              <ListGroup.Item
                                className={
                                  "d-flex justify-content-between align-middle mt-2"
                                }
                              >
                                <div className="d-flex align-items-center">
                                  <img
                                    src={employee.profile_image}
                                    alt={employee.full_name}
                                    width={40}
                                    height={40}
                                    className="rounded-circle me-2"
                                  />
                                  <div className={"ms-2"}>
                                    {employee.full_name} (
                                    {employee.employee_number})
                                    <div className="small">
                                      {employee?.position?.title}
                                    </div>
                                    <div className="small">
                                      {employee?.work_email}
                                    </div>
                                  </div>
                                </div>
                                <FaTimes
                                  size={20}
                                  className={"text-danger ms-3"}
                                  onClick={() => {
                                    setResults(
                                      results.filter(
                                        (emp) => emp.id !== employee.id
                                      )
                                    );
                                  }}
                                />
                              </ListGroup.Item>
                            </Col>
                          );
                        })}
                      </Row>
                    </ListGroup>

                    <div className={"mt-5"}>
                      <h3 className={"text-center"}>
                        {results.length}{" "}
                        {results.length === 1 ? "Result" : "Results"} of{" "}
                        {data?.searched_count} Searched
                      </h3>
                      <Button
                        variant={"primary"}
                        className={"w-100 mt-4"}
                        onClick={() => {
                          onAddResultsClick(results);
                        }}
                      >
                        Add Results
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            }
            falseView={
              <div>
                <GenericForm
                  stateData={data}
                  setStateData={setData}
                  useGlobalState={false}
                  formConfigs={[
                    {
                      valueKey: "ids",
                      labelName: `Search Employees by Emails and/or ${
                        labelsOverride.employee_number || "Employee Number"
                      }s`,
                      type: FormFieldTypes.TEXT_AREA,
                      placeholder: `Enter one employee ${
                        labelsOverride.employee_number || "number"
                      } or email per line`,
                      info: `Enter each item on its own line. It doesn't matter if you enter a mix of emails and ${
                        labelsOverride.employee_number || "Employee Number"
                      }s. Do NOT add a comma or any other separator at the end of lines`,
                    },
                  ]}
                  onSubmit={(data) => {
                    searchEmployees(data);
                  }}
                />
              </div>
            }
          />
        </div>
      }
    />
  );
}

export default EmployeeBulkSearch;
