import React, { useContext } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useAppModal, useOffCanvas } from "../../../utils/functions";
import { AppContext } from "../../../utils/components/AppContext";
import { AddItemButton, NoInfoCard } from "../../../utils/components";
import TimeOffPolicyForm from "../../Forms/Employee/TimeOffPolicyForm";
import { ApprovalWorkflowForm } from "../../Forms/Organization/ApprovalWorkflowForm";
import { FaEdit, FaTrash } from "react-icons/fa";
import { ApprovalWorkflowStepForm } from "../../Forms/Organization/ApprovalWorkflowStepForm";

function ApprovalWorkflowsView({
  workflows,
  setWorkflows,
  positions,
  canManage,
  loaderCallback,
  workflowType,
  putRequest,
  patchRequest,
  deleteRequest,
  addSupervisorStep,
}) {
  const { showOffCanvas } = useOffCanvas();
  const { updateContextState } = useContext(AppContext);
  const { showAppModal } = useAppModal();

  return (
    <div>
      {canManage && (
        <div className={"mb-4"}>
          <AddItemButton
            title={"New Approval Workflow"}
            onClick={() => {
              updateContextState({
                formData: {
                  add_supervisor_step: addSupervisorStep,
                  type: workflowType,
                },
              });
              showOffCanvas({
                title: `Approval Workflow`,
                subtitle: `Add a new approval workflow.`,
                component: (
                  <ApprovalWorkflowForm
                    onSubmit={(updatedData) => {
                      putRequest(
                        "/organization/approval-workflows",
                        loaderCallback,
                        (response) => {
                          setWorkflows([
                            response.approval_workflow,
                            ...workflows,
                          ]);
                        },
                        updatedData
                      );
                    }}
                  />
                ),
              });
            }}
          />
        </div>
      )}

      {workflows.length === 0 && (
        <NoInfoCard text={"No approval workflows have been added yet."} />
      )}

      <Row>
        {workflows.map((workflow, i) => {
          return (
            <Col xs={12} className={"mb-4"} key={i}>
              <Card className={"border-primary border-1 rounded-3"}>
                <Card.Header className={"py-4 d-flex justify-content-between"}>
                  <div>
                    <Card.Title as={"h4"} className={"mb-3"}>
                      {workflow.name}
                    </Card.Title>
                    <Card.Subtitle>{workflow.description}</Card.Subtitle>
                  </div>
                  <div>
                    <Button
                      variant={"outline-primary"}
                      onClick={() => {
                        updateContextState({
                          formData: { ...workflow },
                        });
                        showOffCanvas({
                          title: `Edit ${workflow.name}`,
                          subtitle: `Edit the approval workflow.`,
                          component: (
                            <ApprovalWorkflowForm
                              onSubmit={(updatedData) => {
                                patchRequest(
                                  `/organization/approval-workflows/${workflow.id}`,
                                  loaderCallback,
                                  (response) => {
                                    const allWorkflows = [...workflows];
                                    allWorkflows[i] =
                                      response.approval_workflow;
                                    setWorkflows(allWorkflows);
                                  },
                                  updatedData
                                );
                              }}
                            />
                          ),
                        });
                      }}
                    >
                      <FaEdit className={"me-2"} /> Edit Workflow
                    </Button>
                  </div>
                </Card.Header>
                <Card.Body>
                  <h4 className={"mb-3"}>Approval Steps</h4>
                  {workflow.steps.map((step, stepIndex) => {
                    return (
                      <Card className={"mb-4 ms-3"} key={stepIndex}>
                        <Card.Header
                          className={"py-4 d-flex justify-content-between"}
                        >
                          <div>
                            <Card.Title as={"h5"} className={"mb-3"}>
                              {step.order_position}. {step.name}
                            </Card.Title>
                            <Card.Subtitle>{step.description}</Card.Subtitle>
                          </div>
                          {canManage && !step.for_direct_supervisor && (
                            <div className={"d-flex flex-row"}>
                              <Button
                                variant={"outline-primary"}
                                onClick={() => {
                                  updateContextState({
                                    formData: {
                                      ...step,
                                      position_ids: step.approvers.map(
                                        (approver) => approver.id
                                      ),
                                    },
                                  });
                                  showOffCanvas({
                                    title: `Edit ${step.name}`,
                                    subtitle: `Edit the approval step.`,
                                    component: (
                                      <ApprovalWorkflowStepForm
                                        positions={positions}
                                        onSubmit={(updatedData) => {
                                          patchRequest(
                                            `/organization/approval-workflow/${workflow.id}/approval-step/${step.id}`,
                                            loaderCallback,
                                            (response) => {
                                              const allWorkflows = [
                                                ...workflows,
                                              ];
                                              allWorkflows[i] =
                                                response.approval_workflow;
                                              setWorkflows(allWorkflows);
                                            },
                                            updatedData
                                          );
                                        }}
                                      />
                                    ),
                                  });
                                }}
                              >
                                <FaEdit className={"me-2"} /> Edit Step
                              </Button>

                              {step.can_delete && (
                                <Button
                                  variant={"outline-danger"}
                                  className={"ms-2"}
                                  onClick={() => {
                                    showAppModal({
                                      title: `Delete ${step.name}?`,
                                      component: (
                                        <p>
                                          Are you sure you want to delete the
                                          step <strong>{step.name}</strong>?
                                        </p>
                                      ),
                                      truthyFunction: () => {
                                        deleteRequest(
                                          `/organization/approval-workflow/${workflow.id}/approval-step/${step.id}`,
                                          loaderCallback,
                                          (response) => {
                                            const allWorkflows = [...workflows];
                                            allWorkflows[i] =
                                              response.approval_workflow;
                                            setWorkflows(allWorkflows);
                                          }
                                        );
                                      },
                                    });
                                  }}
                                >
                                  <FaTrash />
                                </Button>
                              )}
                            </div>
                          )}
                          {canManage && step.for_direct_supervisor && (
                            <p>This step cannot be modified</p>
                          )}
                        </Card.Header>
                        <Card.Body>
                          <div>
                            <h5 className={"mb-3"}>Approvers</h5>
                            <ul>
                              {step.approvers.map((approver, i) => {
                                return (
                                  <li key={i} className={"fs-6 mb-3"}>
                                    {approver.title}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </Card.Body>
                      </Card>
                    );
                  })}
                </Card.Body>
                {canManage && (
                  <div className={"d-flex justify-content-center my-4"}>
                    <AddItemButton
                      title={"Add Step"}
                      onClick={() => {
                        updateContextState({
                          formData: {},
                        });
                        showOffCanvas({
                          title: `Add Step to "${workflow.name}"`,
                          subtitle: `Add a new step to the approval workflow.`,
                          component: (
                            <ApprovalWorkflowStepForm
                              positions={positions}
                              onSubmit={(updatedData) => {
                                putRequest(
                                  `/organization/approval-workflow/${workflow.id}/approval-step`,
                                  loaderCallback,
                                  (response) => {
                                    const allWorkflows = [...workflows];
                                    allWorkflows[i] =
                                      response.approval_workflow;
                                    setWorkflows(allWorkflows);
                                  },
                                  updatedData
                                );
                              }}
                            />
                          ),
                        });
                      }}
                    />
                  </div>
                )}
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}

export default ApprovalWorkflowsView;
