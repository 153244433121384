import React from "react";
import EmployeeHighlightCard from "../Employee/EmployeeHighlightCard";
import { FaChevronRight, FaCopy, FaPlus } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { Button, Card } from "react-bootstrap";
import { apiRequest } from "../../../utils/apiRequests";
import { AppRoutes } from "../../../utils/appRoutes";
import { TbSwitchVertical } from "react-icons/tb";
import { useAppModal } from "../../../utils/functions";
import ExpandableText from "../Shared/ExpandableText";
import CopyWithTooltip from "../Shared/CopyWithTooltip";

function OrganizationTreeNode({ nodeData }) {
  const organization = nodeData;
  const cardStyle = {
    minWidth: "400px", // Adjust this value as needed
    maxWidth: "400px", // Adjust this value as needed
    margin: "0 auto", // Center the card in the parent
  };
  const { showAppModal } = useAppModal();

  return (
    <Card style={cardStyle}>
      <Card.Body>
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className={"d-flex flex-row align-items-center"}>
            <img
              src={organization.logo}
              alt={`${organization.name} Logo`}
              width={70}
              height={70}
              className="rounded-circle me-3"
            />
            <div>
              <h5 className={"text-start mb-2"}>{organization.name}</h5>
              <div className={"text-start"}>
                <ExpandableText text={organization.description} lines={2} />
              </div>
            </div>
          </div>
        </div>
        <p className={"text-start mt-3"}>
          <small>Org ID: {organization.id}</small>{" "}
          <CopyWithTooltip text={organization.id} />
        </p>
      </Card.Body>
      <Card.Footer>
        <div className={"d-flex justify-content-end py-3"}>
          {organization.is_member && !organization.is_current && (
            <div className={"d-flex align-items-center"}>
              <Button
                variant="outline-primary"
                size={"sm"}
                onClick={() => {
                  showAppModal({
                    title: `Switch to ${organization.name}`,
                    component: (
                      <p>
                        Are you sure you want to switch to the{" "}
                        {organization.name} organization? Your page will reload
                        and you will be logged out of your current organization.
                      </p>
                    ),
                    truthyFunction: async () => {
                      const { success, response } = await apiRequest.get(
                        `/organization/switch/${organization.id}`
                      );
                      if (success) {
                        window.location.href = AppRoutes.Home.path;
                      } else {
                        showAppModal({
                          title: "Error",
                          component: <p>{response.message}</p>,
                          hideFooter: true,
                        });
                      }
                    },
                  });
                }}
              >
                <TbSwitchVertical className={"me-2"} />
                Switch to this Organization
              </Button>
            </div>
          )}
          {organization.is_member && organization.is_current && (
            <h6 className={"text-success fst-italic"}>Current Organization</h6>
          )}
        </div>
      </Card.Footer>
    </Card>
  );
}

export default OrganizationTreeNode;
