import React, { useRef } from "react";
import OrganizationChart from "@dabeng/react-orgchart";
import { OrganizationCard } from "../OrganizationCard";
import { Button, Card, Col } from "react-bootstrap";
import { FaDownload } from "react-icons/fa";
import {
  globalReplace,
  globalReplaceComma,
  useAppModal,
} from "../../../utils/functions";
import OrganizationTreeNode from "./OrganizationTreeNode";

function OrganizationTree({ organizationTree }) {
  const chartRef = useRef();

  return (
    <div className={"mb-5"}>
      <div className={"mb-3 mt-4 d-flex justify-content-end"}>
        <Button
          variant={"primary"}
          onClick={() => {
            chartRef.current.exportTo(
              `${globalReplace(
                organizationTree.name,
                " ",
                "-"
              )}-organization-tree`,
              "pdf"
            );
          }}
        >
          <FaDownload className={"me-2"} />
          Download Org Tree as PDF
        </Button>
      </div>
      <Card className={"mb-5"}>
        <Card.Header className={"py-4"}>
          <Card.Title className={"fs-3 mb-3"}>Organization Tree</Card.Title>
          <Card.Subtitle>
            <small>
              Explore the Hierarchical Structure of your entire organization
              <br />
              Click and drag to move around the chart
            </small>
          </Card.Subtitle>
        </Card.Header>
      </Card>

      <OrganizationChart
        datasource={organizationTree}
        ref={chartRef}
        chartClass={"bg-transparent"}
        containerClass={"bg-transparent border-0 vh-100 mb-5"}
        draggable={false}
        pan={true}
        zoom={false}
        zoominLimit={1}
        collapsible={true}
        multipleSelect={false}
        NodeTemplate={OrganizationTreeNode}
      />
    </div>
  );
}

export default OrganizationTree;
