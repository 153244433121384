import React from "react";
import { Button, Card, Table } from "react-bootstrap";
import {
  FaChevronLeft,
  FaChevronRight,
  FaEdit,
  FaPlus,
  FaTrash,
} from "react-icons/fa";
import { MONTHS } from "../../../data/months";
import { ABBR_DAYS_OF_WEEK } from "../../../data/weeks";
import { strTimeDifference } from "../../../utils/functions";
import {
  formatDate,
  formDate,
  getUTCDateTime,
  hrsMinsSecsDiff,
  isoWeekday,
  time24Hrs,
  timeDiffCounter,
} from "../../../utils/dateTime";

const daysOfWeek = ABBR_DAYS_OF_WEEK;

function ScheduleCalendar({
  activeDate,
  assignments,
  navigation,
  navCallback,
  canManage,
  addShiftCallback,
  editShiftCallback,
  deleteShiftCallback,
}) {
  if (Object.keys(assignments).length === 0) return <div />;

  // calculate the number of days in the month
  const daysInMonth = parseInt(
    activeDate.endOf("month").startOf("day").format("D")
  );

  // create an array to hold the days
  const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);

  const weeks = [];
  let week = [];

  days.forEach((day, index) => {
    const date = activeDate.add(index, "day");
    const dayOfWeek = isoWeekday(date);

    const isAssigned = assignments[day].length > 0;

    // add empty cells at the start of the month
    if (day === 1 && dayOfWeek !== 1) {
      const emptyCells = Array.from({ length: dayOfWeek - 1 }, () => null);
      week = [...emptyCells, { day, isAssigned }];
    } else {
      week.push({ day, isAssigned });
    }

    if (week.length === 7 || index === days.length - 1) {
      weeks.push(week);
      week = [];
    }
  });

  return (
    <div>
      <Card>
        <Card.Header className={"py-4 d-flex justify-content-between"}>
          <Button
            variant={"outline-primary"}
            disabled={!navigation?.previous_month}
            onClick={() => {
              const nav = { ...navigation };
              nav.start_date = nav.previous_month;
              navCallback(nav);
            }}
          >
            <FaChevronLeft size={30} />
          </Button>
          <h4>{formatDate(activeDate, "MMMM YYYY")}</h4>
          <Button
            variant={"outline-primary"}
            disabled={!navigation?.next_month}
            onClick={() => {
              const nav = { ...navigation };
              nav.start_date = nav.next_month;
              navCallback(nav);
            }}
          >
            <FaChevronRight size={30} />
          </Button>
        </Card.Header>
        <Card.Body>
          <Table responsive bordered>
            <thead>
              <tr className={"text-center py-4 bg-primary"}>
                {daysOfWeek.map((day, index) => (
                  <th key={index} className={"py-3"}>
                    <strong>{day}</strong>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {weeks.map((week, i) => (
                <tr key={`week${i}`} className={"py-5"}>
                  {week.map((day, j) => {
                    if (day) {
                      const dayVal = day.day;
                      const dayDate = activeDate.date(dayVal);
                      const endOfToday = getUTCDateTime().startOf("day");
                      const isPast = dayDate.isBefore(endOfToday);

                      return (
                        <td key={j} className={"custom-cell"}>
                          <div
                            className={
                              "mt-2 d-flex justify-content-between align-items-center"
                            }
                          >
                            <h5>{dayVal}</h5>
                            {canManage && !isPast && (
                              <Button
                                variant={"outline-primary"}
                                size={"sm"}
                                onClick={() => {
                                  addShiftCallback(formDate(dayDate), dayVal);
                                }}
                              >
                                <FaPlus />
                              </Button>
                            )}
                          </div>
                          {assignments[day.day].map((shift, shiftIndex) => {
                            const [hours, minutes] = hrsMinsSecsDiff(
                              shift.start_datetime,
                              shift.end_datetime
                            );
                            return (
                              <Card
                                key={shiftIndex}
                                className={
                                  "border-3 border-success-subtle rounded-3 my-3"
                                }
                              >
                                <Card.Body>
                                  {time24Hrs(shift.start_datetime)} -{" "}
                                  {time24Hrs(shift.end_datetime)}
                                  <br />
                                  {hours} hrs {minutes} mins
                                </Card.Body>
                                {shift.can_modify && !isPast && (
                                  <Card.Footer
                                    className={"d-flex justify-content-between"}
                                  >
                                    <Button
                                      variant={"outline-danger"}
                                      size={"sm"}
                                      onClick={() => {
                                        deleteShiftCallback(
                                          shift,
                                          shiftIndex,
                                          dayVal
                                        );
                                      }}
                                    >
                                      <FaTrash />
                                    </Button>
                                    <Button
                                      variant={"outline-primary"}
                                      size={"sm"}
                                      onClick={() => {
                                        editShiftCallback(
                                          formDate(dayDate),
                                          dayVal,
                                          shift,
                                          shiftIndex
                                        );
                                      }}
                                    >
                                      <FaEdit />
                                    </Button>
                                  </Card.Footer>
                                )}
                              </Card>
                            );
                          })}
                        </td>
                      );
                    } else {
                      return <td key={j} className={"custom-cell"} />;
                    }
                  })}
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
}

export default ScheduleCalendar;
