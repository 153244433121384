import React, { useContext, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { isEmpty, updateObjectState } from "../../utils/functions";
import { AppContext } from "../../utils/components/AppContext";
import EmployeeSearch from "../components/EmployeeSearch";
import { EmployeeList } from "../components/EmployeeCards";
import { formatDateStrings } from "../../utils/dateTime";

export function TeamsForm({ onSubmit, state, departments }) {
  const { contextState, updateContextState } = useContext(AppContext);
  const [formState, setFormState] = useState(
    formatDateStrings({ ...contextState.formData })
  );
  const { labelsOverride } = contextState;

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateContextState({
      formData: formState,
    });
    onSubmit(formState);
  };

  const formValid = () => {
    const requiredFields = ["name", "department_id", "supervisor_employee_id"];
    let valid = true;
    for (const field of requiredFields) {
      if (isEmpty(formState[field])) {
        valid = false;
        break;
      }
    }
    return valid;
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="teamName" className={"mb-4"}>
        <Form.Label>Name *</Form.Label>
        <Form.Control
          type="text"
          name="name"
          required
          value={formState.name || ""}
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group controlId="teamDescription" className={"mb-4"}>
        <Form.Label>Description *</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          name="description"
          value={formState.description || ""}
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group controlId="teamDepartment" className={"mb-4"}>
        <Form.Label>{labelsOverride.department || "Department"} *</Form.Label>
        <Form.Select
          name="department_id"
          required
          value={formState.department_id || ""}
          onChange={handleInputChange}
        >
          <option value="">
            Select a {labelsOverride.department || "Department"}
          </option>
          {departments.map((department) => (
            <option key={department.id} value={department.id}>
              {department.name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      <Form.Group controlId="teamSupervisor" className={"mb-4"}>
        <Form.Label>Manager / Supervisor *</Form.Label>
        <EmployeeSearch
          onResultClick={(employee) => {
            updateObjectState(setFormState, {
              supervisor: employee,
              supervisor_employee_id: employee.id,
            });
          }}
        />
        <EmployeeList
          employees={formState.supervisor ? [formState.supervisor] : []}
        />
      </Form.Group>

      <Form.Group controlId="teamIsActive" className={"mb-4"}>
        <Form.Switch
          name="is_active"
          label="Is Active"
          checked={formState.is_active || false}
          onChange={(event) => {
            setFormState((prevState) => ({
              ...prevState,
              is_active: event.target.checked,
            }));
          }}
        />
      </Form.Group>

      <Form.Group controlId="teamFormed" className={"mb-5"}>
        <Form.Label>Formed</Form.Label>
        <Form.Control
          type="date"
          name="formed_date"
          value={formState.formed_date || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Button
        variant="primary"
        type="submit"
        disabled={!formValid()}
        className={"w-100"}
      >
        Save
      </Button>
    </Form>
  );
}
