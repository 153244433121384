import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { AppContext } from "../../../utils/components/AppContext";
import { formatDateStrings } from "../../../utils/dateTime";
import ControlledDateRangeFormFields from "../ControlledDateRangeFormFields";

function EmployeeTimeOffRequestForm({ onSubmit, configs, policy, timeOff }) {
  const { contextState, updateContextState } = useContext(AppContext);
  const [formState, setFormState] = useState({
    ...formatDateStrings({ ...contextState.formData }, [
      "available_time_off_id",
    ]),
    ...{ team_id: configs.teams.length > 0 ? configs.teams[0].id : null },
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const formValid = () => {
    const requiredFields = [
      "available_time_off_id",
      "time_units",
      "start_date",
      "end_date",
      "comment",
    ];
    if (configs.teams.length > 0) {
      requiredFields.push("team_id");
    }

    // Check that all required fields have a value
    const allFieldsPresent = requiredFields.every((field) => formState[field]);

    const time_units = parseInt(formState.time_units);

    // Check that time_units is a positive integer less than or equal to the maximum allowed time
    const timeUnitsValid =
      time_units > 0 && time_units <= parseInt(timeOff.time_usage.remaining);

    return allFieldsPresent && timeUnitsValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateContextState({
      formData: { ...formState },
    });
    onSubmit(formState);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Text as={"p"} className={"fw-bold"}>
        Required fields are marked with an{" "}
        <span className="text-danger">*</span>
      </Form.Text>

      {configs.teams.length > 1 && (
        <Form.Group controlId="team" className={"mb-4"}>
          <Form.Label>
            Team<span className="text-danger">*</span>
          </Form.Label>
          <Form.Control
            as="select"
            name="team_id"
            value={formState.team_id || ""}
            onChange={handleInputChange}
            required
            className={"w-100"}
          >
            {configs.teams.map((team) => (
              <option key={team.id} value={team.id}>
                {team.name}
              </option>
            ))}
          </Form.Control>
          <Alert variant="info" className={"mb-4 mt-3"} show={!formValid()}>
            The time off approvers of the team you select will be notified and
            asked to approve this request.
          </Alert>
        </Form.Group>
      )}

      <Form.Group controlId="timeUnits" className={"mb-4"}>
        <Form.Label>
          {policy.time_unit} To Request<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="number"
          name="time_units"
          value={formState.time_units || ""}
          onChange={handleInputChange}
          max={timeOff.time_usage.remaining}
        />
        <Form.Text as={"p"} className={"fw-bold fs-5 mt-2"}>
          {timeOff.time_usage.remaining} {policy.time_unit} Available
        </Form.Text>
      </Form.Group>

      <Form.Group controlId="comment" className={"mb-4"}>
        <Form.Label>
          Reason<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          name="comment"
          value={formState.comment || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <ControlledDateRangeFormFields
        formState={formState}
        setFormState={setFormState}
        handleInputChange={handleInputChange}
      />

      {configs.teams.length === 0 && (
        <Alert variant="warning" className={"mb-4"} show={!formValid()}>
          This employee has not been assigned to a team. Their time off request
          will be approved automatically.
        </Alert>
      )}
      <Button
        variant="primary"
        type="submit"
        className={"w-100 mb-5"}
        disabled={!formValid()}
      >
        Save
      </Button>
    </Form>
  );
}

export default EmployeeTimeOffRequestForm;
