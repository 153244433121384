import React, { useContext, useState } from "react";
import { Button, Form, Card } from "react-bootstrap";
import { AppContext } from "../../../utils/components/AppContext";
import {
  formatDateAndTimeStrings,
  formatDateStrings,
} from "../../../utils/dateTime";

function AnnouncementsForm({ onSubmit, configs }) {
  const { contextState, updateContextState } = useContext(AppContext);
  const [formState, setFormState] = useState({
    ...formatDateAndTimeStrings({ ...contextState.formData }),
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const formValid = () => {
    const requiredFields = ["title", "text", "start_datetime", "end_datetime"];
    return requiredFields.every((field) => formState[field]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateContextState({
      formData: { ...formState },
    });
    onSubmit(formState);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Card body className="mb-4">
        <Form.Group controlId="title" className={"mb-4"}>
          <Form.Label>
            Title<span className="text-danger">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            name="title"
            value={formState.title || ""}
            onChange={handleInputChange}
          />
        </Form.Group>

        <Form.Group controlId="text" className={"mb-4"}>
          <Form.Label>
            Text<span className="text-danger">*</span>
          </Form.Label>
          <Form.Control
            as="textarea"
            name="text"
            value={formState.text || ""}
            onChange={handleInputChange}
          />
        </Form.Group>

        <Form.Group controlId="startDatetime" className={"mb-4"}>
          <Form.Label>
            Start Date and Time<span className="text-danger">*</span>
          </Form.Label>
          <Form.Control
            type="datetime-local"
            name="start_datetime"
            value={formState.start_datetime || ""}
            onChange={handleInputChange}
          />
        </Form.Group>

        <Form.Group controlId="endDatetime" className={"mb-4"}>
          <Form.Label>
            End Date and Time<span className="text-danger">*</span>
          </Form.Label>
          <Form.Control
            type="datetime-local"
            name="end_datetime"
            value={formState.end_datetime || ""}
            onChange={handleInputChange}
          />
        </Form.Group>
      </Card>

      <Button
        variant="primary"
        type="submit"
        className={"w-100"}
        disabled={!formValid()}
      >
        Save
      </Button>
    </Form>
  );
}

export default AnnouncementsForm;
