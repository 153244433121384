// src/ContactDownload.js
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const contacts = {
  a1b2c3d4e5f6: {
    fullName: "Alex Harding",
    lastName: "Harding",
    firstName: "Alex",
    email: "alex@humnce.com",
    phoneNumbers: ["+15102690577", "+23278624212"],
    address: "42E Water St. Congo Cross, Freetown, Sierra Leone",
    title: "CEO",
    organization: "humnce. LLC",
    url: "https://humnce.com",
    revision: "2024-06-27T17:07:49.498Z",
  },
  // Add more contacts as needed
};

const ContactCardDownload = () => {
  const { id } = useParams();
  const contact = contacts[id];

  useEffect(() => {
    if (contact) {
      const vCardContent = generateVCard(contact);
      downloadVCard(
        vCardContent,
        `${contact.firstName}_${contact.lastName}.vcf`
      );
    }
  }, [contact]);

  const generateVCard = (contact) => {
    return `
BEGIN:VCARD
VERSION:3.0
FN;CHARSET=UTF-8:${contact.fullName}
N;CHARSET=UTF-8:${contact.lastName};${contact.firstName};;;
EMAIL;CHARSET=UTF-8;type=WORK,INTERNET:${contact.email}
${contact.phoneNumbers.map((number) => `TEL;TYPE=CELL:${number}`).join("\n")}
ADR;CHARSET=UTF-8;TYPE=WORK:;;${contact.address}
TITLE;CHARSET=UTF-8:${contact.title}
ORG;CHARSET=UTF-8:${contact.organization}
URL;type=WORK;CHARSET=UTF-8:${contact.url}
REV:${contact.revision}
END:VCARD
    `;
  };

  const downloadVCard = (vCardContent, fileName) => {
    const blob = new Blob([vCardContent], { type: "text/vcard" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  if (!contact) {
    return <h2>Contact not found</h2>;
  }

  return <div></div>;
};

export default ContactCardDownload;
