import React from "react";
import {
  Card,
  Button,
  Badge,
  Table,
  Accordion,
  Row,
  Col,
} from "react-bootstrap";
import { FaAd, FaEdit, FaPlus, FaTrain, FaTrash } from "react-icons/fa";
import { formatDate } from "../../utils/dateTime";
import { InfoDisplayTable } from "../../utils/components";

export function BranchCard({
  branch,
  canManage,
  onEditClick,
  onDeleteClick,
  onAddLocationClick,
  onEditLocation,
}) {
  return (
    <Card className={"shadow rounded-3"}>
      <Card.Header className={"py-4"}>
        <Card.Title as={"h4"} className={"mb-3"}>
          {branch.name} ({branch.code})
          {branch.is_headquarters && (
            <Badge className={"ms-2"} bg={"primary"} pill={true}>
              Headquarters
            </Badge>
          )}
        </Card.Title>
        <Card.Subtitle className="text-muted">
          {branch.description}
        </Card.Subtitle>
      </Card.Header>
      <Card.Body as={"div"} className={"px-3"}>
        <Row>
          <Col sm={6}>
            <InfoDisplayTable
              data={{
                Address: `${branch.full_address}`,
                Latitude: branch.latitude,
                Longitude: branch.longitude,
                "Clock In / Out Perimeter": branch.allowed_attendance_distance
                  ? `${branch.allowed_attendance_distance} meters`
                  : "N/A",
              }}
            />
          </Col>
          <Col sm={6}>
            <InfoDisplayTable
              data={{
                Industry: branch.industry,
                "Industry Category": branch.industry_category,
                "Date Opened": formatDate(branch.date_opened),
              }}
            />
          </Col>
        </Row>

        {branch.locations && branch.locations.length > 0 && (
          <Accordion>
            <Accordion.Header className={"bg-primary text-white"}>
              View Branch Locations
            </Accordion.Header>
            <Accordion.Body>
              <BranchLocationsTable
                locations={branch.locations}
                onEdit={onEditLocation}
                canManage={canManage}
              />
            </Accordion.Body>
          </Accordion>
        )}
      </Card.Body>
      {canManage && (
        <Card.Footer>
          <div className="d-flex justify-content-end py-3">
            {onAddLocationClick && (
              <Button
                onClick={() => {
                  onAddLocationClick(branch);
                }}
                variant="success"
                className="me-2"
              >
                <FaPlus className={"me-2"} />
                New Location
              </Button>
            )}
            {onEditClick && (
              <Button
                onClick={() => {
                  onEditClick();
                }}
                variant="primary"
                className="me-2"
              >
                <FaEdit className={"me-2"} />
                Edit
              </Button>
            )}
            {onDeleteClick && (
              <Button
                onClick={() => {
                  onDeleteClick();
                }}
                variant="danger"
              >
                <FaTrash className={"me-2"} />
                Delete
              </Button>
            )}
          </div>
        </Card.Footer>
      )}
    </Card>
  );
}

export function BranchLocationsTable({ locations, canManage, onEdit }) {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Code</th>
          <th>Name</th>
          <th>Address</th>
          <th>Latitude & Longitude</th>
          <th>Clock In / Out Perimeter</th>
          <th>Description</th>
          <th>Date Opened</th>
          {canManage && <th>Action</th>}
        </tr>
      </thead>
      <tbody>
        {locations.map((location) => (
          <tr key={location.id}>
            <td>{location.code}</td>
            <td>{location.name}</td>
            <td>{location.address}</td>
            <td>
              {location.latitude || "n/a"}, {location.longitude || "n/a"}
            </td>
            <td>
              {location.allowed_attendance_distance
                ? `${location.allowed_attendance_distance} meters`
                : "n/a"}
            </td>
            <td>{location.description}</td>
            <td>{formatDate(location.date_opened)}</td>
            {canManage && (
              <td>
                <Button
                  variant="outline-primary"
                  onClick={() => {
                    onEdit(location);
                  }}
                >
                  <FaEdit />
                </Button>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
