import React, { useContext, useEffect, useState } from "react";
import { Alert, Form } from "react-bootstrap";
import EmployeeSearch from "../../components/EmployeeSearch";
import { EmployeeList } from "../../components/EmployeeCards";
import { updateObjectState } from "../../../utils/functions";
import { AppContext } from "../../../utils/components/AppContext";
import { useApi } from "../../../hooks/useApi";
import { apiRequest } from "../../../utils/apiRequests";

const ENTIRE_ORGANIZATION = "Entire Organization";
const DEPARTMENTS = "Departments";
const BRANCHES = "Branches";
const TEAMS = "Teams";
const EMPLOYEE_TYPES = "Employee Types";
const SELECT_EMPLOYEES = "Select Employees";
const LEVELS = "Levels";
const POSITIONS = "Positions";

const DEPARTMENT_IDS = "department_ids";
const BRANCH_IDS = "branch_ids";
const TEAM_IDS = "team_ids";
const EMPLOYEE_TYPE = "employee_type";
const EMPLOYEE_IDS = "employee_ids";
const LEVEL_IDS = "level_ids";
const POSITION_IDS = "position_ids";

function OrgEntitySelection({
  passedEntities,
  formState,
  setFormState,
  includeList,
  is_single_person,
}) {
  const { contextState } = useContext(AppContext);
  const { labelsOverride } = contextState;

  const originalRequiredFields = ["entity_ids"];

  const [requiredFields, setRequiredFields] = useState(originalRequiredFields);
  const [excludedEmployees, setExcludedEmployees] = useState([]);
  const [addedEmployees, setAddedEmployees] = useState([]);
  const [entityType, setEntityType] = useState(formState.entity_type);
  const [entities, setEntities] = useState(passedEntities || {});

  const getEntities = async () => {
    const { success, response } = await apiRequest.get(
      `/organization/entities`
    );
    if (success) {
      setEntities(response.entities);
    }
  };

  useEffect(() => {
    setFormState((prevState) => ({
      ...prevState,
      entity_ids: [],
      excluded_employee_ids: [],
    }));

    if (!passedEntities) {
      getEntities();
    }
  }, []);

  const updateRequirement = (key, value = null) => {
    setFormState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
    setRequiredFields([...originalRequiredFields, key]);
  };

  const handleEntityTypeChange = (event) => {
    const { value } = event.target;
    setEntityType(value);
    setFormState((prevState) => ({
      ...prevState,
      entity_type: value,
      entity_ids: value === ENTIRE_ORGANIZATION ? [value] : [],
    }));

    if (value === DEPARTMENTS) {
      updateRequirement(DEPARTMENT_IDS);
    } else if (value === BRANCHES) {
      updateRequirement(BRANCH_IDS);
    } else if (value === TEAMS) {
      updateRequirement(TEAM_IDS);
    } else if (value === EMPLOYEE_TYPES) {
      updateRequirement(EMPLOYEE_TYPE);
    } else if (value === LEVELS) {
      updateRequirement(LEVEL_IDS);
    } else if (value === POSITIONS) {
      updateRequirement(POSITION_IDS);
    } else if (value === SELECT_EMPLOYEES) {
      updateObjectState(setFormState, { excluded_employee_ids: [] });
      setExcludedEmployees([]);
      updateRequirement(EMPLOYEE_IDS, []);
    } else {
      setRequiredFields(originalRequiredFields);
    }
  };

  const SelectionItem = ({ name, value, idsKey }) => {
    return (
      <Form.Check
        type="checkbox"
        label={name}
        name={name}
        value={value || ""}
        checked={formState.entity_ids?.includes(value) || false}
        onChange={(e) => {
          const isChecked = e.target.checked;
          const entity_ids = isChecked
            ? [...formState.entity_ids, e.target.value]
            : formState.entity_ids.filter((id) => id !== e.target.value);

          setFormState((prevState) => ({
            ...prevState,
            entity_ids: entity_ids,
            [idsKey]: true,
          }));
        }}
      />
    );
  };

  const altEntityNames = {
    Departments: labelsOverride.departments,
    Teams: labelsOverride.teams,
  };

  if (!entities.entity_selections) {
    return <div>Getting Entities...</div>;
  } else {
    return (
      <div>
        <small className={"mb-3 text-muted"}>
          Please select the entity type and entities to apply this to
        </small>

        <Alert variant={"warning"} className={"mt-3"}>
          NOTE:
          <br />
          <small>
            Only active employees that have been assigned positions in this
            organization will be affected by this selection.
          </small>
        </Alert>
        <Form.Group controlId="entityType" className={"mb-4"}>
          <Form.Label>
            Entity Type<span className="text-danger">*</span>
          </Form.Label>
          <Form.Control
            as="select"
            name="entity_type"
            value={entityType}
            onChange={handleEntityTypeChange}
          >
            <option value="">Select an entity type</option>
            {entities.entity_selections.map((type) => {
              if (includeList && !includeList.includes(type)) {
                return null;
              }
              return (
                <option key={type} value={type}>
                  {altEntityNames[type] || type}
                </option>
              );
            })}
          </Form.Control>
        </Form.Group>

        {requiredFields.includes(DEPARTMENT_IDS) && (
          <Form.Group controlId="teamDepartment" className={"mb-4"}>
            <Form.Label>
              Select {labelsOverride.departments || "Departments"}*
            </Form.Label>
            {entities.departments.map((department, index) => {
              return (
                <SelectionItem
                  name={department.name}
                  value={department.id}
                  idsKey={DEPARTMENT_IDS}
                  key={index}
                />
              );
            })}
          </Form.Group>
        )}

        {requiredFields.includes(BRANCH_IDS) && (
          <Form.Group controlId="teamBranch" className={"mb-4"}>
            <Form.Label>Select Branches*</Form.Label>
            {entities.branches.map((branch, index) => {
              return (
                <SelectionItem
                  name={branch.name}
                  value={branch.id}
                  idsKey={BRANCH_IDS}
                  key={index}
                />
              );
            })}
          </Form.Group>
        )}

        {requiredFields.includes(TEAM_IDS) && (
          <Form.Group controlId="teamTeam" className={"mb-4"}>
            <Form.Label>Select {labelsOverride.teams || "Teams"} *</Form.Label>
            {entities.teams.map((team, index) => {
              return (
                <SelectionItem
                  name={team.name}
                  value={team.id}
                  idsKey={TEAM_IDS}
                  key={index}
                />
              );
            })}
          </Form.Group>
        )}

        {requiredFields.includes(LEVEL_IDS) && (
          <Form.Group controlId="teamLevel" className={"mb-4"}>
            <Form.Label>Select Levels*</Form.Label>
            {entities.levels.map((level, index) => {
              return (
                <SelectionItem
                  name={level.name}
                  value={level.id}
                  idsKey={LEVEL_IDS}
                  key={index}
                />
              );
            })}
          </Form.Group>
        )}

        {requiredFields.includes(POSITION_IDS) && (
          <Form.Group controlId="teamPosition" className={"mb-4"}>
            <Form.Label>Select Positions*</Form.Label>
            {is_single_person && (
              <Alert variant={"info"}>
                <small>
                  Only positions that are marked as single person positions are
                  being shown
                </small>
              </Alert>
            )}
            {entities.positions.map((position, index) => {
              if (is_single_person && !position.is_single_person) {
                return null;
              }
              return (
                <SelectionItem
                  name={position.name}
                  value={position.id}
                  idsKey={POSITION_IDS}
                  key={index}
                />
              );
            })}
          </Form.Group>
        )}

        {requiredFields.includes(EMPLOYEE_TYPE) && (
          <Form.Group controlId="teamEmployeeType" className={"mb-4"}>
            <Form.Label>Employee Type *</Form.Label>
            {entities.employee_types.map((type, index) => {
              return (
                <SelectionItem
                  name={type}
                  value={type}
                  idsKey={EMPLOYEE_TYPE}
                  key={index}
                />
              );
            })}
          </Form.Group>
        )}

        {requiredFields.includes(EMPLOYEE_IDS) && (
          <div>
            <EmployeeSearch
              onResultClick={(employee) => {
                if (formState.entity_ids.includes(employee.id)) {
                  return;
                }
                const employees = [employee, ...addedEmployees];
                setAddedEmployees(employees);
                setFormState((prevState) => ({
                  ...prevState,
                  entity_ids: [employee.id, ...prevState.entity_ids],
                  [EMPLOYEE_IDS]: true,
                }));
              }}
            />
            {formState.employee_ids.length === 0 && (
              <Alert variant={"danger"}>
                <small>Please select at least one mployee.</small>
              </Alert>
            )}

            <EmployeeList
              employees={addedEmployees}
              add={false}
              action={(employee) => {
                const employees = [...addedEmployees];
                employees.splice(employees.indexOf(employee), 1);
                setAddedEmployees(employees);
                setFormState((prevState) => ({
                  ...prevState,
                  entity_ids: [
                    ...prevState.entity_ids.filter((id) => id !== employee.id),
                  ],
                }));
              }}
            />
          </div>
        )}

        {!requiredFields.includes(EMPLOYEE_IDS) && !is_single_person && (
          <div className={"mt-4"}>
            <Alert variant={"info"}>
              <small>
                [Optional] Use the search box to add employees you want to
                exclude from this entity selection below
              </small>
            </Alert>
            <EmployeeSearch
              onResultClick={(employee) => {
                if (formState.excluded_employee_ids.includes(employee.id)) {
                  return;
                }
                const employees = [employee, ...excludedEmployees];
                setExcludedEmployees(employees);
                setFormState((prevState) => ({
                  ...prevState,
                  excluded_employee_ids: [
                    employee.id,
                    ...prevState.excluded_employee_ids,
                  ],
                }));
              }}
            />

            <EmployeeList
              employees={excludedEmployees}
              add={false}
              action={(employee) => {
                const employees = [...excludedEmployees];
                employees.splice(employees.indexOf(employee), 1);
                setExcludedEmployees(employees);
                setFormState((prevState) => ({
                  ...prevState,
                  excluded_employee_ids: [
                    ...prevState.excluded_employee_ids.filter(
                      (id) => id !== employee.id
                    ),
                  ],
                }));
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

export default OrgEntitySelection;
