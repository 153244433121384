import React, { useContext } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { FaExclamationTriangle } from "react-icons/fa";
import { AppContext } from "../utils/components/AppContext";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../utils/appRoutes";

const SubscriptionExpiredPage = () => {
  const { contextState } = useContext(AppContext);
  const navigate = useNavigate();

  return (
    <Container className="d-flex vh-100">
      <Row className="justify-content-center align-self-center w-100">
        <Col lg={6} lg={4}>
          <Card className="text-center">
            <Card.Header className={"align-middle p-4"}>
              <FaExclamationTriangle color="red" size={100} className="mb-4" />
              <Card.Title>Subscription Expired</Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Text>
                Your organization's subscription has expired. Please contact
                your administrator.
              </Card.Text>
              <Card.Text>
                If you are the administrator, please contact us at{" "}
                <a href="mailto:support@humnce.com">support@humnce.com</a>.
              </Card.Text>
            </Card.Body>
            {contextState.staffPermissions && (
              <Card.Footer className={"py-4"}>
                <Button
                  variant={"outline-primary w-100"}
                  onClick={() =>
                    navigate(`${AppRoutes.Staff.path}?activeTab=organizations`)
                  }
                >
                  Go to Staff Portal
                </Button>
              </Card.Footer>
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SubscriptionExpiredPage;
