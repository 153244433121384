import React, { useEffect, useState } from "react";
import PerformanceFormConfigs from "../FormsConfigs/PerformanceFormConfigs";
import GenericForm from "../Shared/GenericForm";
import {
  isEmpty,
  updateArrayState,
  updateObjectState,
} from "../../../utils/functions";
import { FormFieldTypes } from "../../FormFields";

function ReviewPeriodStepForm({
  readColumns,
  writeColumns,
  onSubmit,
  formData,
  setFormData,
}) {
  const defaultFormConfigs =
    PerformanceFormConfigs.defaultReviewFormStep(readColumns);

  if (formData.isCustom && !formData.id) {
    defaultFormConfigs.push({
      valueKey: "entity_ids",
      type: FormFieldTypes.ENTITY_SELECTION,
      labelName: "Reviewers for This Step",
      alertMsg:
        'Each employee matching the entity selection below will be added as a reviewer for all "Assigned Employees" in this step',
      includeList: ["Positions", "Select Employees"],
      entitySelectionProps: {
        is_single_person: true,
      },
    });
  }
  const [formConfigs, setFormConfigs] = useState(defaultFormConfigs);

  useEffect(() => {
    if (!formData.type && !formData?.validations?.type) {
      return;
    }

    if (formData.type === "Fill Form") {
      let newFormConfigs = formConfigs.filter(
        (config) => config.valueKey !== "write_columns"
      );

      newFormConfigs.push({
        valueKey: "write_columns",
        type: FormFieldTypes.MULTI_STRING_SELECT,
        labelName: "Columns the Reviewer Can Fill",
        optionsList: writeColumns,
        alertMsg: "You can only assign a column to 1 review step per form",
      });
      setFormConfigs(newFormConfigs);
    } else {
      setFormConfigs(defaultFormConfigs);
      let validations = { ...formData.validations };
      if (!isEmpty(validations.write_columns)) {
        delete validations.write_columns;
        updateObjectState(setFormData, { validations });
      }
    }
  }, [formData.type, formData?.validations?.type, writeColumns]);

  useEffect(() => {
    if (formData.require_file_uploads) {
      updateObjectState(setFormData, {
        allow_file_uploads: true,
      });
    }

    if (formData.require_extra_comments) {
      updateObjectState(setFormData, {
        allow_extra_comments: true,
      });
    }
  }, [
    formData.require_file_uploads,
    formData.require_extra_comments,
    formData.allow_file_uploads,
    formData.allow_extra_comments,
  ]);

  useEffect(() => {
    if (!formData.write_columns) return;

    const newWriteColumns = formData.write_columns.filter(
      (column) => !formData.read_columns.includes(column)
    );

    if (newWriteColumns.length > 0) {
      updateObjectState(setFormData, {
        read_columns: [...formData.read_columns, ...newWriteColumns],
      });
    }
  }, [formData.write_columns, formData.read_columns]);

  return (
    <div>
      <GenericForm
        formConfigs={formConfigs}
        stateData={formData}
        setStateData={setFormData}
        useGlobalState={false}
        updateGlobalStateOnSubmit={false}
        onSubmit={(finalData) => {
          onSubmit(finalData);
        }}
      />
    </div>
  );
}

export default ReviewPeriodStepForm;
