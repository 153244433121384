import React, { useEffect, useState } from "react";
import { Button, Card, Row, Table } from "react-bootstrap";
import InfoCard from "../../../utils/components/InfoCard";
import {
  formatMoney,
  updateArrayState,
  updateObjectState,
  useAppModal,
} from "../../../utils/functions";
import { FaCheck, FaEdit, FaPlus } from "react-icons/fa";
import {
  LoadingView,
  NoInfoCard,
  StatusBadge,
} from "../../../utils/components";
import { appConfigs } from "../../../configs";
import CopyWithTooltip from "../Shared/CopyWithTooltip";
import { dateFormats, formatDate } from "../../../utils/dateTime";
import PDFViewer from "../Shared/PDFViewer";

function CashAccountDetails({
  account,
  getRequest,
  onEditClick,
  onFundClick,
  staffManageMode,
}) {
  const { showAppModal, closeModal } = useAppModal();

  const [transactions, setTransactions] = useState(account.transactions || []);
  const [isLoading, setIsLoading] = useState(false);

  const getTransactions = (page) => {
    getRequest(
      `${
        staffManageMode ? "/staff/organization" : "/accounting"
      }/cash-accounts/${account.id}/transactions/${page}`,
      setIsLoading,
      (response) => {
        updateArrayState(setTransactions, response.transactions);
      }
    );
  };

  useEffect(() => {
    if (!account.transactions && transactions.length === 0) {
      getTransactions(1);
    }
  }, []);

  return (
    <LoadingView
      isLoading={isLoading}
      fullHeight={false}
      view={
        <div>
          {onFundClick && (
            <div className={"my-4 d-flex justify-content-end"}>
              <Button variant={"primary"} onClick={onFundClick}>
                <FaPlus className={"me-2"} />
                Fund Account
              </Button>
            </div>
          )}

          <Card>
            <Card.Header className={"py-4"}>
              <div className={"d-flex justify-content-between"}>
                <div>
                  <Card.Title as={"h4"} className={"mb-3"}>
                    {account.name}
                  </Card.Title>
                </div>
                {onEditClick && (
                  <div>
                    <Button variant={"outline-primary"} onClick={onEditClick}>
                      <FaEdit className={"me-2"} />
                      Edit
                    </Button>
                  </div>
                )}
              </div>
              <Card.Subtitle>{account.description}</Card.Subtitle>
            </Card.Header>
            <Card.Body className={"py-4"}>
              <Row>
                <InfoCard
                  header={"Account Balance"}
                  body={`${account.currency} ${formatMoney(account.balance)}`}
                />
                <InfoCard
                  header={"Low Balance Threshold"}
                  body={`${account.currency} ${formatMoney(
                    account.low_balance_threshold
                  )}`}
                />
              </Row>
            </Card.Body>
            <div>
              {transactions.length === 0 && (
                <NoInfoCard
                  text={"This account does not have any transactions yet"}
                />
              )}
              {transactions.length > 0 && (
                <Table striped bordered responsive>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>ID's</th>
                      <th>Description</th>
                      <th>Status</th>
                      <th>Amount</th>
                      <th>Balance</th>
                      <th>File</th>
                      {staffManageMode && <th>Action</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {transactions.map((transaction, index) => {
                      const isPending = transaction.status === "Pending";
                      return (
                        <tr key={index}>
                          <td>
                            {formatDate(
                              transaction.completion_datetime ||
                                transaction.timestamp,
                              dateFormats.daySingleDigitDMY12Hr
                            )}
                          </td>
                          <td>
                            {appConfigs.appName} ID:
                            <br />
                            {transaction.internal_transaction_id}
                            <CopyWithTooltip
                              text={transaction.internal_transaction_id}
                            />
                            {transaction.external_transaction_id && (
                              <p className={"mt-4"}>
                                External ID:
                                <br />
                                {transaction.external_transaction_id}
                                <CopyWithTooltip
                                  text={transaction.external_transaction_id}
                                />
                              </p>
                            )}
                          </td>
                          <td>{transaction.memo}</td>
                          <td>
                            <StatusBadge status={transaction.status} />
                          </td>
                          <td className={"text-end"}>
                            {formatMoney(transaction.amount)}
                          </td>
                          <td className={"text-end"}>
                            {formatMoney(transaction.balance)}
                          </td>
                          <td>
                            {transaction.proof_file_url && (
                              <PDFViewer pdfUrl={transaction.proof_file_url} />
                            )}
                          </td>
                          {staffManageMode && (
                            <td>
                              {isPending && (
                                <Button
                                  variant={"success"}
                                  size={"sm"}
                                  onClick={() => {
                                    showAppModal({
                                      title: "Approve Transaction",
                                      component: (
                                        <p>
                                          Are you sure you want to approve this
                                          transaction?
                                        </p>
                                      ),
                                      truthyFunction: () => {
                                        getRequest(
                                          `/staff/organization/transactions/${transaction.id}/approve`,
                                          setIsLoading,
                                          (response) => {
                                            closeModal();
                                            setTransactions([
                                              response.transaction,
                                              ...transactions.filter(
                                                (t) => t.id !== transaction.id
                                              ),
                                            ]);
                                          },
                                          true
                                        );
                                      },
                                    });
                                  }}
                                >
                                  <FaCheck className={"me-2"} />
                                  Approve
                                </Button>
                              )}
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
            </div>
          </Card>
        </div>
      }
    />
  );
}

export default CashAccountDetails;
