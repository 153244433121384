import React, { useContext } from "react";
import { AddItemButton, ConditionalView } from "../../../utils/components";
import { getUTCDateTime } from "../../../utils/dateTime";
import ScheduleForm from "../../Forms/People/ScheduleForm";
import { Card, Tab, Tabs } from "react-bootstrap";
import { FaBullhorn } from "react-icons/fa";
import Announcement from "../Shared/Announcement";
import ShiftForm from "../../Forms/People/ShiftForm";
import { useOffCanvas } from "../../../utils/functions";
import { AppContext } from "../../../utils/components/AppContext";
import AnnouncementForm from "../../Forms/People/AnnouncementForm";

function ManageAnnouncements({
  announcements,
  setAnnouncements,
  putRequest,
  patchRequest,
  permissions,
  loaderCallback,
}) {
  const { showOffCanvas } = useOffCanvas();
  const { updateContextState } = useContext(AppContext);

  // reverse announcements keys
  announcements = Object.keys(announcements)
    .reverse()
    .reduce((acc, key) => {
      acc[key] = announcements[key];
      return acc;
    }, {});

  return (
    <div>
      {permissions.MANAGE_ANNOUNCEMENTS && (
        <div className={"mb-4"}>
          <AddItemButton
            title={"New Announcement"}
            onClick={() => {
              updateContextState({
                formData: {},
              });
              showOffCanvas({
                title: `Add an Announcement`,
                subtitle: `Add a new announcement for your organization`,
                component: (
                  <AnnouncementForm
                    onSubmit={(updatedData) => {
                      putRequest(
                        "/people/announcements",
                        loaderCallback,
                        (response) => {
                          setAnnouncements(response.announcements);
                        },
                        updatedData
                      );
                    }}
                  />
                ),
              });
            }}
          />
        </div>
      )}

      <Card>
        <Card.Header className={"py-4"}>
          <Card.Title>Announcements</Card.Title>
          <Card.Subtitle as={"p"} className={"mt-3"}>
            Announcements help you communicate with your employees. You can add.
            These announcements will be visible to all employees on their
            dashboard during the dates you specify.
          </Card.Subtitle>
        </Card.Header>
        <Card.Body>
          <Tabs variant={"underline"}>
            {Object.keys(announcements).map((tab, index) => {
              return (
                <Tab title={tab} eventKey={tab} key={index} className={"pt-4"}>
                  <ConditionalView
                    condition={announcements[tab].length > 0}
                    trueView={
                      <div>
                        {announcements[tab].map((announcement, i) => {
                          return (
                            <div key={i} className={"mb-5"}>
                              <Announcement
                                announcement={announcement}
                                canEdit={() => {
                                  updateContextState({
                                    formData: announcement,
                                  });

                                  showOffCanvas({
                                    title: `Edit Announcement`,
                                    subtitle: `Edit announcement for your organization`,
                                    component: (
                                      <AnnouncementForm
                                        onSubmit={(updatedData) => {
                                          patchRequest(
                                            `/people/announcements`,
                                            loaderCallback,
                                            (response) => {
                                              setAnnouncements(
                                                response.announcements
                                              );
                                            },
                                            updatedData
                                          );
                                        }}
                                      />
                                    ),
                                  });
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    }
                    falseView={
                      <div className={"my-auto py-5"}>
                        <FaBullhorn className={"mx-auto d-block"} size={60} />
                        <p className={"text-center mt-3"}>
                          There are no announcements
                        </p>
                      </div>
                    }
                  />
                </Tab>
              );
            })}
          </Tabs>
        </Card.Body>
      </Card>
    </div>
  );
}

export default ManageAnnouncements;
