import React, { useContext, useState } from "react";
import { Form, Button, Card, Alert } from "react-bootstrap";
import { isEmpty } from "../../utils/functions";
import { AppContext } from "../../utils/components/AppContext";
import { formatDateStrings } from "../../utils/dateTime";

export function PositionsForm({
  onSubmit,
  state,
  levels,
  branches,
  departments,
}) {
  const { contextState, updateContextState } = useContext(AppContext);
  const [formState, setFormState] = useState(
    formatDateStrings({ ...contextState.formData })
  );

  const { labelsOverride } = contextState;

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateContextState({
      formData: formState,
    });
    onSubmit(formState);
  };

  const formValid = () => {
    const requiredFields = [
      "level_id",
      "branch_id",
      "department_id",
      "title",
      "description",
    ];
    let valid = true;
    for (const field of requiredFields) {
      if (isEmpty(formState[field])) {
        valid = false;
        break;
      }
    }
    return valid;
  };

  const selectedBranch = branches.find(
    (branch) => branch.id === formState.branch_id
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="positionTitle" className={"mb-4"}>
        <Form.Label>Title *</Form.Label>
        <Form.Control
          type="text"
          name="title"
          required
          value={formState.title || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="positionDescription" className={"mb-4"}>
        <Form.Label>Description *</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          name="description"
          value={formState.description || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="positionLevelId" className={"mb-4"}>
        <Form.Label>Level *</Form.Label>
        <Form.Select
          name="level_id"
          required
          value={formState.level_id || ""}
          onChange={handleInputChange}
        >
          <option value="">Select a level</option>
          {levels.map((level, index) => {
            return (
              <option key={level.id} value={level.id}>
                {level.parent_id ? "-> " : ""}
                {level.name}
              </option>
            );
          })}
        </Form.Select>
      </Form.Group>

      <Form.Group controlId="positionBranchId" className={"mb-4"}>
        <Form.Label>Branch *</Form.Label>
        <Form.Select
          name="branch_id"
          required
          value={formState.branch_id || ""}
          onChange={handleInputChange}
        >
          <option value="">Select a branch</option>
          {branches.map((branch) => (
            <option key={branch.id} value={branch.id}>
              {branch.name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      {selectedBranch &&
        selectedBranch.locations &&
        selectedBranch.locations.length > 0 && (
          <Form.Group controlId="positionBranchLocationId" className={"mb-4"}>
            <Form.Label>Branch Location (Optional)</Form.Label>
            <Form.Select
              name="branch_location_id"
              value={formState.branch_location_id || ""}
              onChange={handleInputChange}
            >
              <option value="">Select a branch location</option>
              {selectedBranch.locations.map((location) => (
                <option key={location.id} value={location.id}>
                  {location.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        )}

      <Form.Group controlId="positionDepartmentId" className={"mb-4"}>
        <Form.Label>{labelsOverride.department || "Department"} *</Form.Label>
        <Form.Select
          name="department_id"
          required
          value={formState.department_id || ""}
          onChange={handleInputChange}
        >
          <option value="">
            Select a {labelsOverride.department || "Department"}
          </option>
          {departments.map((department) => (
            <option key={department.id} value={department.id}>
              {department.name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      <Card body={true} className="mb-4">
        <Form.Group
          controlId="isActive"
          className={"d-flex justify-content-between align-items-center"}
        >
          <Form.Label>
            Head of {labelsOverride.department || "Department"}
          </Form.Label>
          <Form.Switch
            name="is_department_head"
            checked={formState.is_department_head || false}
            onChange={(e) => {
              handleInputChange({
                target: {
                  name: "is_department_head",
                  value: e.target.checked,
                },
              });
              handleInputChange({
                target: {
                  name: "for_single_person",
                  value: e.target.checked ? true : formState.for_single_person,
                },
              });
            }}
          />
        </Form.Group>
        <Form.Text>
          If this position is the head of the{" "}
          {labelsOverride.department || "Department"} selected above check this
          box. Any other position that is marked as the head of the{" "}
          {labelsOverride?.department?.toLowerCase() || "department"} will be
          unmarked. This will also set this position as a single person
          position.
        </Form.Text>
      </Card>

      <Card body={true} className="mb-4">
        <Form.Group
          controlId="isActive"
          className={"d-flex justify-content-between align-items-center"}
        >
          <Form.Label>Head of Organization</Form.Label>
          <Form.Switch
            name="is_organization_head"
            checked={formState.is_organization_head || false}
            onChange={(e) => {
              handleInputChange({
                target: {
                  name: "is_organization_head",
                  value: e.target.checked,
                },
              });
              handleInputChange({
                target: {
                  name: "for_single_person",
                  value: e.target.checked ? true : formState.for_single_person,
                },
              });
            }}
          />
        </Form.Group>
        <Form.Text>
          If this position is the head of the organization, check this box. Any
          other position that is marked as the head of the organization will be
          unmarked. This will also set this position as single person position.
        </Form.Text>
      </Card>

      <Card body={true} className="mb-4">
        <Form.Group
          controlId="isActive"
          className={"d-flex justify-content-between align-items-center"}
        >
          <Form.Label>Single Person Position</Form.Label>
          <Form.Switch
            name="for_single_person"
            checked={formState.for_single_person || false}
            onChange={(e) => {
              if (
                formState.is_department_head ||
                formState.is_organization_head
              ) {
                return;
              }
              handleInputChange({
                target: {
                  name: "for_single_person",
                  value: e.target.checked,
                },
              });
            }}
          />
        </Form.Group>
        <Form.Text>
          If this position is for a single person, check this box. This will
          prevent multiple people from being assigned to this position.
        </Form.Text>
        {(formState.is_organization_head || formState.is_department_head) && (
          <Alert variant={"warning"} className={"small mt-3"}>
            Cannot be changed because this position is the head of the{" "}
            {labelsOverride.department || "Department"} or Organization
          </Alert>
        )}
      </Card>

      <Card body={true} className="mb-4">
        <Form.Group
          controlId="isActive"
          className={"d-flex justify-content-between align-items-center"}
        >
          <Form.Label>Active</Form.Label>
          <Form.Switch
            name="is_active"
            checked={formState.is_active || false}
            onChange={(e) =>
              handleInputChange({
                target: { name: "is_active", value: e.target.checked },
              })
            }
          />
        </Form.Group>
      </Card>

      <Button
        variant="primary"
        type="submit"
        disabled={!formValid()}
        className={"w-100"}
      >
        Save
      </Button>
    </Form>
  );
}
