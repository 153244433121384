import React, { useContext } from "react";
import { Col, Image } from "react-bootstrap";
import whiteColorOutlineLogo from "../../assets/logos/white-color-outline.png";
import { AppConstants } from "../../utils/appConstants";
import { AppContext } from "../../utils/components/AppContext";

function Brand({
  nameColor = "text-white",
  nameSize = "fs-6",
  imageWidth = 50,
  marginStart = "ms-0",
}) {
  const { contextState, updateContextState } = useContext(AppContext);

  const organization = contextState.organization || {};

  return (
    <div className="d-flex align-items-center ms-3">
      <Image
        src={organization.logo}
        alt={`${organization.name} logo`}
        width={imageWidth}
        height={imageWidth}
      />
      <div className="col-10">
        <p
          className={`${marginStart} mb-0 ms-3 ${nameColor} ${nameSize} text-wrap text-truncates`}
        >
          {organization.name}
        </p>
      </div>
    </div>
  );
}

export default Brand;
