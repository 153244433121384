import React from "react";
import { appConfigs } from "../../../configs";
import { AppRoutes } from "../../../utils/appRoutes";
import { useNavigate } from "react-router-dom";

function LogoWithText({ text, navEndpoint }) {
  const navigate = useNavigate();
  return (
    <div className={"py-4"}>
      <img
        src={appConfigs.primaryLogo}
        alt="Company Logo"
        style={{ width: 80, height: "auto" }}
        onClick={() => {
          navigate(navEndpoint || AppRoutes.Index.path);
        }}
        className={"pointer"}
      />
      {text && <h5 className={"my-3"}>{text}</h5>}
    </div>
  );
}

export default LogoWithText;
