export const industries = {
  Agriculture: ["Dairy", "Farming", "Fishery", "Ranching"],
  Arts: ["Arts & Crafts", "Fine Art", "Performing Arts", "Photography"],
  Construction: ["Building Materials", "Civil Engineering", "Construction"],
  "Consumer Goods": [
    "Apparel & Fashion",
    "Consumer Electronics",
    "Consumer Goods",
    "Consumer Services",
    "Cosmetics",
    "Food & Beverages",
    "Furniture",
    "Luxury Goods & Jewelry",
    "Sporting Goods",
    "Tobacco",
    "Wine & Spirits",
  ],
  "Corporate Services": [
    "Accounting",
    "Business Supplies & Equipment",
    "Environmental Services",
    "Events Services",
    "Executive Office",
    "Facilities Services",
    "Human Resources",
    "Information Services",
    "Management Consulting",
    "Outsourcing/Offshoring",
    "Professional Training & Coaching",
    "Security & Investigations",
    "Staffing & Recruiting",
  ],
  Design: ["Architecture & Planning", "Design", "Graphic Design"],
  Education: [
    "E-learning",
    "Education Management",
    "Higher Education",
    "Primary/Secondary Education",
    "Research",
  ],
  "Energy & Mining": ["Mining & Metals", "Oil & Energy", "Utilities"],
  Entertainment: [
    "Animation",
    "Broadcast Media",
    "Computer Games",
    "Entertainment",
    "Media Production",
    "Mobile Games",
    "Motion Pictures & Film",
    "Music",
  ],
  Finance: [
    "Banking",
    "Capital Markets",
    "Financial Services",
    "Insurance",
    "Investment Banking",
    "Investment Management",
    "Venture Capital & Private Equity",
  ],
  "Hardware & Networking": [
    "Computer Hardware",
    "Computer Networking",
    "Nanotechnology",
    "Semiconductors",
    "Telecommunications",
    "Wireless",
  ],
  "Health Care": [
    "Biotechnology",
    "Hospital & Health Care",
    "Medical Device",
    "Medical Practice",
    "Mental Health Care",
    "Pharmaceuticals",
    "Veterinary",
  ],
  Legal: ["Alternative Dispute Resolution", "Law Practice", "Legal Service"],
  Manufacturing: [
    "Automotive",
    "Aviation & Aerospace",
    "Chemicals",
    "Defense & Space",
    "Electrical & Electronic Manufacturing",
    "Food Production",
    "Glass, Ceramics & Concrete",
    "Industrial Automation",
    "Machinery",
    "Mechanical Or Industrial Engineering",
    "Packaging & Containers",
    "Paper & Forest Products",
    "Plastics",
    "Railroad Manufacture",
    "Renewables & Environment",
    "Shipbuilding",
    "Textiles",
  ],
  "Media & Communications": [
    "Market Research",
    "Marketing & Advertising",
    "Newspapers",
    "Online Media",
    "Printing",
    "Public Relations & Communications",
    "Publishing",
    "Translation & Localization",
    "Writing & Editing",
  ],
  "Non-profit": [
    "Civic & Social Organization",
    "Fundraising",
    "Individual & Family Services",
    "International Trade & Development",
    "Libraries",
    "Museums & Institutions",
    "Non-profit Organization Management",
    "Philanthropy",
    "Program Development",
    "Religious Institutions",
    "Think Tanks",
  ],
  "Public Administration": [
    "Government Administration",
    "Government Relations",
    "International Affairs",
    "Judiciary",
    "Legislative Office",
    "Political Organization",
    "Public Policy",
  ],
  "Public Safety": ["Law Enforcement", "Military"],
  "Real Estate": ["Commercial Real Estate", "Private Real Estate"],
  "Recreation & Travel": [
    "Airlines/Aviation",
    "Gambling & Casinos",
    "Hospitality",
    "Leisure, Travel & Tourism",
    "Recreational Facilities & Services",
    "Restaurants",
    "Sports",
  ],
  Retail: ["Retail", "Supermarkets", "Wholesale"],
  "Software & IT Services": [
    "Computer & Network Security",
    "Computer Software",
    "Information Technology & Services",
    "Internet",
  ],
  "Transportation & Logistics": [
    "Import & Export",
    "Logistics & Supply Chain",
    "Maritime",
    "Package/Freight Delivery",
    "Transportation/Trucking/Railroad",
    "Warehousing",
  ],
  "Wellness & Fitness": ["Alternative Medicine", "Health, Wellness & Fitness"],
};
