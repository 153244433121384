import React from "react";
import { Card } from "react-bootstrap";
import { formatDate } from "../../../utils/dateTime";

function HolidayCard({ holiday, policy }) {
  return (
    <div>
      <Card>
        <Card.Header>
          <Card.Title>{policy?.name}</Card.Title>
        </Card.Header>
        <Card.Body className={"py-4"}>
          <Card.Text>
            From: {formatDate(holiday.start_date)}
            <br />
            To:{formatDate(holiday.end_date)}
          </Card.Text>
        </Card.Body>
        <Card.Footer>
          <Card.Text>{policy?.description}</Card.Text>
        </Card.Footer>
      </Card>
    </div>
  );
}

export default HolidayCard;
