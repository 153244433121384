import React, { useContext } from "react";
import { AddItemButton } from "../../../utils/components";
import DeductionsForm from "../../Forms/Payroll/DeductionsForm";
import { Alert, Card } from "react-bootstrap";
import DeductionsCards from "./DeductionsCards";
import { useOffCanvas } from "../../../utils/functions";
import { AppContext } from "../../../utils/components/AppContext";

function PayrollDeductions({
  deductions,
  setDeductions,
  configs,
  permissions,
  putRequest,
  patchRequest,
  loaderCallback,
}) {
  const { showOffCanvas } = useOffCanvas();
  const { updateContextState } = useContext(AppContext);

  return (
    <div>
      {permissions.MANAGE_PAYROLL && (
        <div className={"mb-4"}>
          <AddItemButton
            title={"New Tax / Deduction"}
            onClick={() => {
              updateContextState({
                formData: {
                  is_active: true,
                  organization_pays: false,
                },
              });
              showOffCanvas({
                title: `New Tax / Deduction`,
                subtitle: `Add a new tax or deduction that wil applly to all employees pay.`,
                component: (
                  <DeductionsForm
                    onSubmit={(updateData) => {
                      putRequest(
                        "/payroll/deduction",
                        loaderCallback,
                        (response) => {
                          setDeductions([response.deduction, ...deductions]);
                        },
                        updateData
                      );
                    }}
                    configs={configs}
                  />
                ),
              });
            }}
          />
        </div>
      )}
      <Card className={"mb-5"}>
        <Card.Header className={"py-4"}>
          <Card.Title>Taxes and Deductions</Card.Title>
          <Card.Subtitle>
            Set taxes and deductions that will be applied to employees'
            paychecks.
          </Card.Subtitle>
        </Card.Header>
      </Card>
      <DeductionsCards
        data={deductions}
        canManage={permissions.MANAGE_PAYROLL}
        onEdit={(deduction, index) => {
          updateContextState({
            formData: { ...deduction },
          });
          showOffCanvas({
            title: `Update Tax / Deduction`,
            subtitle: `Update ${deduction.name} that wil applly to all employees pay.`,
            component: (
              <DeductionsForm
                onSubmit={(updateData) => {
                  patchRequest(
                    `/payroll/deduction/${deduction.id}`,
                    loaderCallback,
                    (response) => {
                      const currentDeductions = [...deductions];
                      currentDeductions[index] = response.deduction;
                      setDeductions(currentDeductions);
                    },
                    updateData
                  );
                }}
                configs={configs}
              />
            ),
          });
        }}
      />
    </div>
  );
}

export default PayrollDeductions;
