import React, { useContext, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { AppContext } from "../../../utils/components/AppContext";
import { formatDateStrings } from "../../../utils/dateTime";

function PaymentAccountForm({ onSubmit, configs }) {
  const { contextState, updateContextState } = useContext(AppContext);
  const [formState, setFormState] = useState(
    formatDateStrings({ ...contextState.formData })
  );

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const formValid = () => {
    const requiredFields = ["name", "type", "number", "currency"];
    return requiredFields.every((field) => formState[field]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateContextState({
      formData: { ...formState },
    });
    onSubmit(formState);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Text as={"p"} className={"fw-bold fs-6"}>
        Required fields are marked with an{" "}
        <span className="text-danger">*</span>
      </Form.Text>
      <Form.Group controlId="name" className={"mb-4"}>
        <Form.Label>
          Name<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          name="name"
          value={formState.name || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="type" className={"mb-4"}>
        <Form.Label>
          Type<span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          name="type"
          value={formState.type || ""}
          onChange={handleInputChange}
        >
          <option value="">Select a Type</option>
          {configs.employee_payment_account_types?.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      <Form.Group controlId="number" className={"mb-4"}>
        <Form.Label>
          Number<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          name="number"
          value={formState.number || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="currency" className={"mb-4"}>
        <Form.Label>
          Currency<span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          name="currency"
          value={formState.currency || ""}
          onChange={handleInputChange}
        >
          <option value="">Select a Currency</option>
          {configs.currencies?.map((currency) => (
            <option key={currency} value={currency}>
              {currency}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      {/* Optional fields */}
      <Form.Group controlId="routingNumber" className={"mb-4"}>
        <Form.Label>Routing Number</Form.Label>
        <Form.Control
          type="text"
          name="routing_number"
          value={formState.routing_number || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="swiftCode" className={"mb-4"}>
        <Form.Label>SWIFT Code</Form.Label>
        <Form.Control
          type="text"
          name="swift_code"
          value={formState.swift_code || ""}
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group controlId="ibanNumber" className={"mb-4"}>
        <Form.Label>IBAN Number</Form.Label>
        <Form.Control
          type="text"
          name="iban_number"
          value={formState.iban_number || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="bankName" className={"mb-4"}>
        <Form.Label>Bank Name</Form.Label>
        <Form.Control
          type="text"
          name="bank_name"
          value={formState.bank_name || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="bankAddress" className={"mb-4"}>
        <Form.Label>Bank Address</Form.Label>
        <Form.Control
          type="text"
          name="bank_address"
          value={formState.bank_address || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="isActive" className={"mb-4"}>
        <Form.Switch
          type="checkbox"
          label="Is Active"
          name="is_active"
          checked={formState.is_active || false}
          onChange={(e) =>
            handleInputChange({
              target: { name: "is_active", value: e.target.checked },
            })
          }
        />
        <Form.Text>
          Note: Only one account can be active per currency. Activating an
          account here will deactivate all other accounts.
        </Form.Text>
      </Form.Group>

      <Button
        variant="primary"
        type="submit"
        className={"w-100 mb-5"}
        disabled={!formValid()}
      >
        Save
      </Button>
    </Form>
  );
}

export default PaymentAccountForm;
