import React, { useContext, useEffect, useState } from "react";
import {
  LoadingView,
  StatusBadge,
  TextWithLineBreaks,
} from "../utils/components";
import DashboardPageContainer from "./components/DashboardPageContainer";
import {
  updateObjectState,
  useAppModal,
  useOffCanvas,
} from "../utils/functions";
import { AppContext } from "../utils/components/AppContext";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Alert, Breadcrumb, Button, Card, Tab, Tabs } from "react-bootstrap";
import EmployeeBasicHighlight from "./components/Shared/EmployeeBasicHighlight";
import { dateFormats, formatDate } from "../utils/dateTime";
import { AppRoutes } from "../utils/appRoutes";
import GenericForm from "./Forms/Shared/GenericForm";
import PerformanceFormConfigs from "./Forms/FormsConfigs/PerformanceFormConfigs";
import { FaEdit } from "react-icons/fa";
import ReviewPeriodForms from "./components/Performance/ReviewPeriodForms";
import PaginatedEmployeesDisplay from "./components/Employee/PaginatedEmployeesDisplay";
import { AppBarChart } from "../utils/components/AppCharts";
import { FaTrashCan } from "react-icons/fa6";
import OrgPerformanceStats from "./components/Performance/OrgPerformanceStats";

function PerformanceReviewPeriodDetails({
  getRequest,
  postRequest,
  putRequest,
  patchRequest,
  deleteRequest,
}) {
  const { showOffCanvas } = useOffCanvas();

  const { contextState, updateContextState } = useContext(AppContext);
  const { permissions } = contextState;

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const updateQueryParam = (key, value) => {
    setSearchParams({ ...searchParams, [key]: value });
  };

  const [actionButton, setActionButton] = useState({
    next_status: "",
    button_variant: "",
    button_text: "",
  });
  const [activeTab, setActiveTab] = useState(searchParams.get("activeTab"));
  const [isLoading, setIsLoading] = useState(false);
  const [configs, setConfigs] = useState({});
  const [periodForms, setPeriodForms] = useState([]);
  const [reviewPeriod, setReviewPeriod] = useState({});
  const { showAppModal, closeModal } = useAppModal();

  const { reviewPeriodId } = useParams();

  const activateTab = (key) => {
    setActiveTab(key);
    updateQueryParam("activeTab", key);
  };

  useEffect(() => {
    activateTab(searchParams.get("activeTab") || "overview");
  }, [searchParams]);

  useEffect(() => {
    getRequest(
      `/performance/review-period/${reviewPeriodId}`,
      setIsLoading,
      (response) => {
        setReviewPeriod(response.review_period);
        setConfigs(response.configs);
        setPeriodForms(response.period_forms);
      }
    );
  }, []);

  useEffect(() => {
    const status = reviewPeriod.status;
    if (status === "Created") {
      setActionButton({
        next_status: "In Progress",
        button_variant: "success",
        button_text: "Start Review Period",
      });
    } else if (status === "In Progress") {
      setActionButton({
        next_status: "Paused",
        button_variant: "outline-danger",
        button_text: "Pause Review Period",
      });
    } else if (status === "Paused") {
      setActionButton({
        next_status: "In Progress",
        button_variant: "success",
        button_text: "Resume Review Period",
      });
    }
  }, [reviewPeriod.status]);

  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <div className={"mb-5"}>
          <Breadcrumb>
            <Breadcrumb.Item
              href="#"
              onClick={(e) => {
                e.preventDefault();
                navigate(
                  `${AppRoutes.Performance.path}?activeTab=review_periods`
                );
              }}
            >
              Review Periods
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              {reviewPeriod.name || "Review Period"}
            </Breadcrumb.Item>
          </Breadcrumb>

          <Card className={"mb-4"}>
            <Card.Header className={"py-4"}>
              <div className={"d-flex justify-content-between"}>
                <div>
                  <Card.Title>{reviewPeriod.name}</Card.Title>
                </div>
                <div className={"ms-4"}>
                  <Button
                    variant={"outline-primary"}
                    onClick={() => {
                      updateContextState({
                        formData: { ...reviewPeriod },
                      });
                      showOffCanvas({
                        title: `Edit Review Period`,
                        subtitle: `Edit the Review Period details`,
                        component: (
                          <>
                            <GenericForm
                              formConfigs={PerformanceFormConfigs.reviewPeriod}
                              onSubmit={(formData) => {
                                patchRequest(
                                  `/performance/review-period/${reviewPeriodId}`,
                                  setIsLoading,
                                  (response) => {
                                    setReviewPeriod(response.review_period);
                                  },
                                  formData
                                );
                              }}
                            />
                          </>
                        ),
                      });
                    }}
                  >
                    <FaEdit className={"me-2"} />
                    Edit
                  </Button>
                  {reviewPeriod.can_delete && (
                    <Button
                      variant={"outline-danger"}
                      className={"ms-2"}
                      onClick={() => {
                        showAppModal({
                          title: `Delete "${reviewPeriod.name}"?`,
                          component: (
                            <div>
                              <p>Are you sure you want to proceed?</p>
                            </div>
                          ),
                          truthyFunction: () => {
                            deleteRequest(
                              `/performance/review-period/${reviewPeriodId}`,
                              setIsLoading,
                              () => {
                                navigate(
                                  `${AppRoutes.Performance.path}?activeTab=review_periods`
                                );
                              }
                            );
                          },
                        });
                      }}
                    >
                      <FaTrashCan />
                    </Button>
                  )}
                </div>
              </div>
            </Card.Header>
            <Card.Body className={"py-3"}>
              <Card.Subtitle as={"p"} className={"mt-3"}>
                <TextWithLineBreaks text={reviewPeriod.description} />
              </Card.Subtitle>
            </Card.Body>
            <Card.Footer className={"py-3 d-flex justify-content-between"}>
              <div>
                <h6 className={"text-muted mb-2"}>
                  Created on {formatDate(reviewPeriod.timestamp)}, by:
                </h6>
                <EmployeeBasicHighlight
                  employee={reviewPeriod.author_employee || {}}
                />
              </div>
              <div className={"justify-content-end align-items-end"}>
                <h5 className={"text-end"}>
                  Status: <StatusBadge status={reviewPeriod.status} />
                </h5>
                {reviewPeriod.can_activate &&
                  permissions.MANAGE_PERFORMANCE_REVIEWS && (
                    <div className={"align-middle justify-content-center"}>
                      <Button
                        className={"mt-2"}
                        variant={
                          actionButton.button_variant || "outline-primary"
                        }
                        onClick={() => {
                          showAppModal({
                            title: `${actionButton.button_text} "${reviewPeriod.name}"?`,
                            component: (
                              <div>
                                <p>Are you sure you want to proceed?</p>
                              </div>
                            ),
                            truthyFunction: () => {
                              patchRequest(
                                `/performance/review-period/${reviewPeriodId}/status`,
                                setIsLoading,
                                (response) => {
                                  updateObjectState(
                                    setReviewPeriod,
                                    response.review_period
                                  );
                                },
                                {
                                  status: actionButton.next_status,
                                },
                                false,
                                false
                              );
                            },
                          });
                        }}
                      >
                        {actionButton.button_text || "Activate Review Period"}
                      </Button>
                    </div>
                  )}
              </div>
            </Card.Footer>
          </Card>

          <Tabs
            activeKey={activeTab}
            className={"mb-2 pb-3"}
            variant={"underline"}
            onSelect={(key) => activateTab(key)}
          >
            <Tab eventKey="overview" title="Overview">
              <LoadingView
                isLoading={false}
                view={
                  <div className={"mt-4"}>
                    <Card body={true} className={"mb-2"}>
                      Below are the totals and averages of your "Number" columns
                      for your entire organization and individual entities in
                      this review period. This combines all the forms columns
                      with the same title, min and max values for all the forms
                      in this period
                    </Card>
                    <Alert variant={"info"}>
                      Only employees that have been assigned to forms for review
                      will be included in the stats.
                    </Alert>
                    {reviewPeriod.id && (
                      <OrgPerformanceStats
                        getRequest={getRequest}
                        endpointPrefix={`/performance/period-stats/${reviewPeriod.id}`}
                      />
                    )}
                  </div>
                }
              />
            </Tab>
            <Tab eventKey="forms" title="Forms">
              <div>
                <ReviewPeriodForms
                  reviewPeriod={reviewPeriod}
                  configs={configs}
                  periodForms={periodForms}
                  setPeriodForms={setPeriodForms}
                  permissions={permissions}
                  putRequest={putRequest}
                  deleteRequest={deleteRequest}
                />
              </div>
            </Tab>
            <Tab eventKey="activities" title="Activities">
              <LoadingView
                isLoading={false}
                view={
                  <>
                    <PaginatedEmployeesDisplay
                      endpoint={`/performance/review-period/${reviewPeriodId}/activities`}
                      getRequest={getRequest}
                      minimal={true}
                      extraColumns={[
                        {
                          columnName: "Activity Description",
                          key: "description",
                        },
                        {
                          columnName: "Time",
                          renderComponent: (employee) => (
                            <span>
                              {formatDate(
                                employee.time,
                                dateFormats.daySingleDigitDMY12Hr
                              )}
                            </span>
                          ),
                        },
                      ]}
                    />
                  </>
                }
              />
            </Tab>
          </Tabs>
        </div>
      }
    />
  );
}

function PerformanceReviewPeriodDetailsPage() {
  return (
    <DashboardPageContainer PageComponent={PerformanceReviewPeriodDetails} />
  );
}

export default PerformanceReviewPeriodDetailsPage;
