import { useContext } from "react";
import { ToastContext } from "./components/ToastContext";
import { OffCanvasContext } from "./components/OffCanvasContext";
import { ModalContext } from "./components/ModalContext";
import { ModalContext2 } from "./components/ModalContext2";
import { appConfigs } from "../configs";

export const useToast = () => {
  const { addToast } = useContext(ToastContext);

  const showToast = ({ title, message, success = false }) => {
    addToast({
      id:
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15),
      title,
      message,
      success,
      show: true,
    });
  };

  return { showToast };
};

export const useOffCanvas = () => {
  const { openOffCanvas } = useContext(OffCanvasContext);

  const showOffCanvas = ({ title, subtitle, component }) => {
    openOffCanvas({ title, subtitle, component });
  };
  return { showOffCanvas };
};

export const useAppModal = () => {
  const { openModal, closeModal } = useContext(ModalContext);

  const showAppModal = ({
    title,
    component,
    truthyFunction,
    falsyFunction,
    truthyText,
    falsyText,
    hideFooter,
    size = "lg", // sm, lg, xl
    fullScreen = false,
    onClose,
  }) => {
    openModal({
      title,
      component,
      truthyFunction,
      falsyFunction,
      truthyText,
      falsyText,
      hideFooter,
      size,
      fullScreen,
      onClose,
    });
  };
  return { showAppModal, closeModal };
};

export const useAppModal2 = () => {
  const { openModal, closeModal } = useContext(ModalContext);

  const showAppModal2 = ({
    titleComponent,
    subTitleComponent,
    truthyFunction1, // First truthy function
    truthyFunction2, // Second truthy function
    falsyFunction, // Default falsy function closes modal
    truthyText1, // Text for first truthy button
    truthyText2, // Text for second truthy button
    falsyText, // Text for the falsy button
    hideFooter = true,
    size = "lg",
    fullScreen = false,
    onClose
  }) => {
    openModal({
      title: titleComponent, // No title text, we're using titleComponent instead
      component: (
        <div>
          {subTitleComponent}
          {/* Display the provided title component */}
          {/* Footer with three buttons */}
          {hideFooter && (
            <div className="modal-footer row g-0">
            <div className="col">
              <button className="btn p-2 btn-primary w-100" onClick={truthyFunction1}>
                {truthyText1}
              </button>
            </div>
            <div className="col">
              <button className="btn p-2 btn-danger w-100" onClick={truthyFunction2}>
                {truthyText2}
              </button>
            </div>
            <div className="col">
              <button className="btn p-2 btn-secondary w-100" onClick={falsyFunction}>
                {falsyText}
              </button>
            </div>
          </div>

          )}
        </div>
      ),
      truthyFunction1,
      truthyFunction2,
      falsyFunction,
      truthyText1,
      truthyText2,
      falsyText,
      hideFooter,
      size,
      fullScreen,
      onClose
    });
  };

  return { showAppModal2, closeModal };
};

export const updateObjectState = (state, updateValues) => {
  state((prevState) => ({
    ...prevState,
    ...updateValues,
  }));
};

export const updateArrayState = (state, vals) => {
  state((prevState) => [...prevState, ...vals]);
};

export const isEmpty = (item) => {
  // check if item is an array or an object
  if (item && Array.isArray(item)) {
    return item.length === 0;
  }
  const emptyItems = [``, " ", null, undefined];
  return emptyItems.includes(item);
};

export const formatMoney = (val) => {
  // Convert to a number and ensure it has exactly two decimal places
  if (typeof val !== "string") {
    val = parseFloat(val).toFixed(2);
  } else {
    val = parseFloat(val.replace(/,/g, "")).toFixed(2); // Remove commas if present
  }

  // Split into whole and decimal parts
  const [wholePart, decimalPart] = val.split(".");

  // Format the whole part with commas
  const formattedWholePart = parseInt(wholePart, 10).toLocaleString("en-US");

  // Return combined formatted value with exactly two decimals
  return `${formattedWholePart}.${decimalPart}`;
};

export const globalReplaceComma = (val, replacer = "") => {
  return val.replace(/,/g, replacer);
};

export const globalReplace = (str, valToReplace, replacerVal) => {
  return str.replace(new RegExp(valToReplace, "g"), replacerVal);
};

export const strToFloat = (val) => {
  val = globalReplaceComma(val);
  return parseFloat(val);
};

export function extractItemsFromObject(objectsArray, key, filterFunction) {
  if (filterFunction) {
    return objectsArray.filter(filterFunction).map((obj) => obj[key]);
  }
  return objectsArray.map((obj) => obj[key]);
}

export function updateStateAtIndex(state, setState, updatedItem, index) {
  const items = [...state];
  items[index] = updatedItem;
  setState(items);
}

export const scrollToItem = (itemId, yscroll = 0) => {
  if (!itemId) return;
  const itemElement = document.getElementById(`${itemId}`);
  if (itemElement) {
    itemElement.scrollIntoView({ behavior: "smooth" });
    setTimeout(() => {
      window.scrollBy(0, yscroll);
    }, 1000);
  }
};
export function getObjectFromListByKey(list, key, value) {
  return list.find((item) => item[key] === value) || null;
}

export function timeDifference(time1, time2, callback) {
  if (!time1 && !time2) return "0h 0m 0s";

  // Parse the time strings into Date objects
  const date1 = new Date(time1 + "Z");
  const date2 = time2 ? new Date(time2 + "Z") : new Date(Date.now());

  // Calculate the difference in milliseconds
  let diff = Math.abs(date2 - date1);

  const updateCallback = (diff) => {
    const hours = Math.floor(diff / 1000 / 60 / 60);
    const minutes = Math.floor(diff / 1000 / 60) % 60;
    const seconds = Math.floor(diff / 1000) % 60;
    callback(`${hours}h ${minutes}m ${seconds}s`); // Call the callback with the new time difference
  };

  // Update the difference every second
  if (!time2) {
    const intervalId = setInterval(() => {
      diff = Math.abs(new Date().getTime() - date1.getTime());
      updateCallback(diff);
    }, 1000);
    // Stop updating after 1 hour
    setTimeout(() => {
      clearInterval(intervalId);
    }, 60 * 60 * 1000);
  } else {
    updateCallback(diff);
  }

  // Return the initial difference in hours, minutes, and seconds
  const hours = Math.floor(diff / 1000 / 60 / 60);
  const minutes = Math.floor(diff / 1000 / 60) % 60;
  const seconds = Math.floor(diff / 1000) % 60;
  return `${hours}h ${minutes}m ${seconds}s`;
}

export function getDate(daysToAdd = 0) {
  // Returns today plus the added days in a string format for the date form fiels
  const today = new Date();
  today.setDate(today.getDate() + daysToAdd);
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1
  const day = String(today.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export function toQueryParams(params) {
  const urlParams = new URLSearchParams(params).toString();
  return urlParams;
}

export function updateObject(target, source) {
  for (const key in source) {
    if (source[key] !== null) {
      target[key] = source[key];
    }
  }
  return target;
}

export function strTimeDifference(start_time, end_time) {
  if (!start_time || !end_time) return ["none", "none"];

  const startTimeParts = start_time.split(":").map(Number);
  const endTimeParts = end_time.split(":").map(Number);

  let startDate = new Date();
  startDate.setHours(startTimeParts[0], startTimeParts[1], 0, 0);

  let endDate = new Date();
  endDate.setHours(endTimeParts[0], endTimeParts[1], 0, 0);

  // If the end time is earlier than the start time, add one day to the end time
  if (endDate < startDate) {
    endDate.setDate(endDate.getDate() + 1);
  }

  // Calculate the time difference in milliseconds
  const timeDiff = endDate - startDate;

  // Extract hours and minutes from the time difference
  const hours = Math.floor(timeDiff / 3600000);
  const minutes = Math.floor((timeDiff % 3600000) / 60000);

  return [hours, minutes];
}

export function flattenArrayWithChildren(array) {
  let flattenedArray = [];

  function flatten(item) {
    flattenedArray.push(item);

    if (item.children && item.children.length > 0) {
      item.children.forEach((child) => {
        flatten(child);
      });
    }
  }

  array.forEach((item) => {
    flatten(item);
  });

  return flattenedArray;
}

export function toTitleCase(str) {
  if (isEmpty(str)) return "";
  const val = str.replace(/_/g, " ");
  return val.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function toSnakeCase(str) {
  if (isEmpty(str)) return "";
  return str.replace(/ /g, "_").toLowerCase();
}

export function objectKeysToSnakeCase(obj) {
  const newObj = {};
  for (const key in obj) {
    // remove leading / trailing white space from key
    const newKey = key.trim();
    newObj[toSnakeCase(newKey)] = obj[key];
  }
  return newObj;
}

export const getFileFromUrl = async (fileEndpoint) => {
  try {
    const response = await fetch(fileEndpoint);
    const blob = await response.blob();
    return URL.createObjectURL(blob);
  } catch (error) {
    console.error("There was a problem with fetching the PDF: ", error);
  }
};

export const secsToHrsMinsSecs = (secs, returnText = true) => {
  const hours = Math.floor(secs / 3600);
  const minutes = Math.floor((secs % 3600) / 60);
  const seconds = secs % 60;
  if (returnText) {
    return `${hours}h ${minutes}m ${seconds}s`;
  }
  return [hours, minutes, seconds];
};

export function calculatePercentage(numerator, denominator, toFixed = 2) {
  if (isEmpty(numerator) || isEmpty(denominator)) return 0;
  if (numerator === 0 || denominator === 0) return 0;
  return ((numerator / denominator) * 100).toFixed(toFixed);
}

export function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export function sameObjectValues(obj1, obj2) {
  for (const key in obj1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }
  return true;
}

export function deepCopyObject(obj) {
  // Copy all key value pairs of an object and return a new object
  return JSON.parse(JSON.stringify(obj));
}

export function randomCharacters(length = 36) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export function addFillColorToList(objects, statusKey = "name") {
  const success = ["approved", "auto approved", "active", "completed"];
  const rejected = ["rejected", "cancelled", "inactive", "failed", "reversed"];
  const pending = ["pending"];
  const processing = ["processing", "in progress"];
  const awaiting = ["awaiting attestation"];

  // Define color mappings for each status category
  const colorMapping = {
    success: "#28a745",
    danger: "#dc3545",
    warning: "#ffc107",
    info: "#17a2b8",
    secondary: "#6c757d",
    awaiting: appConfigs.primaryColor,
  };

  // Map through each object and add the `fillColor` based on the status
  return objects.map((obj) => {
    let fillColor;
    const status = obj[statusKey].toLowerCase();

    if (success.includes(status)) {
      fillColor = colorMapping.success;
    } else if (rejected.includes(status)) {
      fillColor = colorMapping.danger;
    } else if (pending.includes(status)) {
      fillColor = colorMapping.warning;
    } else if (processing.includes(status)) {
      fillColor = colorMapping.info;
    } else if (awaiting.includes(status)) {
      fillColor = colorMapping.awaiting;
    } else {
      fillColor = colorMapping.secondary;
    }

    // Return the updated object with the fillColor key
    return {
      ...obj,
      fillColor,
    };
  });
}


export const eventPropGetter = (event) => {
  let backgroundColor;
  if (event.is_past) {
    backgroundColor = "#9898987a"; // Gray for past events with 50% opacity
  } else {
    switch (event.priority) {
      case "High":
        backgroundColor = "#FF45007a"; // Red for high priority with 50% opacity
        break;
      case "Medium":
        backgroundColor = "#e5a1007a"; // Orange for medium priority with 50% opacity
        break;
      case "Low":
        backgroundColor = "#22958a7a"; // Green for low priority with 50% opacity
        break;
      default:
        backgroundColor = "#9898987a"; // Default gray color with 50% opacity if no priority matches
    }
  }

  return {
    style: {
      backgroundColor,
      color: "white",
      borderRadius: "5px",
      border: backgroundColor,
    },
  };
};

// Priority color mapping
export const priorityColors = {
  Low: "#22958a", // Green
  Medium: "#e5a100", // Orange
  High: "#FF4500", // Red
};

export const slotGroupPropGetter = () => {
  return {
    style: {
      borderWidth: "0.1px",
      borderColor: "#ccc", // Customize this to your desired border color
      borderStyle: "none",
    },
  };
};

export const priorityColor = (priority, past = false) => {
  let color = "";
  if (past) {
    color = "#989898";
  } else {
    if (priority === "Medium") {
      color = "e5a100";
    } else if (priority === "Low") {
      color = "22958a";
    } else if (priority === "High") {
      color = "FF4500";
    }
  }

  color = `#${color}`;
  return color;
};
