import React from "react";
import { Card } from "react-bootstrap";
import { dateFormats, formatDate } from "../../../utils/dateTime";
import EmployeeHighlightCard from "../Employee/EmployeeHighlightCard";
import { TextWithLineBreaks } from "../../../utils/components";

function EmployeeActivityCard({ activity }) {
  return (
    <div>
      <Card>
        <Card.Header className={"py-3"}>
          <Card.Title>
            {formatDate(
              activity.timestamp,
              dateFormats.shortDayShortMonthFullDayYearTime12Hr
            )}
          </Card.Title>
          <Card.Subtitle>Activity Date & Time</Card.Subtitle>
        </Card.Header>
        <Card.Body className={"py-4"}>
          <Card.Text>
            <TextWithLineBreaks text={activity.description} />
          </Card.Text>
        </Card.Body>
        <Card.Footer>
          <EmployeeHighlightCard employee={activity.employee} />
        </Card.Footer>
      </Card>
    </div>
  );
}

export default EmployeeActivityCard;
