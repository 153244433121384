import React, { useContext, useEffect, useState } from "react"
import { AppContext } from "../utils/components/AppContext"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import DashboardPageContainer from "./components/DashboardPageContainer"
import { LoadingView } from "../utils/components"
import PageMetaTags from "./components/Shared/PageMetaTags"
import { toTitleCase, updateObjectState } from "../utils/functions"
import { Card, Tab, Tabs } from "react-bootstrap"
import { Products } from "../enums/OrgEnums"
import ManageTimeOffPolicies from "./components/People/ManageTimeOffPolicies"
import { EmpPermissions } from "../enums/EmpPermissions"
import ApprovalWorkflowsView from "./components/Organization/ApprovalWorkflowsView"
import ProductPermissionCheckView from "./components/ProductPermissionCheckView"

function TimeOffMain({
  getRequest,
  postRequest,
  putRequest,
  patchRequest,
  deleteRequest,
}) {
  const { contextState, updateContextState, hasAnyEmpPermission } =
    useContext(AppContext)
  const { permissions, labelsOverride } = contextState

  const navigate = useNavigate()

  const { id } = useParams()

  const [searchParams, setSearchParams] = useSearchParams()
  const updateQueryParam = (key, value) => {
    setSearchParams({ ...searchParams, [key]: value })
  }

  const [isLoading, setIsLoading] = useState(false)
  const [activeTab, setActiveTab] = useState(searchParams.get("activeTab"))
  const [showSecondLoader, setShowSecondLoader] = useState(false)
  const [timeOffPolicies, setTimeOffPolicies] = useState([])
  const [timeOffApprovalWorkflows, setTimeOffApprovalWorkflows] = useState([])
  const [workflowApprovalPositions, setWorkflowApprovalPositions] = useState([])
  const [configs, setConfigs] = useState({})

  const activateTab = (key) => {
    setActiveTab(key)
    updateQueryParam("activeTab", key)
  }

  useEffect(() => {
    activateTab(searchParams.get("activeTab"))
  }, [searchParams])

  useEffect(() => {
    getRequest(`/time-off/policies`, setShowSecondLoader, (response) => {
      setTimeOffPolicies(response.time_off_policies)
      setTimeOffApprovalWorkflows(response.time_off_approval_workflows)
      setWorkflowApprovalPositions(response.workflow_approval_positions)
      setConfigs(response.configs)
    })
  }, [])

  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <ProductPermissionCheckView
          product={Products.TIME_OFF_MANAGEMENT}
          empPermissions={[
            EmpPermissions.VIEW_TIME_OFF_POLICIES,
            EmpPermissions.MANAGE_TIME_OFF_POLICIES,
          ]}
        >
          <PageMetaTags title={`Time Off | ${toTitleCase(activeTab)}`} />
          <Tabs
            activeKey={activeTab}
            className={"mb-2 pb-3"}
            variant={"underline"}
            onSelect={(key) => activateTab(key)}
          >
            {/*<Tab*/}
            {/*  eventKey="overview"*/}
            {/*  title="Overview"*/}
            {/*>*/}
            {/*  <LoadingView*/}
            {/*    isLoading={showSecondLoader}*/}
            {/*    centerLoader={false}*/}
            {/*    view={<></>}*/}
            {/*  />*/}
            {/*</Tab>*/}

            <Tab
              eventKey="policies"
              title="Time Off Policies"
            >
              <LoadingView
                isLoading={showSecondLoader}
                centerLoader={false}
                view={
                  <>
                    <ManageTimeOffPolicies
                      timeOffPolicies={timeOffPolicies}
                      permissions={permissions}
                      config={configs}
                      putRequest={putRequest}
                      patchRequest={patchRequest}
                      setTimeOffPolicies={setTimeOffPolicies}
                      loaderCallback={setShowSecondLoader}
                      positions={workflowApprovalPositions}
                      approvalWorkflows={timeOffApprovalWorkflows}
                      setApprovalWorkflows={setTimeOffApprovalWorkflows}
                      deleteRequest={deleteRequest}
                    />
                  </>
                }
              />
            </Tab>

            <Tab
              title={"Approval Workflows"}
              eventKey={"approval_workflows"}
            >
              <Card className={"mb-4"}>
                <Card.Header className={"py-4"}>
                  <Card.Title
                    as={"h4"}
                    className={"mb-3"}
                  >
                    Time-Off Approval Workflows
                  </Card.Title>
                  <Card.Subtitle>
                    Manage time-off approval workflows to define who should
                    approve time off request and the order in which they should
                    approve it.
                  </Card.Subtitle>
                </Card.Header>
              </Card>

              <ApprovalWorkflowsView
                workflows={timeOffApprovalWorkflows}
                setWorkflows={setTimeOffApprovalWorkflows}
                positions={workflowApprovalPositions}
                canManage={permissions.MANAGE_TIME_OFF_POLICIES}
                workflowType={"EMPLOYEE_TIME_OFF"}
                putRequest={putRequest}
                patchRequest={patchRequest}
                addSupervisorStep={true}
                deleteRequest={deleteRequest}
              />
            </Tab>
          </Tabs>
        </ProductPermissionCheckView>
      }
    />
  )
}

function TimeOffMainPage() {
  return <DashboardPageContainer PageComponent={TimeOffMain} />
}

export default TimeOffMainPage
