import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Card, Form, ListGroup } from "react-bootstrap";
import { InfoDisplayTable } from "../../../utils/components";
import { updateObjectState } from "../../../utils/functions";
import { formatDateStrings } from "../../../utils/dateTime";
import { AppContext } from "../../../utils/components/AppContext";

export function PositionsForm({ positions, onSubmit, isEdit = false }) {
  const { contextState } = useContext(AppContext);
  const { labelsOverride } = contextState;

  const [selectedPosition, setSelectedPosition] = useState(
    isEdit ? formatDateStrings(positions[0]) : null
  );
  const [filterTerm, setFilterTerm] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(selectedPosition);
  };

  useEffect(() => {
    if (isEdit || !selectedPosition?.id) return;
    updateObjectState(setSelectedPosition, {
      is_primary: true,
    });
  }, [selectedPosition?.id]);

  if (positions.length === 0) {
    return (
      <Alert variant={"warning"} className={"mt-4"}>
        No positions available. Please add positions in the People page and
        return here to assign a position.
      </Alert>
    );
  }
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Control
        type="text"
        value={filterTerm}
        onChange={(event) => {
          setFilterTerm(event.target.value);
        }}
        placeholder={"Search Positions"}
        className={"mb-4"}
      />
      <ListGroup className="mb-3">
        {positions.map((position) => {
          if (
            filterTerm.length > 0 &&
            !position.title.toLowerCase().includes(filterTerm.toLowerCase())
          ) {
            return <div key={`z-${position.id}`} />;
          }
          return (
            <ListGroup.Item
              key={position.id}
              className={`mb-3 rounded-3 border-2 pointer ${
                selectedPosition?.id === position.id ? "border-primary" : ""
              }`}
              onClick={() => setSelectedPosition(position)}
            >
              <Form.Check
                type="checkbox"
                id={`position-${position.id}`}
                checked={selectedPosition?.id === position.id}
                onChange={() => setSelectedPosition(position)}
                className={"mb-2"}
              />
              <div
                htmlFor={`position-${position.id}`}
                style={{
                  maxWidth: "100%",
                  overflowWrap: "break-word",
                }}
              >
                <div className="fw-bold fs-5">{position.title}</div>
                <div>{position.description}</div>
                <InfoDisplayTable
                  data={{
                    [labelsOverride.department || "Department"]:
                      position.department.name,
                    Level: position.level.name,
                    Branch: (
                      <span>
                        <strong>{position.branch.name}</strong> <br />{" "}
                        <small>{position.branch.full_address}</small>
                      </span>
                    ),
                    "Branch Location": !position.branch_location ? (
                      "None"
                    ) : (
                      <span>
                        <strong>{position.branch_location.name}</strong> <br />{" "}
                        <small>{position.branch_location.full_address}</small>
                      </span>
                    ),
                  }}
                />
              </div>
            </ListGroup.Item>
          );
        })}
      </ListGroup>

      <Card body={true} className="mb-4">
        <Form.Group
          controlId="isActive"
          className={"d-flex justify-content-between align-items-center"}
        >
          <Form.Label>Is Primary Position</Form.Label>
          <Form.Switch
            name="is_primary"
            checked={selectedPosition?.is_primary || false}
            onChange={(e) =>
              updateObjectState(setSelectedPosition, {
                is_primary: e.target.checked,
              })
            }
          />
        </Form.Group>

        <Form.Text>
          Setting this position as primary will replace the existing primary
          position (if any).
        </Form.Text>
      </Card>

      <Form.Group controlId="startDate" className={"mb-4"}>
        <Form.Label>
          Position Start Date <span className={"text-danger"}>*</span>
        </Form.Label>
        <Form.Control
          type="date"
          name="start_date"
          value={selectedPosition?.start_date || ""}
          onChange={(event) => {
            updateObjectState(setSelectedPosition, {
              start_date: event.target.value,
            });
          }}
        />
      </Form.Group>

      <Form.Group controlId="endDate" className={"mb-4"}>
        <Form.Label>
          Position End Date{" "}
          <span className={"fst-italic text-muted"}>(optional)</span>
        </Form.Label>
        <Form.Control
          type="date"
          name="end_date"
          value={selectedPosition?.end_date || ""}
          onChange={(event) => {
            updateObjectState(setSelectedPosition, {
              end_date: event.target.value,
            });
          }}
        />
        <Form.Text>
          Leave this field empty if the position is ongoing.
        </Form.Text>
      </Form.Group>

      <Button
        variant="primary"
        type="submit"
        className={"w-100 mt-4"}
        disabled={!selectedPosition || !selectedPosition?.start_date}
      >
        Save
      </Button>
    </Form>
  );
}
