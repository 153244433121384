import React, { useState, useEffect } from "react";
import { Form, Button, Alert, InputGroup } from "react-bootstrap";
import { industries } from "../../data/industries";
import { isEmpty, updateObjectState } from "../../utils/functions";
import { FaMapMarkedAlt } from "react-icons/fa";

let availableIndustries = {};

function BranchForm({ onSubmit, state, setState, isMainBranch = true }) {
  const [industryOptions, setIndustryOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);

  useEffect(() => {
    // Define the industry and category options
    availableIndustries = industries;
    setIndustryOptions(Object.keys(availableIndustries));
    setCategoryOptions(availableIndustries[state.industry || "Agriculture"]);
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleIndustryChange = (event) => {
    const { value } = event.target;
    setCategoryOptions(availableIndustries[value]);
    setState((prevState) => ({
      ...prevState,
      industry: value,
      industry_category: "",
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(state);
  };

  const formValid = () => {
    const requiredFields = [
      "name",
      "code",
      "industry",
      "industry_category",
      "date_opened",
      "address",
      "city",
      "region",
    ];
    let valid = true;
    for (const field of requiredFields) {
      if (isEmpty(state[field])) {
        valid = false;
        break;
      }
    }
    return valid;
  };

  const setCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        updateObjectState(setState, {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="branchName" className={"mb-4"}>
        <Form.Label>Name *</Form.Label>
        <Form.Control
          type="text"
          name="name"
          required
          value={state.name || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="branchDescription" className={"mb-4"}>
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          name="description"
          value={state.description || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      {isMainBranch && (
        <Form.Group controlId="branchIsHeadquarters" className={"mb-4"}>
          <Form.Check
            type="checkbox"
            label="Is Headquarters"
            name="is_headquarters"
            checked={state.is_headquarters === true || false}
            onChange={() => {
              updateObjectState(setState, {
                is_headquarters:
                  state.is_headquarters === undefined
                    ? true
                    : !state.is_headquarters,
              });
            }}
          />
        </Form.Group>
      )}

      <Form.Group controlId="branchCode" className={"mb-4"}>
        <Form.Label>
          {isMainBranch ? "Branch" : "Branch Location"} Code *
        </Form.Label>
        <Form.Control
          type="text"
          name="code"
          value={state.code || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="branchAddress" className={"mb-4"}>
        <Form.Label>Address *</Form.Label>
        <Form.Control
          type="text"
          name="address"
          required
          value={state.address || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="branchAddress1" className={"mb-4"}>
        <Form.Label>Address 1</Form.Label>
        <Form.Control
          type="text"
          name="address_1"
          value={state.address_1 || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="branchCity" className={"mb-4"}>
        <Form.Label>City *</Form.Label>
        <Form.Control
          type="text"
          name="city"
          value={state.city || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="branchRegion" className={"mb-4"}>
        <Form.Label>Region *</Form.Label>
        <Form.Control
          type="text"
          name="region"
          value={state.region || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Alert variant="info">
        The latitude and longitude will be used to compare an employee's
        location with their assigned branch when they are clocking in / out.
        Leaving these blank will disable the geo tagging feature.
      </Alert>

      <div className={"d-flex justify-content-end mt-4"}>
        <Button variant="primary" onClick={setCurrentLocation}>
          <FaMapMarkedAlt className={"me-2"} />
          Use Current Location
        </Button>
      </div>

      <Form.Group controlId="branchLatituden" className={"mb-4"}>
        <Form.Label>Latitude</Form.Label>
        <Form.Control
          type="text"
          name="latitude"
          value={state.latitude || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="branchLongitude" className={"mb-4"}>
        <Form.Label>Longitude</Form.Label>
        <Form.Control
          type="text"
          name="longitude"
          value={state.longitude || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="branchLongitude" className={"mb-4"}>
        <Form.Label>Clock In / Out Perimeter</Form.Label>
        <InputGroup>
          <Form.Control
            type="number"
            name="allowed_attendance_distance"
            value={state.allowed_attendance_distance || ""}
            onChange={handleInputChange}
          />
          <InputGroup.Text id="basic-addon2">meters</InputGroup.Text>
        </InputGroup>
        <Form.Text className="text-muted">
          Distance in meters that an employee can clock in / out from the
          branch.
        </Form.Text>
      </Form.Group>

      <Form.Group controlId="branchIndustry" className={"mb-4"}>
        <Form.Label>Industry *</Form.Label>
        <Form.Select
          name="industry"
          value={state.industry || ""}
          onChange={handleIndustryChange}
        >
          {industryOptions.map((industry) => (
            <option key={industry}>{industry}</option>
          ))}
        </Form.Select>
      </Form.Group>

      <Form.Group controlId="branchCategory" className={"mb-4"}>
        <Form.Label>Industry Category *</Form.Label>
        <Form.Select
          name="industry_category"
          value={state.industry_category || ""}
          onChange={handleInputChange}
        >
          {categoryOptions.map((category) => (
            <option key={category}>{category}</option>
          ))}
        </Form.Select>
      </Form.Group>

      <Form.Group controlId="branchDateOpened" className={"mb-4"}>
        <Form.Label>Date Opened *</Form.Label>
        <Form.Control
          type={"date"}
          name="date_opened"
          value={state.date_opened || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Button
        variant="primary"
        type="submit"
        disabled={!formValid()}
        className={"w-100"}
      >
        Save
      </Button>
    </Form>
  );
}
export default BranchForm;
