import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { AppConstants } from "../appConstants";
import React, { useContext } from "react";
import { isEmpty, toTitleCase } from "../functions";
import { Loader, LoadingView } from "../components";
import { AppContext } from "./AppContext";
import { appConfigs } from "../../configs";
import { FaChartBar } from "react-icons/fa";

const calculateTextHeight = (data, xDataKey, angle = -30, fontSize = 12) => {
  const maxLength = Math.max(
    ...data.map((item) => item[xDataKey]?.length || 10)
  );
  return (
    Math.abs(maxLength * (fontSize / 2) * Math.sin((angle * Math.PI) / 180)) +
    30
  );
};

export const AppPieChart = ({
  data,
  nameKey = "name",
  valueKey = "count",
  chartHeight = 300,
}) => {
  const { contextState } = useContext(AppContext);
  const textColor = contextState.darkMode
    ? appConfigs.lightColor
    : appConfigs.darkColor;
  const bkgColor = contextState.darkMode
    ? appConfigs.darkColor
    : appConfigs.lightColor;

  return (
    <>
      {isEmpty(data) && (
        <Loader text={"No Data Available..."} spinnerText={""} />
      )}
      {!isEmpty(data) && (
        <ResponsiveContainer width="100%" height={chartHeight}>
          <PieChart>
            <Pie
              data={data}
              labelLine={false}
              fill="#8884d8"
              nameKey={nameKey}
              dataKey={valueKey}
              label={({ name, percent }) =>
                `${name} ${(percent * 100).toFixed(2)}%`
              }
              style={{ fontSize: 11, fontWeight: "bold" }}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    entry.fillColor ||
                    AppConstants.chartColors[
                      index % AppConstants.chartColors.length
                    ]
                  }
                />
              ))}
            </Pie>

            <Tooltip
              contentStyle={{ color: textColor, backgroundColor: bkgColor }}
              itemStyle={{ color: textColor, fontSize: 12 }}
              cursor={{ fill: "transparent", fontSize: 12 }}
            />
          </PieChart>
        </ResponsiveContainer>
      )}
    </>
  );
};

export const AppBarChart = ({
  data,
  xName = "# of Employees",
  xDataKey = "name",
  yDataKey = "count",
  containerHeight = 400,
  fillColor = appConfigs.primaryColor,
}) => {
  const { contextState } = useContext(AppContext);
  const textColor = contextState.darkMode
    ? appConfigs.lightColor
    : appConfigs.darkColor;
  const bkgColor = contextState.darkMode
    ? appConfigs.darkColor
    : appConfigs.lightColor;

  const xAxisHeight =
    data && data.length > 0 ? calculateTextHeight(data, xDataKey, -30, 12) : 10;

  return (
    <>
      {isEmpty(data) && (
        <p className={"text-center"}>
          <FaChartBar size={50} className={"text-muted mb-2"} />
          <br />
          No Data Available
        </p>
      )}
      {!isEmpty(data) && (
        <div style={{ overflowX: "auto", width: "100%" }}>
          <div style={{ minWidth: `${data.length * 50}px` }}>
            <ResponsiveContainer width={"100%"} height={containerHeight}>
              <BarChart data={data}>
                <CartesianGrid
                  strokeDasharray="3 3"
                  fill={"transparent"}
                  vertical={false}
                />

                {/* Adjusted X-Axis */}
                <XAxis
                  dataKey={xDataKey}
                  axisLine={{ stroke: textColor }}
                  tickLine={false}
                  height={xAxisHeight}
                  tick={{
                    fontSize: 12,
                    fill: textColor,
                  }}
                  angle={-30}
                  dy={0}
                  textAnchor="end"
                  interval={0}
                />

                <YAxis
                  dataKey={yDataKey}
                  tick={{ fill: textColor }}
                  axisLine={{ stroke: textColor }}
                />
                <Tooltip
                  contentStyle={{
                    color: textColor,
                    backgroundColor: bkgColor,
                    textAlign: "left",
                    fontWeight: "bold",
                  }}
                  itemStyle={{
                    color: textColor,
                    textAlign: "left",
                  }}
                  cursor={{ fill: "transparent" }}
                />
                {/*<Legend />*/}
                <Bar
                  dataKey={yDataKey}
                  name={xName || toTitleCase(xDataKey)}
                  fill={fillColor}
                  background={{ fill: "transparent" }}
                  className={"danger-hover"}
                  maxBarSize={50}
                  barGap={2}
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={entry.fillColor || fillColor}
                    />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      )}
    </>
  );
};
