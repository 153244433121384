import React, { useState } from "react";
import { Accordion, Button, Card, Col, Row } from "react-bootstrap";
import {
  InfoDisplayTable,
  NoInfoCard,
  StatusBadge,
  TextWithLineBreaks,
} from "../../../utils/components";
import { formatDate } from "../../../utils/dateTime";
import { AppBarChart, AppPieChart } from "../../../utils/components/AppCharts";
import {
  addFillColorToList,
  useAppModal,
  useOffCanvas,
} from "../../../utils/functions";
import PaginatedEmployeesDisplay from "../Employee/PaginatedEmployeesDisplay";
import EmployeeTable from "../EmployeeTable";
import employee from "../../Forms/Employee";
import { FaEdit, FaPlus } from "react-icons/fa";
import { FaTrashCan } from "react-icons/fa6";
import GenericForm from "../../Forms/Shared/GenericForm";
import { SharedFormConfigs } from "../../Forms/FormsConfigs/SharedFormConfigs";

function ReviewPeriodFormStepCard({
  step,
  onEditClick,
  onDeleteClick,
  getRequest,
  putRequest,
  deleteRequest,
  canAddReviewer,
  canRemoveReviewer,
}) {
  const [showReviewers, setShowReviewers] = useState(false);
  const { showAppModal, closeModal } = useAppModal();
  const { showOffCanvas } = useOffCanvas();

  const addReviewer = () => {
    showOffCanvas({
      title: `Add Reviewer to ${step.name} Step`,
      component: (
        <GenericForm
          formConfigs={SharedFormConfigs.SingleEntitySelection({})}
          onSubmit={(formData) => {
            setShowReviewers(false);
            putRequest(
              `/performance/review-period/${step.period_id}/step/${step.id}/reviewer`,
              null,
              (response) => {
                setShowReviewers(true);
              },
              formData
            );
          }}
        />
      ),
    });
  };

  if (step.type === "Skipped") {
    return (
      <div>
        <Card className={"border-info border-1"}>
          <Card.Header className={"py-3 d-flex justify-content-between"}>
            <div>
              <Card.Title>
                {step.order}. {step.name}
              </Card.Title>
            </div>
          </Card.Header>
          <Card.Body className={"py-4"}>
            <h6 className={"text-center"}>{step.skipped_reason}</h6>
          </Card.Body>
        </Card>
      </div>
    );
  } else {
    return (
      <div>
        <Card>
          <Card.Header className={"py-3 d-flex justify-content-between"}>
            <div>
              <Card.Title>
                {step.order}. {step.name}
              </Card.Title>
            </div>
            <div>
              {onEditClick && (
                <Button
                  variant={"outline-primary"}
                  onClick={() => {
                    onEditClick();
                  }}
                >
                  <FaEdit className={"me-2"} />
                  Edit
                </Button>
              )}
              {onDeleteClick && (
                <Button
                  variant={"outline-danger"}
                  className={"ms-2"}
                  onClick={() => {
                    showAppModal({
                      title: `Delete ${step.name}`,
                      component: (
                        <p>
                          Are you sure you want to delete the step{" "}
                          <strong>{step.name}</strong>?
                        </p>
                      ),
                      truthyFunction: onDeleteClick,
                    });
                  }}
                >
                  <FaTrashCan />
                </Button>
              )}
            </div>
          </Card.Header>
          <Card.Body className={"py-3"}>
            <Card.Subtitle>
              <TextWithLineBreaks text={step.description} />
            </Card.Subtitle>
            <Row className={"mt-5"}>
              <Col lg={4}>
                <InfoDisplayTable
                  title={"Step Details"}
                  data={{
                    Status: <StatusBadge status={step.status} />,
                    Type: step.type,
                    "Created On": formatDate(step.timestamp),
                    "Start Date": formatDate(step.start_date),
                    "End Date": formatDate(step.end_date),
                    "Total Reviews": step?.stats?.total_reviewers,
                  }}
                />
              </Col>
              <Col lg={8}>
                <h6 className={"text-center mb-3"}>Review Statuses</h6>
                <AppPieChart
                  data={addFillColorToList(step.stats.statuses)}
                  xName={"# of Reviews"}
                  chartHeight={250}
                />
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer className={"py-3"}>
            <Accordion>
              <Accordion.Header
                onClick={() => {
                  if (!showReviewers) {
                    setShowReviewers(true);
                  }
                }}
              >
                Step Reviewers
              </Accordion.Header>
              <Accordion.Body>
                {showReviewers && (
                  <>
                    {canAddReviewer && (
                      <div className={"d-flex justify-content-end mb-3"}>
                        <Button
                          variant={"outline-primary"}
                          onClick={() => {
                            addReviewer();
                          }}
                        >
                          <FaPlus className={"me-2"} />
                          Add Reviewer
                        </Button>
                      </div>
                    )}
                    <PaginatedEmployeesDisplay
                      endpoint={`/performance/review-period/${step.period_id}/step/${step.id}/employees`}
                      getRequest={getRequest}
                      minimal={true}
                      extraColumns={[
                        {
                          columnName: "Reviews",
                          renderComponent: (employee) => (
                            <div className={"text-center"}>
                              <Button
                                variant={"link"}
                                className={"text-center"}
                                onClick={() => {
                                  showAppModal({
                                    title: `${employee.full_name}'s Reviewees`,
                                    hideFooter: true,
                                    size: "xl",
                                    component: (
                                      <PaginatedEmployeesDisplay
                                        endpoint={`/performance/review-period/${step.period_id}/step/${step.id}/reviewer/${employee.reviewer_employee_id}/reviewees`}
                                        getRequest={getRequest}
                                        minimal={true}
                                        extraColumns={[
                                          {
                                            columnName: "Review Status",
                                            renderComponent: (employee) => (
                                              <StatusBadge
                                                status={employee.review_status}
                                              />
                                            ),
                                          },
                                        ]}
                                      />
                                    ),
                                  });
                                }}
                              >
                                {employee.reviewees_count}
                              </Button>
                            </div>
                          ),
                        },
                        {
                          columnName: "Read Columns",
                          renderComponent: (employee) => (
                            <small>
                              {employee.read_columns?.join(" | ") || ""}
                            </small>
                          ),
                        },
                        {
                          columnName: "Write Columns",
                          renderComponent: (employee) => (
                            <small>
                              {employee.write_columns?.join(" | ") || ""}
                            </small>
                          ),
                        },
                        {
                          columnName: "Action",
                          renderComponent: (employee) => {
                            if (!employee.can_delete) {
                              return <></>;
                            }
                            return (
                              <Button
                                variant={"outline-danger"}
                                onClick={() => {
                                  showAppModal({
                                    title: `Remove ${employee.full_name} as a Reviewer`,
                                    component: (
                                      <p>
                                        Are you sure you want to remove{" "}
                                        <strong>{employee.full_name}</strong> as
                                        a reviewer?
                                      </p>
                                    ),
                                    truthyFunction: () => {
                                      setShowReviewers(false);
                                      deleteRequest(
                                        `/performance/review-period/${step.period_id}/step/${step.id}/reviewer/${employee.reviewer_employee_id}`,
                                        null,
                                        (response) => {
                                          setShowReviewers(true);
                                        }
                                      );
                                    },
                                  });
                                }}
                              >
                                <FaTrashCan />
                              </Button>
                            );
                          },
                        },
                      ]}
                    />
                  </>
                )}
              </Accordion.Body>
            </Accordion>
          </Card.Footer>
        </Card>
      </div>
    );
  }
}

export default ReviewPeriodFormStepCard;
