import { createContext, useContext, useState } from "react";

export const OffCanvasContext = createContext();

export const useOffCanvas = () => {
  return useContext(OffCanvasContext);
};

export const OffCanvasProvider = ({ children }) => {
  const initContent = { title: "", subtitle: null, component: null };

  const [show, setShow] = useState(false);
  const [content, setContent] = useState(initContent);

  const openOffCanvas = (newContent) => {
    setContent(newContent);
    setShow(true);
  };

  const restoreOffCanvas = () => {
    setShow(true);
  };
  const closeOffCanvas = () => {
    // setContent(initContent);
    setShow(false);
  };

  return (
    <OffCanvasContext.Provider
      value={{
        show,
        content,
        openOffCanvas,
        closeOffCanvas,
        restoreOffCanvas,
      }}
    >
      {children}
    </OffCanvasContext.Provider>
  );
};
