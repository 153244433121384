import React, { useState, useEffect, useContext } from "react";
import { Form } from "react-bootstrap";
import { AppContext } from "./AppContext";

function ThemeSwitch() {
  const { contextState, updateContextState } = useContext(AppContext);
  const [isDark, setIsDark] = useState(
    localStorage.getItem("theme") === "dark"
  );

  const handleChange = (event) => {
    const isDarkMode = event.target.checked;
    setIsDark(isDarkMode);
    document.documentElement.setAttribute(
      "data-bs-theme",
      isDarkMode ? "dark" : "light"
    );
    localStorage.setItem("theme", isDarkMode ? "dark" : "light");
    updateContextState({
      darkMode: isDarkMode,
    });
  };

  // This useEffect is for the initial render of the component.
  useEffect(() => {
    const theme = localStorage.getItem("theme") || "light";
    document.documentElement.setAttribute("data-bs-theme", theme);
    const isDarkMode = theme === "dark";
    setIsDark(isDarkMode);
    updateContextState({
      darkMode: isDarkMode,
    });
  }, []);

  return (
    <div className="d-flex justify-content-center align-items-center">
      <span>Light</span>
      <Form className="mx-2">
        <Form.Check
          type="switch"
          id="custom-switch"
          className="custom-switch mx-2"
          checked={isDark}
          onChange={handleChange}
        />
      </Form>
      <span>Dark</span>
    </div>
  );
}

export default ThemeSwitch;
