import React, { useContext } from "react";
import {
  AddItemButton,
  NoInfoCard,
  TextWithLineBreaks,
} from "../../../utils/components";
import { useOffCanvas } from "../../../utils/functions";
import { AppContext } from "../../../utils/components/AppContext";
import { Button, Card } from "react-bootstrap";
import GenericForm from "../../Forms/Shared/GenericForm";
import PerformanceFormConfigs from "../../Forms/FormsConfigs/PerformanceFormConfigs";
import EmployeeBasicHighlight from "../Shared/EmployeeBasicHighlight";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../utils/appRoutes";
import { formatDate } from "../../../utils/dateTime";

function PerformanceReviewPeriodTab({
  reviewPeriods,
  setReviewPeriods,
  permissions,
  putRequest,
  loaderCallback,
}) {
  const { showOffCanvas } = useOffCanvas();
  const { updateContextState } = useContext(AppContext);

  const navigation = useNavigate();

  return (
    <div>
      {permissions.MANAGE_PERFORMANCE_REVIEWS && (
        <div className={"mb-4"}>
          <AddItemButton
            title={"New Review Period"}
            onClick={() => {
              updateContextState({
                formData: {},
              });
              showOffCanvas({
                title: `Add a Review Period`,
                subtitle: `Add a new Review Period to your organization`,
                component: (
                  <>
                    <GenericForm
                      formConfigs={PerformanceFormConfigs.reviewPeriod}
                      onSubmit={(formData) => {
                        putRequest(
                          `/performance/review-period`,
                          loaderCallback,
                          (response) => {
                            setReviewPeriods([
                              response.review_period,
                              ...reviewPeriods,
                            ]);
                          },
                          formData
                        );
                      }}
                    />
                  </>
                ),
              });
            }}
          />
        </div>
      )}

      <Card className={"mb-4"}>
        <Card.Header className={"py-4"}>
          <Card.Title>Performance Review Periods</Card.Title>
          <Card.Subtitle as={"p"} className={"mt-3"}>
            Manage Performance Reviews Efficiently: Create, update, and oversee
            performance reviews to support your people's growth and align with
            organizational objectives.
          </Card.Subtitle>
        </Card.Header>
      </Card>

      {reviewPeriods?.length === 0 ? (
        <NoInfoCard
          text={"No Review Periods have been added to your organization"}
        />
      ) : (
        reviewPeriods.map((period, index) => {
          return (
            <Card key={index} className={"mb-4"}>
              <Card.Body className={"py-4"}>
                <Card.Title>{period.name}</Card.Title>
                <Card.Subtitle as={"p"} className={"pt-3"}>
                  <TextWithLineBreaks text={period.description} />
                </Card.Subtitle>
              </Card.Body>
              <Card.Footer className={"py-3 d-flex justify-content-between"}>
                <div>
                  <h6 className={"text-muted mb-2"}>
                    Created on {formatDate(period.timestamp)}, by:
                  </h6>
                  <EmployeeBasicHighlight employee={period.author_employee} />
                </div>
                <div className={"align-middle align-content-center"}>
                  <Button
                    variant={"primary"}
                    onClick={() => {
                      navigation(
                        `${AppRoutes.PerformanceReviewPeriod.paramLessPath}/${period.id}`
                      );
                    }}
                  >
                    View Details >
                  </Button>
                </div>
              </Card.Footer>
            </Card>
          );
        })
      )}
    </div>
  );
}

export default PerformanceReviewPeriodTab;
