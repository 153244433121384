import React, { useContext } from "react";
import { Alert, Button, Card, Col, Row } from "react-bootstrap";
import CompensationForm from "../../Forms/Employee/CompensationForm";
import { updateObjectState, useOffCanvas } from "../../../utils/functions";
import { FaPlus } from "react-icons/fa";
import { ConditionalView } from "../../../utils/components";
import CompensationDetails from "./Compensations";
import PaymentAccountForm from "../../Forms/Employee/PaymentAccountForm";
import PaymentAccount from "./PaymentAccount";
import { AppContext } from "../../../utils/components/AppContext";

function ManageCompensation({
  employee,
  setEmployee,
  permissions,
  configs,
  putRequest,
  patchRequest,
  loaderCallback,
}) {
  const { showOffCanvas } = useOffCanvas();
  const { updateContextState } = useContext(AppContext);

  return (
    <div>
      <Row>
        <Col lg={6}>
          <Card className={"mb-3 rounded-3 shadow"}>
            <Card.Header className={"d-flex justify-content-between py-4"}>
              <Card.Title>{employee.first_name}'s Compensations</Card.Title>
              {permissions.MANAGE_EMPLOYEE_DATA && (
                <div>
                  <Button
                    variant={"success"}
                    className="me-2"
                    onClick={() => {
                      updateContextState({
                        formData: {
                          is_active: true,
                        },
                      });
                      showOffCanvas({
                        title: `New Compensation for ${employee.first_name}`,
                        subtitle: `Add a new compensation e.g. salary, allowance, bonus etc.`,
                        component: (
                          <CompensationForm
                            configs={configs}
                            wageType={employee.wage_type}
                            onSubmit={(updatedData) => {
                              putRequest(
                                `/employee/compensations/${employee.id}`,
                                loaderCallback,
                                (response) => {
                                  updateObjectState(
                                    setEmployee,
                                    response.employee
                                  );
                                },
                                updatedData
                              );
                            }}
                          />
                        ),
                      });
                    }}
                  >
                    <FaPlus className={"me-2"} />
                    Add
                  </Button>
                </div>
              )}
            </Card.Header>
            <Card.Body>
              <Alert variant={"info"}>
                All active compensations will be paid in the next pay period on
                or after the payment effective date you select.
              </Alert>
              <ConditionalView
                condition={employee?.compensations?.length > 0}
                falseView={
                  <h6 className={"text-center my-5"}>
                    There are no active compensations for {employee.first_name}
                  </h6>
                }
                trueView={
                  <div>
                    {employee?.compensations?.map((compensation, index) => {
                      const deductionNames = [];
                      configs.deductions?.forEach((deduction) => {
                        if (compensation.deduction_ids.includes(deduction.id)) {
                          deductionNames.push(deduction.name);
                        }
                      });

                      return (
                        <CompensationDetails
                          key={index}
                          compensation={compensation}
                          deductionNames={deductionNames}
                          canEdit={permissions.MANAGE_EMPLOYEE_DATA}
                          onEditClick={() => {
                            updateContextState({
                              formData: { ...compensation },
                            });
                            showOffCanvas({
                              title: `Edit Compensation`,
                              subtitle: `Edit ${compensation.name}`,
                              component: (
                                <CompensationForm
                                  configs={configs}
                                  wageType={employee.wage_type}
                                  editView={true}
                                  onSubmit={(updatedData) => {
                                    patchRequest(
                                      `/employee/compensations/${employee.id}/${compensation.id}`,
                                      loaderCallback,
                                      (response) => {
                                        updateObjectState(
                                          setEmployee,
                                          response.employee
                                        );
                                      },
                                      updatedData,
                                      false
                                    );
                                  }}
                                />
                              ),
                            });
                          }}
                        />
                      );
                    })}
                  </div>
                }
              />
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6}>
          <Card className={"shadow rounded-3"}>
            <Card.Header className={"py-4 d-flex justify-content-between"}>
              <Card.Title>Payment Accounts</Card.Title>
              {permissions.MANAGE_EMPLOYEE_DATA && (
                <div>
                  <Button
                    variant={"success"}
                    className="me-2"
                    onClick={() => {
                      updateContextState({
                        formData: { is_active: true },
                      });
                      showOffCanvas({
                        title: `New Payment Account for ${employee.first_name}`,
                        subtitle: `Add a new payment account where things like their salary, allowances etc can be sent`,
                        component: (
                          <PaymentAccountForm
                            configs={configs}
                            onSubmit={(updatedData) => {
                              putRequest(
                                `/employee/payment-accounts/${employee.id}`,
                                loaderCallback,
                                (response) => {
                                  updateObjectState(setEmployee, {
                                    payment_accounts: [
                                      ...employee.payment_accounts,
                                      response.payment_account,
                                    ],
                                  });
                                },
                                updatedData,
                                false
                              );
                            }}
                          />
                        ),
                      });
                    }}
                  >
                    <FaPlus className={"me-2"} />
                    Add
                  </Button>
                </div>
              )}
            </Card.Header>
            <Card.Body>
              {configs.currencies?.map((currency, index) => {
                const accounts = employee.payment_accounts.filter(
                  (item) => item.currency === currency
                );
                return (
                  <div key={index}>
                    <h5 className={"mb-4 text-center"}>{currency} Accounts</h5>
                    {accounts?.length === 0 && (
                      <p className={"text-center my-4"}>
                        No accounts have been added for this currency
                      </p>
                    )}
                    {accounts.map((account, index) => {
                      return (
                        <div key={index}>
                          <PaymentAccount
                            account={account}
                            canManage={permissions.MANAGE_EMPLOYEE_DATA}
                            onEditClick={() => {
                              updateContextState({
                                formData: { ...account },
                              });
                              showOffCanvas({
                                title: `Edit Payment Account ${account.name}`,
                                subtitle: `Edit payment account where things like their salary, allowances etc can be sent`,
                                component: (
                                  <PaymentAccountForm
                                    configs={configs}
                                    onSubmit={(updatedData) => {
                                      patchRequest(
                                        `/employee/payment-accounts/${employee.id}/${account.id}`,
                                        loaderCallback,
                                        (response) => {
                                          updateObjectState(setEmployee, {
                                            payment_accounts:
                                              response.payment_accounts,
                                          });
                                        },
                                        updatedData,
                                        false
                                      );
                                    }}
                                  />
                                ),
                              });
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default ManageCompensation;
