import React from "react";
import { Card } from "react-bootstrap";
import { formatDate, hrsMinsSecsDiff } from "../../../utils/dateTime";

function ShiftScheduleCard({ schedule }) {
  const [hours, minutes] = hrsMinsSecsDiff(
    schedule.start_datetime,
    schedule.end_datetime
  );
  return (
    <div>
      <Card>
        <Card.Header>
          <Card.Title>{formatDate(schedule.start_datetime)}</Card.Title>
        </Card.Header>
        <Card.Body className={"py-4"}>
          <Card.Text>
            {formatDate(schedule.start_datetime, "h:mm A")} -{" "}
            {formatDate(schedule.end_datetime, "h:mm A")}
            <br />
            {hours} hrs {minutes} mins
          </Card.Text>
        </Card.Body>
        <Card.Footer>
          <Card.Text>{schedule.notes || "No notes"}</Card.Text>
        </Card.Footer>
      </Card>
    </div>
  );
}

export default ShiftScheduleCard;
