import React, { useContext } from "react";
import { Button, Container, Image, Nav, Navbar } from "react-bootstrap";
import { AppContext } from "../../../utils/components/AppContext";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../../utils/functions";
import logo from "../../../assets/logos/white-color-outline.png";
import { AppRoutes } from "../../../utils/appRoutes";
import { AppConstants as appConstants } from "../../../utils/appConstants";
import { appConfigs } from "../../../configs";

function IndexNavbar() {
  const navigate = useNavigate();
  const { contextState, updateContextState } = useContext(AppContext);

  const employee = contextState.employee || {};

  return (
    <Navbar
      // className={`fixed-top ${contextState.darkMode ? "bg-dark" : "bg-light"}`}
      className={`fixed-top bg-body`}
    >
      <Container className={""}>
        <Navbar.Brand
          className={"align-middle pointer"}
          onClick={() => {
            navigate(AppRoutes.Index.path);
          }}
        >
          <img
            alt={`${appConfigs.appName} Logo`}
            src={appConfigs.primaryLogo}
            width="50"
            height="auto"
            className="d-inline-block align-top"
          />
          <Navbar.Text className={"ms-2 fw-bold fs-3 my-auto"}>
            {appConfigs.appName}
          </Navbar.Text>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          {employee.id && (
            <Nav.Link
              onClick={() => {
                navigate(AppRoutes.Home.path);
              }}
            >
              <Navbar.Text className={"me-3 fw-bold"}>
                {employee.full_name}
              </Navbar.Text>
              <Image
                src={employee.profile_image}
                alt="Employee Profile Image"
                height="30"
                width="30"
                roundedCircle
              />
            </Nav.Link>
          )}

          {!employee.id && (
            <Nav.Item className={"me-3"}>
              <Button
                variant={"primary"}
                onClick={() => {
                  navigate(AppRoutes.SignIn.path);
                }}
              >
                Sign In
              </Button>
            </Nav.Item>
          )}
          {/*{!employee.id && (*/}
          {/*  // add sign in and register buttons*/}
          {/*  <Nav.Item>*/}
          {/*    <Button*/}
          {/*      variant={"outline-primary"}*/}
          {/*      onClick={() => {*/}
          {/*        navigate(AppRoutes.SignUp.path);*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      Register*/}
          {/*    </Button>*/}
          {/*  </Nav.Item>*/}
          {/*)}*/}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default IndexNavbar;
