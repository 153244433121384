import React, { useContext, useState, useRef, useEffect } from "react";
import { Button, Form, Image, InputGroup } from "react-bootstrap";
import {
  getFileFromUrl,
  isEmpty,
  updateObjectState,
  useToast,
} from "../../utils/functions";
import { AppContext } from "../../utils/components/AppContext";
import { AppConstants } from "../../utils/appConstants";
import { COUNTRIES } from "../../data/countries";
import { FaEdit } from "react-icons/fa";
import { formatDateStrings } from "../../utils/dateTime";
import { appConfigs } from "../../configs";
import { EmployeeFormFieldsDefinitions } from "./Employee/EmployeeFormFieldsDefinitions";
import { FormFields } from "../FormFields";
import ImageCropInput from "./Shared/ImageCropInput";

function EmployeeForm({ onSubmit, state, showAccessSwitch = false, configs }) {
  const { showToast } = useToast();

  useEffect(() => {
    updateObjectState(setFormState, { profile_image_str: null });
  }, []);
  const { contextState, updateContextState } = useContext(AppContext);
  const [formState, setFormState] = useState({
    ...formatDateStrings({ ...contextState.formData }),
    validations: { first_name: false },
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    updateContextState({
      formData: { ...formState },
    });
    onSubmit(formState);
  };

  return (
    <Form onSubmit={handleSubmit}>
      {EmployeeFormFieldsDefinitions(formState, setFormState).map(
        (field, index) => {
          return (
            <FormFields
              key={index}
              {...field}
              state={formState}
              setState={setFormState}
            />
          );
        }
      )}

      <Button
        variant="primary"
        type="submit"
        className={"w-100"}
        disabled={Object.values(formState.validations).includes(false)}
      >
        Save
      </Button>
    </Form>
  );
}

export default EmployeeForm;
