import React from "react";
import { Card, Button } from "react-bootstrap";
import { FaChevronDown, FaChevronUp, FaEdit, FaPlus } from "react-icons/fa";

const LevelCard = ({
  level,
  isFirst,
  isLast,
  canManage,
  onEditClick,
  onAddChildClick,
  onMoveClick,
  depth = 0,
}) => {
  const cardStyle = {
    marginLeft: depth * 20, // Increase left margin with depth for indentation
    marginRight: depth * 20, // Increase left margin with depth for indentation
    marginTop: depth === 0 ? 0 : 10, // Add top margin for child levels
    clear: "both",
  };

  return (
    <Card style={cardStyle} className={"my-4 border-2 border-primary"}>
      <Card.Header className={"py-4"}>
        <Card.Title>{level.name}</Card.Title>
      </Card.Header>
      <Card.Body>
        <Card.Text>{level.description}</Card.Text>
        {level.children &&
          level.children.map((child, index) => {
            const isFirst = index === 0;
            const isLast = index === level.children.length - 1;
            return (
              <LevelCard
                key={index}
                isFirst={isFirst}
                isLast={isLast}
                level={child}
                canManage={canManage}
                onEditClick={onEditClick}
                onAddChildClick={onAddChildClick}
                depth={depth + 1}
                onMoveClick={onMoveClick}
              />
            );
          })}
      </Card.Body>
      <Card.Footer className={"py-4"}>
        {canManage && (
          <div className={"d-flex justify-content-between"}>
            <div>
              <Button variant="primary" onClick={() => onEditClick(level)}>
                <FaEdit /> Edit Level
              </Button>
              <Button
                variant="success"
                onClick={() => onAddChildClick(level)}
                className={"ms-4"}
              >
                <FaPlus /> Add Sub Level
              </Button>
            </div>
            <div>
              <Button
                className={"me-2"}
                variant="secondary"
                disabled={isFirst}
                onClick={() => onMoveClick(level, level.hierarchy_position + 1)}
              >
                <FaChevronUp />
              </Button>
              <Button
                variant="secondary"
                disabled={isLast}
                onClick={() => onMoveClick(level, level.hierarchy_position - 1)}
              >
                <FaChevronDown />
              </Button>
            </div>
          </div>
        )}
      </Card.Footer>
    </Card>
  );
};

export const LevelList = ({
  levels,
  canManage,
  onEditClick,
  onAddChildClick,
  onMoveClick,
}) => {
  return (
    <div>
      {levels.map((level, index) => {
        const isFirst = index === 0;
        const isLast = index === levels.length - 1;

        return (
          <LevelCard
            key={index}
            isFirst={isFirst}
            isLast={isLast}
            level={level}
            canManage={canManage}
            onEditClick={onEditClick}
            onAddChildClick={onAddChildClick}
            onMoveClick={onMoveClick}
          />
        );
      })}
    </div>
  );
};
