import React from "react";
import IndexNavbar from "./components/Index/IndexNavbar";
import { Col, Container, Image, Row } from "react-bootstrap";
import headerBackgroundImage from "../assets/images/header.jpg";
import employeeProfile from "../assets/images/employee-page.png";
import orgChart from "../assets/images/org-chart.png";
import headerImage from "../assets/images/header-image.png";
import { MdOutlineBusinessCenter } from "react-icons/md";
import { FaUsers } from "react-icons/fa";
import { BiStats } from "react-icons/bi";
import { AiOutlineSchedule } from "react-icons/ai";
import FeatureSection from "./components/Index/FeatureSection";

import appHomePage from "../assets/images/app-home-page.png";
import appHClockinAllowed from "../assets/images/app-clockin-allowed.png";
import appClockInNotAllowed from "../assets/images/app-clockin-not-allowed.png";
import Footer from "./components/Index/Footer";
import { toTitleCase } from "../utils/functions";
import PageMetaTags from "./components/Shared/PageMetaTags";
import { AppConstants } from "../utils/appConstants";
import { useMediaQuery } from "react-responsive";
import { appConfigs } from "../configs";
import AppToasts from "../utils/components/AppToasts";
import AppOffCanvas from "../utils/components/AppOffCanvas";
import AppModal from "../utils/components/AppModal";

const featuresData = [
  {
    title: "Organizational Mastery",
    subtitle:
      "Effortlessly manage branches, permissions, and departments to streamline operations.",
    icon: MdOutlineBusinessCenter,
  },
  {
    title: "Empower Your Workforce",
    subtitle:
      "Centralize employee data, structure teams, and define roles for enhanced collaboration.",
    icon: FaUsers,
  },
  {
    title: "Attendance Simplified",
    subtitle:
      "Implement flexible time-off policies and ensure accurate tracking with geo-tagged attendance.",
    icon: AiOutlineSchedule,
  },
  {
    title: "Insights at a Glance",
    subtitle:
      "Harness the power of data with comprehensive analytics on employee performance and trends.",
    icon: BiStats,
  },
];

const featuresSectionData = [
  {
    title: "Empower Your Employees",
    bulletPoints: [
      "Access comprehensive employee data at a glance.",
      "Monitor positions, levels, and departmental histories.",
      "Manage personal and professional milestones in one place.",
    ],
    image: employeeProfile,
    imageRight: false,
  },
  {
    title: "Visualize Your Company Structure",
    bulletPoints: [
      "Navigate through departments and teams with ease.",
      "Understand the hierarchy and direct reporting relationships.",
      "Get a holistic view of your organizational layout.",
    ],
    image: orgChart,
    imageRight: true,
  },
];

function IndexPage() {
  const headerStyle = {
    backgroundImage: `url(${headerBackgroundImage})`, // Replace 'YourImageURLHere' with your image path
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    height: "50vh", // Adjust the height as needed
  };

  const overlayStyle = {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Black overlay with 50% opacity
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff", // White text color
  };

  const isSmallDevice = useMediaQuery({ maxWidth: 768 });

  const appFeatures = [
    {
      title: "Welcome to Your Personal HR Dashboard",
      bulletPoints: [
        "Get a personalized greeting and a snapshot of your upcoming week.",
        "Switch effortlessly between your schedule, announcements, and more.",
        "Never miss out on your colleagues' milestones and office events.",
      ],
      image: appHomePage,
    },
    {
      title: "Attendance Made Easy",
      bulletPoints: [
        "Track your attendance status and view your proximity to the workplace.",
        "Check in and out with a simple tap, using geo-tagged time tracking.",
        "Review your attendance records anytime for personal tracking and accountability.",
      ],
      imageRight: !isSmallDevice,
      image: appHClockinAllowed,
    },
    {
      title: "Stay Within Reach",
      bulletPoints: [
        "Receive instant notifications if you're clocking in from outside the allowed distance.",
        "Clear instructions to correct your location for successful clock-ins.",
        "Full visibility into your attendance history for reliability and trust.",
      ],
      image: appClockInNotAllowed,
    },
  ];

  return (
    <div>
      <PageMetaTags title={appConfigs.appName} />
      <IndexNavbar />
      <Container className={"mt-5 pt-5"}>
        <Row>
          <Col lg={6} className={"d-flex align-items-center"}>
            <div>
              <h1 className={""}>Empower Your Workforce, Streamline Your HR</h1>
              <p className={"fs-4 mt-4"}>
                Unleash the Full Potential of Your Team with Our Next-Generation
                HR Solutions
              </p>
            </div>
          </Col>
          <Col
            lg={6}
            className={"d-flex align-items-center justify-content-center"}
            style={{
              padding: "100px 20px 100px 20px",
            }}
          >
            <Image
              src={headerImage}
              alt={"HR Software"}
              fluid
              className={"img-fluid mx-auto"}
              width={500}
            />
          </Col>
        </Row>
      </Container>
      <div className={"bg-primary text-white py-5"}>
        <Container className={"my-5"}>
          <Row>
            {featuresData.map((feature, index) => (
              <div key={index} className={"col-md-6 col-lg-3 mb-4"}>
                <div className="text-center">
                  <feature.icon size={50} />
                  <h5 className={"mt-3"}>{feature.title}</h5>
                  <p>{feature.subtitle}</p>
                </div>
              </div>
            ))}
          </Row>
        </Container>
      </div>
      <div>
        <Container>
          {featuresSectionData.map((feature, index) => {
            if (isSmallDevice) {
              feature.imageRight = false;
            }
            return <FeatureSection {...feature} key={index} />;
          })}
        </Container>
      </div>
      <div className={"py-5"}>
        <Container>
          <h1 className={"text-center"}>Easy to use app for employees</h1>
          <p className={"text-center mb-5 mt-3"}>
            Empower your team with personalized dashboards, seamless attendance
            tracking, and real-time organizational insights.
          </p>
          {appFeatures.map((feature, index) => (
            <FeatureSection {...feature} key={index} />
          ))}
        </Container>
      </div>

      <Footer />
      <AppModal />
      <AppToasts />
    </div>
  );
}

export default IndexPage;
