import React, { useContext, useEffect, useState } from "react";
import {
  InfoDisplayTable,
  LoadingView,
  StatusBadge,
} from "../utils/components";
import DashboardPageContainer from "./components/DashboardPageContainer";
import {
  addFillColorToList,
  formatMoney,
  toTitleCase,
  updateObjectState,
  useAppModal,
  useOffCanvas,
} from "../utils/functions";
import { AppContext } from "../utils/components/AppContext";
import { useNavigate, useParams } from "react-router-dom";
import PageMetaTags from "./components/Shared/PageMetaTags";
import AppBreadCrumb from "./components/Shared/AppBreadCrumb";
import { AppRoutes } from "../utils/appRoutes";
import ProductPermissionCheckView from "./components/ProductPermissionCheckView";
import { EmpPermissions } from "../enums/EmpPermissions";
import { Products } from "../enums/OrgEnums";
import { Button, Card, Col, Row, Tab, Tabs } from "react-bootstrap";
import { formatDate } from "../utils/dateTime";
import { AppPieChart } from "../utils/components/AppCharts";
import PaginatedEmployeesDisplay from "./components/Employee/PaginatedEmployeesDisplay";
import { PDFViewer } from "@react-pdf/renderer";
import EmployeePayslipDocument from "./components/Payroll/EmployeePayslip";
import { FaMinus, FaTrashAlt } from "react-icons/fa";
import GenericForm from "./Forms/Shared/GenericForm";
import { FormFieldTypes } from "./FormFields";

function PayPeriodDetails({
  getRequest,
  postRequest,
  putRequest,
  patchRequest,
}) {
  const { contextState, updateContextState } = useContext(AppContext);
  const { permissions, countryConfigs } = contextState;
  const { showOffCanvas } = useOffCanvas();
  const { showAppModal } = useAppModal();

  const navigate = useNavigate();

  const [secondLoader, setSecondLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [payPeriod, setPayPeriod] = useState({});

  const { payPeriodId } = useParams();

  const getPaPeriodDetails = () => {
    getRequest(`/payroll/pay-period/${payPeriodId}`, setIsLoading, (res) => {
      setPayPeriod(res.pay_period);
    });
  };

  useEffect(() => {
    getPaPeriodDetails();
  }, []);

  const removeEmployee = (employee) => {
    showAppModal({
      title: "Remove Employee",
      component: (
        <p>
          Are you sure you want to remove {employee.first_name} from this pay
          period?
        </p>
      ),
      truthyFunction: () => {},
    });
  };

  const addDeduction = (employee) => {
    showOffCanvas({
      title: `Add Deduction for ${employee.first_name}`,
      subtitle: `Add an extra deduction for ${employee.first_name} that should be applied to this pay period.`,
      component: (
        <div>
          <GenericForm
            useGlobalState={true}
            useFormData={true}
            formConfigs={[
              {
                valueKey: "amount",
                type: FormFieldTypes.NUMBER,
              },
              {
                valueKey: "note",
                type: FormFieldTypes.NUMBER,
                labelName: "Reason for Deduction",
              },
              {
                valueKey: "pdf_file",
                type: FormFieldTypes.PDF,
                labelName: "Attach Document",
                isRequired: false,
              },
            ]}
          />
        </div>
      ),
    });
  };

  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <ProductPermissionCheckView
          product={Products.PAYROLL_MANAGEMENT}
          empPermissions={EmpPermissions.VIEW_PAYROLL}
        >
          <PageMetaTags title={`Payroll | Pay Period`} />
          <AppBreadCrumb
            items={[
              {
                text: "Pay Periods",
                path: `${AppRoutes.Payroll.path}?activeTab=pay_periods`,
              },
              {
                text: "Pay Period",
              },
            ]}
          />

          <Card className={"mb-4"}>
            <Card.Body className={"my-4"}>
              <Row>
                <Col lg={6}>
                  <InfoDisplayTable
                    title={"Pay Period Details"}
                    data={{
                      "Start Date": formatDate(payPeriod.start_date),
                      "End Date": formatDate(payPeriod.end_date),
                      "Payment Date": formatDate(payPeriod.payment_date),
                      Status: <StatusBadge status={payPeriod.status} />,
                    }}
                  />
                </Col>
                <Col lg={6}>
                  <h6 className={"text-center"}>Payments Statuses</h6>
                  <AppPieChart
                    data={addFillColorToList(payPeriod.stats || [])}
                    chartHeight={200}
                  />
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer className={"d-flex justify-content-end"}>
              <Button variant={"outline-primary"} onClick={() => {}}>
                Send for Approval
              </Button>
            </Card.Footer>
          </Card>

          <Tabs
            defaultActiveKey={countryConfigs.main_currency}
            id="pay-period-tabs"
          >
            {countryConfigs?.supported_currencies?.map((currency, i) => {
              currency = currency.toUpperCase();

              return (
                <Tab key={i} eventKey={currency} title={currency}>
                  <PaginatedEmployeesDisplay
                    endpoint={`/payroll/pay-period/${payPeriodId}/transactions/${currency}`}
                    getRequest={getRequest}
                    minimal={true}
                    extraColumns={[
                      {
                        columnName: "Total Earnings",
                        renderComponent: (data) => {
                          return (
                            <span>
                              {currency} {formatMoney(data.total_comp)}
                            </span>
                          );
                        },
                      },
                      {
                        columnName: "Deductions",
                        renderComponent: (data) => {
                          return (
                            <span>
                              -{currency}{" "}
                              {formatMoney(data.emp_deductions_paid_total)}
                            </span>
                          );
                        },
                      },
                      {
                        columnName: "Employer Contributions",
                        renderComponent: (data) => {
                          return (
                            <span>
                              {currency}{" "}
                              {formatMoney(data.org_deductions_paid_total)}
                            </span>
                          );
                        },
                      },
                      {
                        columnName: "Net Pay",
                        renderComponent: (data) => {
                          return (
                            <span>
                              {currency} {formatMoney(data.net_comp)}
                            </span>
                          );
                        },
                      },
                      {
                        columnName: "Payment Account",
                        key: "payment_account",
                      },
                      {
                        columnName: "Payslip",
                        renderComponent: (data) => {
                          return (
                            <Button
                              variant={"link"}
                              onClick={() => {
                                showAppModal({
                                  title: "Payslip",
                                  hideFooter: true,
                                  size: "xl",
                                  component: (
                                    <div className={"w-100"}>
                                      <PDFViewer
                                        width="100%"
                                        height={window.innerHeight - 100}
                                      >
                                        <EmployeePayslipDocument
                                          data={{
                                            ...data,
                                            currency,
                                            payment_date: formatDate(
                                              payPeriod.payment_date
                                            ),
                                            period_start_date: formatDate(
                                              payPeriod.start_date
                                            ),
                                            period_end_date: formatDate(
                                              payPeriod.end_date
                                            ),
                                          }}
                                          organization={payPeriod.organization}
                                        />
                                      </PDFViewer>
                                    </div>
                                  ),
                                });
                              }}
                            >
                              View Payslip
                            </Button>
                          );
                        },
                      },
                      {
                        columnName: "Actions",
                        renderComponent: (data) => {
                          return (
                            <ProductPermissionCheckView
                              empPermissions={EmpPermissions.MANAGE_PAYROLL}
                            >
                              <div className={"d-flex flex-row"}>
                                <Button
                                  variant={"outline-primary"}
                                  className={"me-2"}
                                  onClick={() => {
                                    addDeduction(data);
                                  }}
                                >
                                  <FaMinus />
                                </Button>
                                <Button
                                  variant={"outline-danger"}
                                  onClick={() => {
                                    removeEmployee(data);
                                  }}
                                >
                                  <FaTrashAlt />
                                </Button>
                              </div>
                            </ProductPermissionCheckView>
                          );
                        },
                      },
                    ]}
                  />
                </Tab>
              );
            })}
          </Tabs>
        </ProductPermissionCheckView>
      }
    />
  );
}

function PayPeriodDetailsPage() {
  return <DashboardPageContainer PageComponent={PayPeriodDetails} />;
}

export default PayPeriodDetailsPage;
