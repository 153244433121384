import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import IndexNavbar from "./components/Index/IndexNavbar";
import { AppConstants } from "../utils/appConstants";
import PageMetaTags from "./components/Shared/PageMetaTags";
import { appConfigs } from "../configs";

const PrivacyPolicyPage = () => {
  return (
    <>
      <PageMetaTags title={"Privacy Policy"} />
      <IndexNavbar />
      <Container className={"my-5 pt-5"}>
        <Row className="justify-content-md-center">
          <Col xs={12} lg={8}>
            <h1>Privacy Policy</h1>
            <p>Last updated: March 17, 2024</p>

            <h2>Introduction</h2>
            <p>
              Welcome to {appConfigs.companyName}'s HR application. This Privacy
              Policy outlines how we collect, use, and protect your information
              when you use our services. Our platform is designed to streamline
              HR processes efficiently while ensuring your privacy and data
              protection rights are respected. By using {appConfigs.companyName}
              , you agree to the collection and use of information in accordance
              with this policy. We are committed to maintaining the
              confidentiality, integrity, and security of your personal data.
            </p>

            <h2>Information Collection</h2>
            <p>
              {appConfigs.companyName} ("{appConfigs.companyName}," "we," or
              "us") collects information to provide, maintain, and improve our
              HR application. This information may include:
            </p>
            <ul>
              <li>
                User Accounts: When you create an account, we collect data like
                your name, email address, company affiliation, and role.
              </li>
              <li>
                Company Data: HR administrators may upload employee data,
                including names, contact information, job titles, performance
                reviews, and payroll information.
              </li>
              <li>
                Usage Data: We may collect data on how you interact with the
                application, such as accessed features and pages visited.
              </li>
            </ul>

            <h2>Use of Information</h2>
            <p>
              We use the information we collect for various purposes, including:
            </p>
            <ul>
              <li>
                Providing and improving {appConfigs.companyName} services.
              </li>
              <li>Administering user accounts and subscriptions.</li>
              <li>
                Facilitating communication between HR personnel and employees.
              </li>
              <li>Sending important updates and notifications.</li>
              <li>Conducting data analysis to improve user experience.</li>
            </ul>

            <h2>Data Sharing and Disclosure</h2>
            <p>
              {appConfigs.companyName} will not share your data with any third
              party except in the following circumstances:
            </p>
            <ul>
              <li>
                Service Providers: We may share data with trusted vendors who
                assist us in operating the application (e.g., data storage,
                analytics). These vendors are contractually obligated to protect
                your data.
              </li>
              <li>
                Legal Requirements: We may disclose data if required by law,
                subpoena, or to protect the rights and safety of users or the
                public.
              </li>
            </ul>

            <h2>User Rights and Data Control</h2>
            <p>
              You have control over your data in {appConfigs.companyName}. You
              can:
            </p>
            <ul>
              <li>Access and update your user account information.</li>
              <li>
                Request the deletion of your personal data (subject to legal and
                contractual restrictions).
              </li>
              <li>Opt-out of receiving marketing communications.</li>
            </ul>

            <h2>Cookies and Tracking Technologies</h2>
            <p>
              {appConfigs.companyName} may use cookies and similar tracking
              technologies to collect and store certain information. This
              information is used to enhance user experience, analyze usage
              trends, and personalize content. You can manage your cookie
              preferences within your browser settings.
            </p>

            <h2>Data Security</h2>
            <p>
              {appConfigs.companyName} takes data security seriously. We
              implement various security measures to protect user information,
              including secure servers, encryption, and access controls.
              However, no security system is foolproof.
            </p>

            <h2>International Data Transfers</h2>
            <p>
              {appConfigs.companyName}'s servers may be located outside your
              country of residence. By using {appConfigs.companyName}, you
              consent to the transfer of your information to these locations. We
              comply with applicable data protection regulations regarding
              international data transfers.
            </p>

            <h2>Children's Privacy</h2>
            <p>
              {appConfigs.companyName} is not directed towards children under
              13. We do not knowingly collect personal information from children
              under 13. If you are a parent or guardian and believe your child
              has provided us with personal information, please contact us at:
              {AppConstants.supportEmail} for deletion.
            </p>

            <h2>Changes to This Privacy Policy</h2>
            <p>
              {appConfigs.companyName} may update this Privacy Policy
              periodically. We will notify you of any significant changes.
            </p>

            <h2>Contact Information</h2>
            <p>
              If you have any questions regarding this Privacy Policy, please
              contact us at: {AppConstants.supportEmail}
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PrivacyPolicyPage;
