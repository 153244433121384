import React from "react";
import { Col, Row } from "react-bootstrap";
import { AppBarChart, AppPieChart } from "../../../utils/components/AppCharts";

function DefaultEmployeesStats({ statsData }) {
  const chartHeight = 350;
  return (
    <div>
      <Row className={"my-5"}>
        <Col lg={6}>
          <h4 className={"text-center mb-3"}>Gender Distribution </h4>
          <AppPieChart
            data={statsData.gender_distribution}
            chartHeight={chartHeight}
          />
        </Col>
        <Col lg={6}>
          <h4 className={"text-center mb-3"}>Employee Types</h4>
          <AppPieChart
            data={statsData.employee_types}
            chartHeight={chartHeight}
          />
        </Col>
      </Row>

      <Row className={"my-5"}>
        <Col lg={6}>
          <h4 className={"text-center mb-3"}>Position Distribution </h4>
          <AppPieChart
            data={statsData.positions_distribution}
            chartHeight={chartHeight}
          />
        </Col>
        <Col lg={6}>
          <h4 className={"text-center mb-3"}>Level Distribution</h4>
          <AppPieChart
            data={statsData.levels_distribution}
            chartHeight={chartHeight}
          />
        </Col>
      </Row>

      <div className={"mb-5 mt-4"}>
        <h4 className={"text-center mb-5"}>Employees Age Brackets</h4>
        <AppBarChart data={statsData.age_brackets} xName={"Age Brackets"} />
      </div>
    </div>
  );
}

export default DefaultEmployeesStats;
