import React from "react";
import { Card, Table } from "react-bootstrap";
import EmployeeHighlightCard from "./EmployeeHighlightCard";
import { NoInfoCard, StatusBadge } from "../../../utils/components";
import { dateFormats, formatDate } from "../../../utils/dateTime";
import EmployeeBasicHighlight from "../Shared/EmployeeBasicHighlight";

function ApprovalsTable({ approvalSteps }) {
  return (
    <>
      {approvalSteps.length === 0 && (
        <div>
          <NoInfoCard text={"No approval steps defined for this request"} />
        </div>
      )}

      {approvalSteps.map((step, index) => {
        return (
          <Card
            key={index}
            className={"mb-4"}
            style={{ opacity: step.is_active ? 1 : 0.5 }}
          >
            <Card.Header className={"py-4"}>
              <Card.Title as={"h5"} className={"mb-3"}>
                Step {step.order_position}. {step.name}
              </Card.Title>
              <Card.Subtitle>{step.description}</Card.Subtitle>
            </Card.Header>
            <Card.Body as={"div"}>
              <Table striped bordered hover responsive>
                <thead>
                  <tr className={"text-center fw-bold"}>
                    <th>Approver</th>
                    <th>Comments / Reason</th>
                    <th>Action Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {step.approvers.map((approver, index) => {
                    return (
                      <tr key={index} className={"align-middle text-center"}>
                        <td>
                          <EmployeeBasicHighlight
                            employee={approver.employee}
                          />
                        </td>
                        <td>{approver.comments || "None"}</td>
                        <td>
                          {formatDate(
                            approver.action_date,
                            dateFormats.daySingleDigitDMY12Hr
                          )}
                        </td>
                        <td>
                          <StatusBadge status={approver.status || "Pending"} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        );
      })}
    </>
  );
}

export default ApprovalsTable;
