import React, { useEffect, useState } from "react";
import { timeDiffCounter } from "../dateTime";
import InfoCard from "./InfoCard";

function CounterCard({ startTime, endTime, header }) {
  const [time, setTime] = useState("0.00");

  useEffect(() => {
    setTime(timeDiffCounter(startTime, endTime, setTime));
  }, [time]);
  return <InfoCard lg={4} header={header} body={time} />;
}

export default CounterCard;
