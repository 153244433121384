import React, { useState } from "react";
import { FaCheck, FaCopy } from "react-icons/fa";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const CopyWithTooltip = ({ text }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    setShowTooltip(true);
    setTimeout(() => {
      setShowTooltip(false);
    }, 2000); // Hide tooltip after 2 seconds
  };

  return (
    <>
      {showTooltip && <FaCheck className={"ms-2 fw-bolder text-success"} />}
      {!showTooltip && (
        <FaCopy className={"ms-2 pointer"} onClick={handleCopy} />
      )}
    </>
  );
};

export default CopyWithTooltip;
