import React, { useContext, useEffect, useRef, useState } from "react";
import { ConditionalView, LoadingView } from "../utils/components";
import { apiRequest } from "../utils/apiRequests";
import DashboardPageContainer from "./components/DashboardPageContainer";
import {
  getObjectFromListByKey,
  toTitleCase,
  updateObjectState,
  updateStateAtIndex,
  useToast,
} from "../utils/functions";
import { AppContext } from "../utils/components/AppContext";
import { Tabs, Tab, Offcanvas, Button, Form, Col } from "react-bootstrap";
import { OrganizationCard } from "./components/OrganizationCard";
import { PermissionGroupHighlight } from "./components/PermissionGroups";
import { FaEdit, FaPlus } from "react-icons/fa";
import { AppConstants } from "../utils/appConstants";
import OrganizationForm from "./Forms/Organization";
import { BranchCard } from "./components/Branch";
import BranchForm from "./Forms/Branch";
import { EmployeeLargeCard, EmployeeList } from "./components/EmployeeCards";
import { DepartmentForm } from "./Forms/Department";
import { DepartmentCard } from "./components/Department";
import { PermissionGroupsForm, PermissionsForm } from "./Forms/PermissionsForm";
import EmployeeSearch from "./components/EmployeeSearch";
import { formatDateStrings } from "../utils/dateTime";
import OrganizationTree from "./components/Organization/OrganizationTree";
import PageMetaTags from "./components/Shared/PageMetaTags";
import { useSearchParams } from "react-router-dom";
import { appConfigs } from "../configs";
import ProductPermissionCheckView from "./components/ProductPermissionCheckView";
import { Products } from "../enums/OrgEnums";

const ORGANIZATION = "organization";
const CHILD_ORGANIZATION = "child_organization";
const DEPARTMENT = "department";
const BRANCH = "branch";
const GROUP = "permission_group";
const GROUP_MEMBER = "group_member";
const GROUP_PERMISSION = "group_permission";

export function AdminPage() {
  const { showToast } = useToast();
  const { contextState, updateContextState, hasOrgProduct, hasEmpPermission } =
    useContext(AppContext);
  const { permissions } = contextState;

  const { labelsOverride } = contextState;

  useEffect(() => {
    getAdminInfo();
  }, []);

  const tabsPermissions = {
    organization: permissions.MANAGE_ORGANIZATION,
    permission_group: permissions.MANAGE_PERMISSIONS,
    departments: permissions.MANAGE_DEPARTMENTS,
    branches: permissions.MANAGE_BRANCHES,
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const [canvasInfo, setCanvasInfo] = useState({
    type: "",
    title: "",
    subtitle: "",
  });
  const [activeTabTitle, setActiveTabTitle] = useState("");
  const [updatedItemId, setUpdatedItemId] = useState(null);
  const [allPermissions, setAllPermissions] = useState([]);
  const [activeTab, setActiveTab] = useState(null);
  const [data, setData] = useState({});
  const [organizationTree, setOrganizationTree] = useState({});
  const [organization, setOrganization] = useState({});
  const [branches, setBranches] = useState(null);
  const [departments, setDepartments] = useState(null);
  const [groups, setGroups] = useState(null);
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const [filterTerm, setFilterTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isLoading) return;
    scrollToItem(updatedItemId);
  }, [groups, branches, departments, isLoading]);

  useEffect(() => {
    const tabName = searchParams.get("activeTab");
    setActiveTab(tabName);
  }, [searchParams]);

  const getAdminInfo = async () => {
    setIsLoading(true);
    setUpdatedItemId(null);
    const { success, response } = await apiRequest.get(`/organization/admin`);
    if (success) {
      setGroups(response.groups);
      setBranches(response.branches);
      setDepartments(response.departments);
      setOrganization(response.organization);
      setActiveTab(searchParams.get("activeTab") || response.active_tab);
      setAllPermissions(response.all_permissions);
      setOrganizationTree(response.organization_tree);
    } else {
      showToast({
        title: "Error Getting Info",
        message: response.message,
      });
    }
    setIsLoading(false);
  };

  const updateItem = async (endpoint, type, img_key = null) => {
    let vals = { ...data };
    setUpdatedItemId(null);
    setIsLoading(true);
    const formData = new FormData();
    if (img_key) {
      formData.append("image", vals[img_key]);
      delete vals[img_key];
    }
    formData.append("inputs", JSON.stringify(vals));

    const { success, response } = await apiRequest.patch(
      endpoint,
      formData,
      true
    );
    if (success) {
      if (type === ORGANIZATION) {
        setOrganization(response.organization);
      } else if (type === CHILD_ORGANIZATION) {
      } else if ([GROUP, GROUP_MEMBER, GROUP_PERMISSION].includes(type)) {
        setUpdatedItemId(response.permission_group.id);
      } else {
        setUpdatedItemId(response[type].id);
      }
      if ([GROUP, GROUP_MEMBER, GROUP_PERMISSION].includes(type)) {
        updateStateAtIndex(
          groups,
          setGroups,
          response.permission_group,
          data.update_index
        );
      }
      if (type === DEPARTMENT) {
        updateStateAtIndex(
          departments,
          setDepartments,
          response.department,
          data.update_index
        );
      }
      if (type === BRANCH) {
        updateStateAtIndex(
          branches,
          setBranches,
          response.branch,
          data.update_index
        );
      }
      setShowOffCanvas(false);
      showToast({
        message: response.message,
        success: true,
      });
    } else {
      showToast({
        title: "Error Updating Info",
        message: response.message,
      });
    }
    setIsLoading(false);
  };

  const addItem = async (endpoint, type) => {
    setIsLoading(true);
    const { success, response } = await apiRequest.put(endpoint, data);
    if (success) {
      const item = [response[type]];
      if (type === DEPARTMENT) {
        setDepartments([...item, ...departments]);
      }
      if (type === BRANCH) {
        setBranches([...item, ...branches]);
      }
      if (type === GROUP) {
        setGroups([...item, ...groups]);
      }
      if (type === CHILD_ORGANIZATION) {
        setOrganizationTree(response.organization_tree);
      }
      setShowOffCanvas(false);
      showToast({
        message: response.message,
        success: true,
      });
    } else {
      showToast({
        title: "Error adding " + type,
        message: response.message,
      });
    }
    setIsLoading(false);
  };

  const scrollToItem = (itemId) => {
    if (!itemId) return;
    const itemElement = document.getElementById(`${itemId}`);
    if (itemElement) {
      itemElement.scrollIntoView({ behavior: "smooth" });
      setTimeout(() => {
        window.scrollBy(0, -75);
      }, 1000);
    }
    setUpdatedItemId(null);
  };

  const updateQueryParam = (key, value) => {
    setSearchParams({ ...searchParams, [key]: value });
  };

  const deleteItem = async (endpoint, item_id, type) => {
    setIsLoading(true);
    const { success, response } = await apiRequest.delete(endpoint);
    if (success) {
      if (type === DEPARTMENT) {
        setDepartments(departments.filter((d) => d.id !== item_id));
      }
      if (type === BRANCH) {
        setBranches(branches.filter((b) => b.id !== item_id));
      }
      if (type === GROUP) {
        setGroups(groups.filter((g) => g.id !== item_id));
      }
      showToast({
        message: response.message,
        success: true,
      });
    } else {
      showToast({
        title: "Error deleting " + type,
        message: response.message,
      });
    }
    setIsLoading(false);
  };

  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <>
          <PageMetaTags title={`Admin | ${toTitleCase(activeTab)}`} />
          <ConditionalView
            condition={organization || branches || departments || groups}
            trueView={
              <div className={"min-vh-100"}>
                <Tabs
                  className={"mb-2 mt-3 sticky-top bg-body"}
                  activeKey={activeTab}
                  id="controlled-tab"
                  variant={"underline"}
                  onSelect={(key) => updateQueryParam("activeTab", key)}
                >
                  {organization &&
                    hasOrgProduct(Products.ORGANIZATION_MANAGEMENT) && (
                      <Tab
                        eventKey={"organization"}
                        title={"Organization"}
                        tabClassName={"position-sticky top-0"}
                      >
                        <OrganizationCard
                          organization={organization}
                          onEditClick={() => {
                            setData(organization);
                            setCanvasInfo({
                              title: `Edit ${organization.name}'s Info`,
                              type: ORGANIZATION,
                              subtitle: `Changes you make here will be visible to anyone who interacts with your organization on ${appConfigs.appName}`,
                            });
                            setShowOffCanvas(true);
                          }}
                          onAddChildClick={
                            !hasOrgProduct(Products.MULTI_ORGANIZATION)
                              ? null
                              : () => {
                                  setData({
                                    industry: organization.industry,
                                    industry_category:
                                      organization.industry_category,
                                  });
                                  setCanvasInfo({
                                    title: `Add Child Organization`,
                                    type: CHILD_ORGANIZATION,
                                    subtitle: `Add a new organization that is a child of ${organization.name}`,
                                  });
                                  setShowOffCanvas(true);
                                }
                          }
                        />
                        <ProductPermissionCheckView
                          product={Products.MULTI_ORGANIZATION}
                        >
                          <div className={"mt-5"}>
                            <h3 className={"text-center mb-4"}>
                              Organization Tree
                            </h3>
                            <OrganizationTree
                              organizationTree={organizationTree}
                            />
                          </div>
                        </ProductPermissionCheckView>
                      </Tab>
                    )}
                  {groups && (
                    <Tab
                      eventKey={"permission_group"}
                      title={"Permission Groups"}
                    >
                      {permissions.MANAGE_PERMISSIONS && (
                        <div
                          className={
                            "pe-4 py-2 d-flex justify-content-end gap-0"
                          }
                        >
                          <Button
                            className={"rounded-3 p-2"}
                            variant={"success"}
                            size={"sm"}
                            style={{ position: "relative" }}
                            onClick={() => {
                              setData({});
                              setCanvasInfo({
                                title: `Add a New Permission Group`,
                                type: GROUP,
                                subtitle: `Create a custom group and add permissions that fit your organization's needs.`,
                              });
                              setShowOffCanvas(true);
                            }}
                          >
                            <FaPlus className={"me-2"} />
                            New Group
                          </Button>
                        </div>
                      )}
                      <Form>
                        <Form.Group
                          controlId="employeeSearch"
                          className={"my-4"}
                        >
                          <Form.Control
                            type="text"
                            value={filterTerm}
                            onChange={(event) => {
                              setFilterTerm(event.target.value);
                            }}
                            placeholder={"Search Permission Groups"}
                          />
                        </Form.Group>
                      </Form>
                      {groups.map((group, i) => {
                        if (
                          filterTerm.length > 0 &&
                          !group.name
                            .toLowerCase()
                            .includes(filterTerm.toLowerCase())
                        ) {
                          return <div key={i} />;
                        }
                        return (
                          <div key={i} id={group.id}>
                            <PermissionGroupHighlight
                              group={group}
                              canManage={permissions.MANAGE_PERMISSIONS}
                              onEditClick={(permissions) => {
                                setData({
                                  ...group,
                                  update_index: i,
                                });
                                setCanvasInfo({
                                  title: `Edit Group`,
                                  subtitle: `Modify the details for the "${group.name}" permission group.`,
                                  type: GROUP,
                                });
                                setShowOffCanvas(true);
                              }}
                              onManagePermissions={(permissions) => {
                                setData({
                                  group_id: group.id,
                                  existingPermissions: permissions,
                                  update_index: i,
                                });
                                setCanvasInfo({
                                  title: `Manage Permissions`,
                                  subtitle: `Add or remove permissions for ${group.name}. Note that default permissions cannot be removed for default groups. You can always create a new group and add your desired permissions.`,
                                  type: GROUP_PERMISSION,
                                });
                                setShowOffCanvas(true);
                              }}
                              onManageMembersClick={() => {
                                setData({
                                  group_id: group.id,
                                  members: group.members,
                                  update_index: i,
                                });
                                setCanvasInfo({
                                  title: `Manage Members`,
                                  subtitle: `Add or remove members for ${group.name}.`,
                                  type: GROUP_MEMBER,
                                });
                                setShowOffCanvas(true);
                              }}
                            />
                          </div>
                        );
                      })}
                    </Tab>
                  )}

                  {departments && (
                    <Tab
                      eventKey={"departments"}
                      title={labelsOverride.departments || "Departments"}
                    >
                      {permissions.MANAGE_DEPARTMENTS && (
                        <div
                          className={
                            "pe-4 py-2 d-flex justify-content-end gap-0"
                          }
                        >
                          <Button
                            className={"rounded-3 p-2"}
                            variant={"primary"}
                            size={"sm"}
                            style={{ position: "relative" }}
                            onClick={() => {
                              setData({});
                              setCanvasInfo({
                                title: `Add a New ${
                                  labelsOverride.department || "Department"
                                }`,
                                type: DEPARTMENT,
                                subtitle: `Add a new ${
                                  labelsOverride.department || "Department"
                                } to your organization."`,
                              });
                              setShowOffCanvas(true);
                            }}
                          >
                            <FaPlus className={"me-2"} />
                            New {labelsOverride.department || "Department"}
                          </Button>
                        </div>
                      )}
                      <Form>
                        <Form.Group
                          controlId="employeeSearch"
                          className={"my-4"}
                        >
                          <Form.Control
                            type="text"
                            value={filterTerm}
                            onChange={(event) => {
                              setFilterTerm(event.target.value);
                            }}
                            placeholder={`Search ${
                              labelsOverride.departments || "Departments"
                            }`}
                          />
                        </Form.Group>
                      </Form>
                      {departments.map((department, i) => {
                        if (
                          filterTerm.length > 0 &&
                          !department.name
                            .toLowerCase()
                            .includes(filterTerm.toLowerCase())
                        ) {
                          return <div key={i} />;
                        }
                        return (
                          <div key={i} id={department.id}>
                            <DepartmentCard
                              department={department}
                              canManage={permissions.MANAGE_DEPARTMENTS}
                              onEditClick={() => {
                                setData({
                                  ...department,
                                  update_index: i,
                                });
                                setCanvasInfo({
                                  title: `Edit Department`,
                                  type: DEPARTMENT,
                                  subtitle: `Edit the "${department.name}" department`,
                                });
                                setShowOffCanvas(true);
                              }}
                              onDeleteClick={(department) => {
                                deleteItem(
                                  `/organization/departments/${department.id}`,
                                  department.id,
                                  DEPARTMENT
                                );
                              }}
                            />
                          </div>
                        );
                      })}
                      {departments?.length === 0 && (
                        <h4 className={"my-5 text-center"}>
                          There are no
                          {labelsOverride.departments || "Departments"} for your
                          organization. Add one or more so you can start adding
                          people to your organization.
                        </h4>
                      )}
                    </Tab>
                  )}

                  {branches && (
                    <Tab eventKey={"branches"} title={"Branches"}>
                      {permissions.MANAGE_BRANCHES && (
                        <div
                          className={
                            "pe-4 pt-2 pb-1 d-flex justify-content-end gap-0"
                          }
                        >
                          <Button
                            className={"rounded-3 p-2"}
                            variant={"success"}
                            size={"sm"}
                            style={{ position: "relative" }}
                            onClick={() => {
                              setData({
                                is_headquarters: false,
                                industry:
                                  organization?.industry ||
                                  branches[0].industry,
                                industry_category:
                                  organization?.industry_category ||
                                  branches[0].industry_category,
                              });
                              setCanvasInfo({
                                title: `Add a New Branch`,
                                type: BRANCH,
                                subtitle: `Add a new branch to your organization`,
                              });
                              setShowOffCanvas(true);
                            }}
                          >
                            <FaPlus className={"me-2"} />
                            New Branch
                          </Button>
                        </div>
                      )}
                      <Form>
                        <Form.Group
                          controlId="employeeSearch"
                          className={"my-4"}
                        >
                          <Form.Control
                            type="text"
                            value={filterTerm}
                            onChange={(event) => {
                              setFilterTerm(event.target.value);
                            }}
                            placeholder={"Search Branches"}
                          />
                        </Form.Group>
                      </Form>

                      {branches.map((branch, i) => {
                        if (
                          filterTerm.length > 0 &&
                          !branch.name
                            .toLowerCase()
                            .includes(filterTerm.toLowerCase())
                        ) {
                          return <div key={i} />;
                        }
                        return (
                          <div key={i} className={"mb-4"} id={branch.id}>
                            <BranchCard
                              branch={branch}
                              canManage={permissions.MANAGE_BRANCHES}
                              onEditClick={() => {
                                setData({ ...branch, update_index: i });
                                setCanvasInfo({
                                  title: `Edit Branch`,
                                  type: BRANCH,
                                  subtitle: `Modify information for the "${branch.name}" branch`,
                                });
                                setShowOffCanvas(true);
                              }}
                              onAddLocationClick={(val) => {
                                setData({
                                  branch_id: branch.id,
                                  industry: branch.industry,
                                  industry_category: branch.industry_category,
                                  update_index: i,
                                });
                                setCanvasInfo({
                                  title: `New Branch Location`,
                                  type: BRANCH,
                                  subtitle: `Add a location to the "${branch.name}" branch`,
                                });
                                setShowOffCanvas(true);
                              }}
                              onEditLocation={(location) => {
                                setData({ ...location, update_index: i });
                                setCanvasInfo({
                                  title: `Edit Branch Location`,
                                  type: BRANCH,
                                  subtitle: `Modify information for the "${location.name}" branch location`,
                                });
                                setShowOffCanvas(true);
                              }}
                              // onDeleteClick={() => {}}
                            />
                          </div>
                        );
                      })}
                    </Tab>
                  )}
                </Tabs>
              </div>
            }
            falseView={<></>}
          />

          <Offcanvas
            show={showOffCanvas}
            onHide={() => setShowOffCanvas(false)}
            placement={"end"}
            backdrop={"static"}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>{canvasInfo.title}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className={"mb-5"}>{canvasInfo.subtitle}</div>
              {canvasInfo.type === ORGANIZATION && (
                <div>
                  <OrganizationForm
                    state={formatDateStrings({ ...data })}
                    setState={setData}
                    onSubmit={() => {
                      let img_key = null;
                      const has_image = typeof data.logo !== "string";
                      if (has_image) {
                        img_key = "logo";
                      }
                      updateItem(
                        `/organization/${organization.id}`,
                        ORGANIZATION,
                        img_key
                      );
                    }}
                    isEdit={true}
                  />
                </div>
              )}

              {canvasInfo.type === CHILD_ORGANIZATION && (
                <div>
                  <OrganizationForm
                    state={formatDateStrings({ ...data })}
                    setState={setData}
                    onSubmit={() => {
                      let img_key = null;
                      const has_image = typeof data.logo !== "string";
                      if (has_image) {
                        img_key = "logo";
                      }
                      updateItem(
                        `/organization/child`,
                        CHILD_ORGANIZATION,
                        img_key
                      );
                    }}
                    isEdit={false}
                  />
                </div>
              )}

              {canvasInfo.type === GROUP && (
                <div>
                  <PermissionGroupsForm
                    state={formatDateStrings({ ...data })}
                    setState={setData}
                    onSubmit={() => {
                      if (data.id) {
                        updateItem(
                          `/organization/permission-groups/${data.id}`,
                          GROUP
                        );
                      } else {
                        addItem(`/organization/permission-groups`, GROUP);
                      }
                    }}
                  />
                </div>
              )}

              {canvasInfo.type === DEPARTMENT && (
                <div>
                  <DepartmentForm
                    state={formatDateStrings({ ...data })}
                    setState={setData}
                    onSubmit={() => {
                      if (data.id) {
                        updateItem(
                          `/organization/departments/${data.id}`,
                          DEPARTMENT
                        );
                      } else {
                        addItem(`/organization/departments`, DEPARTMENT);
                      }
                    }}
                  />
                </div>
              )}

              {canvasInfo.type === BRANCH && (
                <div>
                  <BranchForm
                    state={formatDateStrings({ ...data })}
                    setState={setData}
                    isMainBranch={!data.branch_id}
                    onSubmit={() => {
                      if (data.id) {
                        let endpoint = `/organization/branches/${data.id}`;
                        if (data.branch_id) {
                          endpoint = `/organization/branches/location/${data.id}`;
                        }
                        updateItem(endpoint, BRANCH);
                      } else {
                        let endpoint = `/organization/branches`;
                        if (data.branch_id) {
                          endpoint = `/organization/branches/${data.branch_id}/location`;
                        }
                        addItem(endpoint, BRANCH);
                      }
                    }}
                  />
                </div>
              )}

              {canvasInfo.type === GROUP_PERMISSION && (
                <div>
                  <PermissionsForm
                    permissions={allPermissions}
                    setState={setData}
                    existingPermissions={data.existingPermissions}
                    onSubmit={(permissions) => {
                      updateItem(
                        `/organization/permission-groups/${data.group_id}/permissions`,
                        GROUP_PERMISSION
                      );
                    }}
                  />
                </div>
              )}

              {canvasInfo.type === GROUP_MEMBER && (
                <div>
                  <EmployeeSearch
                    onResultClick={(employee) => {
                      if (
                        getObjectFromListByKey(data.members, "id", employee.id)
                      ) {
                        return;
                      }
                      updateObjectState(setData, {
                        members: [employee, ...data.members],
                      });
                    }}
                  />
                  <p className={"my-3 fw-bold"}>Group Members</p>
                  <EmployeeList
                    employees={data.members}
                    add={false}
                    action={(employee, i) => {
                      const members = [...data.members];
                      members.splice(i, 1);
                      updateObjectState(setData, {
                        members,
                      });
                    }}
                  />
                  <Button
                    type="submit"
                    variant="primary"
                    className={"w-100 mt-4"}
                    onClick={() => {
                      updateItem(
                        `/organization/permission-groups/${data.group_id}/members`,
                        GROUP_MEMBER
                      );
                    }}
                  >
                    Save
                  </Button>
                </div>
              )}
            </Offcanvas.Body>
          </Offcanvas>
        </>
      }
    />
  );
}

function Admin() {
  return <DashboardPageContainer PageComponent={AdminPage} />;
}
export default Admin;
