import React, { useContext } from "react";
import {
  AddItemButton,
  NoInfoCard,
  StatusBadge,
} from "../../../utils/components";
import {
  isEmpty,
  updateArrayState,
  updateObjectState,
  useAppModal,
  useOffCanvas,
} from "../../../utils/functions";
import { AppContext } from "../../../utils/components/AppContext";
import { Alert, Badge, Button, Card, Row } from "react-bootstrap";
import { RiAlertLine } from "react-icons/ri";
import InfoCard from "../../../utils/components/InfoCard";
import EmployeesTableDisplayModal from "../Employee/EmployeesTableDisplayModal";
import ProductPermissionCheckView from "../ProductPermissionCheckView";
import { EmpPermissions } from "../../../enums/EmpPermissions";
import CompensationForm from "../../Forms/Employee/CompensationForm";
import { formatDate } from "../../../utils/dateTime";
import { FaChevronRight } from "react-icons/fa";
import EmployeeHighlightCard from "../Employee/EmployeeHighlightCard";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../utils/appRoutes";

function PayPeriodsHighlight({
  payPeriods,
  setPayPeriods,
  permissions,
  putRequest,
  getRequest,
  patchRequest,
  loaderCallback,
  payrollSettings,
  setPayrollSettings,
  configs,
}) {
  const { showOffCanvas } = useOffCanvas();
  const { updateContextState, hasEmpPermission } = useContext(AppContext);
  const { showAppModal } = useAppModal();

  const navigate = useNavigate();

  const displayAlerts = (settings) => {
    if (
      !isEmpty(settings.non_comp_employees_count) &&
      settings.non_comp_employees_count > 0
    ) {
      return true;
    }

    return false;
  };

  const bulkAssignCompensation = (noCompOnly = false) => {
    updateContextState({
      formData: {
        is_active: true,
      },
    });
    showOffCanvas({
      title: `Bulk Assign Compensation`,
      subtitle: `Assign compensation to multiple employees at once.`,
      component: (
        <>
          {noCompOnly && (
            <Alert variant={"warning"} className={"mb-5"}>
              <strong>
                Only employees with NO active compensations will be assigned
                this compensation.
              </strong>
            </Alert>
          )}
          <CompensationForm
            configs={configs}
            wageType={"Salary"}
            isBulk={true}
            onSubmit={(data) => {
              if (noCompOnly) {
                data.no_comp_only = true;
              }
              putRequest(
                `/payroll/compensation/bulk`,
                loaderCallback,
                (res) => {
                  updateObjectState(setPayrollSettings, {
                    non_comp_employees_count: res.unassigned_count,
                  });
                },
                data
              );
            }}
          />
        </>
      ),
    });
  };

  return (
    <div>
      <Card>
        <Card.Header className={"py-4"}>
          <Card.Title>Pay Periods</Card.Title>
          <Card.Subtitle as={"p"} className={"mt-3"}>
            Customize pay cycles to match your organization's preferences, from
            weekly to monthly and more, for streamlined and precise payroll
            management.
          </Card.Subtitle>
        </Card.Header>
      </Card>
      <ProductPermissionCheckView
        empPermissions={EmpPermissions.MANAGE_COMPENSATION}
      >
        <div className={"d-flex justify-content-end mt-3"}>
          <AddItemButton
            title={"Bulk Assign Compensation"}
            onClick={() => {
              bulkAssignCompensation();
            }}
          />
        </div>
      </ProductPermissionCheckView>

      {displayAlerts(payrollSettings) && (
        <Card className={"mt-4"}>
          <Card.Header className={"py-4 text-center"}>
            <Badge bg={"danger"} className={"text-center fs-2"}>
              <RiAlertLine className={""} size={45} /> Alerts
            </Badge>
          </Card.Header>
          <Card.Body className={"py-3"}>
            <Row>
              {!isEmpty(payrollSettings.non_comp_employees_count) &&
                payrollSettings.non_comp_employees_count > 0 && (
                  <InfoCard
                    lg={4}
                    header={"Employees without Compensation"}
                    footer={
                      <ProductPermissionCheckView
                        empPermissions={EmpPermissions.MANAGE_COMPENSATION}
                        falsyComponent={
                          <small>
                            Someone with the{" "}
                            {EmpPermissions.MANAGE_COMPENSATION} permission can
                            bulk assign compensations to employees.
                          </small>
                        }
                      >
                        <div className={"h-100"}>
                          <Button
                            variant={"outline-primary"}
                            size={"sm"}
                            className={"align-self-center align-middle"}
                            onClick={() => {
                              bulkAssignCompensation(true);
                            }}
                          >
                            Bulk Assign Compensations
                          </Button>
                        </div>
                      </ProductPermissionCheckView>
                    }
                    body={
                      <EmployeesTableDisplayModal
                        getRequest={getRequest}
                        modalTitle={`Employees without Compensation`}
                        endpoint={`/payroll/employees-without-comp`}
                      >
                        <h5 className={"text-center text-primary"}>
                          {payrollSettings.non_comp_employees_count} Employees
                        </h5>
                      </EmployeesTableDisplayModal>
                    }
                  />
                )}
            </Row>
          </Card.Body>
        </Card>
      )}

      {payrollSettings.con_generate_next_pay_period &&
        hasEmpPermission(EmpPermissions.MANAGE_PAYROLL) && (
          <Card body={true} className={"mt-4 py-4 text-center"}>
            <h5>Generate Next Pay Period: </h5>
            <p>
              Generating the next pay period will give you the opportunity to
              view upcoming salaries and other paymnets and make adjustments
              before submitting for approval
            </p>
            <Button
              variant={"primary"}
              className={"mt-3"}
              onClick={() => {
                showAppModal({
                  title: "Generate Next Pay Period",
                  component: (
                    <p>
                      Are you sure you want to generate the next pay period?
                      This action cannot be undone.
                    </p>
                  ),
                  truthyFunction: () => {
                    patchRequest(
                      `/payroll/pay-periods/generate-next`,
                      loaderCallback,
                      (res) => {
                        updateObjectState(setPayrollSettings, {
                          con_generate_next_pay_period: false,
                        });
                        setPayPeriods([res.pay_period, ...payPeriods]);
                      },
                      {},
                      false,
                      false
                    );
                  },
                });
              }}
            >
              Generate...
            </Button>
          </Card>
        )}

      <div className={"mt-4"}>
        <h2 className={"text-center my-5"}>Pay Periods</h2>
        {payPeriods.length === 0 && (
          <NoInfoCard
            text={"No pay periods have been created for your organization."}
          />
        )}

        {payPeriods.map((period, i) => {
          return (
            <Card key={i} className={"mb-3"}>
              <Card.Body
                className={"d-flex justify-content-between py-4 pointer"}
                onClick={() => {
                  navigate(`${AppRoutes.PayPeriod.paramLessPath}/${period.id}`);
                }}
              >
                <div>
                  <h6>
                    {formatDate(period.start_date)} -{" "}
                    {formatDate(period.end_date)}
                  </h6>
                  <p className={"small mt-2 mb-0"}>
                    Created on: {formatDate(period.timestamp)}
                  </p>
                </div>
                <div>
                  <StatusBadge status={period.status} />
                  <FaChevronRight className={"ms-3"} />
                </div>
              </Card.Body>
              <Card.Footer>
                <strong>Created By:</strong>
                <EmployeeHighlightCard employee={period.author_employee} />
              </Card.Footer>
            </Card>
          );
        })}
      </div>
    </div>
  );
}

export default PayPeriodsHighlight;
