import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../utils/components/AppContext";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import DashboardPageContainer from "./components/DashboardPageContainer";
import {
  InfoDisplayTable,
  LoadingView,
  StatusBadge,
} from "../utils/components";
import PageMetaTags from "./components/Shared/PageMetaTags";
import {
  addFillColorToList,
  toTitleCase,
  useAppModal,
  useOffCanvas,
} from "../utils/functions";
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Col,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import PerformanceReviewForm from "./Forms/Performance/PerformanceReviewForm";
import PaginatedEmployeesDisplay from "./components/Employee/PaginatedEmployeesDisplay";
import PerformanceReviewPeriodFormStepTab from "./components/Performance/PerformanceReviewPeriodFormStepTab";
import { AppRoutes } from "../utils/appRoutes";
import { FaTrashCan } from "react-icons/fa6";
import GenericForm from "./Forms/Shared/GenericForm";
import { SharedFormConfigs } from "./Forms/FormsConfigs/SharedFormConfigs";
import { FaExternalLinkAlt, FaPlus } from "react-icons/fa";
import AppBreadCrumb from "./components/Shared/AppBreadCrumb";
import ReviewPeriodFormStatsView from "./components/Performance/ReviewPeriodFormStatsView";
import { AppPieChart } from "../utils/components/AppCharts";

function PerformanceReviewPeriodForm({
  getRequest,
  postRequest,
  putRequest,
  patchRequest,
  deleteRequest,
}) {
  const { contextState, updateContextState } = useContext(AppContext);
  const { permissions, labelsOverride } = contextState;

  const navigate = useNavigate();

  const { formId } = useParams();
  const { showAppModal, closeModal } = useAppModal();
  const { showOffCanvas } = useOffCanvas();

  const [searchParams, setSearchParams] = useSearchParams();
  const updateQueryParam = (key, value) => {
    setSearchParams({ ...searchParams, [key]: value });
  };

  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(searchParams.get("activeTab"));
  const [showSecondLoader, setShowSecondLoader] = useState(false);
  const [form, setForm] = useState({});
  const [steps, setSteps] = useState([]);
  const [reviewPeriod, setReviewPeriod] = useState({});
  const [periodFormStats, setPeriodFormStats] = useState({});
  const [periodForm, setPeriodForm] = useState({});

  const getPeriodData = (isUpdate = false) => {
    getRequest(
      `/performance/review-period/form/${formId}?updateFirstStep=${isUpdate}`,
      setIsLoading,
      (response) => {
        setPeriodForm(response.period_form);
        setForm(response.period_form.form);
        setSteps(response.form_steps);
        setReviewPeriod(response.review_period);
        setPeriodFormStats(response.period_form_stats);
      },
      isUpdate
    );
  };

  useEffect(() => {
    getPeriodData();
  }, []);

  const activateTab = (key) => {
    setActiveTab(key);
    updateQueryParam("activeTab", key);
  };

  useEffect(() => {
    activateTab(searchParams.get("activeTab") || "overview");
  }, [searchParams]);

  const addReviewee = () => {
    showOffCanvas({
      title: `Add reviewee to ${reviewPeriod.name} review period`,
      component: (
        <GenericForm
          formConfigs={SharedFormConfigs.SingleEntitySelection({})}
          onSubmit={(formData) => {
            putRequest(
              `/performance/review-period/${reviewPeriod.id}/period-form/${periodForm.id}/reviewee`,
              setIsLoading,
              (response) => {
                getPeriodData();
              },
              formData
            );
          }}
        />
      ),
    });
  };

  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <div>
          <PageMetaTags
            title={`Performance Review Form | ${toTitleCase(activeTab)}`}
          />

          <AppBreadCrumb
            items={[
              {
                text: "Review Periods",
                path: `${AppRoutes.Performance.path}?activeTab=review_periods`,
              },
              {
                text: reviewPeriod.name,
                path: `${AppRoutes.PerformanceReviewPeriod.paramLessPath}/${periodForm.period_id}?activeTab=forms`,
              },
              { text: `Period Form: ${form?.name || "Form"}` },
            ]}
          />

          <Card className={"mb-3 border-1 border-primary"}>
            <Card.Header className={"py-4 d-flex justify-content-between"}>
              <Card.Title>"{form?.name}" Form</Card.Title>
              {permissions.MANAGE_PERFORMANCE_REVIEWS && periodForm.can_delete && (
                <div>
                  <Button
                    variant={"outline-danger"}
                    onClick={() => {
                      showAppModal({
                        title: `Delete ${form.name} Form from ${reviewPeriod.name}`,
                        component: (
                          <p>
                            Are you sure you want to delete{" "}
                            <strong>{form.name}</strong> form from{" "}
                            {reviewPeriod.name}?
                          </p>
                        ),
                        truthyFunction: () => {
                          deleteRequest(
                            `/performance/review-period/form/${formId}`,
                            setIsLoading,
                            (response) => {
                              navigate(
                                `${AppRoutes.PerformanceReviewPeriod.paramLessPath}/${periodForm.period_id}?activeTab=forms`
                              );
                            }
                          );
                        },
                      });
                    }}
                  >
                    <FaTrashCan />
                  </Button>
                </div>
              )}
            </Card.Header>
            <Card.Body>
              <Row className={"mt-4"}>
                <Col lg={6} className={"mb-4"}>
                  <InfoDisplayTable
                    title={"Form Details"}
                    data={{
                      "Assigned To": periodForm.entity_type,
                      "Assigned Entities": periodForm?.entity_names?.join(", "),
                      "Assigned Employees": periodForm?.employees_count,
                    }}
                  />
                </Col>
                <Col lg={6} className={"mb-4"}>
                  <h6 className={"text-center mb-3"}>
                    Overall Review Statuses
                  </h6>
                  <AppPieChart
                    data={addFillColorToList(periodForm.statuses || []) || []}
                    xName={"# of Reviews"}
                    chartHeight={250}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>

          {!periodForm.first_step_fully_published &&
            reviewPeriod.status === "In Progress" && (
              <div>
                <Alert
                  variant={"info"}
                  className={"d-flex justify-content-between"}
                >
                  <p className={"strong"}>
                    One or more reviews are currently in the CREATED status, the
                    reviewers will not be able to see the review in this state.
                  </p>
                  <div>
                    <Button
                      variant={"info"}
                      onClick={() => {
                        getPeriodData(true);
                      }}
                    >
                      Update Status
                    </Button>
                  </div>
                </Alert>
              </div>
            )}

          <Tabs
            activeKey={activeTab}
            className={"mb-2 pb-3"}
            variant={"underline"}
            onSelect={(key) => activateTab(key)}
          >
            <Tab eventKey="overview" title="Overview">
              <LoadingView
                isLoading={showSecondLoader}
                centerLoader={false}
                view={
                  <>
                    <ReviewPeriodFormStatsView
                      periodForm={periodForm}
                      periodFormStats={periodFormStats}
                      performanceForm={form}
                      permissions={permissions}
                      loaderCallback={setShowSecondLoader}
                      getRequest={getRequest}
                    />
                  </>
                }
              />
            </Tab>
            <Tab eventKey="review_steps" title="Review Steps">
              <LoadingView
                isLoading={showSecondLoader}
                centerLoader={false}
                view={
                  <>
                    <PerformanceReviewPeriodFormStepTab
                      periodForm={periodForm}
                      setPeriodForm={setPeriodForm}
                      performanceForm={form}
                      steps={steps}
                      setSteps={setSteps}
                      permissions={permissions}
                      putRequest={putRequest}
                      loaderCallback={setShowSecondLoader}
                      deleteRequest={deleteRequest}
                      getRequest={getRequest}
                      patchRequest={patchRequest}
                    />
                  </>
                }
              />
            </Tab>
            <Tab eventKey="assigned_employees" title="Assigned Employees">
              <LoadingView
                isLoading={showSecondLoader}
                centerLoader={false}
                view={
                  <>
                    <Card body={true}>
                      <Card.Title>
                        These are the employees to be reviewed in this form (The
                        Reviewees).
                      </Card.Title>
                    </Card>

                    {periodForm.id && (
                      <div>
                        {permissions.MANAGE_PERFORMANCE_REVIEWS && (
                          <div
                            className={"d-flex justify-content-end mb-3 mt-4"}
                          >
                            <Button
                              variant={"outline-primary"}
                              onClick={() => {
                                addReviewee();
                              }}
                            >
                              <FaPlus className={"me-2"} />
                              Add Reviewee
                            </Button>
                          </div>
                        )}

                        <PaginatedEmployeesDisplay
                          endpoint={`/performance/review-period/form/${periodForm.id}/employees`}
                          getRequest={getRequest}
                          minimal={true}
                          extraColumns={[
                            {
                              columnName: "Review Status",
                              renderComponent: (employee) => (
                                <StatusBadge status={employee.review_status} />
                              ),
                            },
                            {
                              columnName: "Actions",
                              renderComponent: (employee) => {
                                return (
                                  <div>
                                    {employee.can_view_reviews && (
                                      <Button
                                        variant={"outline-primary"}
                                        className={"me-2"}
                                        onClick={() => {
                                          window.open(
                                            `/performance/review-form/${periodForm.id}/employee/${employee.id}`,
                                            "_blank"
                                          );
                                        }}
                                      >
                                        View Reviews
                                        <FaExternalLinkAlt className={"ms-2"} />
                                      </Button>
                                    )}
                                    {employee.can_delete &&
                                      permissions.MANAGE_PERFORMANCE_REVIEWS && (
                                        <Button
                                          variant={"outline-danger"}
                                          onClick={() => {
                                            showAppModal({
                                              title: `Remove ${employee.full_name} as a Reviewee`,
                                              component: (
                                                <p>
                                                  Are you sure you want to
                                                  remove{" "}
                                                  <strong>
                                                    {employee.full_name}
                                                  </strong>{" "}
                                                  as a reviewee?
                                                </p>
                                              ),
                                              truthyFunction: () => {
                                                deleteRequest(
                                                  `/performance/review-period/${reviewPeriod.id}/reviewee/${employee.id}`,
                                                  setIsLoading,
                                                  (response) => {
                                                    getPeriodData();
                                                  }
                                                );
                                              },
                                            });
                                          }}
                                        >
                                          <FaTrashCan />
                                        </Button>
                                      )}
                                  </div>
                                );
                              },
                            },
                          ]}
                        />
                      </div>
                    )}
                  </>
                }
              />
            </Tab>
            <Tab eventKey="form_fields" title="Form Fields">
              <LoadingView
                isLoading={showSecondLoader}
                centerLoader={false}
                view={
                  <>
                    {form.id && (
                      <PerformanceReviewForm
                        data={{ ...form }}
                        forUpdates={false}
                      />
                    )}
                  </>
                }
              />
            </Tab>
          </Tabs>
        </div>
      }
    />
  );
}

function PerformanceReviewPeriodFormPage() {
  return <DashboardPageContainer PageComponent={PerformanceReviewPeriodForm} />;
}

export default PerformanceReviewPeriodFormPage;
