import React, { useContext, useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { formatDateStrings } from "../../../utils/dateTime";
import { isEmpty } from "../../../utils/functions";
import { AppContext } from "../../../utils/components/AppContext";

export function ApprovalWorkflowForm({ onSubmit, state }) {
  const { contextState, updateContextState } = useContext(AppContext);
  const [formState, setFormState] = useState(
    formatDateStrings({ ...contextState.formData })
  );

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateContextState({
      formData: formState,
    });
    onSubmit(formState);
  };

  const formValid = () => {
    const requiredFields = ["name"];
    let valid = true;
    for (const field of requiredFields) {
      if (isEmpty(formState[field])) {
        valid = false;
        break;
      }
    }
    return valid;
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="levelName" className={"mb-4"}>
        <Form.Label>Name *</Form.Label>
        <Form.Control
          type="text"
          name="name"
          required
          value={formState.name || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="levelDescription" className={"mb-4"}>
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          name="description"
          value={formState.description || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      {formState.add_supervisor_step && (
        <Alert variant={"info"} className={"mb-4"}>
          A supervisor approval step will automatically be added to this
          workflow when it is created.
        </Alert>
      )}

      <Button
        variant="primary"
        type="submit"
        disabled={!formValid()}
        className={"w-100"}
      >
        Save
      </Button>
    </Form>
  );
}
