import React, { useContext } from "react";
import { AppContext } from "../../utils/components/AppContext";

function ProductPermissionCheckView({
  children,
  empPermissions,
  staffPermissions,
  product,
  falsyComponent,
}) {
  /*
    This component is used to check if the user has permission to view a product.
    It checks if the product is in the organization products list and if the user has the permission to view it.

    product is the Products enum from src/enums/OrgEnums.js
    empPermissions is the EmpPermissions from src/enums/EmpPermissions.js
    staffPermissions is the StaffPermissions from src/enums/StaffPermissions

    If a permission or product is not passed then it will return true for that check.
  */

  const { hasAnyEmpPermission, hasAnyStaffPermission, hasAnyOrgProduct } =
    useContext(AppContext);

  let empPermissionCheck = true;
  let staffPermissionCheck = true;
  let productCheck = true;

  if (empPermissions) {
    empPermissionCheck = hasAnyEmpPermission(
      Array.isArray(empPermissions) ? empPermissions : [empPermissions]
    );
  }

  if (staffPermissions) {
    staffPermissionCheck = hasAnyStaffPermission(
      Array.isArray(staffPermissions) ? staffPermissions : [staffPermissions]
    );
  }

  if (product) {
    productCheck = hasAnyOrgProduct(
      Array.isArray(product) ? product : [product]
    );
  }

  if (empPermissionCheck && staffPermissionCheck && productCheck) {
    return <>{children}</>;
  } else {
    return falsyComponent ? <>{falsyComponent}</> : null;
  }
}

export default ProductPermissionCheckView;
