import React, { useState } from "react";
import { Button, Card, Form, Table } from "react-bootstrap";
import { FormFields, FormFieldTypes } from "../../FormFields";
import {
  columnTypesToFormFields,
  READ_ONLY_TEXT,
} from "./PerformanceFormHelpers";
import { TextWithLineBreaks } from "../../../utils/components";
import { updateObjectState } from "../../../utils/functions";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import OtherReviewInputs from "../../components/Performance/OtherReviewInputs";
import EmployeeHighlightCard from "../../components/Employee/EmployeeHighlightCard";
import EmployeeVerticalHighlightCard from "../../components/Employee/EmployeeVerticalHighlightCard";
import PDFViewer from "../../components/Shared/PDFViewer";
import PerformanceAttestationMessages from "../../components/Performance/PerformanceAttestationMessages";

function EmployeePerformanceForm({
  rows,
  columns,
  formData,
  setFormData,
  otherData,
  otherReviews,
  setOtherData,
  onSubmit,
  readOnly,
  writeColumns,
  reviewer,
  formTableData,
  step,
  putRequest,
}) {
  const [localFormData, setLocalFormData] = useState({});

  if (!formData) {
    formData = localFormData;
  }

  if (!setFormData) {
    setFormData = setLocalFormData;
  }

  reviewer = reviewer || {};
  step = step || {};
  const columnReviewers = formTableData?.column_reviewers || {};
  const columnTotals = formTableData?.column_totals || {};

  const validForm = () => {
    let isValid =
      formData.validations &&
      !Object.values(formData.validations).includes(false);

    if (!isValid) {
      return false;
    }

    if (otherData && Object.keys(otherData).length > 0) {
      isValid =
        otherData.validations &&
        !Object.values(otherData.validations).includes(false);

      if (step.type === "Approve" && isValid) {
        isValid = [true, false].includes(otherData.approved);
      }
    }

    return isValid;
  };

  return (
    <div>
      <Table bordered responsive>
        <thead>
          <tr>
            {columns.map((column, colsIndex) => {
              return (
                <th
                  key={colsIndex}
                  className={`text-center ${
                    writeColumns && writeColumns.includes(column.title)
                      ? "bg-primary text-light border-primary border-1"
                      : ""
                  }`}
                  style={{
                    minWidth: "250px",
                  }}
                >
                  {column.title}
                </th>
              );
            })}
          </tr>
          <tr>
            {columns.map((column, colsIndex) => {
              const colReviewer = columnReviewers[column.id];
              if (!colReviewer) {
                return <th key={colsIndex}></th>;
              }
              return (
                <th
                  key={colsIndex}
                  style={{
                    minWidth: "250px",
                  }}
                  className={"pt-4"}
                >
                  <EmployeeVerticalHighlightCard employee={colReviewer} />
                </th>
              );
            })}
          </tr>
        </thead>

        {columns.length > 0 && (
          <tbody>
            {rows.map((row, rowsIndex) => {
              return (
                <tr key={rowsIndex}>
                  {columns.map((column, colsIndex2) => {
                    const cell = row[column.title] || {};

                    let choices = cell.choices || column.choices;
                    let minValue = cell.min_value || column.min_value;
                    let maxValue = cell.max_value || column.max_value;

                    const cellId = cell.id;

                    const disabled =
                      readOnly ||
                      (writeColumns && !writeColumns.includes(column.title));

                    const hideInput =
                      column.column_type ===
                        columnTypesToFormFields["PDF File"] && disabled;

                    return (
                      <td
                        key={colsIndex2}
                        className={`${
                          writeColumns && writeColumns.includes(column.title)
                            ? "border-primary border-2"
                            : ""
                        }`}
                      >
                        {column.column_type === READ_ONLY_TEXT && (
                          <div>
                            <TextWithLineBreaks
                              text={cell.text || ""}
                              textClassNames={"small"}
                            />
                          </div>
                        )}
                        {column.column_type !== READ_ONLY_TEXT &&
                          !hideInput && (
                            <FormFields
                              state={formData}
                              setState={setFormData}
                              labelName={column.title}
                              valueKey={
                                cellId || `${rowsIndex}_x_${column.title}`
                              }
                              type={columnTypesToFormFields[column.column_type]}
                              optionsList={
                                choices && typeof choices === "string"
                                  ? choices.split("\n")
                                  : choices
                              }
                              hideLabel={true}
                              minValue={minValue}
                              maxValue={maxValue}
                              disabled={disabled}
                              isRequired={
                                writeColumns &&
                                writeColumns.includes(column.title)
                              }
                              maxFileMb={5}
                            />
                          )}

                        {hideInput && (
                          <div className={"mt-3"}>
                            <PDFViewer pdfUrl={formData[`${cellId}`]} />
                          </div>
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {columnTotals && (
              <tr>
                {columns.map((column, colsIndex) => {
                  return (
                    <td
                      key={colsIndex}
                      className={`text-center fw-bold py-3 border-primary`}
                    >
                      {columnTotals[column.id] || ""}
                    </td>
                  );
                })}
              </tr>
            )}
          </tbody>
        )}
      </Table>

      {otherReviews && otherReviews.length > 0 && (
        <div className={"my-4"}>
          <OtherReviewInputs reviewers={otherReviews} title={"Other Reviews"} />
        </div>
      )}

      {reviewer && !reviewer.is_complete && (
        <div>
          {reviewer.allow_extra_comments && (
            <Card body={true} className={"mt-4 border-2 border-primary"}>
              <FormFields
                state={otherData}
                setState={setOtherData}
                labelName={"Additional Comments"}
                valueKey={"extra_comments"}
                type={FormFieldTypes.TEXT_AREA}
                info={"Add any additional comments below"}
                isRequired={reviewer.require_extra_comments}
              />
            </Card>
          )}

          {reviewer.allow_file_uploads && (
            <Card body={true} className={"mt-4 border-2 border-primary"}>
              <FormFields
                state={otherData}
                setState={setOtherData}
                labelName={"PDF File Upload"}
                valueKey={"pdf_file"}
                type={FormFieldTypes.PDF}
                maxFileMb={5}
                info={"Upload a file in PDF format to support your review"}
                isRequired={reviewer.require_file_uploads}
              />
            </Card>
          )}

          {step.type === "Comment" && (
            <Card body={true} className={"mt-4 border-2 border-primary"}>
              <FormFields
                state={otherData}
                setState={setOtherData}
                labelName={"Comments"}
                valueKey={"reviewer_comments"}
                type={FormFieldTypes.TEXT_AREA}
                info={"Add your comments to this review below"}
                isRequired={true}
              />
            </Card>
          )}

          {step.type === "Approve" && (
            <Card body={true} className={"mt-4 border-2 border-primary"}>
              <FormFields
                state={otherData}
                setState={setOtherData}
                labelName={"Approval or Rejection Comments"}
                valueKey={"approval_comments"}
                type={FormFieldTypes.TEXT_AREA}
                info={
                  "Add comments below to explain your approval or rejection ot his review"
                }
                isRequired={true}
              />
              <div className={"mt-4 text-center"}>
                <Button
                  variant={
                    otherData.approved === true ? "success" : "outline-success"
                  }
                  className={"w-25 me-2"}
                  onClick={() => {
                    updateObjectState(setOtherData, {
                      approved: true,
                    });
                  }}
                >
                  <FaCheckCircle className={"me-2"} size={20} />
                  Approve
                </Button>
                <Button
                  variant={
                    otherData.approved === false ? "danger" : "outline-danger"
                  }
                  className={"w-25"}
                  onClick={() => {
                    updateObjectState(setOtherData, {
                      approved: false,
                    });
                  }}
                >
                  <FaTimesCircle className={"me-2"} size={20} />
                  Reject
                </Button>
              </div>
            </Card>
          )}
        </div>
      )}

      <PerformanceAttestationMessages
        reviewer={reviewer}
        putRequest={putRequest}
      />

      {onSubmit && (
        <div className={"text-center"}>
          <Button
            className={"my-5 w-25 align-self-center"}
            disabled={!validForm()}
            onClick={() => {
              const fData = new FormData();
              const oData = { ...otherData };

              if (otherData.pdf_file_url) {
                fData.append("pdf_file", oData.pdf_file);
                delete oData.pdf_file;
                delete oData.pdf_file_url;
              }
              delete oData.validations;

              const data = { ...formData };
              delete data.validations;

              const pdfColumns = columns.filter(
                (column) =>
                  column.column_type === columnTypesToFormFields["PDF File"]
              );

              const pdfCellFiles = rows.map((row) => {
                return pdfColumns.map((column) => {
                  const cell = row[column.title];
                  const file = data[cell.id];
                  data[cell.id] = null;
                  delete data[`${cell.id}_url`];

                  return {
                    id: cell.id,
                    file: file,
                  };
                });
              });

              // pdfCellFilesObject should be {id: file,...}
              const pdfCellFilesObject = {};
              pdfCellFiles.forEach((pdfCellFile) => {
                pdfCellFile.forEach((cellFile) => {
                  pdfCellFilesObject[cellFile.id] = cellFile.file;
                });
              });

              fData.append(
                "inputs",
                JSON.stringify({ data: data, other_data: oData })
              );
              onSubmit(fData, pdfCellFilesObject);
            }}
          >
            Submit
          </Button>
        </div>
      )}
    </div>
  );
}

export default EmployeePerformanceForm;
