import React, { useContext } from "react";
import { AddItemButton } from "../../../utils/components";
import { useOffCanvas } from "../../../utils/functions";
import { AppContext } from "../../../utils/components/AppContext";
import { Button, Card, Tab, Tabs } from "react-bootstrap";
import GenericForm from "../../Forms/Shared/GenericForm";
import AccountingFormsConfigs from "../../Forms/FormsConfigs/AccountingFormsConfigs";
import CashAccountDetails from "./CashAccountDetails";

function AccountingAccountsTab({
  accounts,
  setAccounts,
  permissions,
  getRequest,
  putRequest,
  patchRequest,
  loaderCallback,
  configs,
  staffManageMode,
}) {
  const { showOffCanvas } = useOffCanvas();
  const { updateContextState } = useContext(AppContext);

  const currencies = configs.currencies || [];

  return (
    <div>
      <Card>
        <Card.Header className={"py-4 mb-4"}>
          <Card.Title>Cash Accounts</Card.Title>
          <Card.Subtitle as={"p"} className={"mt-3"}>
            Oversee your cash accounts, access transaction history, and deposit
            funds seamlessly.
          </Card.Subtitle>
        </Card.Header>
      </Card>

      <Tabs className={"mb-2 mt-4 pb-3"}>
        {currencies.map((currency, index) => {
          return (
            <Tab key={index} eventKey={currency} title={currency}>
              {!accounts[currency].length > 0 && (
                <Card body={true} className={"py-4 text-center"}>
                  <p>
                    Your organization does not have an account in the {currency}{" "}
                    currency.
                    <br />
                    Please contact your admin if you don't see a button below
                    that says "Create {currency} Account"
                  </p>
                  {permissions.MANAGE_CASH_ACCOUNTS && (
                    <p className={"mt-"}>
                      <Button
                        variant={"primary"}
                        onClick={() => {
                          updateContextState({
                            formData: {
                              currency,
                            },
                          });

                          showOffCanvas({
                            title: `Create ${currency} Account`,
                            subtitle: `Create a new account in the ${currency} currency.`,
                            component: (
                              <div>
                                <GenericForm
                                  formConfigs={AccountingFormsConfigs.account}
                                  onSubmit={(formData) => {
                                    putRequest(
                                      "/accounting/cash-accounts",
                                      loaderCallback,
                                      (response) => {
                                        setAccounts({
                                          ...accounts,
                                          [currency]: [
                                            response.cash_account,
                                            ...accounts[currency],
                                          ],
                                        });
                                      },
                                      formData
                                    );
                                  }}
                                />
                              </div>
                            ),
                          });
                        }}
                      >
                        Create {currency} Account
                      </Button>
                    </p>
                  )}

                  {}
                </Card>
              )}
              {accounts[currency].map((account, index) => {
                return (
                  <div key={index} className={"mb-4"}>
                    <CashAccountDetails
                      account={account}
                      key={index}
                      getRequest={getRequest}
                      staffManageMode={staffManageMode}
                      onEditClick={
                        !permissions.MANAGE_CASH_ACCOUNTS
                          ? null
                          : () => {
                              updateContextState({
                                formData: { ...account },
                              });

                              showOffCanvas({
                                title: `Edit ${account.name}`,
                                subtitle: `Edit the ${account.name} account.`,
                                component: (
                                  <div>
                                    <GenericForm
                                      formConfigs={
                                        AccountingFormsConfigs.account
                                      }
                                      onSubmit={(formData) => {
                                        patchRequest(
                                          `/accounting/cash-accounts/${account.id}`,
                                          loaderCallback,
                                          (response) => {
                                            setAccounts({
                                              ...accounts,
                                              [currency]: accounts[
                                                currency
                                              ].map((acc) => {
                                                if (acc.id === account.id) {
                                                  return response.cash_account;
                                                }
                                                return acc;
                                              }),
                                            });
                                          },
                                          formData
                                        );
                                      }}
                                    />
                                  </div>
                                ),
                              });
                            }
                      }
                      onFundClick={
                        !permissions.MANAGE_CASH_ACCOUNTS
                          ? null
                          : () => {
                              const currencyCompanyAccounts =
                                configs.company_accounts[currency];
                              updateContextState({
                                formData: {
                                  company_account:
                                    currencyCompanyAccounts.length === 1
                                      ? currencyCompanyAccounts[0]
                                      : null,
                                },
                              });

                              showOffCanvas({
                                title: `Fund ${account.name}`,
                                subtitle: `Deposit funds into the ${account.name} account.`,
                                component: (
                                  <div>
                                    <GenericForm
                                      formConfigs={AccountingFormsConfigs.fundAccount(
                                        currencyCompanyAccounts
                                      )}
                                      useFormData={true}
                                      onSubmit={(formData) => {
                                        putRequest(
                                          `/accounting/cash-accounts/${account.id}/fund`,
                                          loaderCallback,
                                          (response) => {
                                            setAccounts({
                                              ...accounts,
                                              [currency]: accounts[
                                                currency
                                              ].map((acc) => {
                                                if (acc.id === account.id) {
                                                  return response.cash_account;
                                                }
                                                return acc;
                                              }),
                                            });
                                          },
                                          formData,
                                          true
                                        );
                                      }}
                                    />
                                  </div>
                                ),
                              });
                            }
                      }
                    />
                  </div>
                );
              })}
            </Tab>
          );
        })}
      </Tabs>
    </div>
  );
}

export default AccountingAccountsTab;
