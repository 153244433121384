import React, { useContext, useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import {
  AddItemButton,
  ConditionalView,
  InfoDisplayTable,
} from "../../../utils/components";
import EmployeeEducationForm from "../../Forms/Employee/EducationForm";
import {
  getFileFromUrl,
  updateObjectState,
  useOffCanvas,
} from "../../../utils/functions";
import { COUNTRIES } from "../../../data/countries";
import { formatDate } from "../../../utils/dateTime";
import { FaExternalLinkAlt } from "react-icons/fa";
import { AppContext } from "../../../utils/components/AppContext";
import EmployeeSkillForm from "../../Forms/Employee/EmployeeSkillForm";
import PDFViewer from "../Shared/PDFViewer";

function SkillssList({
  permissions,
  configs,
  employee,
  setEmployee,
  loaderCallback,
  purRequest,
  patchRequest,
}) {
  const { updateContextState } = useContext(AppContext);
  const { showOffCanvas } = useOffCanvas();

  const [skills, setSkills] = useState(employee.skills || []);

  return (
    <Card>
      <Card.Header
        className={"py-4 d-flex justify-content-between align-middle"}
      >
        <div>
          <h4>Skills</h4>
        </div>
        <div>
          {permissions.MANAGE_EMPLOYEE_DATA && (
            <AddItemButton
              title={"Add Skill"}
              onClick={() => {
                updateContextState({
                  formData: {},
                });
                showOffCanvas({
                  title: `Add a Skill`,
                  subtitle: `Add a new skill for ${employee.first_name}`,
                  component: (
                    <EmployeeSkillForm
                      onSubmit={(data) => {
                        const formData = new FormData();
                        formData.append(
                          "certificate_file",
                          data.certificate_file
                        );
                        delete data.certificate_file;
                        formData.append("inputs", JSON.stringify(data));

                        purRequest(
                          `/employee/${employee.id}/skill`,
                          loaderCallback,
                          (response) => {
                            updateObjectState(setEmployee, {
                              skills: [response.skill, ...skills],
                            });
                          },
                          formData,
                          true
                        );
                      }}
                      configs={configs}
                    />
                  ),
                });
              }}
            />
          )}
        </div>
      </Card.Header>
      <Card.Body className={"py-4"}>
        <ConditionalView
          condition={skills.length > 0}
          falseView={
            <p className={"text-center"}>No skill information has been added</p>
          }
          trueView={
            <>
              {skills.map((skill, i) => {
                return (
                  <InfoDisplayTable
                    key={i}
                    data={{
                      "Skill Name": skill.name,
                      Description: skill.description,
                      Proficiency: skill.proficiency,
                      "Completion Date": formatDate(skill.completion_date),
                      Certificate: (
                        <PDFViewer
                          title={`${skill.name} Certificate`}
                          pdfUrl={skill.certificate_document_file_url}
                        />
                      ),
                    }}
                    canEdit={permissions.MANAGE_EMPLOYEE_DATA}
                    onEditClick={() => {
                      updateContextState({
                        formData: { ...skill },
                      });
                      showOffCanvas({
                        title: `Edit Skill`,
                        subtitle: `Edit skill information for ${employee.first_name}`,
                        component: (
                          <EmployeeSkillForm
                            onSubmit={(data) => {
                              const formData = new FormData();
                              formData.append(
                                "certificate_file",
                                data.certificate_file
                              );
                              delete data.certificate_file;
                              formData.append("inputs", JSON.stringify(data));

                              patchRequest(
                                `/employee/skill/${skill.id}`,
                                loaderCallback,
                                (response) => {
                                  updateObjectState(setEmployee, {
                                    skills: skills.map((e) =>
                                      e.id === skill.id ? response.skill : e
                                    ),
                                  });
                                },
                                formData,
                                true
                              );
                            }}
                            configs={configs}
                          />
                        ),
                      });
                    }}
                  />
                );
              })}
            </>
          }
        />
      </Card.Body>
    </Card>
  );
}

export default SkillssList;
