import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { MdEmail, MdLocationOn } from "react-icons/md";
import { AppConstants } from "../../../utils/appConstants";
import { AppRoutes } from "../../../utils/appRoutes";
import { useNavigate } from "react-router-dom";
import { appConfigs } from "../../../configs";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer className="footer bg-dark text-white mt-5 text-center w-100">
      <Container className="py-5">
        <Row className="text-center">
          <Col>
            <h5 className="text-uppercase mb-4 font-weight-bold">Contact Us</h5>
            <p>
              <MdEmail />{" "}
              <a href="mailto:support@humnce.com" className="text-white">
                support@humnce.com
              </a>
            </p>
            <p>
              <MdLocationOn /> 42E Water St. Congo Cross, Freetown, Sierra Leone
            </p>
          </Col>

          {/* Consider adding additional columns for other footer content here, if needed */}
        </Row>
      </Container>

      <div
        className="text-center py-3 flex-row"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
      >
        <p>
          <a
            href={AppRoutes.PrivacyPolicy.path}
            className="text-white"
            onClick={(e) => {
              e.preventDefault();
              navigate(AppRoutes.PrivacyPolicy.path);
            }}
          >
            Privacy Policy
          </a>
        </p>
        <p>
          <a
            href={AppRoutes.TermsOfService.path}
            className="text-white"
            onClick={(e) => {
              e.preventDefault();
              navigate(AppRoutes.TermsOfService.path);
            }}
          >
            Terms of Service
          </a>
        </p>
        <p>
          © {new Date().getFullYear()} {appConfigs.companyName} - All Rights
          Reserved
        </p>
      </div>
    </footer>
  );
};

export default Footer;
