import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../utils/components/AppContext";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import DashboardPageContainer from "./components/DashboardPageContainer";
import { LoadingView, NoInfoCard } from "../utils/components";
import PageMetaTags from "./components/Shared/PageMetaTags";
import { toTitleCase } from "../utils/functions";
import { Tab, Tabs } from "react-bootstrap";
import PerformanceFormsTab from "./components/Performance/PerformanceFormsTab";
import PerformanceReviewPeriodTab from "./components/Performance/PerformanceReviewPeriodTab";

function PerformanceMain({
  getRequest,
  postRequest,
  putRequest,
  patchRequest,
  deleteRequest,
}) {
  const { contextState, updateContextState } = useContext(AppContext);
  const { permissions, labelsOverride } = contextState;

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const updateQueryParam = (key, value) => {
    setSearchParams({ ...searchParams, [key]: value });
  };

  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(searchParams.get("activeTab"));
  const [showSecondLoader, setShowSecondLoader] = useState(false);
  const [reviewPeriods, setReviewPeriods] = useState([]);
  const [forms, setForms] = useState([]);

  const activateTab = (key) => {
    setActiveTab(key);
    updateQueryParam("activeTab", key);
  };

  useEffect(() => {
    activateTab(searchParams.get("activeTab"));
  }, [searchParams]);

  const getInitData = () => {
    getRequest(`/performance`, setIsLoading, (response) => {
      setForms(response.forms);
      setReviewPeriods(response.review_periods);
    });
  };

  useEffect(() => {
    getInitData();
  }, []);

  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <div>
          <PageMetaTags title={`Performance | ${toTitleCase(activeTab)}`} />
          <Tabs
            activeKey={activeTab}
            className={"mb-2 pb-3"}
            variant={"underline"}
            onSelect={(key) => activateTab(key)}
          >
            <Tab eventKey="overview" title="Overview">
              <LoadingView
                isLoading={showSecondLoader}
                centerLoader={false}
                view={
                  <>
                    <NoInfoCard
                      text={
                        "Overall performance overview will be available when at least 1 review period is completed."
                      }
                    />
                  </>
                }
              />
            </Tab>
            <Tab eventKey="review_periods" title="Review Periods">
              <LoadingView
                isLoading={showSecondLoader}
                centerLoader={false}
                view={
                  <>
                    <PerformanceReviewPeriodTab
                      reviewPeriods={reviewPeriods}
                      setReviewPeriods={setReviewPeriods}
                      permissions={permissions}
                      putRequest={putRequest}
                      loaderCallback={setShowSecondLoader}
                    />
                  </>
                }
              />
            </Tab>
            <Tab eventKey="forms" title="Forms">
              <LoadingView
                isLoading={showSecondLoader}
                centerLoader={false}
                view={
                  <>
                    <PerformanceFormsTab
                      forms={forms}
                      setForms={setForms}
                      permissions={permissions}
                      putRequest={putRequest}
                      patchRequest={patchRequest}
                      deleteRequest={deleteRequest}
                      loaderCallback={setShowSecondLoader}
                    />
                  </>
                }
              />
            </Tab>
          </Tabs>
        </div>
      }
    />
  );
}

function PerformanceMainPage() {
  return <DashboardPageContainer PageComponent={PerformanceMain} />;
}

export default PerformanceMainPage;
