import React, { useContext, useState } from "react";
import { AddItemButton } from "../../../utils/components";
import { useOffCanvas } from "../../../utils/functions";
import { AppContext } from "../../../utils/components/AppContext";
import { Button, Card } from "react-bootstrap";
import { StaffPermissions } from "../../../enums/staffEnums";
import CopyWithTooltip from "../Shared/CopyWithTooltip";
import GenericForm from "../../Forms/Shared/GenericForm";
import { StaffFormsConfigs } from "../../Forms/FormsConfigs/StaffFormsConfigs";
import { FaChevronRight, FaEnvelope } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../utils/appRoutes";

function StaffOrganizationCard({
  organizations,
  setOrganizations,
  configs,
  putRequest,
  postRequest,
  loaderCallback,
}) {
  const { showOffCanvas } = useOffCanvas();
  const { updateContextState, hasStaffPermission } = useContext(AppContext);

  const navigate = useNavigate();

  return (
    <div>
      {hasStaffPermission(
        StaffPermissions.MANAGE_ORGANIZATIONS_SUBSCRIPTIONS
      ) && (
        <div className={"mb-4"}>
          <AddItemButton
            title={"New Organization"}
            onClick={() => {
              updateContextState({
                formData: {},
              });
              showOffCanvas({
                title: `Add a new organization Admin`,
                subtitle: `This will send an email to the address below asking the user to create an account and onboard their organization.`,
                component: (
                  <div>
                    <GenericForm
                      formConfigs={StaffFormsConfigs.organization}
                      useFormData={true}
                      onSubmit={(formData) => {
                        putRequest(
                          "/staff/organizations",
                          loaderCallback,
                          (resp) => {
                            setOrganizations([
                              resp.organization,
                              ...organizations,
                            ]);
                          },
                          formData,
                          true
                        );
                      }}
                    />
                  </div>
                ),
              });
            }}
          />
        </div>
      )}

      {organizations.map((organization, index) => (
        <Card key={organization.id} className={"mb-4"}>
          <Card.Header className={"py-4"}>
            <div className={"d-flex flex-row"}>
              <img
                src={organization?.logo || ""}
                alt={`${organization?.name} Logo`}
                width={70}
                height={70}
                className="rounded-circle me-3"
              />
              <div>
                <Card.Title>{organization.name || "Info Pending"}</Card.Title>
                <Card.Subtitle as={"p"} className={"mt-3"}>
                  {organization.description}
                </Card.Subtitle>

                <p className={"text-start mt-3"}>
                  <small>Org ID: {organization.id}</small>{" "}
                  <CopyWithTooltip text={organization.id} />
                </p>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <div className={"d-flex flex-row justify-content-end"}>
              {organization.is_new && (
                <Button
                  variant={"outline-warning"}
                  className={"me-2"}
                  onClick={() => {
                    updateContextState({
                      formData: {
                        admin_email: organization.creator_email,
                        name: organization.name,
                      },
                    });
                    showOffCanvas({
                      title: `Resend Organization Admin Welcome Email`,
                      subtitle: `This will send an email to the address below asking the user to create an account and onboard their organization.`,
                      component: (
                        <div>
                          <GenericForm
                            formConfigs={StaffFormsConfigs.organization.slice(
                              0,
                              2
                            )} // only allow the first 2 items
                            useGlobalState={true}
                            onSubmit={(formData) => {
                              postRequest(
                                `/staff/organization/${organization.id}/resend-invite`,
                                loaderCallback,
                                (resp) => {},
                                formData
                              );
                            }}
                          />
                        </div>
                      ),
                    });
                  }}
                >
                  <FaEnvelope className={"me-2"} /> Resend Welcome Email
                </Button>
              )}

              <Button
                variant={"outline-primary"}
                onClick={() => {
                  navigate(
                    `${AppRoutes.StaffOrgView.paramLessPath}${organization.id}?activeTab=overview`
                  );
                }}
              >
                View Organization <FaChevronRight className={"ms-2"} />
              </Button>
            </div>
          </Card.Body>
        </Card>
      ))}
    </div>
  );
}

export default StaffOrganizationCard;
