import React, { useContext, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { COUNTRIES } from "../../../data/countries";
import { AppContext } from "../../../utils/components/AppContext";
import { formatDateStrings } from "../../../utils/dateTime";

function EmployeeContactsForm({ onSubmit, configs }) {
  const { contextState, updateContextState } = useContext(AppContext);
  const [formState, setFormState] = useState(
    formatDateStrings({ ...contextState.formData })
  );

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const formValid = () => {
    const requiredFields = [
      "type",
      "relationship",
      "first_name",
      "last_name",
      "phone",
      "address",
      "city",
      "region",
      "country",
    ];
    return requiredFields.every((field) => formState[field]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateContextState({
      formData: { ...formState },
    });
    onSubmit(formState);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Text as={"p"} className={"fw-bold fs-6"}>
        Required fields are marked with an{" "}
        <span className="text-danger">*</span>
      </Form.Text>
      <Form.Group controlId="contactType" className={"my-4"}>
        <Form.Label>
          Contact Type<span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          name="type"
          value={formState.type || ""}
          onChange={handleInputChange}
        >
          <option value="">Select a Contact Type</option>
          {configs.contact_types.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      <Form.Group controlId="relationship" className={"mb-4"}>
        <Form.Label>
          Relationship<span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          name="relationship"
          value={formState.relationship || ""}
          onChange={handleInputChange}
        >
          <option value="">Select a Relationship</option>
          {configs.contact_relationships.map((relationship) => (
            <option key={relationship} value={relationship}>
              {relationship}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      <Form.Group controlId="firstName" className={"mb-4"}>
        <Form.Label>
          First Name<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          name="first_name"
          value={formState.first_name || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="middleName" className={"mb-4"}>
        <Form.Label>Middle Name</Form.Label>
        <Form.Control
          type="text"
          name="middle_name"
          value={formState.middle_name || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="lastName" className={"mb-4"}>
        <Form.Label>
          Last Name<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          name="last_name"
          value={formState.last_name || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="phone" className={"mb-4"}>
        <Form.Label>
          Phone<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="tel"
          name="phone"
          value={formState.phone || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="email" className={"mb-4"}>
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          name="email"
          value={formState.email || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="address" className={"mb-4"}>
        <Form.Label>
          Address<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          name="address"
          value={formState.address || ""}
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group controlId="address1" className={"mb-4"}>
        <Form.Label>Apt / Unit / Floor</Form.Label>
        <Form.Control
          type="text"
          name="address_1"
          value={formState.address_1 || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="city" className={"mb-4"}>
        <Form.Label>
          City<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          name="city"
          value={formState.city || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="region" className={"mb-4"}>
        <Form.Label>
          Region<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          name="region"
          value={formState.region || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="postalCode" className={"mb-4"}>
        <Form.Label>Postal Code</Form.Label>
        <Form.Control
          type="text"
          name="postal_code"
          value={formState.postal_code || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="country" className={"mb-4"}>
        <Form.Label>
          Country<span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          name="country"
          value={formState.country || ""}
          onChange={handleInputChange}
        >
          <option value="">Select a Country</option>
          {Object.keys(COUNTRIES).map((country) => (
            <option key={country} value={country}>
              {COUNTRIES[country]}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <Form.Group controlId="comment" className={"mb-5"}>
        <Form.Label>Comments</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          name="comment"
          value={formState.comment || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Button
        variant="primary"
        type="submit"
        className={"w-100 mb-5"}
        disabled={!formValid()}
      >
        Save
      </Button>
    </Form>
  );
}

export default EmployeeContactsForm;
