export class Products {
  static PEOPLE_MANAGEMENT = "PEOPLE_MANAGEMENT";
  static ORGANIZATION_MANAGEMENT = "ORGANIZATION_MANAGEMENT";
  static MULTI_ORGANIZATION = "MULTI_ORGANIZATION";
  static ATTENDANCE_MANAGEMENT = "ATTENDANCE_MANAGEMENT";
  static TIME_OFF_MANAGEMENT = "TIME_OFF_MANAGEMENT";
  static PAYROLL_MANAGEMENT = "PAYROLL_MANAGEMENT";
  static EXPENSE_MANAGEMENT = "EXPENSE_MANAGEMENT";
  static RECRUITMENT_MANAGEMENT = "RECRUITMENT_MANAGEMENT";
  static PERFORMANCE_MANAGEMENT = "PERFORMANCE_MANAGEMENT";
  static LEARNING_MANAGEMENT = "LEARNING_MANAGEMENT";
  static ASSET_MANAGEMENT = "ASSET_MANAGEMENT";
  static PROJECT_MANAGEMENT = "PROJECT_MANAGEMENT";
  static CUSTOMER_RELATIONSHIP_MANAGEMENT = "CUSTOMER_RELATIONSHIP_MANAGEMENT";
}
