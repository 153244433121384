import React, { useContext, useEffect, useState } from "react";
import { LoadingView } from "../utils/components";
import { apiRequest } from "../utils/apiRequests";
import DashboardPageContainer from "./components/DashboardPageContainer";
import { useOffCanvas, useToast } from "../utils/functions";
import { AppContext } from "../utils/components/AppContext";
import { OffCanvasContext } from "../utils/components/OffCanvasContext";
import { useNavigate } from "react-router-dom";

export function RecruitmentManagementPage() {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { showOffCanvas } = useOffCanvas();
  const { contextState, updateContextState } = useContext(AppContext);
  const { closeOffCanvas, restoreOffCanvas } = useContext(OffCanvasContext);

  const { permissions } = contextState;

  useEffect(() => {
    getRecruitmentManagementInfo();
  }, []);

  const [overview, setOverview] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getRecruitmentManagementInfo = async () => {
    setIsLoading(true);
    const { success, response } = await apiRequest.get(`/organization/admin`);
    if (success) {
      setOverview(response.overview);
    } else {
      showToast({
        title: "Error Getting Info",
        message: response.message,
      });
    }
    setIsLoading(false);
  };

  return <LoadingView isLoading={isLoading} view={<></>} />;
}

function RecruitmentManagement() {
  return <DashboardPageContainer PageComponent={RecruitmentManagementPage} />;
}
export default RecruitmentManagement;
