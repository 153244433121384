import React from "react";
import { Card } from "react-bootstrap";
import { BsCheckCircleFill } from "react-icons/bs";

function ImageWithInfoCard({ image, title, bulletPoints }) {
  return (
    <Card>
      <Card.Header>
        <Card.Img variant="top" src={image} />
        <Card.Body>
          <Card.Title as={"h3"} className={"text-center my-4"}>
            {title}
          </Card.Title>
          <ul className="list-unstyled">
            {bulletPoints.map((point, idx) => (
              <li key={idx} className="mb-4">
                <BsCheckCircleFill
                  className="me-2"
                  style={{ color: "#01a31f" }}
                />
                {point}
              </li>
            ))}
          </ul>
        </Card.Body>
      </Card.Header>
    </Card>
  );
}

export default ImageWithInfoCard;
