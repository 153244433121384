import React from "react";
import { InfoDisplayTable } from "../../../utils/components";
import { Badge } from "react-bootstrap";
import { formatDate } from "../../../utils/dateTime";

function CompensationDetails({
  compensation,
  deductionNames,
  canEdit,
  onEditClick,
}) {
  return (
    <div>
      <InfoDisplayTable
        data={{
          Description: compensation.description,
          Type: compensation.type,
          "Amount Type": compensation.amount_type,
          Frequency: compensation.frequency,
          Currency: compensation.currency,
          Amount: compensation.amount,
          Status: compensation.is_active ? (
            <Badge bg="success">Active</Badge>
          ) : (
            <Badge bg="info">Inactive</Badge>
          ),
          "Effective Date": formatDate(compensation.start_date),
          "End Date": formatDate(compensation.end_date),
          Deductions: deductionNames.join(", "),
        }}
        title={`${compensation.name}`}
        canEdit={canEdit}
        onEditClick={() => onEditClick(compensation)}
      />
    </div>
  );
}

export default CompensationDetails;
