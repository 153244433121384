import React, { useContext, useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { formatDateStrings } from "../../../utils/dateTime";
import { isEmpty } from "../../../utils/functions";
import { AppContext } from "../../../utils/components/AppContext";

export function ApprovalWorkflowStepForm({ onSubmit, positions }) {
  const { contextState, updateContextState } = useContext(AppContext);
  const [formState, setFormState] = useState(
    formatDateStrings({ position_ids: [], ...contextState.formData })
  );

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateContextState({
      formData: formState,
    });
    onSubmit(formState);
  };

  const formValid = () => {
    const requiredFields = ["name"];
    let valid = true;
    for (const field of requiredFields) {
      if (isEmpty(formState[field])) {
        valid = false;
        break;
      }
    }
    if (formState.position_ids.length === 0) {
      valid = false;
    }
    return valid;
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="levelName" className={"mb-4"}>
        <Form.Label>Name *</Form.Label>
        <Form.Control
          type="text"
          name="name"
          required
          value={formState.name || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="levelDescription" className={"mb-4"}>
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          name="description"
          value={formState.description || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="levelDescription" className={"mb-4"}>
        <Form.Label>Approvers Positions *</Form.Label>
        <div className={"mb-3"}>
          <Form.Text>
            Select the positions of the people that should approve this step in
            the workflow.
          </Form.Text>
        </div>

        {positions.map((position, index) => {
          return (
            <Form.Check
              key={index}
              type={"checkbox"}
              className={"mb-2"}
              id={position.id}
              label={position.title}
              checked={formState.position_ids.includes(position.id)}
              onChange={(e) => {
                let newPositions = [...formState.position_ids];
                if (e.target.checked) {
                  newPositions.push(position.id);
                } else {
                  newPositions = newPositions.filter(
                    (id) => id !== position.id
                  );
                }
                setFormState((prevState) => ({
                  ...prevState,
                  position_ids: newPositions,
                }));
              }}
            />
          );
        })}
      </Form.Group>

      <Button
        variant="primary"
        type="submit"
        disabled={!formValid()}
        className={"w-100"}
      >
        Save
      </Button>
    </Form>
  );
}
