import React, { useState } from "react";
import { getTodayFormFormat } from "../../utils/dateTime";
import { Alert, Form } from "react-bootstrap";

function ControlledDateRangeFormFields({
  handleInputChange,
  formState,
  setFormState,
  startDateKey = "start_date",
  endDateKey = "end_date",
  startDateLabel = "Start Date",
  endDateLabel = "End Date",
  minDate = null,
  disableStartDate = false,
  disableEndDate = false,
}) {
  const [errors, setErrors] = useState({
    [startDateKey]: null,
    [endDateKey]: null,
  });
  const minimumDate = minDate || getTodayFormFormat();

  return (
    <div>
      <Form.Group controlId="startDate" className={"mb-4"}>
        <Form.Label>
          {startDateLabel}
          <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="date"
          name={startDateKey}
          value={formState[startDateKey] || ""}
          min={minimumDate}
          disabled={disableStartDate}
          onChange={(e) => {
            const value = e.target.value;
            let isValueValid = false;
            if (
              value &&
              formState[endDateKey] &&
              value > formState[endDateKey]
            ) {
              setErrors({
                ...errors,
                [startDateKey]: `${startDateLabel} cannot be greater than ${endDateLabel}`,
              });
            } else if (value && value < minDate) {
              setErrors({
                ...errors,
                [startDateKey]: `${startDateLabel} cannot be in the past`,
              });
            } else {
              setErrors({
                ...errors,
                [startDateKey]: null,
              });
              isValueValid = true;
            }

            if (isValueValid) {
              handleInputChange(e);
            } else {
              setFormState({
                ...formState,
                [startDateKey]: "",
              });
            }
          }}
        />
        {errors[startDateKey] && (
          <Alert variant={"danger"} className={"mt-2"}>
            {errors[startDateKey]}
          </Alert>
        )}
      </Form.Group>

      <Form.Group controlId="endDate" className={"mb-4"}>
        <Form.Label>
          {endDateLabel}
          <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="date"
          name={endDateKey}
          value={formState[endDateKey] || ""}
          min={formState[startDateKey] || minimumDate}
          disabled={disableEndDate}
          onChange={(e) => {
            const value = e.target.value;
            let isValueValid = false;
            const minDate = formState[startDateKey] || minimumDate;

            if (value && value < minDate) {
              setErrors({
                ...errors,
                [endDateKey]: `${endDateLabel} cannot be less than ${startDateLabel}`,
              });
            } else {
              setErrors({
                ...errors,
                [endDateKey]: null,
              });
              isValueValid = true;
            }

            if (isValueValid) {
              handleInputChange(e);
            } else {
              setFormState({
                ...formState,
                [endDateKey]: "",
              });
            }
          }}
        />
        {errors[endDateKey] && (
          <Alert variant={"danger"} className={"mt-2"}>
            {errors[endDateKey]}
          </Alert>
        )}
      </Form.Group>
    </div>
  );
}

export default ControlledDateRangeFormFields;
