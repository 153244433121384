import React, { useEffect, useRef, useState } from "react";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
  convertToPixelCrop,
} from "react-image-crop";
import { Button, Form, Image as BsImage, Modal } from "react-bootstrap";
import { useToast } from "../../../utils/functions";
import { AppConstants } from "../../../utils/appConstants";
import { FaEdit } from "react-icons/fa";

import "react-image-crop/dist/ReactCrop.css";
import { useDebounceEffect } from "../../../hooks/useDebounceEffect";
import { canvasPreview } from "../../components/Shared/canvasPreview";

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 95,
        height: 95,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

function ImageCropInput({
  onComplete,
  imgSrc,
  rounded = false,
  maxMb = 0.3,
  imageWidth = 100,
}) {
  const scale = 1;
  const rotate = 0;
  const maxMbBytes = maxMb * 1024 * 1024;

  const { showToast } = useToast();
  const [imageSrc, setImageSrc] = useState(imgSrc);
  const [crop, setCrop] = useState();
  const [showModal, setShowModal] = useState(false);
  const [completedCrop, setCompletedCrop] = useState();
  const fileInputRef = useRef(null);

  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const blobUrlRef = useRef("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (
      file &&
      (file.type === "image/jpeg" || file.type === "image/png")
      // file.size <= maxMb * 1024 * 1024
    ) {
      setCrop(undefined);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = (e) => {
        setImageSrc(e.target.result.toString());
        setShowModal(true);
      };
    } else {
      showToast({
        message: `Invalid file type or size. Please select a jpg or png file`,
      });
    }
  };

  function onImageLoad(e) {
    const { width, height } = e.currentTarget;
    const loadCrop = centerAspectCrop(width, height, 1);
    setCrop(loadCrop);
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop]
  );

  async function getCroppedImage() {
    const image = imgRef.current;
    const previewCanvas = previewCanvasRef.current;
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error("Crop canvas does not exist");
    }

    // This will size relative to the uploaded image
    // size. If you want to size according to what they
    // are looking at on screen, remove scaleX + scaleY
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = new OffscreenCanvas(
      completedCrop.width, // * scaleX,
      completedCrop.height // * scaleY
    );
    const ctx = offscreen.getContext("2d");
    if (!ctx) {
      throw new Error("No 2d context");
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height
    );
    // You might want { type: "image/jpeg", quality: <0 to 1> } to
    // reduce image size

    let quality = 1; // Start with the best quality
    let blob = await offscreen.convertToBlob({
      type: "image/jpeg",
      quality: quality,
    });

    // Reduce quality until the blob size is under 2 MB or quality drops below a threshold
    while (blob.size > maxMbBytes && quality > 0.1) {
      quality -= 0.1; // Reduce quality by 10%
      blob = await offscreen.convertToBlob({
        type: "image/jpeg",
        quality: quality,
      });
    }

    if (blobUrlRef.current) {
      URL.revokeObjectURL(blobUrlRef.current);
    }
    blobUrlRef.current = URL.createObjectURL(blob);
    return {
      file: blob,
      url: blobUrlRef.current,
    };
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%", // Adjust according to the parent container's dimensions
        }}
      >
        <div style={{ position: "relative" }}>
          <BsImage
            src={imgSrc || AppConstants.defaultImage}
            alt="Profile"
            height={`${imageWidth}`}
            width={`${imageWidth}`}
            roundedCircle={rounded}
            style={{ cursor: "pointer" }}
            onClick={() => fileInputRef.current.click()}
          />
          <span
            className="position-absolute bg-primary text-white rounded-circle"
            onClick={() => fileInputRef.current.click()}
            style={{
              cursor: "pointer",
              fontSize: "0.8rem",
              padding: "0.25rem",
              top: "0.25rem",
              right: "0.25rem",
            }}
          >
            <FaEdit />
          </span>
        </div>
      </div>

      <Form.Control
        ref={fileInputRef}
        type="file"
        name="Image"
        accept=".jpg, .jpeg, .png"
        onChange={handleFileChange}
        className="d-none"
      />

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Crop Image</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center mb-4 mt-3 position-relative">
          <ReactCrop
            crop={crop}
            ruleOfThirds={false}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            locked={false}
            circularCrop={rounded}
            aspect={1}
            keepSelection={true}
          >
            <BsImage
              ref={imgRef}
              src={imageSrc}
              alt="Crop"
              // style={{ maxWidth: "100%" }}
              onLoad={onImageLoad}
              style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
            />
          </ReactCrop>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setCrop(null);
              setShowModal(false);
              fileInputRef.current.click();
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={async () => {
              const { file, url } = await getCroppedImage();
              onComplete(file, url);
              setShowModal(false);
              setCrop(undefined);
            }}
          >
            Save Selection
          </Button>
        </Modal.Footer>
      </Modal>

      {!!completedCrop && (
        <>
          <div>
            <canvas
              ref={previewCanvasRef}
              style={{
                display: "hidden",
                border: "1px solid black",
                objectFit: "contain",
                width: 0, //completedCrop.width,
                height: 0, //completedCrop.height,
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default ImageCropInput;
