import React, { useContext, useEffect, useRef, useState } from "react";
import { LoadingView } from "../utils/components";
import { apiRequest } from "../utils/apiRequests";
import DashboardPageContainer from "./components/DashboardPageContainer";
import {
  toTitleCase,
  useAppModal,
  useOffCanvas,
  useToast,
} from "../utils/functions";
import { AppContext } from "../utils/components/AppContext";
import { OffCanvasContext } from "../utils/components/OffCanvasContext";
import { useNavigate } from "react-router-dom";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import EmployeeHighlightCard from "./components/Employee/EmployeeHighlightCard";
import OrganizationChart from "@dabeng/react-orgchart";
import { FaDownload } from "react-icons/fa";
import { AppRoutes } from "../utils/appRoutes";
import PageMetaTags from "./components/Shared/PageMetaTags";

const OrgNode = ({ nodeData }) => {
  const cardStyle = {
    minWidth: "300px",
    maxWidth: "300px",
    margin: "0 auto",
  };
  return (
    <div className={"primary-hover"} style={cardStyle}>
      <div className="">
        <EmployeeHighlightCard employee={nodeData.supervisor || nodeData} />
      </div>
    </div>
  );
};

export function OrganizationChartPage({ getRequest }) {
  const navigate = useNavigate();
  const { showAppModal } = useAppModal();
  const { showOffCanvas } = useOffCanvas();
  const { contextState, updateContextState } = useContext(AppContext);
  const { closeOffCanvas, restoreOffCanvas } = useContext(OffCanvasContext);

  const { permissions } = contextState;

  useEffect(() => {
    getOrganizationChartInfo();
  }, []);

  const [config, setConfig] = useState({});
  const [chartData, setChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getOrganizationChartInfo = async () => {
    getRequest(`/organization/org-chart`, setIsLoading, (response) => {
      setChartData(response.chart_data);
    });
  };

  const orgchartRef = useRef();
  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <div>
          <PageMetaTags title={`Organization Chart`} />
          <div className={"mb-3 mt-4 d-flex justify-content-end"}>
            <Button
              variant={"primary"}
              onClick={() => {
                orgchartRef.current.exportTo("org-chart", "pdf");
              }}
            >
              <FaDownload className={"me-2"} />
              Download Org Chart as PDF
            </Button>
          </div>

          <Card className={"mb-5"}>
            <Card.Header className={"py-4"}>
              <Card.Title className={"fs-3 mb-3"}>
                Organization Chart / Organogram
              </Card.Title>
              <Card.Subtitle>
                <small>
                  The chain of command: see who reports to whom in your current
                  organization
                  <br />
                  Click and drag to move around the chart
                </small>
              </Card.Subtitle>
            </Card.Header>
          </Card>
          {chartData && (
            <OrganizationChart
              ref={orgchartRef}
              datasource={chartData || {}}
              chartClass={"bg-transparent"}
              containerClass={"bg-transparent border-0 vh-100"}
              NodeTemplate={OrgNode}
              draggable={false}
              pan={true}
              zoom={false}
              zoominLimit={2}
              collapsible={true}
              onClickNode={(nodeData) => {
                if (permissions.VIEW_EMPLOYEE_DATA) {
                  navigate(
                    `${AppRoutes.Employee.paramLessPath}${
                      nodeData.employee_id || nodeData.id
                    }`
                  );
                }
              }}
            />
          )}
        </div>
      }
    />
  );
}

function OrganizationChartComponent() {
  return <DashboardPageContainer PageComponent={OrganizationChartPage} />;
}
export default OrganizationChartComponent;
