import React, { useContext, useState } from "react";
import { AddItemButton, NoInfoCard } from "../../../utils/components";
import {
  updateObjectState,
  useAppModal,
  useOffCanvas,
} from "../../../utils/functions";
import { AppContext } from "../../../utils/components/AppContext";
import {
  Accordion,
  Alert,
  Button,
  Card,
  Form,
  InputGroup,
} from "react-bootstrap";
import PerformanceReviewForm from "../../Forms/Performance/PerformanceReviewForm";
import {
  FaCopy,
  FaEdit,
  FaInfoCircle,
  FaPlus,
  FaSearch,
  FaTimes,
} from "react-icons/fa";
import { FaTrashCan } from "react-icons/fa6";

function PerformanceFormsTab({
  forms,
  setForms,
  permissions,
  putRequest,
  patchRequest,
  deleteRequest,
  loaderCallback,
}) {
  const { showOffCanvas } = useOffCanvas();
  const { updateContextState } = useContext(AppContext);
  const { showAppModal, closeModal } = useAppModal();

  const [searchTerm, setSearchTerm] = useState("");
  const [addNewForm, setAddNewForm] = useState(false);
  const [editFormId, setEditFormId] = useState(null);
  const [copyFormData, setCopyFormData] = useState({});

  return (
    <div id={"new-form-creation"}>
      {permissions.MANAGE_PERFORMANCE_REVIEWS && (
        <div className={"mb-4 d-flex justify-content-end"}>
          <Button
            variant={addNewForm ? "outline-danger" : "primary"}
            onClick={() => {
              setCopyFormData({});
              setAddNewForm(!addNewForm);
            }}
          >
            {addNewForm ? (
              <FaTimes className={"me-2"} />
            ) : (
              <FaPlus className={"me-2"} />
            )}
            {addNewForm ? "Cancel" : "New Form"}
          </Button>
        </div>
      )}

      {addNewForm && (
        <Card className={"mb-4 border-2 border-primary"}>
          <Card.Header className={"py-4"}>
            <Card.Title>Create a New Performance Form</Card.Title>
          </Card.Header>
          <Card.Body>
            <PerformanceReviewForm
              data={copyFormData}
              onSubmit={(formData, setLoadingIndicator) => {
                putRequest(
                  `/performance/review-forms`,
                  setLoadingIndicator,
                  (response) => {
                    setForms([response.form, ...forms]);
                    setAddNewForm(false);
                  },
                  formData,
                  false,
                  false
                );
              }}
            />
          </Card.Body>
        </Card>
      )}
      <Card className={"mb-4"}>
        <Card.Header className={"py-4"}>
          <Card.Title>Perfromance Review Forms</Card.Title>
          <Card.Subtitle as={"p"} className={"mt-3"}>
            Here, you can view and manage all performance forms that can be used
            for performance reviews
          </Card.Subtitle>
        </Card.Header>
      </Card>

      {forms && forms.length === 0 ? (
        <NoInfoCard text={"No performance forms have been added yet"} />
      ) : (
        <div>
          <InputGroup className={"mb-4"}>
            <InputGroup.Text>
              <FaSearch />
            </InputGroup.Text>
            <Form.Control
              type="text"
              value={searchTerm || ""}
              onChange={(event) => {
                setSearchTerm(event.target.value);
              }}
              placeholder={`Search forms by name`}
            />
          </InputGroup>

          {forms.map((form, index) => {
            if (
              searchTerm &&
              !form.name.toLowerCase().includes(searchTerm.toLowerCase())
            ) {
              return null;
            }
            return (
              <Card key={index} className={"mb-4 border-1 border-primary"}>
                <Card.Header className={"d-flex justify-content-between py-3"}>
                  <Card.Title>{form.name}</Card.Title>
                </Card.Header>
                <Card.Body>
                  <div className={"mb-4"}>
                    <p>{form.description}</p>
                  </div>

                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <h5>Form Fields</h5>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className={"mb-4 d-flex justify-content-end"}>
                          {permissions.MANAGE_PERFORMANCE_REVIEWS &&
                            !form.can_modify && (
                              <div>
                                <Alert variant={"info"} className={"me-2"}>
                                  <FaInfoCircle className={"me-1"} size={20} />
                                  <small className={"text-"}>
                                    This form is in use and cannot be modified
                                  </small>
                                </Alert>
                              </div>
                            )}

                          {permissions.MANAGE_PERFORMANCE_REVIEWS && (
                            <div>
                              <Button
                                variant={"outline-primary"}
                                className={"me-2"}
                                onClick={() => {
                                  const rows = [];
                                  const columns = [];
                                  form.rows.forEach((row) => {
                                    delete row.id;
                                    rows.push({ ...row });
                                  });
                                  form.columns.forEach((column) => {
                                    delete column.id;
                                    columns.push({ ...column });
                                  });
                                  setCopyFormData({
                                    rows,
                                    columns,
                                  });
                                  setAddNewForm(true);
                                  document
                                    .getElementById("new-form-creation")
                                    .scrollIntoView({
                                      behavior: "smooth",
                                    });
                                }}
                              >
                                <FaCopy className={"me-2"} />
                                Copy
                              </Button>

                              {form.can_modify && (
                                <>
                                  <Button
                                    variant={
                                      editFormId === form.id
                                        ? "outline-danger"
                                        : "primary"
                                    }
                                    onClick={() => {
                                      setEditFormId(
                                        editFormId === form.id ? null : form.id
                                      );
                                    }}
                                  >
                                    {editFormId === form.id ? (
                                      <FaTimes className={"me-2"} />
                                    ) : (
                                      <FaEdit className={"me-2"} />
                                    )}
                                    {editFormId === form.id
                                      ? "Cancel"
                                      : "Edit Form"}
                                  </Button>
                                  <Button
                                    variant={"outline-danger"}
                                    className={"ms-2"}
                                    onClick={() => {
                                      showAppModal({
                                        title: "Delete Form",
                                        component: (
                                          <p>
                                            Are you sure you want to delete the
                                            form <strong>{form.name}</strong>?
                                          </p>
                                        ),
                                        truthyFunction: () => {
                                          deleteRequest(
                                            `/performance/review-forms/${form.id}`,
                                            loaderCallback,
                                            (response) => {
                                              setForms(
                                                forms.filter(
                                                  (f) => f.id !== form.id
                                                )
                                              );
                                              closeModal();
                                            }
                                          );
                                        },
                                      });
                                    }}
                                  >
                                    <FaTrashCan />
                                  </Button>
                                </>
                              )}
                            </div>
                          )}
                        </div>

                        <PerformanceReviewForm
                          data={{ ...form }}
                          forUpdates={editFormId === form.id}
                          onSubmit={(formData, setLoadingIndicator) => {
                            patchRequest(
                              `/performance/review-forms/${form.id}`,
                              setLoadingIndicator,
                              (response) => {
                                const updatedForms = forms.map((f) =>
                                  f.id === form.id ? response.form : f
                                );
                                setForms(updatedForms);
                                setEditFormId(null);
                              },
                              formData,
                              false,
                              false
                            );
                          }}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Card.Body>
              </Card>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default PerformanceFormsTab;
