import React, { createContext, useEffect, useState } from "react"
import { AppRoutes } from "../appRoutes"
import {
  BsBook,
  BsFileEarmarkSpreadsheet,
  BsHouse,
  BsWallet,
} from "react-icons/bs"
import {
  FaCalendar,
  FaCog,
  FaCogs,
  FaSitemap,
  FaUmbrellaBeach,
  FaUserPlus,
  FaUsers,
  FaUsersCog,
  FaUserTie,
} from "react-icons/fa"
import { FaFolderTree, FaSquarePersonConfined } from "react-icons/fa6"
import { StaffPermissions } from "../../enums/staffEnums"
import { Products } from "../../enums/OrgEnums"
import { EmpPermissions } from "../../enums/EmpPermissions"
import { TbClockPin } from "react-icons/tb"

export const AppContext = createContext()

const initState = {
  authenticated: undefined,
  employee: {},
  organization: {
    products: [],
  },
  permissions: {},
  formData: {},
  targetPath: null,
  navLinks: [],
  adminOrganization: null,
  staffPermissions: null,
  labelsOverride: {},
  isMobileApp: localStorage.getItem("isMobileApp") === "true",
  leftNavbarScrollPosition: 0,
  countryConfigs: {},
}

const AppContextProvider = ({ children }) => {
  const [contextState, setContextState] = useState(initState)

  const updateContextState = (updatedState) => {
    setContextState({ ...contextState, ...updatedState })
  }

  const hasEmpPermission = (permission) => {
    if (!contextState.permissions) return false
    return contextState.permissions[permission]
  }

  const hasAnyEmpPermission = (permissions) => {
    // permissions should be a list
    if (!contextState.permissions) return false
    return permissions.some(
      (permission) => contextState.permissions[permission]
    )
  }

  const hasStaffPermission = (permission) => {
    if (!contextState.staffPermissions) return false
    return contextState.staffPermissions.includes(permission)
  }

  const hasAnyStaffPermission = (permissions) => {
    // permissions should be a list
    if (!contextState.staffPermissions) return false
    return permissions.some((permission) =>
      contextState.staffPermissions.includes(permission)
    )
  }

  const hasOrgProduct = (product) => {
    if (!contextState.organization?.products) return false
    return contextState.organization.products.includes(product)
  }

  const hasAnyOrgProduct = (products) => {
    // products should be a list of Products
    if (!contextState.organization?.products) return false
    return products.some((product) =>
      contextState.organization.products.includes(product)
    )
  }

  useEffect(() => {
    const permissions = contextState.permissions || {}
    const employee = contextState.employee || {}
    const navLinks = [
      {
        name: "Home",
        path: AppRoutes.Home.path,
        icon: BsHouse,
        permission: null,
      },
      {
        name: "My Profile",
        path: AppRoutes.MyProfile.path,
        icon: FaUserTie,
        permission: null,
      },
      {
        name: "Appointments",
        path: AppRoutes.Appointments.path,
        icon: FaCalendar,
        permission: null,
        alwaysShow: false,
      },
      {
        name: "Organogram",
        path: AppRoutes.OrgChart.path,
        icon: FaSitemap,
        permission: null,
        alwaysShow: false,
      },
      {
        name: "Organization Tree",
        path: AppRoutes.OrgTree.path,
        icon: FaFolderTree,
        permission: null,
        alwaysShow: false,
      },
      {
        name: "People",
        path: AppRoutes.People.path,
        icon: FaUsers,
        permission: null,
        expandTabs: false,
        tabs: {
          overview: { hasPermission: permissions.VIEW_EMPLOYEES_STATS },
          people: {
            hasPermission: permissions.VIEW_EMPLOYEE_DATA,
          },
          teams: {
            hasPermission: permissions.VIEW_TEAMS || permissions.MANAGE_TEAMS,
            otherName: contextState?.labelsOverride?.teams,
          },
          positions: {
            hasPermission:
              permissions.MANAGE_POSITIONS || permissions.VIEW_POSITIONS,
          },
          levels: {
            hasPermission: permissions.MANAGE_LEVELS || permissions.VIEW_LEVELS,
          },
          announcements: {
            hasPermission:
              permissions.MANAGE_ANNOUNCEMENTS ||
              permissions.VIEW_ANNOUNCEMENTS,
          },
          activities: {
            hasPermission:
              permissions.VIEW_EMPLOYEES_ACTIVITIES ||
              permissions.MANAGE_EMPLOYEES_ACTIVITIES,
          },
        },
      },
      {
        name: "Attendance",
        path: AppRoutes.Attendance.path,
        icon: TbClockPin,
        permission: null,
        tabs: {
          // overview: {
          //   hasPermission: hasAnyEmpPermission([
          //     EmpPermissions.VIEW_ATTENDANCE,
          //     EmpPermissions.MANAGE_ATTENDANCE,
          //   ]),
          // },
          logs: {
            hasPermission: hasAnyEmpPermission([
              EmpPermissions.VIEW_ATTENDANCE,
              EmpPermissions.MANAGE_ATTENDANCE,
            ]),
          },
          shifts: {
            hasPermission: hasAnyEmpPermission([
              EmpPermissions.VIEW_SHIFT_SCHEDULES,
              EmpPermissions.MANAGE_SHIFTS,
            ]),
          },
          schedules: {
            hasPermission: hasAnyEmpPermission([
              EmpPermissions.VIEW_SHIFT_SCHEDULES,
              EmpPermissions.MANAGE_SHIFTS,
            ]),
          },
          devices: {
            hasPermission: hasAnyEmpPermission([
              EmpPermissions.VIEW_ATTENDANCE,
              EmpPermissions.MANAGE_ATTENDANCE,
            ]),
          },
        },
      },
      {
        name: "Time Off",
        path: AppRoutes.TimeOff.path,
        icon: FaUmbrellaBeach,
        permission: null,
        tabs: {
          // overview: {
          //   hasPermission: hasAnyEmpPermission([
          //     EmpPermissions.VIEW_TIME_OFF_POLICIES,
          //     EmpPermissions.MANAGE_TIME_OFF_POLICIES,
          //   ]),
          // },
          policies: {
            hasPermission: hasAnyEmpPermission([
              EmpPermissions.VIEW_TIME_OFF_POLICIES,
              EmpPermissions.MANAGE_TIME_OFF_POLICIES,
            ]),
          },
          approval_workflows: {
            hasPermission: hasAnyEmpPermission([
              EmpPermissions.VIEW_TIME_OFF_POLICIES,
              EmpPermissions.MANAGE_TIME_OFF_POLICIES,
            ]),
          },
        },
      },
      {
        name: "Accounting",
        path: AppRoutes.Accounting.path,
        icon: BsFileEarmarkSpreadsheet,
        permission: null,
        tabs: {
          overview: {
            hasPermission:
              permissions.VIEW_CASH_ACCOUNTS ||
              permissions.MANAGE_CASH_ACCOUNTS,
          },
          accounts: {
            hasPermission:
              permissions.VIEW_CASH_ACCOUNTS ||
              permissions.MANAGE_CASH_ACCOUNTS,
          },
        },
      },
      {
        name: "Payroll",
        path: AppRoutes.Payroll.path,
        icon: BsBook,
        permission: null,
        tabs: {
          overview: {
            hasPermission:
              permissions.VIEW_PAYROLL || permissions.MANAGE_PAYROLL,
          },
          pay_periods: {
            hasPermission:
              permissions.VIEW_PAYROLL || permissions.MANAGE_PAYROLL,
          },
          taxes_and_deductions: {
            hasPermission:
              permissions.VIEW_PAYROLL || permissions.MANAGE_PAYROLL,
          },
          settings: {
            hasPermission: permissions.MANAGE_PAYROLL,
          },
        },
      },
      {
        name: "Performance",
        path: AppRoutes.Performance.path,
        icon: FaSquarePersonConfined,
        permission: null,
        expandTabs: false,
        tabs: {
          overview: {
            hasPermission: permissions.MANAGE_PERFORMANCE_REVIEWS,
          },
          review_periods: {
            hasPermission: permissions.MANAGE_PERFORMANCE_REVIEWS,
          },
          forms: {
            hasPermission: permissions.MANAGE_PERFORMANCE_REVIEWS,
          },
        },
      },
      {
        name: "Managers Hub",
        path: AppRoutes.ManagersHub.path,
        icon: FaUsersCog,
        permission: null,
      },
      {
        name: "Recruitment",
        path: AppRoutes.Recruitment.path,
        icon: FaUserPlus,
        permission: null,
      },
      {
        name: "Admin",
        path: AppRoutes.Admin.path,
        icon: FaCog,
        permission: null,
        expandTabs: false,
        tabs: {
          organization: {
            hasPermission:
              permissions.VIEW_ORGANIZATION_INFO ||
              permissions.MANAGE_ORGANIZATION_INFO,
          },
          permission_group: {
            hasPermission:
              permissions.VIEW_PERMISSIONS || permissions.MANAGE_PERMISSIONS,
          },
          departments: {
            hasPermission:
              permissions.VIEW_DEPARTMENTS || permissions.MANAGE_DEPARTMENTS,
            otherName: contextState?.labelsOverride?.departments,
          },
          branches: {
            hasPermission:
              permissions.VIEW_BRANCHES || permissions.MANAGE_BRANCHES,
          },
        },
      },
    ]

    if (contextState.adminOrganization) {
      navLinks.push({
        name: "Humnce Admin Hub",
        path: AppRoutes.Staff.path,
        icon: FaCogs,
        permission: null,
        alwaysShow: true,
        tabs: {
          organizations: {
            hasPermission: hasAnyStaffPermission([
              StaffPermissions.VIEW_ORGANIZATIONS,
              StaffPermissions.MANAGE_ORGANIZATIONS_SUBSCRIPTIONS,
              StaffPermissions.VIEW_ORGANIZATIONS,
            ]),
          },
          staff: {
            hasPermission: hasAnyStaffPermission([
              StaffPermissions.VIEW_STAFF,
              StaffPermissions.MANAGE_STAFF,
            ]),
          },
        },
      })
    }

    updateContextState({
      navLinks: navLinks,
    })
  }, [
    contextState.permissions,
    contextState.adminOrganization,
    contextState.staffPermissions,
    contextState.employee,
  ])

  const logOut = () => {
    setContextState(initState)
  }

  return (
    <AppContext.Provider
      value={{
        contextState,
        updateContextState,
        setContextState,
        logOut,
        hasEmpPermission,
        hasAnyEmpPermission,
        hasStaffPermission,
        hasAnyStaffPermission,
        hasOrgProduct,
        hasAnyOrgProduct,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default AppContextProvider
