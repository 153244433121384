import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../utils/components/AppContext";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import DashboardPageContainer from "./components/DashboardPageContainer";
import { LoadingView, StatusBadge } from "../utils/components";
import PageMetaTags from "./components/Shared/PageMetaTags";
import { toTitleCase } from "../utils/functions";
import { Breadcrumb, Button, Card, Tab, Tabs } from "react-bootstrap";
import { AppRoutes } from "../utils/appRoutes";
import PaginatedEmployeesDisplay from "./components/Employee/PaginatedEmployeesDisplay";
import { FaExternalLinkAlt } from "react-icons/fa";
import AppBreadCrumb from "./components/Shared/AppBreadCrumb";

function EmployeePerformanceReviews({
  getRequest,
  postRequest,
  putRequest,
  patchRequest,
}) {
  const { contextState, updateContextState } = useContext(AppContext);
  const { permissions, labelsOverride } = contextState;

  const navigate = useNavigate();

  const { id } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const updateQueryParam = (key, value) => {
    setSearchParams({ ...searchParams, [key]: value });
  };

  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(searchParams.get("activeTab"));
  const [showSecondLoader, setShowSecondLoader] = useState(false);

  const activateTab = (key) => {
    setActiveTab(key);
    updateQueryParam("activeTab", key);
  };

  useEffect(() => {
    activateTab(searchParams.get("activeTab") || "pending");
  }, [searchParams]);

  const getColumns = (actionText) => {
    return [
      {
        columnName: "Review Status",
        renderComponent: (employee) => <StatusBadge status={employee.status} />,
      },
      {
        columnName: "Period",
        key: "period_name",
      },
      {
        columnName: "Step",
        key: "step_name",
      },
      {
        columnName: "Step Review Status",
        renderComponent: (employee) => (
          <StatusBadge status={employee.review_status} />
        ),
      },
      {
        columnName: "Form",
        key: "form_name",
      },
      {
        columnName: "Action",
        renderComponent: (employee) => (
          <Button
            variant={"link"}
            size={"sm"}
            onClick={() => {
              navigate(
                `${AppRoutes.CompletePerformanceReview.paramLessPath}/${employee.reviewer_id}`
              );
            }}
          >
            {actionText}
          </Button>
        ),
      },
    ];
  };

  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <div>
          <PageMetaTags
            title={`Performance Reviews | ${toTitleCase(activeTab)}`}
          />

          <AppBreadCrumb
            items={[
              {
                text: "Home",
                path: AppRoutes.Home.path,
              },
              {
                text: "Performance Reviews",
              },
            ]}
          />

          <Card className={"my-4"}>
            <Card.Header className={"py-3"}>
              <Card.Title>Performance Reviews</Card.Title>
              <Card.Text>
                View / complete performance reviews that have been assigned to
                you for completion
              </Card.Text>
            </Card.Header>
          </Card>
          <Tabs
            activeKey={activeTab}
            className={"mb-2 pb-3"}
            variant={"underline"}
            onSelect={(key) => activateTab(key)}
          >
            <Tab eventKey="pending" title="Pending">
              <LoadingView
                isLoading={showSecondLoader}
                centerLoader={false}
                view={
                  <>
                    <PaginatedEmployeesDisplay
                      endpoint={`/employee/self/performance/reviewees/pending`}
                      getRequest={getRequest}
                      minimal={true}
                      noEmployeesMsg={
                        "You don't have any reviews in this status"
                      }
                      extraColumns={getColumns("Start Review")}
                    />
                  </>
                }
              />
            </Tab>
            <Tab eventKey="in_progress" title="In Progress">
              <LoadingView
                isLoading={showSecondLoader}
                centerLoader={false}
                view={
                  <>
                    <PaginatedEmployeesDisplay
                      endpoint={`/employee/self/performance/reviewees/in-progress`}
                      getRequest={getRequest}
                      minimal={true}
                      noEmployeesMsg={
                        "You don't have any reviews in this status"
                      }
                      extraColumns={getColumns("View Details")}
                    />
                  </>
                }
              />
            </Tab>
            <Tab eventKey="attestation" title="In Attestation">
              <LoadingView
                isLoading={showSecondLoader}
                centerLoader={false}
                view={
                  <>
                    <PaginatedEmployeesDisplay
                      endpoint={`/employee/self/performance/reviewees/awaiting-attestation`}
                      getRequest={getRequest}
                      minimal={true}
                      noEmployeesMsg={
                        "You don't have any reviews in this status"
                      }
                      extraColumns={getColumns("View / Attest")}
                    />
                  </>
                }
              />
            </Tab>
            <Tab eventKey="completed" title="Completed">
              <LoadingView
                isLoading={showSecondLoader}
                centerLoader={false}
                view={
                  <>
                    <PaginatedEmployeesDisplay
                      endpoint={`/employee/self/performance/reviewees/completed`}
                      getRequest={getRequest}
                      minimal={true}
                      noEmployeesMsg={
                        "You don't have any reviews in this status"
                      }
                      extraColumns={getColumns("View Details")}
                    />
                  </>
                }
              />
            </Tab>
          </Tabs>
        </div>
      }
    />
  );
}

function EmployeePerformanceReviewsPage() {
  return <DashboardPageContainer PageComponent={EmployeePerformanceReviews} />;
}

export default EmployeePerformanceReviewsPage;
