import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Card, Alert } from "react-bootstrap";
import { AppContext } from "../../../utils/components/AppContext";
import EntitySelection from "../EntitySelection";
import { formatDateStrings, getTodayFormFormat } from "../../../utils/dateTime";
import ControlledDateRangeFormFields from "../ControlledDateRangeFormFields";

function ExtendScheduleForm({ onSubmit }) {
  const { contextState, updateContextState } = useContext(AppContext);
  const [formState, setFormState] = useState(
    formatDateStrings({ ...contextState.formData })
  );
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const formValid = () => {
    const requiredFields = ["start_date", "end_date"];
    return requiredFields.every((field) => formState[field]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateContextState({
      formData: { ...formState },
    });
    onSubmit(formState);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Text as={"p"} className={"fw-bold fs-6"}>
        Required fields are marked with an{" "}
        <span className="text-danger">*</span>
      </Form.Text>

      <ControlledDateRangeFormFields
        handleInputChange={handleInputChange}
        formState={formState}
        setFormState={setFormState}
        minDate={formState.start_date}
        disableStartDate={true}
      />

      <Button
        variant="primary"
        type="submit"
        className={"w-100 mb-5"}
        disabled={!formValid()}
      >
        Save
      </Button>
    </Form>
  );
}

export default ExtendScheduleForm;
