import React from "react";
import { FaPlus, FaChevronRight } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { Card } from "react-bootstrap";

function EmployeeHighlightCard({
  employee,
  action,
  add,
  showIcon,
  index,
  limitSize = false,
  useCard = true,
  forceRow = true,
}) {
  let divClass = "flex-column flex-lg-row";
  let imgClass = "me-lg-3 mb-3 mb-lg-0";
  let div2Class = "text-center text-lg-start";

  if (forceRow) {
    divClass = "flex-row";
    imgClass = "me-2 mb-0";
    div2Class = "text-start";
  }

  return (
    <div
      className={`${limitSize ? "emp-card-size-limit" : ""}${
        useCard ? "lg- card-body" : ""
      }`}
    >
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className={`d-flex align-items-center ${divClass}`}>
          <img
            src={employee.profile_image}
            alt={employee.full_name}
            width={50}
            height={50}
            className={`rounded-circle ${imgClass}`}
          />
          <div className={"text-start"}>
            <p className={`${div2Class}`}>
              <strong>{employee.full_name}</strong>
              <br />
              <small>
                {employee?.position?.title || "No Position"} - (
                {employee.employee_number})
              </small>
            </p>
          </div>
        </div>
        {action && !employee.no_action && (
          <div
            className={"pointer"}
            onClick={() => {
              action(employee, index);
            }}
          >
            {add && showIcon ? (
              <FaPlus />
            ) : !add && showIcon ? (
              <AiOutlineClose />
            ) : (
              <FaChevronRight />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default EmployeeHighlightCard;
