import React from "react";
import { InfoDisplayTable } from "../../../utils/components";
import { Badge } from "react-bootstrap";

function TimeOffPolicyDetails({
  policy,
  canEdit,
  onEditClick,
  approvalWorkFlowName,
}) {
  return (
    <div>
      <InfoDisplayTable
        data={{
          Description: policy.description,
          Type: policy.type,
          "Time Unit": policy.time_unit,
          "Available Time Units": policy.renewal_time_units,
          "Renews Yearly": policy.renews_yearly ? "Yes" : "No",
          "Carryover At Year's End": policy.carryover ? "Yes" : "No",
          "Applies to Employee Types": policy.apply_to_employee_types
            ? policy.apply_to_employee_types.join(", ")
            : "Selective",
          "Approval Workflow": approvalWorkFlowName,
          Status: policy.is_active ? (
            <Badge bg="success">Active</Badge>
          ) : (
            <Badge bg="info">Inactive</Badge>
          ),
        }}
        title={`${policy.name}`}
        canEdit={canEdit}
        onEditClick={() => onEditClick(policy)}
      />
    </div>
  );
}

export default TimeOffPolicyDetails;
