import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { getFileFromUrl, useAppModal } from "../../../utils/functions";
import { FaRegFilePdf } from "react-icons/fa";

const PDFViewer = ({ pdfUrl, title, buttonText, buttonVariant = "link" }) => {
  const { showAppModal } = useAppModal();
  const [fileBlob, setFileBlob] = useState(null);

  useEffect(() => {
    getPDFFile();
  }, []);

  const getPDFFile = async () => {
    if (pdfUrl) {
      const file = await getFileFromUrl(pdfUrl);
      setFileBlob(file);
    }
  };

  const displayPdf = () => {
    showAppModal({
      title: title || "PDF Viewer",
      size: "xl",
      fullScreen: false,
      component: (
        <embed
          src={fileBlob}
          width="100%"
          height={`${window.outerHeight * 0.9}px`}
        />
      ),
      hideFooter: true,
    });
  };

  return (
    <div>
      {fileBlob && (
        <Button variant={buttonVariant} onClick={displayPdf}>
          <FaRegFilePdf className={"me-1 fs-4"} />
          {buttonText || "View PDF"}
        </Button>
      )}

      {!fileBlob && <p>None</p>}
    </div>
  );
};

export default PDFViewer;
