import React, { useContext } from "react"
import { AddItemButton, StatusBadge } from "../../../utils/components"
import { LevelsForm } from "../../Forms/Levels"
import {
  getDate,
  getObjectFromListByKey,
  useOffCanvas,
} from "../../../utils/functions"
import { AppContext } from "../../../utils/components/AppContext"
import { Card, Table } from "react-bootstrap"
import ScheduleForm from "../../Forms/People/ScheduleForm"
import EmployeeHighlightCard from "../Employee/EmployeeHighlightCard"
import { useNavigate } from "react-router-dom"
import { AppRoutes } from "../../../utils/appRoutes"
import { getUTCDateTime } from "../../../utils/dateTime"

function ManageSchedules({
  schedules,
  setSchedules,
  permissions,
  configs,
  putRequest,
  patchRequest,
  loaderCallback,
}) {
  const { showOffCanvas } = useOffCanvas()
  const { updateContextState } = useContext(AppContext)
  const navigate = useNavigate()

  return (
    <div>
      {permissions.MANAGE_SCHEDULES && (
        <div className={"mb-4"}>
          <AddItemButton
            title={"New Schedule"}
            onClick={() => {
              updateContextState({
                formData: {
                  start_date: getUTCDateTime(),
                  end_date: getUTCDateTime().add(30, "days"),
                },
              })
              showOffCanvas({
                title: `Add a schedule`,
                subtitle: `Add a new schedule to your organization`,
                component: (
                  <ScheduleForm
                    configs={configs}
                    onSubmit={(updatedData) => {
                      putRequest(
                        "/attendance/schedules",
                        loaderCallback,
                        (response) => {
                          setSchedules([response.schedule, ...schedules])
                        },
                        updatedData
                      )
                    }}
                  />
                ),
              })
            }}
          />
        </div>
      )}

      <Card>
        <Card.Header className={"py-4"}>
          <Card.Title>Schedules</Card.Title>
          <Card.Subtitle
            as={"p"}
            className={"mt-3"}
          >
            Schedules provides a centralized platform for managing employee
            schedules efficiently. With this feature, you can create and
            customize work schedules, assign shifts to employees, and track
            attendance effortlessly. Whether you run a small team or a large
            organization, our Schedules tool ensures optimized workforce
            management, reducing scheduling conflicts and improving overall
            productivity.
          </Card.Subtitle>
        </Card.Header>
        <Card.Body>
          <Table
            striped
            bordered
            responsive
            hover
          >
            <thead className={"py-3"}>
              <tr className={"text-center align-middle th-trans"}>
                <th>Created By</th>
                <th>Shift</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Break Time</th>
                <th>Applies To</th>
                <th># of Shifts Assigned</th>
                <th>Processing</th>
              </tr>
            </thead>
            <tbody>
              {schedules.map((schedule, i) => {
                const shift = getObjectFromListByKey(
                  configs.shifts,
                  "id",
                  schedule.shift_id
                )
                return (
                  <tr
                    key={i}
                    className={"text-center align-middle pointer"}
                    onClick={() => {
                      navigate(AppRoutes.Schedule.paramLessPath + schedule.id)
                    }}
                  >
                    <td className={"text-start"}>
                      <EmployeeHighlightCard
                        employee={schedule.author_employee}
                      />
                    </td>
                    <td>{shift.name}</td>
                    <td>{shift.start_time}</td>
                    <td>{shift.end_time}</td>
                    <td>{shift.break_time} mins</td>
                    <td>
                      <p className={"fw-bold mb-3"}>{schedule.entity_type}</p>
                      {schedule.entity_names &&
                        schedule.entity_names.map((name, i) => {
                          return <p key={i}>{name}</p>
                        })}
                    </td>
                    <td>{schedule.assignments_count}</td>
                    <td>
                      <StatusBadge status={schedule.status} />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  )
}

export default ManageSchedules
