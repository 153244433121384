import React, { useContext, useEffect, useState } from "react"
import { AppContext } from "../utils/components/AppContext"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import DashboardPageContainer from "./components/DashboardPageContainer"
import { LoadingView } from "../utils/components"
import PageMetaTags from "./components/Shared/PageMetaTags"
import { toTitleCase, updateObjectState } from "../utils/functions"
import { Tab, Tabs } from "react-bootstrap"
import EmployeesAttendanceView from "./components/Shared/EmployeesAttendanceView"
import ProductPermissionCheckView from "./components/ProductPermissionCheckView"
import { EmpPermissions } from "../enums/EmpPermissions"
import { Products } from "../enums/OrgEnums"
import ManageShifts from "./components/Attendance/ManageShifts"
import ManageSchedules from "./components/Attendance/ManageSchedules"
import AttendanceDevicesTab from "./components/Attendance/AttendanceDevicesTab"

function AttendanceMain({ getRequest, postRequest, putRequest, patchRequest }) {
  const {
    contextState,
    updateContextState,
    hasEmpPermission,
    hasAnyEmpPermission,
  } = useContext(AppContext)
  const { permissions, labelsOverride } = contextState

  const navigate = useNavigate()

  const { id } = useParams()

  const [searchParams, setSearchParams] = useSearchParams()
  const updateQueryParam = (key, value) => {
    setSearchParams({ ...searchParams, [key]: value })
  }

  const [isLoading, setIsLoading] = useState(false)
  const [activeTab, setActiveTab] = useState(searchParams.get("activeTab"))
  const [showSecondLoader, setShowSecondLoader] = useState(false)
  const [configs, setConfigs] = useState({})

  const [fetchStatus, setFetchStatus] = useState({
    teams: false,
    shifts: false,
    schedules: false,
    attendance: false,
  })
  const [shifts, setShifts] = useState([])
  const [schedules, setSchedules] = useState([])
  const [devices, setDevices] = useState({
    used: [],
    un_used: [],
  })

  useEffect(() => {
    getRequest(`/attendance/overview`, setIsLoading, (res) => {
      setSchedules(res.schedules)
      setShifts(res.shifts)
      setDevices(res.devices)
      setConfigs(res.configs)
    })
  }, [])

  const activateTab = (key) => {
    setActiveTab(key)
    updateQueryParam("activeTab", key)
  }

  useEffect(() => {
    activateTab(searchParams.get("activeTab"))
  }, [searchParams])

  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <ProductPermissionCheckView
          empPermissions={EmpPermissions.VIEW_ATTENDANCE}
          product={Products.ATTENDANCE_MANAGEMENT}
        >
          <PageMetaTags title={`Attendance | ${toTitleCase(activeTab)}`} />
          <Tabs
            activeKey={activeTab}
            className={"mb-2 pb-3"}
            variant={"underline"}
            onSelect={(key) => activateTab(key)}
          >
            {/*<Tab*/}
            {/*  eventKey="overview"*/}
            {/*  title="Overview"*/}
            {/*>*/}
            {/*  <LoadingView*/}
            {/*    isLoading={showSecondLoader}*/}
            {/*    centerLoader={false}*/}
            {/*    view={<></>}*/}
            {/*  />*/}
            {/*</Tab>*/}

            {hasAnyEmpPermission([
              EmpPermissions.VIEW_ATTENDANCE,
              EmpPermissions.MANAGE_ATTENDANCE,
            ]) && (
              <Tab
                eventKey="logs"
                title="Logs"
              >
                <LoadingView
                  isLoading={showSecondLoader}
                  centerLoader={false}
                  view={
                    <>
                      <EmployeesAttendanceView
                        attendancesEndpoint={`/attendance/logs`}
                        statsEndpoint={`/attendance/stats`}
                        subTitle={`Attendance for all employees in the "${contextState?.organization?.name}" organization`}
                        getRequest={getRequest}
                      />
                    </>
                  }
                />
              </Tab>
            )}

            {hasAnyEmpPermission([
              EmpPermissions.VIEW_SHIFT_SCHEDULES,
              EmpPermissions.MANAGE_SHIFTS,
            ]) && (
              <Tab
                title={"Shifts"}
                eventKey="shifts"
              >
                <LoadingView
                  isLoading={showSecondLoader}
                  centerLoader={false}
                  view={
                    <>
                      <ManageShifts
                        shifts={shifts}
                        setShifts={setShifts}
                        permissions={permissions}
                        putRequest={putRequest}
                        patchRequest={patchRequest}
                        loaderCallback={setShowSecondLoader}
                      />
                    </>
                  }
                />
              </Tab>
            )}

            {hasAnyEmpPermission([
              EmpPermissions.VIEW_SHIFT_SCHEDULES,
              EmpPermissions.MANAGE_SHIFTS,
            ]) && (
              <Tab
                title={"Schedules"}
                eventKey="schedules"
              >
                <LoadingView
                  isLoading={showSecondLoader}
                  centerLoader={false}
                  view={
                    <>
                      <ManageSchedules
                        schedules={schedules}
                        setSchedules={setSchedules}
                        shifts={shifts}
                        permissions={permissions}
                        putRequest={putRequest}
                        patchRequest={patchRequest}
                        loaderCallback={setShowSecondLoader}
                        configs={{
                          ...configs,
                          shifts,
                        }}
                      />
                    </>
                  }
                />
              </Tab>
            )}

            {hasAnyEmpPermission([
              EmpPermissions.VIEW_ATTENDANCE,
              EmpPermissions.MANAGE_ATTENDANCE,
            ]) && (
              <Tab
                eventKey="devices"
                title="Devices"
              >
                <LoadingView
                  isLoading={showSecondLoader}
                  centerLoader={false}
                  view={
                    <>
                      <AttendanceDevicesTab
                        devices={devices}
                        setDevices={setDevices}
                        permissions={permissions}
                        putRequest={putRequest}
                        patchRequest={patchRequest}
                        loaderCallback={setShowSecondLoader}
                        configs={configs}
                      />
                    </>
                  }
                />
              </Tab>
            )}
          </Tabs>
        </ProductPermissionCheckView>
      }
    />
  )
}

function AttendanceMainPage() {
  return <DashboardPageContainer PageComponent={AttendanceMain} />
}

export default AttendanceMainPage
