import React, { useContext, useState } from "react";
import { Button, Form, Card, Alert } from "react-bootstrap";
import { AppContext } from "../../../utils/components/AppContext";
import { formatDateStrings } from "../../../utils/dateTime";

function ShiftForm({ onSubmit, configs, isEdit = false }) {
  const { contextState, updateContextState } = useContext(AppContext);
  const [formState, setFormState] = useState({
    active_days: [],
    is_active: true,
    ...contextState.formData,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleMultiSelectChange = (event) => {
    const { options } = event.target;
    let value = [];
    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setFormState((prevState) => ({
      ...prevState,
      active_days: value,
    }));
  };

  const formValid = () => {
    const requiredFields = ["name", "start_time", "min_start_time", "end_time"];
    if (formState.active_days.length === 0) {
      return false;
    }
    return requiredFields.every((field) => formState[field]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateContextState({
      formData: { ...formState },
    });
    onSubmit(formState);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Text as={"p"} className={"fw-bold fs-6"}>
        Required fields are marked with an{" "}
        <span className="text-danger">*</span>
      </Form.Text>

      <Form.Group controlId="name" className={"mb-4"}>
        <Form.Label>
          Name<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          name="name"
          value={formState.name || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="description" className={"mb-4"}>
        <Form.Label>Description</Form.Label>
        <Form.Control
          type="text"
          name="description"
          value={formState.description || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Card body={true} className="mb-4">
        <Form.Group
          controlId="isActive"
          className={"d-flex justify-content-between align-items-center"}
        >
          <Form.Label>Make Shift Active</Form.Label>
          <Form.Switch
            name="is_active"
            checked={formState.is_active || false}
            onChange={(e) =>
              handleInputChange({
                target: { name: "is_active", value: e.target.checked },
              })
            }
          />
        </Form.Group>
      </Card>

      <Alert variant={"info"}>
        Enter all times in 24 hour format. For example, if the shift starts at
        2:00 PM enter <strong>14:00</strong>
      </Alert>

      {isEdit && (
        <Alert variant={"warning"}>
          <strong>Warning:</strong>
          <br /> Changing the shift start / end time will affect some future
          scheduled employee shifts that use this shift. Only scheduled shifts
          whose times have NOT been changed will be affected.
        </Alert>
      )}
      <Form.Group controlId="startTime" className={"mb-4"}>
        <Form.Label>
          Shift Start Time<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="time"
          name="start_time"
          value={formState.start_time || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="minEndTime" className={"mb-4"}>
        <Form.Label>
          Earliest Start Time<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="time"
          name="min_start_time"
          value={formState.min_start_time || ""}
          onChange={handleInputChange}
        />
        <Form.Text>
          Enter the earliest time employees can start clocking in for this shift
          e.g. if the shift starts at 9:00 and you want people to be able to
          clock in 5 minutes before, set this to 8:55
        </Form.Text>
      </Form.Group>

      <Form.Group controlId="endTime" className={"mb-4"}>
        <Form.Label>
          Shift End Time<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="time"
          name="end_time"
          value={formState.end_time || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="breakTime" className={"mb-4"}>
        <Form.Label>
          Break Time<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="number"
          name="break_time"
          value={formState.break_time || ""}
          onChange={handleInputChange}
        />
        <Form.Text>Enter break time in minutes</Form.Text>
      </Form.Group>

      <Form.Group controlId="activeDays" className={"mb-4"}>
        <Form.Label>Active Days</Form.Label>
        <div className={"mb-3"}>
          <Form.Text>
            Select the days you want the shift to be active on
          </Form.Text>
        </div>
        {Object.entries(configs.days).map(([key, value]) => (
          <Form.Check
            type="checkbox"
            id={`active_days-${key}`}
            label={value}
            name="active_days"
            value={key}
            key={key}
            checked={formState.active_days?.includes(key) || false}
            onChange={(e) => {
              const isChecked = e.target.checked;
              setFormState((prevState) => ({
                ...prevState,
                active_days: isChecked
                  ? [...prevState.active_days, e.target.value]
                  : prevState.active_days.filter(
                      (day) => day !== e.target.value
                    ),
              }));
            }}
          />
        ))}
      </Form.Group>

      <Button
        variant="primary"
        type="submit"
        className={"w-100 mb-5"}
        disabled={!formValid()}
      >
        Save
      </Button>
    </Form>
  );
}

export default ShiftForm;
