import React, { useContext } from "react";
import { Alert, Badge, Button, Card, Col, Row } from "react-bootstrap";
import { ConditionalView, InfoDisplayTable } from "../../../utils/components";
import { PositionsForm } from "../../Forms/Employee/PositionsForm";
import {
  updateObjectState,
  useAppModal,
  useOffCanvas,
} from "../../../utils/functions";
import {
  FaEdit,
  FaFolderPlus,
  FaInfoCircle,
  FaPlus,
  FaTrash,
  FaUser,
  FaUserTimes,
} from "react-icons/fa";
import PersonalInformationForm from "../../Forms/Employee/PersonalInformationForm";
import EmployeeContactsForm from "../../Forms/Employee/EmployeeContacts";
import { AppContext } from "../../../utils/components/AppContext";
import { formatDate } from "../../../utils/dateTime";
import ImageViewerComponent from "../Shared/ImageViewerComponent";
import GenericForm from "../../Forms/Shared/GenericForm";
import { FormFieldTypes } from "../../FormFields";

function ManageProfile({
  employee,
  setEmployee,
  getRequest,
  patchRequest,
  putRequest,
  deleteRequest,
  permissions,
  configs,
  loaderCallback,
  isSelf = false,
}) {
  const { showOffCanvas } = useOffCanvas();
  const { contextState, updateContextState } = useContext(AppContext);
  const { labelsOverride } = contextState;
  const { showAppModal, closeModal } = useAppModal();
  const globalPermissions = contextState.permissions || {};

  const { organization } = contextState;

  const sendWelcomeEmail = () => {
    getRequest(
      `/employee/${employee.id}/send-welcome-email`,
      loaderCallback,
      (resp) => {},
      true
    );
  };

  return (
    <div>
      {!employee.has_account && permissions.MANAGE_EMPLOYEE_DATA && (
        <Alert variant={"info"} className={"fw-bold"}>
          <FaInfoCircle className={"me-2 text-warning"} size={30} />
          {employee.first_name} has not set up their account yet. If they are
          having issues creating their password, you can send them the welcome
          email again.
          <Button
            variant={"outline-info"}
            size={"sm"}
            className={"ms-4 float-end"}
            onClick={sendWelcomeEmail}
          >
            Send Welcome Email
          </Button>
        </Alert>
      )}
      <Row>
        <Col lg={6}>
          <Card className={"rounded-3 shadow mb-4"}>
            <Card.Header className={"d-flex justify-content-between py-4"}>
              <div>
                <Card.Title>Positions</Card.Title>
              </div>
              {permissions.MANAGE_EMPLOYEE_DATA && (
                <Button
                  variant={"primary"}
                  className="me-2"
                  onClick={() => {
                    showOffCanvas({
                      title: `New Position for ${employee.first_name}`,
                      subtitle: `This position will replace their existing position if they have one.`,
                      component: (
                        <PositionsForm
                          positions={configs.positions}
                          onSubmit={(position) => {
                            putRequest(
                              `/employee/position/${employee.id}`,
                              loaderCallback,
                              (response) => {
                                updateObjectState(
                                  setEmployee,
                                  response.employee
                                );
                              },
                              position,
                              false
                            );
                          }}
                        />
                      ),
                    });
                  }}
                >
                  <FaPlus className={"me-2 mb-1"} />
                  Add Position
                </Button>
              )}
            </Card.Header>
            <Card.Body>
              <ConditionalView
                condition={
                  employee.position_histories &&
                  employee.position_histories.length > 0
                }
                trueView={
                  <>
                    {employee.position_histories &&
                      employee.position_histories.map((history, index) => {
                        const position = history.position || {};
                        const isPrimaryPosition =
                          employee.current_position_id === history.position_id;
                        return (
                          <div key={history.id}>
                            <InfoDisplayTable
                              data={{
                                Title: (
                                  <strong>
                                    {position?.title}{" "}
                                    {isPrimaryPosition && (
                                      <Badge className={"ms-1"} bg={"success"}>
                                        Primary
                                      </Badge>
                                    )}
                                  </strong>
                                ),
                                [labelsOverride.department || "Department"]:
                                  position?.department?.name,
                                Level: position?.level?.name,
                                "Start Date": formatDate(history.start_date),
                                "End Date": history.end_date ? (
                                  formatDate(history.end_date)
                                ) : (
                                  <Badge className={"p-2"}>
                                    Current Position
                                  </Badge>
                                ),
                                Branch: (
                                  <span>
                                    <strong>{position?.branch?.name}</strong>{" "}
                                    <br />{" "}
                                    <small>
                                      {position?.branch?.full_address}
                                    </small>
                                  </span>
                                ),
                                "Branch Location":
                                  !position?.branch_location ? (
                                    "None"
                                  ) : (
                                    <span>
                                      <strong>
                                        {position?.branch_location?.name}
                                      </strong>{" "}
                                      <br />{" "}
                                      <small>
                                        {
                                          position?.branch_location
                                            ?.full_address
                                        }
                                      </small>
                                    </span>
                                  ),
                                Organization: position?.organization,
                              }}
                              canEdit={
                                permissions.MANAGE_EMPLOYEE_DATA &&
                                organization.id === position.organization_id
                              }
                              actionComponent={
                                <div>
                                  <Button
                                    variant={"outline-primary"}
                                    size={"sm"}
                                    className="me-2"
                                    onClick={() => {
                                      showOffCanvas({
                                        title: `Edit ${position.title}`,
                                        subtitle: `You can only change the start and end dates for this position`,
                                        component: (
                                          <PositionsForm
                                            positions={[
                                              {
                                                ...position,
                                                start_date: history.start_date,
                                                end_date: history.end_date,
                                                is_primary:
                                                  history.position_id ===
                                                  employee.current_position_id,
                                              },
                                            ]}
                                            isEdit={true}
                                            onSubmit={(position) => {
                                              patchRequest(
                                                `/employee/${employee.id}/position-history/${history.id}`,
                                                loaderCallback,
                                                (response) => {
                                                  updateObjectState(
                                                    setEmployee,
                                                    response.employee
                                                  );
                                                },
                                                position,
                                                false
                                              );
                                            }}
                                          />
                                        ),
                                      });
                                    }}
                                  >
                                    <FaEdit className={"me-2 mb-1"} />
                                    Edit Position
                                  </Button>

                                  <Button
                                    variant={"outline-danger"}
                                    size={"sm"}
                                    onClick={() => {
                                      showAppModal({
                                        title: `Delete ${position.title} for ${employee.first_name}`,
                                        component: (
                                          <p>
                                            Are you sure you want to proceed?
                                          </p>
                                        ),
                                        truthyFunction: () => {
                                          deleteRequest(
                                            `/employee/position-history/${history.id}`,
                                            loaderCallback,
                                            (response) => {
                                              updateObjectState(
                                                setEmployee,
                                                response.employee
                                              );
                                            }
                                          );
                                          closeModal();
                                        },
                                      });
                                    }}
                                  >
                                    <FaTrash className={"me-2 mb-1"} />
                                  </Button>
                                </div>
                              }
                            />
                          </div>
                        );
                      })}
                  </>
                }
                falseView={
                  <div>
                    <div className={"d-flex justify-content-between mb-3"}>
                      <h5>Positions</h5>
                    </div>
                    {permissions.MANAGE_EMPLOYEE_DATA && (
                      <Button
                        variant={"primary w-100"}
                        onClick={() => {
                          showOffCanvas({
                            title: `New Position for ${employee.first_name}`,
                            subtitle: `This position will replace their existing position if they have one.`,
                            component: (
                              <PositionsForm
                                positions={configs.positions}
                                onSubmit={(position) => {
                                  patchRequest(
                                    `/employee/position/${employee.id}`,
                                    loaderCallback,
                                    (response) => {
                                      updateObjectState(
                                        setEmployee,
                                        response.employee
                                      );
                                    },
                                    position,
                                    false
                                  );
                                }}
                              />
                            ),
                          });
                        }}
                      >
                        <FaFolderPlus /> Add a positon for {employee.first_name}
                      </Button>
                    )}
                  </div>
                }
              />
            </Card.Body>
          </Card>

          {globalPermissions.MANAGE_EMPLOYEE_DATA &&
            !employee.is_inactive &&
            !isSelf && (
              <div className={"mt-3 mb-5"}>
                <Button
                  variant={"outline-danger"}
                  className={"w-100"}
                  onClick={() => {
                    updateContextState({
                      formData: {},
                    });

                    showOffCanvas({
                      title: `End Employment for ${employee.first_name}`,
                      subtitle:
                        "This will deactivate the employee's account and prevent modifications to their profile.",
                      component: (
                        <GenericForm
                          formConfigs={[
                            {
                              valueKey: "type",
                              labelName: "Employment End Type",
                              type: FormFieldTypes.STRING_SELECT,
                              optionsList: configs.inactive_employee_types,
                              info: `IF you select "Mistakenly Added", the employee will be removed from the system entirely. This action cannot be undone.`,
                            },
                            {
                              valueKey: "reason",
                              labelName: "Reason for Ending Employment",
                              type: FormFieldTypes.TEXT_AREA,
                            },
                            {
                              valueKey: "inactive_date",
                              labelName: "Date of Employment End",
                              type: FormFieldTypes.DATE,
                            },
                          ]}
                          onSubmit={(data) => {
                            patchRequest(
                              `/employee/${employee.id}/end-employment`,
                              loaderCallback,
                              (response) => {
                                // reload window
                                window.location.reload();
                              },
                              data,
                              false
                            );
                          }}
                        />
                      ),
                    });
                  }}
                >
                  <FaUserTimes className={"me-2 mb-1"} />
                  End Employment
                </Button>
              </div>
            )}
        </Col>

        <Col lg={6}>
          <Card className={"rounded-3 shadow"}>
            <Card.Header className={"pt-4"}>
              <Card.Title>Personal Information</Card.Title>
            </Card.Header>
            <Card.Body>
              <InfoDisplayTable
                // title={"Personal Information"}
                data={{
                  Email: employee.email || "n/a",
                  Phone: employee.phone || "n/a",
                  Religion: employee.religion || "n/a",
                  "Marital Status": employee.marital_status || "n/a",
                  Race: employee.race || "n/a",
                  Tribe: employee.tribe || "n/a",
                  Nationality: employee.nationality || "n/a",
                  "ID Type": employee.id_type || "n/a",
                  "ID No.": employee.id_number || "n/a",
                  "ID Expiry": formatDate(employee.id_expiry),
                  "ID Image": (
                    <ImageViewerComponent
                      title={"ID Image"}
                      imageUrl={employee.id_image}
                    />
                  ),
                  "Work Authorization Expiry": formatDate(
                    employee.work_authorization_expiry
                  ),
                  "Work Authorization Image": (
                    <ImageViewerComponent
                      title={"Work Authorization Image"}
                      imageUrl={employee.work_authorization_image}
                    />
                  ),
                }}
                canEdit={permissions.MANAGE_EMPLOYEE_DATA || isSelf}
                onEditClick={() => {
                  updateContextState({
                    formData: { ...employee },
                  });
                  showOffCanvas({
                    title: `${employee.first_name}'s Personal Info`,
                    subtitle: "Add or edit their personal info",
                    component: (
                      <PersonalInformationForm
                        configs={configs}
                        isSelf={isSelf}
                        onSubmit={(updateData) => {
                          const formData = new FormData();
                          if (updateData.id_image !== employee.id_image) {
                            const id_image = updateData.id_image;
                            delete updateData.id_image;
                            formData.append("id_image", id_image);
                          }
                          if (
                            updateData.work_authorization_image !==
                            employee.work_authorization_image
                          ) {
                            const work_auth_image =
                              updateData.work_authorization_image;
                            delete updateData.work_authorization_image;
                            formData.append("work_auth_image", work_auth_image);
                          }
                          formData.append("inputs", JSON.stringify(updateData));
                          patchRequest(
                            isSelf
                              ? "/employee/self"
                              : `/employee/${employee.id}`,
                            loaderCallback,
                            (response) => {
                              updateObjectState(setEmployee, response.employee);
                            },
                            formData,
                            true
                          );
                        }}
                      />
                    ),
                  });
                }}
              />
            </Card.Body>
          </Card>

          <Card className={"rounded-3 mt-4 shadow"}>
            <Card.Header>
              <Card.Title>Contacts Information</Card.Title>
            </Card.Header>
            <Card.Body>
              <ConditionalView
                condition={employee?.contacts?.length > 0}
                trueView={
                  <>
                    {employee?.contacts?.map((contact, index) => {
                      return (
                        <div key={index} className={"mb-5"}>
                          <InfoDisplayTable
                            title={`${contact.type} Contact`}
                            data={{
                              Name: contact.full_name,
                              Relationship: contact.relationship,
                              Phone: contact.phone,
                              Email: contact.email,
                              Address: contact.full_address,
                              Comments: contact.comment,
                            }}
                            canEdit={permissions.MANAGE_EMPLOYEE_DATA || isSelf}
                            onEditClick={() => {
                              updateContextState({
                                formData: { ...contact },
                              });
                              showOffCanvas({
                                title: `Edit Contact`,
                                subtitle: `Edit contact so they can be reached if the need arises.`,
                                component: (
                                  <EmployeeContactsForm
                                    configs={configs}
                                    onSubmit={(contact) => {
                                      patchRequest(
                                        isSelf
                                          ? `/employee/self/contacts/${contact.id}`
                                          : `/employee/contacts/${employee.id}/${contact.id}`,
                                        loaderCallback,
                                        (response) => {
                                          updateObjectState(
                                            setEmployee,
                                            response.employee
                                          );
                                        },
                                        contact,
                                        false
                                      );
                                    }}
                                  />
                                ),
                              });
                            }}
                          />
                        </div>
                      );
                    })}
                  </>
                }
                falseView={
                  <div className={"my-3"}>
                    <p className={"text-center"}>
                      No contacts have been added for this employee. You can add
                      contacts like, emergency contact and next of kin
                    </p>
                  </div>
                }
              />

              {(permissions.MANAGE_EMPLOYEE_DATA || isSelf) && (
                <Button
                  className={"my-3"}
                  variant={"primary w-100"}
                  onClick={() => {
                    updateContextState({
                      formData: {},
                    });
                    showOffCanvas({
                      title: `New Contact for ${employee.first_name}`,
                      subtitle: `Add a contact so they can be reached if the need arises.`,
                      component: (
                        <EmployeeContactsForm
                          configs={configs}
                          onSubmit={(contact) => {
                            putRequest(
                              isSelf
                                ? `/employee/self/contacts`
                                : `/employee/contacts/${employee.id}`,
                              loaderCallback,
                              (response) => {
                                updateObjectState(
                                  setEmployee,
                                  response.employee
                                );
                              },
                              contact,
                              false
                            );
                          }}
                        />
                      ),
                    });
                  }}
                >
                  <FaUser className={"mb-1"} /> Add a New contact
                </Button>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6}></Col>
        <Col lg={6}></Col>
        <Col lg={6}></Col>
      </Row>
    </div>
  );
}

export default ManageProfile;
