import React, { useContext } from "react"
import { AddItemButton } from "../../../utils/components"
import TimeOffPolicyForm from "../../Forms/Employee/TimeOffPolicyForm"
import { Card, Col, Row, Tab, Tabs } from "react-bootstrap"
import TimeOffPolicyDetails from "./TimeOffPolicy"
import { useOffCanvas } from "../../../utils/functions"
import { AppContext } from "../../../utils/components/AppContext"
import ApprovalWorkflowsView from "../Organization/ApprovalWorkflowsView"

function ManageTimeOffPolicies({
  timeOffPolicies,
  approvalWorkflows,
  positions,
  permissions,
  config,
  putRequest,
  patchRequest,
  setTimeOffPolicies,
  setApprovalWorkflows,
  loaderCallback,
  deleteRequest,
}) {
  const { showOffCanvas } = useOffCanvas()
  const { updateContextState } = useContext(AppContext)

  return (
    <div>
      {permissions.MANAGE_TIME_OFF_POLICIES && (
        <div className={"mb-4"}>
          <AddItemButton
            title={"New Time Off Policy"}
            onClick={() => {
              updateContextState({
                formData: {
                  is_active: true,
                  renews_yearly: true,
                  carryover: true,
                  apply_to_existing: false,
                  apply_to_new_employees: false,
                  is_holiday: false,
                  apply_to_employee_types: [],
                },
              })
              showOffCanvas({
                title: `Add a Policy`,
                subtitle: `Add a new time off policy.`,
                component: (
                  <TimeOffPolicyForm
                    configs={config}
                    approvalWorkflows={approvalWorkflows}
                    onSubmit={(updatedData) => {
                      putRequest(
                        "/time-off/policies",
                        loaderCallback,
                        (response) => {
                          setTimeOffPolicies([
                            response.time_off_policy,
                            ...timeOffPolicies,
                          ])
                        },
                        updatedData
                      )
                    }}
                  />
                ),
              })
            }}
          />
        </div>
      )}

      <Card>
        <Card.Header className={"py-4"}>
          <Card.Title>Time Off Policies</Card.Title>
          <Card.Subtitle>
            <small>
              Add time off policies that will apply to all employees.
            </small>
          </Card.Subtitle>
        </Card.Header>
      </Card>
      <Row className={"mt-3"}>
        {timeOffPolicies.map((policy, index) => {
          return (
            <Col
              key={index}
              lg={4}
            >
              <Card
                body={true}
                className={"shadow rounded-3 mt-3"}
              >
                <TimeOffPolicyDetails
                  policy={policy}
                  canEdit={permissions.MANAGE_TIME_OFF_POLICIES}
                  approvalWorkFlowName={
                    approvalWorkflows.find(
                      (workflow) => workflow.id === policy.approval_workflow_id
                    )?.name
                  }
                  onEditClick={() => {
                    updateContextState({
                      formData: { ...policy },
                    })
                    showOffCanvas({
                      title: `Edit ${policy.name} Policy`,
                      subtitle: `All changes made will only apply to new employees.`,
                      component: (
                        <TimeOffPolicyForm
                          configs={config}
                          isEdit={true}
                          approvalWorkflows={approvalWorkflows}
                          onSubmit={(updatedData) => {
                            patchRequest(
                              `/time-off/policies/${policy.id}`,
                              loaderCallback,
                              (response) => {
                                const allPolicies = [...timeOffPolicies]
                                allPolicies[index] = response.time_off_policy
                                setTimeOffPolicies(allPolicies)
                              },
                              updatedData
                            )
                          }}
                        />
                      ),
                    })
                  }}
                />
              </Card>
            </Col>
          )
        })}
      </Row>
    </div>
  )
}

export default ManageTimeOffPolicies
