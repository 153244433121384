import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import IndexNavbar from "./components/Index/IndexNavbar";
import { AppConstants } from "../utils/appConstants";
import PageMetaTags from "./components/Shared/PageMetaTags";
import { appConfigs } from "../configs";

const TermsOfServicePage = () => {
  return (
    <>
      <PageMetaTags title={`${appConfigs.appName} - Terms of Service`} />
      <IndexNavbar />
      <Container className={"my-5 pt-5"}>
        <Row className="justify-content-md-center">
          <Col xs={12} lg={8}>
            <h1>Terms of Service</h1>
            <p>Last updated: March 17 2024</p>

            <h2>Introduction</h2>
            <p>
              Welcome to {appConfigs.companyName}'s HR application. By accessing
              our services, you agree to comply with our Terms of Service
              outlined here. Our platform is designed to streamline HR
              processes, offering tools for organization management, payroll,
              recruitment, finances and more.
            </p>

            <h2>Acceptable Use Policy</h2>
            <p>
              Users of {appConfigs.companyName}'s application are expected to
              use the services responsibly and legally. Prohibited activities
              include unauthorized access, sharing sensitive information without
              consent, and any form of misuse that could affect the service's
              integrity or other users' rights.
            </p>

            <h2>Account Registration and Termination</h2>
            <p>
              To use our services, users must register an account by providing
              accurate information. {appConfigs.companyName} reserves the right
              to suspend or terminate accounts due to inactivity, misuse, or
              breach of our terms.
            </p>

            <h2>Privacy Policy</h2>
            <p>
              {appConfigs.companyName} is committed to protecting your privacy.
              We collect, use, and store your data only as necessary for the
              provision of our services and as described in our Privacy Policy.
            </p>

            <h2>Intellectual Property Rights</h2>
            <p>
              All content and software provided on {appConfigs.companyName}'s
              platform are the property of {appConfigs.companyName} or its
              licensors. Users have the right to use the service within the
              scope of its intended purpose but may not copy, distribute, or
              create derivative works without permission.
            </p>

            <h2>Disclaimers and Limitation of Liability</h2>
            <p>
              {appConfigs.companyName} does not guarantee that the service will
              be error-free or uninterrupted. The company will not be liable for
              indirect damages arising from the use of the service.
            </p>

            <h2>Governing Law</h2>
            <p>
              These Terms of Service are governed by [Applicable Law], without
              regard to its conflict of law provisions.
            </p>

            <h2>Changes to Terms</h2>
            <p>
              {appConfigs.companyName} reserves the right to update these Terms
              at any time. Users will be notified of significant changes through
              the service or via email.
            </p>

            <h2>Contact Information</h2>
            <p>
              For questions about these Terms, please contact us at
              {AppConstants.supportEmail}.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default TermsOfServicePage;
