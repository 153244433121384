import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../utils/components/AppContext";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import DashboardPageContainer from "./components/DashboardPageContainer";
import { LoadingView } from "../utils/components";
import PageMetaTags from "./components/Shared/PageMetaTags";
import { toTitleCase } from "../utils/functions";
import { Tab, Tabs } from "react-bootstrap";
import AccountingAccountsTab from "./components/Accounting/AccountingAccountsTab";

function AccountingMain({ getRequest, postRequest, putRequest, patchRequest }) {
  const { contextState, updateContextState } = useContext(AppContext);
  const { permissions, labelsOverride } = contextState;

  const navigate = useNavigate();

  const { id } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const updateQueryParam = (key, value) => {
    setSearchParams({ ...searchParams, [key]: value });
  };

  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(searchParams.get("activeTab"));
  const [showSecondLoader, setShowSecondLoader] = useState(false);
  const [configs, setConfigs] = useState({});
  const [cashAccounts, setCashAccounts] = useState({});

  const activateTab = (key) => {
    setActiveTab(key);
    updateQueryParam("activeTab", key);
  };

  useEffect(() => {
    activateTab(searchParams.get("activeTab"));
  }, [searchParams]);

  const getCashAccounts = () => {
    getRequest(`/accounting/cash-accounts`, setIsLoading, (response) => {
      setCashAccounts(response.cash_accounts);
      setConfigs(response.configs);
    });
  };

  useEffect(() => {
    getCashAccounts();
  }, []);

  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <div>
          <PageMetaTags title={`Accounting | ${toTitleCase(activeTab)}`} />
          <Tabs
            activeKey={activeTab}
            className={"mb-2 pb-3"}
            variant={"underline"}
            onSelect={(key) => activateTab(key)}
          >
            <Tab eventKey="overview" title="Overview">
              <LoadingView
                isLoading={showSecondLoader}
                centerLoader={false}
                view={<></>}
              />
            </Tab>
            <Tab eventKey="accounts" title="Accounts">
              <LoadingView
                isLoading={showSecondLoader}
                centerLoader={false}
                view={
                  <>
                    <AccountingAccountsTab
                      accounts={cashAccounts}
                      setAccounts={setCashAccounts}
                      permissions={permissions}
                      getRequest={getRequest}
                      putRequest={putRequest}
                      loaderCallback={setShowSecondLoader}
                      patchRequest={patchRequest}
                      configs={configs}
                    />
                  </>
                }
              />
            </Tab>
          </Tabs>
        </div>
      }
    />
  );
}

function AccountingMainPage() {
  return <DashboardPageContainer PageComponent={AccountingMain} />;
}

export default AccountingMainPage;
