import React, { useEffect, useState } from "react";
import { FormFieldTypes } from "../../FormFields";
import GenericForm from "../Shared/GenericForm";
import { deepCopyObject } from "../../../utils/functions";

function PerformanceReviewFormColumnForm({
  data,
  setData,
  columnTypeOptions,
  onSubmit,
}) {
  const initColumnFields = [
    {
      valueKey: "title",
      labelName: "Column Title",
      disabled: data.is_row,
    },
    {
      valueKey: "column_type",
      labelName: "Column Type",
      type: FormFieldTypes.STRING_SELECT,
      optionsList: columnTypeOptions,
      disabled: data.is_row,
    },
  ];

  const [columnFields, setColumnFields] = useState(initColumnFields);

  const numberFields = [
    {
      valueKey: "min_value",
      labelName: "Minimum Value",
      type: FormFieldTypes.NUMBER,
      info: "The minimum value that can be entered in this column",
    },
    {
      valueKey: "max_value",
      labelName: "Maximum Value",
      type: FormFieldTypes.NUMBER,
      info: "The maximum value that can be entered in this column",
    },
  ];

  const pdfFields = [
    {
      valueKey: "min_value",
      labelName: "Minimum Pages",
      type: FormFieldTypes.NUMBER,
      info: "The minimum number of pages the PDF should have",
    },
    {
      valueKey: "max_value",
      labelName: "Maximum Pages",
      type: FormFieldTypes.NUMBER,
      info: "The maximum number of pages the PDF can have",
    },
  ];

  const choiceFields = [
    {
      valueKey: "choices",
      type: FormFieldTypes.TEXT_AREA,
      alertMsg: "Enter each option on a new line",
      info: "Enter the options that can be selected in this column",
    },
  ];

  function validateRangeLines(input, min, max) {
    if (!min || !max || !input) return false;

    min = parseFloat(min);
    max = parseFloat(max);
    const lines = input.split("\n");

    // Regex to match <text> <min>-<max>, allowing decimal numbers
    const pattern = new RegExp(`^.+ (\\d+(\\.\\d+)?)-(\\d+(\\.\\d+)?)$`);

    return lines.every((line) => {
      const match = line.match(pattern);

      if (!match) return false;

      const low = parseFloat(match[1]);
      const high = parseFloat(match[3]);

      return low >= min && high <= max && low <= high;
    });
  }

  useEffect(() => {
    if (data.column_type === "Number") {
      const newFields = [...initColumnFields, ...numberFields];
      if (!data.is_row) {
        newFields.push({
          valueKey: "ranges",
          type: FormFieldTypes.TEXT_AREA,
          info: "This will be used to generate stats for your form and review periods. Enter each range on its own line, see example below",
          alertMsg: (
            <strong>
              Not-Meeting-Expectations 0-49
              <br />
              Average 50-75
              <br />
              Exceeding-Expectation 76-100
            </strong>
          ),
          validation: (input) => {
            return validateRangeLines(input, data.min_value, data.max_value);
          },
        });
      }
      setColumnFields(newFields);
    } else if (
      ["Single Choice", "Multiple Choice"].includes(data.column_type)
    ) {
      setColumnFields([...initColumnFields, ...choiceFields]);
    } else if (data.column_type === "PDF File") {
      setColumnFields([...initColumnFields, ...pdfFields]);
    } else {
      setColumnFields([...initColumnFields]);
    }
  }, [data.column_type, data.min_value, data.max_value]);

  return (
    <div>
      <GenericForm
        formConfigs={columnFields}
        stateData={data}
        setStateData={setData}
        useGlobalState={false}
        updateGlobalStateOnSubmit={false}
        onSubmit={() => {
          onSubmit(data);
        }}
      />
    </div>
  );
}

export default PerformanceReviewFormColumnForm;
