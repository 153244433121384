import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import EmployeeSearch from "../components/EmployeeSearch";
import {
  getObjectFromListByKey,
  updateObjectState,
  useAppModal,
} from "../../utils/functions";
import { EmployeeList } from "../components/EmployeeCards";
import EmployeeBulkSearch from "../components/Shared/EmployeesBulkSearch";

const UpdateMembersForm = ({ data, setData, onSubmit }) => {
  const { showAppModal, closeModal } = useAppModal();
  return (
    <Form
      onSubmit={(event) => {
        event.preventDefault();
      }}
    >
      <EmployeeSearch
        onResultClick={(employee) => {
          if (getObjectFromListByKey(data.members, "id", employee.id)) {
            return;
          }
          updateObjectState(setData, {
            members: [employee, ...data.members],
          });
        }}
      />
      <h4 className={"my-3 text-center"}>OR</h4>
      <Button
        variant="primary"
        className={"w-100"}
        onClick={() => {
          showAppModal({
            title: "Bulk Search Employees",
            hideFooter: true,
            component: (
              <EmployeeBulkSearch
                onAddResultsClick={(employees) => {
                  updateObjectState(setData, {
                    members: [...data.members, ...employees],
                  });
                  closeModal();
                }}
              />
            ),
          });
        }}
      >
        Search Multiple Employees
      </Button>
      <h3 className={"mb-3 mt-5 fw-bold"}>Members</h3>
      <EmployeeList
        employees={data.members}
        add={false}
        action={(employee, i) => {
          const members = [...data.members];
          members.splice(i, 1);
          updateObjectState(setData, {
            members,
          });
        }}
      />
      <Button
        type="submit"
        variant="primary"
        className={"w-100 mt-4"}
        onClick={(event) => {
          event.preventDefault();
          onSubmit(data);
        }}
      >
        Save
      </Button>
    </Form>
  );
};

export default UpdateMembersForm;
