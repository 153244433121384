import React from "react";
import { Card } from "react-bootstrap";
import EmployeeHighlightCard from "../Employee/EmployeeHighlightCard";
import { StatusBadge, TextWithLineBreaks } from "../../../utils/components";
import PDFViewer from "../Shared/PDFViewer";
import { dateFormats, formatDate } from "../../../utils/dateTime";

function OtherReviewInputs({ reviewers, title }) {
  if (!reviewers || reviewers.length === 0) {
    return <div />;
  }

  return (
    <div>
      {reviewers.map((reviewer, index) => {
        return (
          <Card key={index} className={"border-2 border-success mb-4"}>
            <Card.Header className={"py-4"}>
              <h6 className={"text-muted"}>Submitted by</h6>
              <EmployeeHighlightCard employee={reviewer.employee} />
              <h6 className={"text-muted"}>
                On:{" "}
                {formatDate(
                  reviewer.completion_datetime,
                  dateFormats.shortDayShortMonthFullDayYearTime12Hr
                )}
              </h6>
              <h6 className={"mt-3"}>
                Review Step: <strong>{reviewer.review_step}</strong>
              </h6>
            </Card.Header>
            <Card.Body>
              {reviewer.items.map((item, i) => (
                <Card key={i} className={"mb-4"}>
                  <Card.Header className={"py-3"}>
                    <h6>{item.title}</h6>
                  </Card.Header>
                  <Card.Body>
                    {["comments", "extra_comments"].includes(item.type) && (
                      <p>
                        <TextWithLineBreaks text={item.content} />
                      </p>
                    )}
                    {item.type === "file_uploads" && (
                      <p>
                        {item.content.map((file, j) => (
                          <PDFViewer
                            pdfUrl={file.url}
                            title={file.name}
                            key={j}
                          />
                        ))}
                      </p>
                    )}
                    {["approval"].includes(item.type) && (
                      <div>
                        <TextWithLineBreaks text={item.content.comment} />
                        <div className={"mt-3"} />
                        <StatusBadge status={item.content.status} />
                      </div>
                    )}
                  </Card.Body>
                </Card>
              ))}
            </Card.Body>
          </Card>
        );
      })}
    </div>
  );
}

export default OtherReviewInputs;
