import React from "react";
import { formDate } from "../../../utils/dateTime";
import { Button, Table } from "react-bootstrap";
import { StatusBadge } from "../../../utils/components";
import { FaEdit } from "react-icons/fa";

function ApproversInfoTable({ approvers, onClick, onEditClick }) {
  return (
    <div>
      <Table striped bordered hover>
        <thead>
          <tr className={"text-center"}>
            <th></th>
            <th>Name</th>
            <th>Employee #</th>
            <th>Work Phone</th>
            <th>Work Email</th>
            <th>Position</th>
            <th>Status</th>
            <th>Required</th>
            {onEditClick && <th>Action</th>}
          </tr>
        </thead>
        <tbody>
          {approvers?.map((approver) => {
            const employee = approver.employee;
            return (
              <tr
                key={employee.id}
                className={`align-middle text-center ${
                  onClick ? "pointer" : ""
                }`}
                onClick={onClick ? () => onClick(approver) : () => {}}
              >
                <td className={"text-center"}>
                  <img
                    src={employee.profile_image}
                    alt={employee.full_name}
                    width="35"
                    height="35"
                    className={"rounded-circle"}
                  />
                </td>
                <td>{employee.full_name}</td>
                <td>{employee.employee_number}</td>
                <td>{employee.work_phone}</td>
                <td>{employee.work_email}</td>
                <td>{employee?.position?.title || "Not Assigned"}</td>
                <td>
                  <StatusBadge
                    status={approver.is_active ? "Active" : "Inactive"}
                  />
                </td>
                <td>{approver.is_required ? "Yes" : "No"}</td>
                {onEditClick && (
                  <td>
                    <Button
                      variant={"link"}
                      onClick={() => onEditClick(approver)}
                    >
                      <FaEdit size={20} />
                    </Button>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

export default ApproversInfoTable;
