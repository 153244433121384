import React, { useContext, useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import {
  AddItemButton,
  ConditionalView,
  InfoDisplayTable,
} from "../../../utils/components";
import EmployeeEducationForm from "../../Forms/Employee/EducationForm";
import {
  getFileFromUrl,
  updateObjectState,
  useOffCanvas,
} from "../../../utils/functions";
import { COUNTRIES } from "../../../data/countries";
import { formatDate } from "../../../utils/dateTime";
import { FaExternalLinkAlt } from "react-icons/fa";
import { AppContext } from "../../../utils/components/AppContext";
import PDFViewer from "../Shared/PDFViewer";

function EducationsList({
  permissions,
  configs,
  employee,
  setEmployee,
  loaderCallback,
  purRequest,
  patchRequest,
}) {
  const { updateContextState } = useContext(AppContext);
  const { showOffCanvas } = useOffCanvas();

  const [educations, setEducations] = useState(employee.educations || []);

  return (
    <Card>
      <Card.Header className={"py-4 d-flex justify-content-between"}>
        <div>
          <h4>Education</h4>
        </div>
        <div>
          {permissions.MANAGE_EMPLOYEE_DATA && (
            <AddItemButton
              title={"Add Education"}
              onClick={() => {
                updateContextState({
                  formData: {},
                });
                showOffCanvas({
                  title: `Add an Education`,
                  subtitle: `Add a new education for ${employee.first_name}`,
                  component: (
                    <EmployeeEducationForm
                      onSubmit={(data) => {
                        const formData = new FormData();
                        formData.append(
                          "certificate_file",
                          data.certificate_file
                        );
                        delete data.certificate_file;
                        formData.append("inputs", JSON.stringify(data));

                        purRequest(
                          `/employee/${employee.id}/education`,
                          loaderCallback,
                          (response) => {
                            updateObjectState(setEmployee, {
                              educations: [response.education, ...educations],
                            });
                          },
                          formData,
                          true
                        );
                      }}
                      configs={configs}
                    />
                  ),
                });
              }}
            />
          )}
        </div>
      </Card.Header>
      <Card.Body className={"py-4"}>
        <ConditionalView
          condition={educations.length > 0}
          falseView={
            <p className={"text-center"}>
              No education information has been added
            </p>
          }
          trueView={
            <>
              {educations.map((education, i) => {
                return (
                  <InfoDisplayTable
                    key={i}
                    data={{
                      "Field of Study": education.field_of_study,
                      "Graduation Level": education.level,
                      Institution: education.institution_name,
                      "Institution Location": `${education.institution_city}, ${
                        COUNTRIES[education.institution_country]
                      }`,
                      "Start Date": formatDate(education.start_date),
                      "End Date": formatDate(education.end_date),
                      "Certificate / Diploma": (
                        <PDFViewer
                          title={`Education Document`}
                          pdfUrl={
                            education.degree_diploma_certificate_document_file_url
                          }
                        />
                      ),
                    }}
                    canEdit={permissions.MANAGE_EMPLOYEE_DATA}
                    onEditClick={() => {
                      updateContextState({
                        formData: { ...education },
                      });
                      showOffCanvas({
                        title: `Edit Education`,
                        subtitle: `Edit education information for ${employee.first_name}`,
                        component: (
                          <EmployeeEducationForm
                            onSubmit={(data) => {
                              const formData = new FormData();
                              formData.append(
                                "certificate_file",
                                data.certificate_file
                              );
                              delete data.certificate_file;
                              formData.append("inputs", JSON.stringify(data));

                              patchRequest(
                                `/employee/education/${education.id}`,
                                loaderCallback,
                                (response) => {
                                  updateObjectState(setEmployee, {
                                    educations: educations.map((e) =>
                                      e.id === education.id
                                        ? response.education
                                        : e
                                    ),
                                  });
                                },
                                formData,
                                true
                              );
                            }}
                            configs={configs}
                          />
                        ),
                      });
                    }}
                  />
                );
              })}
            </>
          }
        />
      </Card.Body>
    </Card>
  );
}

export default EducationsList;
