import axios from "axios";
import { appConfigs } from "../configs";
import { AppConstants } from "./appConstants";
import { AppRoutes } from "./appRoutes";

class Requests {
  host = appConfigs.apiHostname;

  axiosInstance = axios.create({
    baseURL: this.host,
  });

  catchError(error) {
    return {
      success: false,
      response: {
        message:
          "An unknown error occurred. Please try again or contact us if this error persists.",
      },
      statusCode: error?.response?.status,
    };
  }

  async sendRequest(
    endpoint,
    method,
    data = null,
    multipart = false,
    extraHeaders = {}
  ) {
    try {
      const requestConfig = {
        method: method,
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            AppConstants.authTokenKey
          )}`,
          ...extraHeaders,
          "Content-Type": multipart
            ? "multipart/form-data"
            : "application/json",
        },
        data: data ? (multipart ? data : JSON.stringify(data)) : undefined,
        validateStatus: function (status) {
          return status >= 200 && status < 600; // Always resolve promise for any status code
        },
      };

      const response = await this.axiosInstance.request(
        endpoint,
        requestConfig
      );
      const statusCode = response.status;
      if ([200, 201].includes(statusCode)) {
        return {
          success: true,
          response: response.data,
          statusCode,
        };
      }

      if (statusCode === 401 && endpoint !== "/user/authn") {
        const signInUrl = new URL(
          AppRoutes.SignIn.path,
          window.location.origin
        );
        signInUrl.searchParams.append("next", window.location.pathname);
        window.location.href = signInUrl.toString();
        return;
      }

      const resp = response.data;
      if (statusCode === 403 && resp.message === "No Subscription") {
        window.location.href = AppRoutes.SubscriptionExpired.path;
      }

      return {
        success: false,
        response: resp,
        statusCode,
      };
    } catch (error) {
      return this.catchError(error);
    }
  }

  async post(endpoint, data = null, multipart = false, extraHeaders = {}) {
    return this.sendRequest(endpoint, "POST", data, multipart, extraHeaders);
  }

  async put(endpoint, data = null, multipart = false, extraHeaders = {}) {
    return this.sendRequest(endpoint, "PUT", data, multipart, extraHeaders);
  }

  async patch(endpoint, data = null, multipart = false, extraHeaders = {}) {
    return this.sendRequest(endpoint, "PATCH", data, multipart, extraHeaders);
  }

  async get(endpoint, extraHeaders = {}) {
    return this.sendRequest(endpoint, "GET", undefined, false, extraHeaders);
  }

  async delete(endpoint, extraHeaders = {}) {
    return this.sendRequest(endpoint, "DELETE", undefined, false, extraHeaders);
  }

  // New function to upload files to AWS S3 using a pre-signed URL
  async uploadFileToS3(presignedUrl, file) {
    try {
      const response = await axios.put(presignedUrl, file, {
        headers: {
          "Content-Type": file.type, // Ensure proper file type for S3
          "Content-Disposition": "inline",
        },
      });

      if (response.status === 200) {
        return {
          success: true,
          response: "File uploaded successfully.",
          statusCode: 200,
        };
      } else {
        return {
          success: false,
          response: "File upload failed.",
          statusCode: response.status,
        };
      }
    } catch (error) {
      return this.catchError(error);
    }
  }
}

export const apiRequest = new Requests();
