import React, { useState } from "react";
import { AddItemButton, ConditionalView } from "../../../utils/components";
import { Alert, Button, Card, Form } from "react-bootstrap";
import OrganizationListItem from "../Organization/OrganizationListItem";
import { updateObjectState, useOffCanvas } from "../../../utils/functions";
import SelectOrganizationsForm from "../../Forms/Organization/SelectOrganizationsForm";
import { FaExchangeAlt, FaPlus } from "react-icons/fa";

function EmployeeOrganizations({
  employee,
  setEmployee,
  permissions,
  postRequest,
  putRequest,
  getRequest,
  patchRequest,
  loaderCallback,
  configs,
  setCongigs,
}) {
  const { showOffCanvas } = useOffCanvas();

  const [filterTerm, setFilterTerm] = useState("");

  const organizations = employee.organizations || [];
  return (
    <div>
      <Card body={true} className={"mt-2 mb-3 py-3"}>
        <Card.Title>{employee.first_name}'s Organizations</Card.Title>
        <Card.Subtitle>
          These are is list of organizations that {employee.first_name} is a
          part of.
        </Card.Subtitle>
      </Card>
      <ConditionalView
        condition={organizations && organizations.length > 0}
        falseView={
          <Card body={true} className="text-center">
            <h5>{employee.first_name} is not part of any organization</h5>
          </Card>
        }
        trueView={
          <div>
            {permissions.MANAGE_EMPLOYEE_ORGANIZATION_TRANSITION &&
              configs?.other_organizations?.length > 0 && (
                <div className={"d-flex justify-content-end mt-4"}>
                  <Button
                    variant={"primary"}
                    onClick={() => {
                      showOffCanvas({
                        title: `Add ${employee.first_name} to an Organization`,
                        component: (
                          <SelectOrganizationsForm
                            organizations={configs.other_organizations || []}
                            onSubmit={(organizationIds) => {
                              putRequest(
                                `/employee/organizations/${employee.id}`,
                                loaderCallback,
                                (response) => {
                                  updateObjectState(setEmployee, {
                                    organizations: response.organizations,
                                  });
                                  updateObjectState(setCongigs, {
                                    other_organizations:
                                      response.other_organizations,
                                  });
                                },
                                {
                                  organization_ids: Array.from(organizationIds),
                                }
                              );
                            }}
                          />
                        ),
                      });
                    }}
                  >
                    <FaPlus className={"me-2"} />
                    {`Add ${employee.first_name} to an Organization`}
                  </Button>
                </div>
              )}

            <Form>
              <Form.Group controlId="employeeSearch" className={"my-4"}>
                <Form.Control
                  type="text"
                  value={filterTerm}
                  onChange={(event) => {
                    setFilterTerm(event.target.value);
                  }}
                  placeholder={"Search Organizations"}
                />
              </Form.Group>
            </Form>

            {permissions.MANAGE_EMPLOYEE_ORGANIZATION_TRANSITION &&
              configs?.other_organizations?.length > 0 && (
                <div className={"mt-4"}>
                  <Alert variant={"info"}>
                    <Alert.Heading>
                      Want to transfer {employee.first_name} to another
                      9rganization?
                    </Alert.Heading>
                    First, add them to the organization you want to transfer
                    them to and you will see an option to transfer them at the
                    bottom right of that organization below.
                  </Alert>
                </div>
              )}
            {organizations.map((organization, i) => {
              if (
                !organization.name
                  .toLowerCase()
                  .includes(filterTerm.toLowerCase())
              ) {
                return null;
              }
              return (
                <div key={i} className={"mb-4"}>
                  <OrganizationListItem
                    organization={organization}
                    permissions={permissions}
                    onChangeStatusClick={
                      permissions.MANAGE_EMPLOYEE_ORGANIZATION_TRANSITION &&
                      !organization.transfer_pending
                        ? () => {
                            getRequest(
                              `/employee/organization/status/${organization.map_id}`,
                              loaderCallback,
                              (response) => {
                                updateObjectState(setEmployee, {
                                  organizations: response.organizations,
                                });
                              },
                              true
                            );
                          }
                        : null
                    }
                    onTransferClick={
                      permissions.MANAGE_EMPLOYEE_ORGANIZATION_TRANSITION &&
                      !organization.transfer_pending
                        ? (org, comments) => {
                            postRequest(
                              `/employee/organization/transfer/${organization.map_id}`,
                              loaderCallback,
                              (response) => {
                                updateObjectState(setEmployee, {
                                  organizations: response.organizations,
                                });
                              },
                              {
                                comments: comments,
                              },
                              false,
                              false
                            );
                          }
                        : null
                    }
                  />
                </div>
              );
            })}
          </div>
        }
      />
    </div>
  );
}

export default EmployeeOrganizations;
