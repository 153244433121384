import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Card, Col, Row, Tab, Tabs } from "react-bootstrap";
import { ConditionalView, LoadingView } from "../../../utils/components";
import ClockInClockOutCard from "./ClockInClockoutCard";
import {
  updateObjectState,
  useAppModal,
  useOffCanvas,
} from "../../../utils/functions";
import InfoCard from "../../../utils/components/InfoCard";
import AttendanceCalendar from "./AttendanceCalendar";
import {
  dateFormats,
  formatDate,
  formDate,
  getUTCDateTime,
  time24Hrs,
  timeDiffCounter,
} from "../../../utils/dateTime";
import CounterCard from "../../../utils/components/CounterCard";
import { AppContext } from "../../../utils/components/AppContext";
import AttendanceForm from "../../Forms/Employee/AttendanceForm";
import EmployeesAttendanceView from "../Shared/EmployeesAttendanceView";

function AttendanceManagement({
  employee,
  setEmployee,
  getRequest,
  putRequest,
  patchRequest,
  deleteRequest,
  loaderCallback,
  navCallback,
  permissions,
  contextState,
}) {
  const { showAppModal } = useAppModal();
  const { updateContextState } = useContext(AppContext);
  const { showOffCanvas } = useOffCanvas();
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("listView");

  const updateAttendances = (response) => {
    const attendances = { ...employee.attendances };
    attendances[response.day] = response.attendances;

    updateObjectState(setEmployee, {
      attendances,
      last_attendance: response.last_attendance,
    });
  };

  return (
    <div>
      <Card>
        <Card.Header>
          <Card.Title>{`${employee.first_name}'s Attendance`}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col lg={9} className={"d-flex flex-column justify-content-around"}>
              <ConditionalView
                condition={employee.last_attendance}
                trueView={
                  <>
                    <Row>
                      <InfoCard
                        lg={4}
                        header={"Last Clocked in"}
                        colClass={"mb-3"}
                        body={
                          formatDate(
                            employee?.last_attendance?.clockin_time,
                            dateFormats.shortDateTime24Hr
                          ) || "N/A"
                        }
                      />
                      <InfoCard
                        lg={4}
                        header={"Last Clocked out"}
                        colClass={"mb-3"}
                        body={
                          employee?.last_attendance?.clockout_time
                            ? formatDate(
                                employee?.last_attendance?.clockout_time,
                                dateFormats.shortDateTime24Hr
                              )
                            : "N/A"
                        }
                      />
                      <CounterCard
                        header={"Time Worked"}
                        startTime={employee?.last_attendance?.clockin_time}
                        endTime={employee?.last_attendance?.clockout_time}
                      />
                    </Row>
                  </>
                }
                falseView={
                  <>
                    <h5 className={"text-center align-middle mt-5"}>
                      {`No attendance records to show for ${employee.first_name}`}
                    </h5>
                  </>
                }
              />
            </Col>
            <Col lg={3} className={""}>
              {permissions.MANAGE_CLOCKIN_CLOCKOUT && (
                <div className={"text-center align-middle"}>
                  <strong>{employee.mobile_device_id || "No ID Yet"}</strong>
                  <br />
                  <small>Mobile Device ID</small>
                  <br />
                  {employee.mobile_device_id && (
                    <Button
                      variant={"outline-danger"}
                      size={"sm"}
                      className={"mt-3"}
                      onClick={() => {
                        showAppModal({
                          title: `Reset Device ID for ${employee.first_name}?`,
                          component: (
                            <Alert variant={"warning"}>
                              Are you sure you want to reset the device ID for{" "}
                              {employee.first_name}? This will enable them to
                              clock in / out using a new or different device.
                            </Alert>
                          ),
                          truthyFunction: async () => {
                            await getRequest(
                              `/employee/${employee.id}/reset-device-id`,
                              setIsLoading,
                              (response) => {
                                updateObjectState(setEmployee, {
                                  mobile_device_id: null,
                                });
                              }
                            );
                          },
                        });
                      }}
                    >
                      Remove Device ID
                    </Button>
                  )}
                </div>
              )}

              {permissions.MANAGE_CLOCKIN_CLOCKOUTSS && (
                <ConditionalView
                  condition={
                    employee?.last_attendance?.clockin_time &&
                    !employee?.last_attendance?.clockout_time
                  }
                  trueView={
                    <>
                      <ClockInClockOutCard
                        headerText={"Clocked In"}
                        headerBkg={"bg-warning"}
                        bodyText={`Clock ${employee.first_name} Out`}
                        bodyBkg={"bg-danger"}
                        clockIn={false}
                        onClick={() => {
                          showAppModal({
                            title: `Clock Out for ${employee.first_name}?`,
                            component: (
                              <Alert variant={"warning"}>
                                Please note that this activity will be logged
                                and management can see that you{" "}
                                <strong>Clocked Out</strong> for them.
                              </Alert>
                            ),
                            truthyFunction: async () => {
                              await getRequest(
                                `/employee/${employee.id}/attendance/${employee.last_attendance.id}/clock-out`,
                                setIsLoading,
                                (response) => {
                                  updateAttendances(response);
                                },
                                true
                              );
                            },
                          });
                        }}
                      />
                    </>
                  }
                  falseView={
                    <>
                      <ClockInClockOutCard
                        headerText={"Clocked Out"}
                        headerBkg={"bg-info"}
                        bodyText={`Clock ${employee.first_name} In`}
                        bodyBkg={"bg-success"}
                        onClick={() => {
                          showAppModal({
                            title: `Clock In for ${employee.first_name}?`,
                            component: (
                              <Alert variant={"warning"}>
                                Please note that this activity will be logged
                                and management can see that you{" "}
                                <strong>Clocked In</strong> for them.
                              </Alert>
                            ),
                            truthyFunction: () => {
                              getRequest(
                                `/employee/${employee.id}/attendance/clock-in`,
                                setIsLoading,
                                (response) => {
                                  updateAttendances(response);
                                },
                                true
                              );
                            },
                          });
                        }}
                      />
                    </>
                  }
                />
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <LoadingView
        isLoading={isLoading}
        fullHeight={false}
        view={
          <Tabs
            id="attendance-management-tabs"
            className="mb-3"
            activeKey={activeTab}
            onSelect={(k) => setActiveTab(k)}
          >
            <Tab eventKey="listView" title="List View">
              <EmployeesAttendanceView
                attendancesEndpoint={`/employee/${employee.id}/attendance/paginated/records`}
                statsEndpoint={`/employee/${employee.id}/attendance/stats`}
                subTitle={`Attendance records for ${employee.first_name}`}
                getRequest={getRequest}
              />
            </Tab>
            <Tab eventKey="calendarView" title="Calendar View">
              <Card className={"mt-5"}>
                <AttendanceCalendar
                  activeDate={getUTCDateTime(
                    employee?.navigation?.active_month
                  )}
                  attendance={employee?.attendances}
                  navigation={employee.navigation}
                  attendanceInfo={employee.attendance_info}
                  navCallback={navCallback}
                  permissions={permissions}
                  contextState={contextState}
                  addCallback={(date) => {
                    updateContextState({
                      formData: {
                        date: formDate(date),
                      },
                    });
                    showOffCanvas({
                      title: "Add Attendance",
                      subtitle: `Add attandance time for ${employee.first_name} on this date`,
                      component: (
                        <AttendanceForm
                          onSubmit={(updateData) => {
                            putRequest(
                              `/employee/${employee.id}/attendance`,
                              setIsLoading,
                              (response) => {
                                updateAttendances(response);
                              },
                              updateData
                            );
                          }}
                        />
                      ),
                    });
                  }}
                  editCallback={(date, attendance) => {
                    updateContextState({
                      formData: {
                        date: formDate(date),
                        ...attendance,
                        clockin_time: time24Hrs(attendance.clockin_time),
                        clockout_time: time24Hrs(attendance.clockout_time),
                      },
                    });
                    showOffCanvas({
                      title: "Edit Attendance",
                      subtitle: `Edit attandance time for ${employee.first_name} on this date`,
                      component: (
                        <AttendanceForm
                          onSubmit={(updateData) => {
                            patchRequest(
                              `/employee/${employee.id}/attendance`,
                              setIsLoading,
                              (response) => {
                                updateAttendances(response);
                              },
                              updateData
                            );
                          }}
                        />
                      ),
                    });
                  }}
                  deleteCallback={(attendanceId) => {
                    showAppModal({
                      title: `Delete Attendance for ${employee.first_name} on this date?`,
                      component: (
                        <p>Are you sure you want to delete this attendance?</p>
                      ),
                      truthyFunction: () => {
                        deleteRequest(
                          `/employee/attendance/${attendanceId}`,
                          setIsLoading,
                          (response) => {
                            updateAttendances(response);
                          },
                          true
                        );
                      },
                    });
                  }}
                />
              </Card>
            </Tab>
          </Tabs>
        }
      />
    </div>
  );
}

export default AttendanceManagement;
