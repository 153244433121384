import React, { useEffect, useState } from "react";
import { toTitleCase, updateObjectState } from "../../../utils/functions";
import { Accordion, Card, Form, InputGroup, Tab, Tabs } from "react-bootstrap";
import { LoadingView } from "../../../utils/components";
import { AppBarChart } from "../../../utils/components/AppCharts";
import { FaSearch, FaTimes } from "react-icons/fa";

function OrgPerformanceStats({ getRequest, endpointPrefix }) {
  const tabKeysConfigs = {
    organization: {
      key: "organization",
      entityTypeName: "Entire Organization",
    },
    departments: {
      key: "departments",
    },
    branches: {
      key: "branches",
    },
    teams: {
      key: "teams",
    },
    levels: {
      key: "levels",
    },
    employee_types: {
      key: "employee_types",
    },
  };

  const [tabLoading, setTabLoading] = useState({});
  const [entitiesStats, setEntitiesStats] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [entities, setEntities] = useState({});
  const [fetchedTabs, setFetchedTabs] = useState(["organization"]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    getRequest(`/organization/entities`, null, async (resp) => {
      setEntities({ ...resp.entities, organization: [] });
    });
    getEntityStats("organization", "organization", "organization");
  }, []);

  const getEntityStats = async (endpoint, id_, tabKey) => {
    updateObjectState(setTabLoading, {
      [tabKey]: true,
    });
    await getRequest(
      `${endpointPrefix}/${endpoint}/${id_}`,
      null,
      async (resp) => {
        updateObjectState(setEntitiesStats, {
          [id_]: resp.stats,
        });
      }
    );
    updateObjectState(setTabLoading, {
      [tabKey]: false,
    });
  };

  const EntityStats = ({ stats, entityName, loading }) => {
    if (!stats) {
      return (
        <LoadingView isLoading={true} fullHeight={false} centerLoader={false} />
      );
    }
    return (
      <div>
        <LoadingView
          isLoading={loading}
          fullHeight={false}
          view={
            <div className={"mt-4"}>
              <Card
                body={true}
                className={"mb-3 fs-2 text-center border-primary border-2"}
              >
                <Card.Title>{stats.total_employees} Employees</Card.Title>
              </Card>
              <Card className={"mb-4"}>
                <Card.Header className={"py-3"}>
                  <Card.Title>{toTitleCase(entityName)} Totals</Card.Title>
                </Card.Header>
                <Card.Body>
                  <AppBarChart data={stats.totals} xName={"Column Total"} />
                </Card.Body>
              </Card>
              <Card className={"mb-4"}>
                <Card.Header className={"py-3"}>
                  <Card.Title>{toTitleCase(entityName)} Averages</Card.Title>
                </Card.Header>
                <Card.Body>
                  <AppBarChart data={stats.averages} xName={"Column Average"} />
                </Card.Body>
              </Card>
            </div>
          }
        />
      </div>
    );
  };

  return (
    <div>
      <Tabs className={"mb-2 pb-3"}>
        {Object.values(tabKeysConfigs).map((config, index) => {
          const entityItems = entities[config.key] || [];
          const entityKey = config.key;
          return (
            <Tab
              eventKey={config.key}
              title={toTitleCase(config.key)}
              key={index}
            >
              {entityKey === "organization" && (
                <EntityStats
                  stats={entitiesStats[config.key]}
                  entityName={config.key}
                  loading={tabLoading[config.key]}
                />
              )}

              {entityKey !== "organization" && (
                <div>
                  <InputGroup className={"my-4"}>
                    <InputGroup.Text>
                      <FaSearch />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      placeholder={`Search ${toTitleCase(config.key)}`}
                    />
                    <InputGroup.Text>
                      <FaTimes
                        className={"pointer"}
                        onClick={() => {
                          setSearchTerm("");
                        }}
                      />
                    </InputGroup.Text>
                  </InputGroup>

                  {entityItems.map((item, itemIndex) => {
                    const entityStats = entitiesStats[item.id || item];
                    const name = item.name || item;

                    if (
                      searchTerm &&
                      !name.toLowerCase().includes(searchTerm.toLowerCase())
                    ) {
                      return null;
                    }

                    return (
                      <Accordion key={itemIndex} className={"mb-3"}>
                        <Accordion.Item eventKey={`${itemIndex}`}>
                          <Accordion.Header
                            onClick={() => {
                              if (!entityStats) {
                                getEntityStats(
                                  config.key,
                                  item.id || item,
                                  item.id || item
                                );
                              }
                            }}
                          >
                            {item.name || item}
                          </Accordion.Header>
                          <Accordion.Body>
                            <EntityStats
                              stats={entityStats}
                              entityName={item.name || item}
                              loading={tabLoading[item.id]}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    );
                  })}
                </div>
              )}
            </Tab>
          );
        })}
      </Tabs>
    </div>
  );
}

export default OrgPerformanceStats;
