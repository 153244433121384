import React from "react";

function EmployeeVerticalHighlightCard({ employee }) {
  return (
    <div
      className={"d-flex flex-column align-items-center align-content-center"}
    >
      <img
        src={employee.profile_image}
        alt={employee.full_name}
        width={50}
        height={50}
        className={`rounded-circle`}
      />

      <p className={"text-center mt-3"}>
        <strong>{employee.full_name}</strong>
        <br />
        <small className={"placeholder-xs text-muted"}>
          {employee?.position?.title || "No Position"} - (
          {employee.employee_number})
        </small>
      </p>
    </div>
  );
}

export default EmployeeVerticalHighlightCard;
