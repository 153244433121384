import React from "react";
import { Button, Card } from "react-bootstrap";
import { dateFormats, formatDate } from "../../../utils/dateTime";
import EmployeeInfo from "../../EmployeeManagement";
import EmployeeHighlightCard from "../Employee/EmployeeHighlightCard";
import { TextWithLineBreaks } from "../../../utils/components";
import { FaEdit } from "react-icons/fa";

function Announcement({ announcement, canEdit }) {
  return (
    <div>
      <Card>
        <Card.Header className={"py-4"}>
          <div className={"d-flex justify-content-between"}>
            <div>
              <Card.Title>{announcement.title}</Card.Title>
              <br />
              <Card.Subtitle>
                Starts:{" "}
                {formatDate(
                  announcement.start_datetime,
                  dateFormats.shortDayShortMonthFullDayYearTime24Hr
                )}
                <br />
                Ends:{" "}
                {formatDate(
                  announcement.end_datetime,
                  dateFormats.shortDayShortMonthFullDayYearTime24Hr
                )}
              </Card.Subtitle>
            </div>
            {canEdit && (
              <div>
                <Button
                  className={"mr-2"}
                  variant={"outline-primary"}
                  onClick={() => {
                    canEdit(announcement);
                  }}
                >
                  <FaEdit className={"me-2"} />
                  Edit
                </Button>
              </div>
            )}
          </div>
        </Card.Header>
        <Card.Body>
          <Card.Text>
            <TextWithLineBreaks text={announcement.text} />
          </Card.Text>
        </Card.Body>
        <Card.Footer>
          <Card.Text>By:</Card.Text>
          <EmployeeHighlightCard employee={announcement.author_employee} />
        </Card.Footer>
      </Card>
    </div>
  );
}

export default Announcement;
