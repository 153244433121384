import React, { useContext } from "react";
import { Card, Row, Col, Table, Button, Image } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import { EmployeeList } from "./EmployeeCards";
import { AppRoutes } from "../../utils/appRoutes";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../utils/dateTime";
import { InfoDisplayTable } from "../../utils/components";
import { AppContext } from "../../utils/components/AppContext";

export const EmployeeWorkInfo = ({
  employee,
  canManage,
  onEditClick,
  allowSupervisorClick,
}) => {
  const navigate = useNavigate();
  const { contextState, updateContextState } = useContext(AppContext);
  const { labelsOverride } = contextState;

  return (
    <Card className={"rounded-3 py-3"}>
      <Card.Body>
        <h2 className={"mb-lg-1 mb-3 text-cente"}>{employee.full_name} </h2>
        <Row>
          <Col lg={6} className={""}>
            <Row>
              <Col
                className={
                  "d-flex justify-content-lg-end col-lg-2 col-5 pt-lg-5"
                }
              >
                <div
                  className="position-relative"
                  style={{ width: "120px", height: "120px" }}
                >
                  <Image
                    src={employee.profile_image}
                    alt="Employee"
                    className="rounded-circle img-fluid h-auto w-100"
                  />
                </div>
              </Col>

              <Col lg={10} className={""}>
                <InfoDisplayTable
                  data={{
                    Position: employee.position
                      ? employee.position.title
                      : "No Position Assigned",
                    [labelsOverride.employee_number || "Employee #"]:
                      employee.employee_number,
                    [labelsOverride.team || "Team"]: employee.team
                      ? employee.team.name
                      : `No ${labelsOverride.team || "Team"} Assigned`,
                    [labelsOverride.start_date || "Start Date"]: formatDate(
                      employee.start_date
                    ),
                    "Reports To": employee.team ? (
                      <EmployeeList
                        employees={[employee.team.supervisor]}
                        showIcon={false}
                        action={
                          allowSupervisorClick
                            ? (supervisor) => {
                                navigate(
                                  AppRoutes.Employee.paramLessPath +
                                    supervisor.id
                                );
                              }
                            : null
                        }
                      />
                    ) : (
                      `No ${
                        labelsOverride.team || "Team"
                      } or Supervisor Assigned`
                    ),
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col lg={6} className={""}>
            <InfoDisplayTable
              data={{
                Email: employee.work_email || "n/a",
                Phone: employee.work_phone || "n/a",
                "Date of Birth": formatDate(employee.date_of_birth),
                Address: employee.full_address,
                Gender: employee.gender,
              }}
            />
          </Col>
        </Row>
      </Card.Body>

      {canManage && (
        <Card.Footer>
          <div className={"d-flex justify-content-end mb-1"}>
            <Button
              onClick={() => {
                onEditClick(employee);
              }}
              variant={"outline-primary"}
              className="me-2"
            >
              <FaEdit className={"me-2"} />
              Edit
            </Button>
          </div>
        </Card.Footer>
      )}
    </Card>
  );
};
