import React, { useContext } from "react";
import { AddItemButton } from "../../../utils/components";
import { PositionsForm } from "../../Forms/Positions";
import { Card } from "react-bootstrap";
import { PositionsTable } from "../Positions";
import { useOffCanvas } from "../../../utils/functions";
import { AppContext } from "../../../utils/components/AppContext";

function ManagePositions({
  positions,
  setPositions,
  permissions,
  putRequest,
  getRequest,
  patchRequest,
  levels,
  config,
  loaderCallback,
}) {
  const { showOffCanvas } = useOffCanvas();
  const { updateContextState } = useContext(AppContext);

  return (
    <div>
      {permissions.MANAGE_POSITIONS && (
        <div className={"mb-3"}>
          <AddItemButton
            title={"New Position"}
            onClick={() => {
              updateContextState({
                formData: {
                  is_active: true,
                  for_single_person: false,
                  is_department_head: false,
                  is_organization_head: false,
                },
              });
              showOffCanvas({
                title: `Add a Position`,
                subtitle: `Add a new position to your organization`,
                component: (
                  <PositionsForm
                    levels={levels}
                    branches={config.branches}
                    departments={config.departments}
                    onSubmit={(updatedData) => {
                      putRequest(
                        "/people/positions",
                        loaderCallback,
                        (response) => {
                          setPositions([response.position, ...positions]);
                        },
                        updatedData
                      );
                    }}
                  />
                ),
              });
            }}
          />
        </div>
      )}

      <Card>
        <Card.Header>
          <Card.Title>Positions</Card.Title>
          <Card.Subtitle>
            <small>
              Positions within an organization reflect specific roles and
              responsibilities that contribute to the overall success of the
              company. By adding, editing, or deactivating positions, you can
              customize your organization's structure to best fit your needs.
              Examples of positions include sales representative, marketing
              manager, accountant, and CEO.
            </small>
          </Card.Subtitle>
        </Card.Header>
        <Card.Body>
          <PositionsTable
            positions={positions}
            getRequest={getRequest}
            canManage={permissions.MANAGE_POSITIONS}
            onEditClick={(position, index) => {
              updateContextState({
                formData: position,
              });
              showOffCanvas({
                title: `Edit ${position.title} Position`,
                subtitle: `Update the information for this position`,
                component: (
                  <PositionsForm
                    levels={levels}
                    branches={config.branches}
                    departments={config.departments}
                    onSubmit={(updatedData) => {
                      patchRequest(
                        `/people/positions/${position.id}`,
                        loaderCallback,
                        (response) => {
                          const currentPositions = positions;
                          currentPositions[index] = response.position;
                          setPositions(currentPositions);
                        },
                        updatedData
                      );
                    }}
                  />
                ),
              });
            }}
          />
        </Card.Body>
      </Card>
    </div>
  );
}

export default ManagePositions;
