import AppToasts from "./components/AppToasts";
import React, { useContext, useEffect } from "react";
import { AppContext } from "./components/AppContext";
import { apiRequest } from "./apiRequests";
import { FaEdit, FaPlus } from "react-icons/fa";
import {
  Badge,
  Button,
  Card,
  Image,
  Offcanvas,
  Spinner,
  Table,
} from "react-bootstrap";
import { appConfigs } from "../configs";

export function ConditionalView({ condition, trueView, falseView }) {
  if (condition) {
    return <>{trueView}</>;
  } else {
    return <>{falseView}</>;
  }
}

export function LoadingView({
  isLoading,
  view,
  centerLoader = true,
  text = "Please Wait...",
  addAppToasts = false,
  fullHeight = true,
  spinnerText = "Loading",
}) {
  // isLoading = true;
  return (
    <ConditionalView
      condition={isLoading}
      trueView={
        <div
          className={`text-center ${
            centerLoader ? "min-vh-100" : "mt-5 pt-5"
          } d-flex align-items-center justify-content-center flex-column`}
        >
          <Spinner animation="grow" role="status">
            <Image
              src={appConfigs.primaryLogo}
              alt={`${appConfigs.appName} logo`}
              height={"45"}
              width={"45"}
              className="align-self-center"
            />
          </Spinner>

          <div className={"mt-4"}>
            <small>{text}</small>
          </div>
        </div>
      }
      falseView={
        <div className={`${fullHeight ? "min-vh-100" : ""}`}>
          {view}
          {addAppToasts && <AppToasts />}
        </div>
      }
    />
  );
}

export function AddItemButton({ title, onClick }) {
  return (
    <div className={"pe-4 pt-2 pb-1 d-flex justify-content-end gap-0"}>
      <Button
        className={"rounded-3 p-2"}
        variant={"primary"}
        size={"sm"}
        style={{ position: "relative" }}
        onClick={() => onClick()}
      >
        <FaPlus className={"me-2"} />
        {title}
      </Button>
    </div>
  );
}

export function AppOffCanvasLocal({
  showOffCanvas,
  setShowOffCanvas,
  title,
  subtitle,
  component,
}) {
  return (
    <Offcanvas
      show={showOffCanvas}
      onHide={() => setShowOffCanvas(false)}
      placement={"end"}
      backdrop={"static"}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>{title}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className={"mb-5"}>{subtitle}</div>
        <>{component}</>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export function InfoDisplayTable({
  data,
  title,
  subtitle,
  canEdit,
  onEditClick,
  actionComponent = null,
  boldFieldNames = false,
  boldValues = false,
}) {
  return (
    <div>
      <div className={"d-flex justify-content-between mb-3"}>
        <h5>{title}</h5>
        {canEdit && (
          <div>
            {actionComponent ? (
              actionComponent
            ) : (
              <Button
                onClick={() => {
                  onEditClick();
                }}
                variant={"outline-primary"}
                className="me-2"
              >
                <FaEdit className={"me-2"} />
                Edit
              </Button>
            )}
          </div>
        )}
      </div>
      <div className={subtitle ? "mb-3" : ""}>
        <p>{subtitle}</p>
      </div>
      <Table borderless>
        <tbody>
          {Object.keys(data).map((key) => {
            return (
              <tr key={key} className={"align-middle mb-3"}>
                <td className={boldFieldNames ? "fw-bold" : ""}>{key}</td>
                <td className={boldValues ? "fw-bold" : ""}>
                  {data[key] || "None"}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

export function StatusBadge({ status, className = "" }) {
  const success = ["approved", "auto approved", "active", "completed"];
  const rejected = ["rejected", "cancelled", "inactive", "failed"];
  const pending = ["pending"];
  const processing = ["processing", "in progress"];
  const awaiting = ["awaiting attestation"];

  let variant;
  let txtColor = "text-white";

  const lowerStatus = status?.toLowerCase() || "";

  if (success.includes(lowerStatus)) {
    variant = "success";
  } else if (rejected.includes(lowerStatus)) {
    variant = "danger";
  } else if (pending.includes(lowerStatus)) {
    variant = "warning";
    txtColor = "text-dark";
  } else if (processing.includes(lowerStatus)) {
    variant = "info";
    txtColor = "text-dark";
  } else if (awaiting.includes(lowerStatus)) {
    variant = "primary";
    txtColor = "text-white";
  } else {
    variant = "secondary";
  }

  return (
    <Badge bg={variant} className={`p-2 ${txtColor} ${className}`}>
      {status}
    </Badge>
  );
}

export function TextWithLineBreaks({ text, textClassNames = "" }) {
  // Split text by newline characters and map to JSX
  if (!text) {
    return null;
  }
  const textWithLineBreaks = text.split("\n").map((line, index, array) => (
    <React.Fragment key={index}>
      <span className={textClassNames}>
        {line}
        {index !== array.length - 1 && <br />}
      </span>
    </React.Fragment>
  ));

  return <span>{textWithLineBreaks}</span>;
}

export function Loader({
  fullHeight = false,
  text = "Please Wait...",
  spinnerText = "Loading...",
}) {
  return (
    <div
      className={`text-center ${
        fullHeight ? "min-vh-100" : "mt-5 pt-5"
      } d-flex align-items-center justify-content-center flex-column`}
    >
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">{spinnerText}</span>
      </div>
      <div className={"mt-3"}>
        <small>{text}</small>
      </div>
    </div>
  );
}

export function NoInfoCard({ text }) {
  return (
    <Card className={"my-4 py-3"}>
      <Card.Body>
        <p className={"text-center"}>{text}</p>
      </Card.Body>
    </Card>
  );
}
